@import "bootstrap-reboot";
@import "bootstrap-grid";
@import "bootstrap-utilities";

:root {
  --color-dark:#2c2c2c;
  --color-primary: #d71920;
  --main-font: open-sans, sans-serif;
  --heading-font: le-monde-livre-std, serif;
}

body {
  background-color: #fbfbfb;
  color: #535353;
  font-family: var(--main-font);
  font-size: 1rem;
  line-height: 1.7;
}

a {
  color: #000;
  transition: color .25s ease-in-out;
}

a:hover {
  color: var(--color-primary);
  text-decoration: none;
}

a:focus,
a:active {
  outline: none;
  text-decoration: none;
  text-shadow: none;
}

section {
  padding-bottom: 35px;
  padding-top: 35px;
}

.clear {
  clear: both;
}

img {
  height: auto;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  line-height: 1.4;
}

h1, h2 {
  font-family: var(--main-font);
  font-weight: 400;
}

h3, h4, h5, h6 {
  font-family: var(--heading-font);
  font-style: normal;
  line-height: 1.6;
}

h1,
.page-title {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.9rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

h4 {
  font-size: 1.2rem;
  font-weight: 400;
}

h5 {
  font-size: 1rem;
  font-weight: 400;
}

h6 {
  font-size: 1rem;
  font-weight: 400;
}

body.one-page {
  overflow-x: hidden;
}

.hg_ovhidden {
  overflow: hidden;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}


/*dash-list - style for unordered lists */
ul.dash-list,
ul.dash-list ul {
  list-style: none;
  padding: 0;
}

ul.dash-list ul {
  margin-bottom: 5px;
  margin-top: 10px;
}

ul.dash-list li {
  line-height: 1.6;
  padding: 4px 0;
}

ul.dash-list li::before {
  background: #fe6060;
  content: '';
  display: inline-block;
  height: 2px;
  margin-right: 12px;
  vertical-align: middle;
  width: 8px;
}

/* Custom Colors */
.text-white {
  color: #fff;
}

.tcolor {
  color: var(--color-primary) !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #2d2d2d !important;
}

.gray {
  color: #7c7c7c !important;
}

.gray2 {
  color: #545d60 !important;
}

.light-gray {
  color: #fbfbfb;
}

.light-gray2 {
  color: #dedede;
}

.light-gray3 {
  color: #c8c8c8;
}

.dark-gray {
  color: #343434 !important;
}

.orange {
  color: #e74c3c !important;
}

.blue {
  color: #3498db !important;
}

.b-gray {
  border-bottom-color: #898989 !important;
}

/* Backgrounds Colors */
.bg-white {
  background-color: #fff !important;
}

.tbg {
  background-color: var(--color-primary) !important;
}

.bg-gray {
  background-color: #6b6b6b !important;
}

.bg-lightgray {
  background-color: #fbfbfb !important;
}

.bg-lightgray2 {
  background-color: #FEFEFE !important;
}

.bg-dark-gray {
  background-color: #353535 !important;
}

.bg-black-gray {
  background-color: #1b1b1b !important;
}

/* Colored sections */
.colored-section[data-color="darkgray"] {
  background-color: #1a1c25;
  color: #fff;
}

h4[data-color="white"] {
  color: #fff;
}


/* Opacity */
.op-3 {
  opacity: 0.3 !important;
}

.text-shadow {
  text-shadow: 0 2px 1px #000;
}

.vh-100 {
  height: 100vh;
}


/* Page Subheader Colors */
.page-subheader.uh_flat_dark_blue,
.kl-slideshow.uh_flat_dark_blue {
  background-color: #345370;
}

.page-subheader.uh_light_blue_-_flat,
.kl-slideshow.uh_light_blue_-_flat {
  background-color: #26ade4;
}

.page-subheader.uh_neutral_color,
.kl-slideshow.uh_neutral_color {
  background-color: #607d8b;
}

.page-subheader.uh_light_gray,
.kl-slideshow.uh_light_gray {
  background-color: #e0e0e0;
}

.page-subheader.uh_flat_redish,
.kl-slideshow.uh_flat_redish {
  background-color: #db4437;
}

.page-subheader.uh_flat_beige,
.kl-slideshow.uh_flat_beige {
  background-color: #a88c85;
}

.uh_hg_def_header_style,
.hg_def_header_style,
.page-subheader.hg_def_header_style,
.kl-slideshow.hg_def_header_style,
.page-subheader.uh_hg_def_header_style,
.kl-slideshow.uh_hg_def_header_style {
  background-color: #222222;
}


/* Min Height */
.min-200 {
  min-height: 200px !important;
}

.min-300 {
  min-height: 300px !important;
}

.min-380 {
  min-height: 380px !important;
}

.min-330 {
  min-height: 330px !important;
}

.min-370 {
  min-height: 370px !important;
}

.min-400 {
  min-height: 400px !important;
}

.min-450 {
  min-height: 450px !important;
}

.min-500 {
  min-height: 500px !important;
}

.min-600 {
  min-height: 600px !important;
}

.min-700 {
  min-height: 700px !important;
}

.min-800 {
  min-height: 800px !important;
}

.min-820 {
  min-height: 820px !important;
}

/* Max Height */
.max-300 {
  max-height: 300px !important;
}

/* Heights */
.h-260 {
  height: 260px !important;
}

.h-300 {
  height: 300px !important;
}

.h-320 {
  height: 320px !important;
}

.h-370 {
  height: 370px !important;
}

.h-520 {
  height: 520px !important;
}

.h-545 {
  height: 545px !important;
}

.h-400 {
  height: 400px !important;
}

.h-420 {
  height: 420px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-600 {
  height: 600px !important;
}

.h-615 {
  height: 615px !important;
}

.h-625 {
  height: 625px !important;
}

.h-700 {
  height: 700px !important;
}

.h-720 {
  height: 720px !important;
}

.h-750 {
  height: 750px !important;
}

.h-760 {
  height: 760px !important;
}

.h-800 {
  height: 800px !important;
}

/* Align Styles */
.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright {
  float: right;
  margin: 0 0 10px 20px;
}

.alignleft {
  float: left;
  margin: 0 20px 10px 0;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.textcenter {
  text-align: center !important;
}

.textright {
  text-align: right !important;
}

.textleft {
  text-align: left !important;
}

.vertical-aligned {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.center-block {
  padding-top: calc(100vh - 50%);
}

.bottom-block {
  bottom: 50px;
  position: absolute;
  width: 100%;
}

.absolute {
  position: absolute;
}

.t-underline {
  text-decoration: underline;
}

blockquote {
  border-left: 5px solid var(--color-primary);
  padding-left: 30px;
}

blockquote.blockquote-reverse,
blockquote.float-right {
  border-left: 0;
  border-left: none;
  border-right: 5px solid var(--color-primary);
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
}


/* Font Family */
.kl-font-alt {
  font-family: var(--heading-font)
}

.kl-font-main {
  font-family: var(--main-font)
}


/* Gutter Sizes - Medium */
.row.gutter-md {
  margin-left: 0;
  margin-right: 0;
}

.row.gutter-md > [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

/* Equalizer classes used for first or last elements in a row to align to fixed-width
containers of the page, from inside of a full-width section and gutter */
/* Gutter Sizes - zero gutter on container fluid */
.row.gutter {
  margin-left: -15px;
  margin-right: -15px;
}

.row.gutter > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .hg_col_eq_first {
    padding-left: calc((100vw - 750px) / 2);
  }

  .hg_col_eq_last {
    padding-right: calc((100vw - 750px) / 2);
  }

  /* Gutter Sizes - Small */
  .row.gutter-sm {
    margin-left: -15px;
    margin-right: -15px;
  }

  .row.gutter-sm > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Gutter Sizes - Extra small */
  .row.gutter-xs {
    margin-left: -5px;
    margin-right: -5px;
  }

  .row.gutter-xs > [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* Gutter Sizes - zero gutter */
  .row.gutter-0 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .row.gutter-0 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .hg_col_eq_first {
    padding-left: calc((100vw - 970px) / 2);
  }

  .hg_col_eq_last {
    padding-right: calc((100vw - 970px) / 2);
  }

  /* Gutter Sizes - Medium */
  .row.gutter-md {
    margin-left: -25px;
    margin-right: -25px;
  }

  .row.gutter-md > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {
  /* Gutter Sizes - Large */
  .row.gutter-lg {
    margin-left: -45px;
    margin-right: -45px;
  }

  .row.gutter-lg > [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hg_col_eq_first {
    padding-left: calc((100vw - 1170px) / 2);
  }

  .hg_col_eq_last {
    padding-right: calc((100vw - 1170px) / 2);
  }

  body.boxed-layout .hg_col_eq_first {
    padding-left: 30px;
  }
}

/* Assure no X overflow is happening in fluid container */
.container-fluid.no-pad-cols .row,
.row.no-pad-cols {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid.no-pad-cols,
.container-fluid.no-pad-cols > .row > div[class*="col-"],
.no-pad-cols > div[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}


.o-hidden {
  overflow: hidden;
}

.inline-block {
  display: inline-block !important;
}

/* Justified blocks */
.justified-blocks {
  text-align: justify;
}

.justified-blocks .jsf-block {
  display: inline-block;
}

.justified-blocks .jsf-stretch {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%;
}


/* PAGE PRELOADER */
.no-js body.preloader {
  display: none;
}

body.preloader:before {
  align-items: center;
  background-color: #fff;
  content: '';
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 9998;
}

body.preloader:after {
  animation: preloader-pulsating-circle 1s ease-out;
  animation-iteration-count: infinite;
  border: 2px solid var(--color-primary);
  border-radius: 30px;
  content: '';
  height: 42px;
  left: calc(50% - 21px);
  opacity: 0;
  position: absolute;
  top: calc(50% - 21px);
  width: 42px;
  z-index: 9999;
}

@keyframes preloader-pulsating-circle {
  0% {
    opacity: 0.0;
    transform: scale(0.1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

/* end PAGE PRELOADER */


/* Circled Icon */
.circled-icon {
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  box-sizing: content-box;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 46px;
}

.circled-icon:before {
  display: block;
  line-height: 46px;
  vertical-align: middle;
}

.circled-icon.ci-xsmall {
  height: 22px;
  line-height: 22px;
  width: 22px;
}

.circled-icon.ci-xsmall:before {
  line-height: 23px;
}

.circled-icon.ci-small {
  height: 36px;
  line-height: 36px;
  width: 36px;
}

.circled-icon.ci-small:before {
  line-height: 36px;
}

.circled-icon.ci-large {
  height: 76px;
  line-height: 76px;
  width: 76px;
}

.circled-icon.ci-large:before {
  line-height: 76px;
}

.circled-icon.ci-faded {
  border-color: rgba(255, 255, 255, .5);
}

ul.fancy-list,
ul.fancy-list ul {
  color: #888888;
  list-style: none;
  padding: 0
}

ul.fancy-list ul {
  margin-top: 10px;
}

ul.fancy-list li {
  border-top: 1px solid #e6e6e6;
  line-height: 1.4;
  padding: 12px 0;
}

ul.fancy-list li:first-child {
  border-top: 0;
}

ul.fancy-list[data-columns="2"] li:nth-child(2) {
  border-top: 0;
}

ul.fancy-list.pluses li::before {
  content: '+';
  font-family: var(--main-font), Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  opacity: .8;
}

ul.fancy-list li a {
  color: #45474d;
  display: block;
  font-size: 13px;
  line-height: 1.6;
  position: relative;
  text-decoration: none;
}

ul.fancy-list li a:hover {
  color: #fe6060;
}

ul.fancy-list.right-icon li::before,
ul.fancy-list.right-icon.pluses li::before {
  display: none;
}

.bottom-border {
  border-bottom: 1px solid #e8e8e8;
}


/* Main Menu Badges */
.main-nav ul .zn-mega-new-item {
  background-color: var(--color-primary);
  border-radius: 2px;
  color: #fff;
  font-size: 10px;
  line-height: 1;
  padding: 5px;
  position: absolute;
  right: 3px;
  text-transform: none;
  text-transform: initial;
  top: 50%;
  transform: translateY(-50%);
}

.main-nav ul ul .zn-mega-new-item {
  left: auto;
  right: 10px;
  text-transform: uppercase;
}

.main-nav ul ul.sub-menu li a:not(:only-child) .zn-mega-new-item {
  right: 30px;
}

.main-nav .zn_mega_container ul .zn-mega-new-item {
  right: 3px;
}


/* Scroll to top button */
#totop {
  background-color: #404040;
  border-radius: 2px;
  bottom: 15%;
  color: #fff;
  display: block;
  font-size: 11px;
  font-weight: 900;
  line-height: 1;
  opacity: 0;
  padding: 14px 0;
  position: fixed;
  right: -60px;
  text-align: center;
  width: 49px;
  z-index: 111;
}

#totop.show {
  opacity: 0.7;
  right: 10px;
}

#totop:hover {
  opacity: 1;
}
#totop svg {
  width: 100%;
}

/*  Sparkles */
#sparkles,
.th-sparkles {
  display: none;
}

.th-sparkles-visible .th-sparkles {
  display: block;
}

.th-sparkles > span {
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%);
  display: block;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  height: 15px;
  position: absolute;
  width: 15px;
}

.th-sparkles .shiny-spark1 {
  height: 20px;
  opacity: .4;
  width: 20px;
}

.th-sparkles .shiny-spark2 {
  height: 10px;
  opacity: .7;
  width: 10px;
}

.th-sparkles .shiny-spark3 {
  height: 30px;
  opacity: .1;
  width: 30px;
}

.th-sparkles .shiny-spark4 {
  height: 3px;
  opacity: .9;
  width: 3px;
}

.th-sparkles .shiny-spark5 {
  height: 15px;
  opacity: .3;
  width: 15px;
}

.th-sparkles .shiny-spark6 {
  height: 25px;
  opacity: .5;
  width: 25px;
}


/* page title */
.page-title,
.subtitle {
  border-bottom: 1px solid #cbcbcb;
  line-height: 1.4;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.page-title {
  color: #050505;
  font-size: 36px;
  margin-top: 0;
}

.subtitle {
  color: #595959;
  font-size: 36px;
  font-weight: 300;
}

.wgt-title {
  border-bottom: 2px solid #EDEDED;
  color: #454545;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.3;
  margin-bottom: 35px;
  padding-bottom: 10px;
}

.wgt-title > span {
  border-bottom: 2px solid var(--color-primary);
  display: inline-block;
  margin-bottom: -12px;
  padding-bottom: 10px;
}


/* support panel */
.panel-checkbox {
  display: none;
}

.support_panel {
  background: #f9f9f9;
  box-shadow:0 0 14px rgba(0, 0, 0, .1) inset;
  height: auto;
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: max-height .2s ease-out;
  z-index: 1;
}

#support_p:checked + .support_panel {
  margin-top: 0;
  max-height: 1000px;
  transition: max-height .4s ease-out;
}

#sliding_panel.opened {
  margin-top: 0;
}

.support_panel .container {
  padding: 15px 15px 25px;
}

#close_sliding_panel {
  background: #CCC;
  display: block;
  height: 14px;
  padding: 40px 10px 10px 10px;
  position: absolute;
  right: 14px;
  top: 0;
  width: 14px;
}

#close_sliding_panel:hover {
  background: #ddd;
}

.support-info {
  color:#000;
  display: inline-block;
  margin-right: 4px;
  opacity: 0.8;
}

.support-info.opened {
  display: none;
}

#support_p:checked .support-info.opened {
  display: none;
}

.topnav-item.spanel-label {
  margin-bottom: 0;
}

.topnav-item.spanel-label span {
  display: inline-block;
}

.support-panel-close {
  background: rgba(0, 0, 0, .08);
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 28px;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: 30px;
  text-align: center;
  top: 30px;
  transform: scale(0.1);
  transition: all .15s ease-in-out;
  width: 50px;
  z-index: 2;
}

#support_p:checked + .support_panel .support-panel-close {
  transform: scale(1);
}

.support-panel-close:hover {
  background: rgba(0, 0, 0, .15);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, .15);
}


/* Buttons styles */
.btn:focus,
.btn.focus {
  box-shadow: none;
}

/*** Btn Lined (ghost) */
.btn-lined {
  background: none;
  border-color: #fff;
  border-radius: 0;
  border-style: solid;
  border-width: 2px;
  box-shadow: none;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  padding: 7px 20px;
  text-shadow: none;
  transition: all .1s ease-in-out;
}
form button {
  align-self: center;
  margin-left: auto;
  margin-right: 15px;
}

.btn-lined:hover {
  border-color: rgba(255, 255, 255, .7);
  color: #fff;
}

/* Btn Lined - Skewed style */
.btn-lined.btn-skewed {
  background: none;
  border: 0;
  position: relative;
}

.btn-lined.btn-skewed:before {
  border: 2px solid #fff;
  border-radius: 4px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: skewX(-8deg);
}

.btn.btn-dark {
  color: var(--color-dark);
}

.btn.btn-dark:before {
  border-color: var(--color-dark);
  transition:transform .3s, border-color .3s, background .3s
}

.btn > span {
  z-index:5;
  position:relative
}

.btn.btn-dark:hover {
  color:#fff
}

.btn-lined.btn-dark:hover:before {
  background:var(--color-dark);
  border-color:transparent;
}

.btn-lined.lined-dark {
  border-color: #242424;
  color: #242424;
}

.btn-lined.lined-dark:hover {
  color: #343434;
}

.btn-lined.lined-dark.btn-skewed {
  background: none;
  border: 0;
  position: relative;
}

.btn-lined.lined-dark.btn-skewed:before {
  border: 2px solid #242424;
  border-radius: 4px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: skewX(-8deg);
}

.btn-lined.lined-dark.btn-skewed:hover:before {
  border-color: rgba(0, 0, 0, .7);
}

/* Btn Lined Dark (ghost) */
.btn-lined.lined-gray {
  border-color: #8E8E8E;
  color: #8E8E8E;
}

.btn-lined.lined-gray:hover {
  color: #454545;
}

/* Btn Lined Gray - Skewed style */
.btn-lined.lined-gray.btn-skewed {
  background: none;
  border: 0;
  position: relative;
}

.btn-lined.lined-gray.btn-skewed:before {
  border: 2px solid #8E8E8E;
  border-radius: 4px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: skewX(-8deg);
}

.btn-lined.lined-gray.btn-skewed:hover:before {
  border-color: rgba(0, 0, 0, .4);
}

/* Button Lined Full Light */
.btn-lined.lined-full-light {
  background: rgba(255, 255, 255, 0.8);
  border-color: #fff;
  color: #242424;
}

.btn-lined.lined-full-light:hover {
  background-color: #fff;
  color: #343434;
}

/* Button Lined Full Dark */
.btn-lined.lined-full-dark {
  background: rgba(0, 0, 0, 0.8);
  border-color: #000;
  color: #e0e0e0;
}

.btn-lined.lined-full-dark:hover {
  background-color: #000;
  color: #f9f9f9;
}

/* Button Lined Custom */
.btn-lined.lined-custom {
  border-color: var(--color-primary);
  color: #000000;
}

.btn-lined.lined-custom:hover {
  border-color: #9c1617;
}

/*** Filled buttons */
.btn-fullwhite,
.btn-fullblack,
.btn-fullcolor {
  border-width: 0;
}

.btn-fullwhite,
.btn-fullblack,
.btn-fullcolor,
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-link {
  border-radius: 0;
  border-style: solid;
  box-shadow: none;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.6;
  padding: 10px 20px;
  text-shadow: none;
  transition: background-color .15s ease-out;
  display: inline-block;
}

/*** Btn FullColor */
.btn-fullcolor,
.btn-fullcolor:focus {
  background-color: var(--color-primary);
  color: #fff;
}

.btn-fullcolor:hover {
  color: #fff;
}

/*** Btn Fullwhite */
.btn-fullwhite {
  background-color: #fff;
  color: #242424;
  border: 4px solid #d71920;
  box-shadow: 1px 1px 14px -7px #000;
}

.btn-fullwhite:hover {
  background-color: rgba(255, 255, 255, 0.85);
  color: #353535;
  border: 4px solid #242424;
}

/*** Btn Fullblack */
.btn-fullblack {
  background-color: #090909;
  color: #f9f9f9;
}

.btn-fullblack:hover {
  background-color: rgba(0, 0, 0, 0.85);
  color: #eee;
}

.btn-fullcolor:hover, .btn-fullcolor.btn-skewed:hover:before, .cart-container .buttons .button.wc-forward:hover {
  background-color: #e5e5e5;
  color:#333
}

/* Filled buttons - Skewed style */
.btn-fullwhite.btn-skewed,
.btn-fullblack.btn-skewed,
.btn-fullcolor.btn-skewed,
.btn-fullwhite.btn-skewed:hover,
.btn-fullblack.btn-skewed:hover,
.btn-fullcolor.btn-skewed:hover {
  background: none;
  border: 0;
  position: relative;
  z-index: 0;
}

.btn-fullwhite.btn-skewed:before,
.btn-fullblack.btn-skewed:before,
.btn-fullcolor.btn-skewed:before {
  border-radius: 4px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: skewX(-8deg);
  transition: background-color .2s ease-in-out;
  z-index: -1;
}

/*** Btn FullColor */
.btn-fullcolor.btn-skewed:before {
  background-color: var(--color-primary);
}

/*** Btn Fullwhite */
.btn-fullwhite.btn-skewed:before {
  background-color: #fff;
}

.btn-fullwhite.btn-skewed:hover:before {
  background-color: rgba(255, 255, 255, 0.85);
}

/*** Btn Fullblack */
.btn-fullblack.btn-skewed:before {
  background-color: #090909;
}

.btn-fullblack.btn-skewed:hover:before {
  background-color: rgba(0, 0, 0, 0.85);
}

/* Btn sizing presets */
.btn-group-lg > .btn,
.btn-lg {
  font-size: 18px;
  line-height: 1.3333333;
  padding: 20px 30px;
}

.btn-group-lg > .btn.btn-lined,
.btn-lg.btn-lined {
  padding: 18px 30px;
}

.btn-group-md > .btn,
.btn-md {
  font-size: 14px;
  line-height: 1.3333333;
  padding: 16px 25px;
}

.btn-group-md > .btn.btn-lined,
.btn-md.btn-lined {
  padding: 14px 25px;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  padding: 5px 10px 4px;
}

.btn-group-sm > .btn.btn-lined,
.btn-sm.btn-lined {
  padding: 3px 10px 2px;
}

.btn-group-xs > .btn,
.btn-xs {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
  padding: 1px 5px;
}

.btn-group-xs > .btn.btn-lined,
.btn-xs.btn-lined {
  padding: 1px 5px;
}

/* Button element */
.btn-element > span,
.btn-element > i {
  display: inline-block;
  vertical-align: middle;
}

.btn-element > span + i {
  margin-left: 10px
}

.btn-element > i + span {
  margin-left: 10px
}

/* Small hack for icon font */
[data-zniconfam]:before,
[data-zniconfam] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.hg_separator {
  border-top-color: #e2e2e2;
  border-top-style: solid;
  border-top-width: 1px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.hg_separator.style2 {
  border-top-color: #ededed;
  border-top-width: 4px;
  margin-bottom: 45px;
  margin-top: 30px;
}

.section-shadow {
  box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.1), inset 0 2px 0px rgba(0, 0, 0, 0.07), inset 0 3px 0px rgba(0, 0, 0, 0.03);
}

body .kl-ioscaption--style4 .more:before {
  background: rgba(205, 33, 34, 0.7);
  border-color: var(--color-primary);
}

body .kl-ioscaption--style4 .more:hover:before {
  background: rgba(205, 33, 34, 0.9);
}

/*** NEW MENU SYSTEM ***/
.main-nav {
  display: none;
}

.hoverTrigger {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
}

.ht_end {
  right: 0;
}
.ht_start {
  left: 0;
}

@media (min-width: 992px) {
  .main-nav {
    display: block;
  }

  .main-nav ul {
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .main-nav > ul:after,
  .main-nav > ul:before {
    content: " ";
    display: table
  }

  .main-nav > ul:after {
    clear: both
  }

  .main-nav ul li {
    margin-bottom: 0;
  }

  .main-nav ul li a {
    display: block;
    font-family: var(--main-font);
    font-weight: 400;
  }

  /* FIRST LEVEL */
  .main-nav > ul > li {
    float: left;
    margin-left: 5px;
    margin-top: 0;
  }

  .main-nav > ul > li:not(.menu-item-mega-parent) {
    position: relative;
  }

  .main-nav > ul > li:first-child {
    margin-left: 0;
  }

  .main-nav > ul > li > a {
    color: var(--color-dark);
    font-family: var(--main-font);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    padding: 6px 12px;
    position: relative;
    overflow:hidden;
    text-transform: uppercase;
    transition: color .3s ease-in-out, transform .2s ease-in-out;
    z-index: 1;
  }

  @keyframes navOnHover {
    0% {
      transform: translateX(-102%);
    }
    100% {
      transform: translateX(0%);
    }
  }


  @keyframes navOffHover {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(102%);
    }
  }

  .main-nav > ul > li > a:before{
    background-color: var(--color-primary);
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(102%);
    transition: transform .3s ease-in-out;
    animation: navOffHover .3s ease-in-out forwards;
    width: 100%;
    height:100%;
    z-index: -1;
  }

  .main-nav > ul > li > a.currentPage,
  .main-nav > ul > li > a:hover,
  .main-nav > ul > li:hover > a {
    color: #fff;
    text-decoration: none;
  }

  .main-nav > ul > li:hover > a:before,
  .main-nav > ul > li > a.currentPage:before {
    animation: navOnHover .3s ease-in-out forwards;
  }

  .hoverTrigger:hover {
    width:100%;
    z-index:3
  }


  /* FIRST SUB-LEVEL */
  .main-nav ul ul.sub-menu li {
    position: relative;
  }

  .main-nav ul ul.sub-menu ul {
    margin-top: 0;
    top: 100%;
  }

  .main-nav ul ul.sub-menu li a:not(:only-child):after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    height: 2px;
    margin-top: -1px;
    opacity: .6;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 10px;
  }

  .main-nav ul ul.sub-menu li:hover a:not(:only-child):after {
    background-color: #fff;
  }

  .main-nav ul ul.sub-menu li a {
    border-bottom-color: rgba(0, 0, 0, 0.03);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #757575;
    font-size: 12px;
    min-width: 235px;
    padding: 15px 20px;
    position: relative;
    text-transform: uppercase;
    transition: background .2s;
  }

  .main-nav ul ul.sub-menu li a[title="separator"] {
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.05) !important;
    height: 5px;
    padding: 0;
    text-indent: -2999px;
  }

  .main-nav ul ul.sub-menu li.active > a,
  .main-nav ul ul.sub-menu li:hover > a,
  .main-nav .sub-menu .currentPage {
    background: rgb(35 31 32);
    color: #ffffff;
  }

  /* REST OF SUB-LEVELS */
  .main-nav ul ul.sub-menu,
  .main-nav .hg_mega_container {
    background: #f5f5f5;
    box-shadow: 0 0 4px rgba(0, 0, 0, .4);
    position: absolute;
  }

  .main-nav > ul > li > ul.sub-menu:before {
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    top: -10px;
    width: 100%;
  }

  /* fix for top hover */
  .main-nav > ul > li > ul.sub-menu:after {
    border: 6px solid transparent;
    border-bottom-color: #f5f5f5;
    border-bottom-width: 5px;
    content: '';
    display: block;
    left: 14px;
    position: absolute;
    top: -11px;
  }

  .main-nav ul ul.sub-menu li:hover > ul {
    left: 100%;
    opacity: 1;
    top: 0;
  }

  /* MEGAMENU OVERRIDES */
  .main-nav .hg_mega_container a.zn_mega_title[href="#custom-invisible"],
  .main-nav .hg_mega_container li:last-child a.zn_mega_title[href="#custom-invisible"] {
    color: transparent;
  }

  .main-nav .hg_mega_container {
    background: #f5f5f5;
    background: rgba(245, 245, 245, 0.95);
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 4px rgba(0, 0, 0, .4);
    margin-top: 10px;
    padding: 6px 0;
    padding: 20px 0;
    position: absolute;
  }

  .main-nav .hg_mega_container:before {
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    top: -10px;
    width: 100%;
  }

  /* fix for top hover */
  .main-nav .hg_mega_container li a {
    color: #757575;
    font-size: 13px;
    padding: 10px 0;
    position: relative;
    text-transform: uppercase;
  }

  .main-nav .hg_mega_container li a:not(.zn_mega_title):hover,
  .main-nav .hg_mega_container li.active > a:not(.zn_mega_title) {
    color: #323232;
  }

  .main-nav .hg_mega_container li a:not(.zn_mega_title):before {
    background-color: var(--color-primary);
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: width .15s cubic-bezier(0.680, 0, 0.265, 1);
    width: 0;
  }

  .main-nav .hg_mega_container li a:not(.zn_mega_title):hover:before,
  .main-nav .hg_mega_container li.active > a:not(.zn_mega_title):before {
    width: 100%;
  }

  .main-nav .hg_mega_container .zn_mega_title_hide {
    display: none;
  }

  .main-nav .hg_mega_container .zn_mega_title_hide.v-hidden {
    display: block;
    visibility: hidden;
  }

  .main-nav .hg_mega_container li a.zn_mega_title,
  .main-nav div.hg_mega_container ul li:last-child > a.zn_mega_title {
    border-bottom-color: rgba(0, 0, 0, 0.1);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #333;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 12px 0px;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }

  .main-nav .menu-item-spacer {
    display: inline-block;
    height: 25px;
    width: 100%;
  }

  /* ANIMATIONS */
  .main-nav ul ul.sub-menu,
  .main-nav .hg_mega_container {
    left: -9999px;
    opacity: 0;
    transition: opacity .4s ease, top .45s ease;
    visibility: hidden;
  }

  .main-nav .hg_mega_container {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
  }

  .main-nav ul ul.sub-menu {
    top: 150%;
  }

  .main-nav .hg_mega_container {
    margin-top: 25px;
    transition: opacity .4s ease, top .45s ease, margin-top .4s ease;
  }

  .main-nav ul li:hover > ul,
  .main-nav ul li:hover > .hg_mega_container {
    left: 0;
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }

  .main-nav ul li:hover > ul.sub-menu {
    top: 135%;
  }

  .main-nav ul li:hover > .hg_mega_container {
    margin-top: 10px;
  }

  .main-nav ul ul li a {
    transition: padding-left .35s ease, border-right .35s ease;
  }

  /* MENU BADGES */
  .main-nav ul .hg-mega-new-item {
    background-color: var(--color-primary);
    border-radius: 2px;
    color: #fff;
    font-size: 10px;
    line-height: 1;
    padding: 5px;
    position: absolute;
    right: 3px;
    text-transform: none;
    text-transform: initial;
    top: 50%;
    transform: translateY(-50%);
  }

  .main-nav ul ul .hg-mega-new-item {
    left: auto;
    right: 10px;
  }

  .main-nav ul ul.sub-menu li a:not(:only-child) .hg-mega-new-item {
    right: 23px;
  }

  .main-nav .hg_mega_container ul .hg-mega-new-item {
    right: 3px;
  }

  #menu-main-menu li > a[href="#"] {
    cursor: default;
  }
}

/* MENU CHASER STYLES */
.chaser {
  display: none
}

@media (min-width: 992px) {
  .chaser {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: rgba(0, 0, 0, .85);
    display: block;
    opacity: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(0, -30px, 0);
    transition-duration: .3s;
    transition-property: opacity, visibility, transform;
    transition-timing-function: ease-out;
    visibility: hidden;
    width: 100%;
    will-change: transform;
    z-index: 111;
  }

  .chaser.visible {
    opacity: 1;
    transform: translateZ(0);
    visibility: visible;
  }

  .chaser #wpk-main-menu {
    position: static;
    position: initial;
  }

  .chaser .main-menu,
  .chaser .main-menu ul {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .chaser .main-menu li:not(.menu-item-mega-parent) {
    position: relative;
  }

  .chaser .main-menu > li {
    border-left: 1px solid rgba(255, 255, 255, .1);
    float: left;
  }

  .chaser .main-menu > li:last-child {
    border-right: 1px solid rgba(255, 255, 255, .1);
  }

  .chaser .main-menu .sub-menu,
  .chaser .main-menu .hg_mega_container {
    border-radius: 0;
    left: -2999px;
    opacity: 0;
    position: absolute;
    transform: translateY(20px);
    transition: opacity .15s ease-in-out, transform .15s ease-in-out;
  }

  .chaser .main-menu .hg_mega_container {
    left: -2999px !important;
  }

  .chaser .main-menu .zn_mega_row_start:after,
  .chaser .main-menu .zn_mega_row_start:before {
    content: " ";
    display: table;
  }

  .chaser .main-menu .zn_mega_row_start:after {
    clear: both;
  }

  .chaser .main-menu li ul {
    background: rgba(0, 0, 0, 0.9);
    min-width: 220px;
    padding: 5px;
  }

  .chaser .main-menu li ul li ul {
    background: none;
  }

  .chaser .main-menu .hg_mega_container > ul {
    padding-bottom: 20px;
    padding-top: 0
  }

  .chaser .main-menu .hg_mega_container > ul:first-child {
    padding-top: 20px
  }

  .chaser .main-menu .hg_mega_container > ul:last-child {
    padding-bottom: 30px;
  }

  .chaser .main-menu .hg_mega_container ul ul {
    background: none;
    padding: 10px 0 0;
  }

  .chaser .main-menu .hg_mega_container .zn_mega_title {
    background: none;
    border-bottom: 1px solid #454545;
    padding-left: 20px;
  }

  .chaser .main-menu .hg_mega_container .zn_mega_title_hide {
    display: none;
  }

  .chaser .main-menu .hg_mega_container .zn_mega_title_only_hide {
    display: block;
    visibility: hidden;
  }

  .chaser .main-menu li a {
    color: #ddd;
    display: block;
    font-size: 14px;
    line-height: 1.4;
    padding: 7px 15px;
    position: relative;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
  }

  .chaser .main-menu li:hover > a:not(:only-child) + .sub-menu,
  .chaser .main-menu li:hover > a:not(:only-child) + .hg_mega_container {
    left: 0;
    opacity: 1;
    transform: translateY(0);
  }

  .chaser .main-menu li:hover > a:not(:only-child) + .hg_mega_container {
    left: 15px !important;
  }

  .chaser .main-menu li > a:not(:only-child) {
    padding-right: 25px;
  }

  .chaser .main-menu li > a:not(:only-child):after {
    background: #ccc;
    content: '';
    height: 2px;
    margin-top: -1px;
    opacity: .5;
    position: absolute;
    right: 9px;
    top: 50%;
    width: 6px;
  }

  .chaser .main-menu li li:hover > a:not(:only-child) + .sub-menu {
    left: 100%;
    top: -4px;
  }

  .chaser .main-menu li:hover > a,
  .chaser .main-menu li a:hover {
    background: #000;
    color: #fff;
  }

  .chaser .main-menu li.active > a,
  .chaser .main-menu li > a.active {
    background: var(--color-primary);
    color: #ffffff;
  }

  .chaser .zn-mega-new-item {
    display: none;
  }

  .chaser .spanel-label,
  .chaser .topnav--lang {
    display: none;
  }

  .chaser .kl-header-toptext,
  .chaser .kl-header-toptext a {
    color: #fff;
    float: right;
    margin-left: 5px;
  }

  .chaser .header-leftside-container {
    padding-top: 10px;
  }

  .chaser .header-search {
    display: none;
  }

  .chaser .main-menu > li,
  .chaser .main-menu > li:last-child {
    border: none;
  }

  .chaser .main-menu li a[title="separator"] {
    display: none;
    text-indent: -2999px;
  }

  .chaser .main-menu,
  .chaser .col-sm-10,
  .chaser .col-md-12 {
    position: static;
    position: initial;
  }

  .chaser .row {
    position: relative;
  }

  .chaser .main-menu li li:hover > a:not(:only-child) + .sub-menu {
    background: rgba(0, 0, 0, 0.9);
  }

  .chaser .menu-item-spacer {
    display: inline-block;
    height: 25px;
    width: 100%;
  }

  .chaser #wpk-main-menu {
    padding-left: 0
  }

  .chaser #_wpk-custom-bar {
    height: 32px;
  }
}


/* Responsive Menu */
#zn-res-menuwrapper {
  display: none
}

#zn-res-menu {
  list-style: none;
  margin: 0;
  -webkit-backface-visibility: hidden;
  background: #fff;
  display: none;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transform-style: preserve-3d;
  transition: transform .5s ease;
  width: 100%;
  z-index: 11112;

  &.zn-menu-visible {
    transform: translate3d(0, 0, 0)
  }

  * {
    -webkit-backface-visibility: hidden
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0
  }

  ul {
    position: fixed !important;

    &.zn-menu-visible {
      transform: translate3d(0, 0, 0)
    }
  }

  li {

    max-width: 100%;
    position: relative;
    width: 100%;

    &.active > a {
      color: #333
    }

    & > a {
      color: #8C8C8C;
      display: block;
      font-size: .9rem;
      padding: 13px 28px;
      text-transform: uppercase;
      z-index: 1;
    }

    &.mobile-action > a {
      font-size:1.1rem
    }

  }

  .zn_res_has_submenu > span,
  .zn_res_menu_go_back > span {
    border-left: 1px solid;
    border-left-color: inherit;
    cursor: pointer;
    height: 44px;
    line-height: 49px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    vertical-align: middle;
    width: 100%;
    z-index: 2;
  }

  li ul {
    -webkit-backface-visibility: hidden;
    background: #fff;
    display: none;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(100%, 0, 0);
    transform-style: preserve-3d;
    transition: transform .5s ease;
    width: 100%;
    z-index: 11112
  }

  a {
    cursor: pointer
  }

}

.zn_res_menu_go_back a:first-child svg {
  margin-right: 10px;
}

.zn-close-menu-button svg {
  font-size: 1.3rem;
}

.zn_res_menu_visible #zn-res-menu,
.zn_res_menu_visible #zn-res-menu li ul {
  display: none
}

.zn_res_has_submenu .zn_res_submenu_trigger {
  align-items: center;
  border-left: 2px solid #ccc;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  z-index:90
}

.zn_res_menu_go_back {
  border-bottom: 2px solid;
}

#zn-res-menu .zn_res_menu_go_back > a {
  display: inline-block;
  padding-left: 48px;
  padding-right: 0;
  text-align: center;
  width: calc(100% - 49px);
}

#zn-res-menu .zn_res_menu_go_back > span {
  border-left: none;
  border-right: 1px solid;
  border-right-color: inherit;
  left: 0;
  right: auto;
}

.zn-res-menuwrapper {
  display: block;
}

.zn-res-trigger {
  display: block;
  height: 28px;
  margin-right: -15px;
  position: relative;
  width: 25px;
}

#zn-res-menu .zn_res_has_submenu > span::before,
#zn-res-menu .zn_res_menu_go_back > span::before {
  display: none;
}

.zn-res-trigger::after {
  background: var(--color-dark);
  border-radius: 4px;
  box-shadow: 0 8px 0 var(--color-dark), 0 16px 0 var(--color-dark);
  content: '';
  height: 4px;
  left: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 25px
}

#zn-res-menu li > a[title=separator], .zn_res_has_submenu .new-item {
  display: none;
}

#zn-res-menu.zn-menu-visible .zn-mega-new-item {
  display: none;
}

/* Menu Overlay */
.zn-res-menu-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity .4s ease-out, visibility .4s ease-out;
  visibility: hidden;
  width: 100%;
  z-index: 11111;
}

.zn-menu-visible + .zn-res-menu-overlay {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 992px) {
  #zn-res-menuwrapper {
    display: block;
  }

  .zn_res_menu_visible #zn-res-menu,
  .zn_res_menu_visible #zn-res-menu li ul {
    display: block;
    overflow: hidden;
  }

  #main-menu {
    display: none;
  }

  #zn-res-menu li.active {
    background-color: #F3F3F3;
  }

  #zn-res-menu li.active > li {
    background-color: #F3F3F3;
  }

  #main-menu .dropdown-menu {
    margin-left: -20px;
    padding-left: 10px;
  }

  #main-menu .dropdown-menu {
    border: none;
    box-shadow: none;
  }

  #main-menu .dropdown-menu li > a {
    font-size: 12px;
    padding: 3px 8px;
  }

  #main-menu .dropdown-menu li > a:hover {
    background-color: transparent;
  }

  #main-menu .dropdown-menu > .active > a,
  #main-menu .dropdown-menu > .active > a:hover,
  #main-menu .dropdown-menu > .active > a:focus {
    background-color: transparent;
  }

  #main-menu .dropdown-menu li.active > .inner-elem {
    background-color: var(--color-primary);
  }

  #main-menu > ul {
    -webkit-backface-visibility: hidden;
    transition: left 0.2s ease-out;
  }

  #main-menu > ul ul {
    background: transparent;
    opacity: 1 !important;
    position: static;
    visibility: visible !important;
  }

  #main-menu > ul li {
    float: none;
    margin-left: 0;
    padding: 5px 0;
    padding-left: 0;
    width: 100%;
  }

  #zn-res-menu .zn_res_menu_go_back > .zn-close-menu-button {
    border-left: 2px solid #535353;
    display: inline-block;
    height: 47px;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    z-index: 0;
  }

  #zn-res-menu .zn-close-menu-button span {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.ctabutton {
  border-radius: 4px;
  background: var(--color-primary);
  color: #fff;
  font-size: .88rem;
  font-weight: 700;
  padding: 15px 15px 14px;
  text-align: center;
  box-shadow: inset 2px 2px 6px #88011a78, 1px 1px 10px -2px #00000080;

  &:hover {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    color: #000;

    .trisvg {
      fill: #fff;
    }
  }

  &:focus {
    color: #fff;
  }

  .trisvg {
    fill: var(--color-primary);
    height: 5px;
    left: 0;
    margin-top: -0.2px;
    position: absolute;
    top: 100%;
    width: 100%;

    path {
      fill: var(--color-primary);
    }
  }

  strong {
    display: block;
    font-size: 17px;
    font-weight: 900;
    line-height: 24px;
  }
}


.quote-ribbon .ctabutton {
  right:28px;
  position: absolute;
}

.site-header-main .ctabutton {

}

.kl-cta-lined {
  background-color: transparent;
  box-shadow: none;
  margin-top: 5px;
  padding: 8px 20px 9px;
}

.screen-reader-text {
  display: none;
}

/* TOP NAVIGATION */
.topnav {
  list-style: none;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  position: relative;
  z-index: 111;
}

.topnav > .topnav-item span {
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: var(--heading-font);
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 2.2;
  opacity: .8;
  padding: 0;
  transition: opacity .15s ease-in-out;
}

.topnav > .topnav-item i {
  color: #fff;
  opacity: .8;
}

.topnav .topnav-item {
  cursor: pointer;
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 2.2rem;
  padding: 0;
}

.topnav .topnav-item a,
.topnav .topnav-item span {
  color: #fff;
  opacity: 0.8;
}

.topnav a:hover.topnav-item,
.topnav .topnav-item:hover a,
.topnav a:hover,
.topnav .topnav-item:hover span,
.topnav .spanel-label:hover .support-info,
.topnav > .topnav-item:hover i {
  color: #fff;
  opacity: 1;
}

.topnav .popup-with-form a {
  font-size: 13px;
}

.site-logo {
  width: 200px;
  padding: 15px 0;
}

.site-logo img {
  max-width: 100%;
}

.logosize--contain .site-logo .logo-img {
  height: 100%;
  width: auto;
}

.site-header,
.logosize--contain .site-logo a {
  height: 100px;
}

.logosize--contain .site-logo .logo-img,
.logosize--contain .site-logo .logo-img-sticky {
  max-height: 100px;
  vertical-align: middle;
}

#page_wrapper {
  overflow: hidden;
  position: relative;
}

.site-header {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
  z-index: 100;
}

.siteheader-container {
  position: relative;
}

.site-header {
  background: none;
  border: 0;
  box-shadow: 0 0 40px #000000b5;
  height: auto;
}

.subpage .site-header {
  position: relative
}

.site-header-main {
  font-size: clamp(1rem, 2.4vw, 1.7rem);
  font-weight: 700;
}

.site-header .logosize--contain .site-logo a {
  height: 70px;
}

.site-header .separator {
  background: var(--color-dark);
  height: 3px;
  opacity: 1;
}

.site-header .separator.dark {
  background: #9a9a9a;
}

.site-header .kl-header-bg {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, transparent 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
  height: 170%;
  left: 0;
  opacity: .6;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.site-header .site-header-top {
  padding: 10px 0 20px;
}

.site-header-bottom {
  padding: 20px 0;
}

.site-header .site-header-top-wrapper {
  position: relative;
}

.site-header .searchBtn.active {
  border-radius: 2px 2px 0 0;
}

.site-header .kl-header-toptext {
  color: #fff;
  font-size: 0.95rem;
  line-height: 2.2;
}

.site-header .kl-header-toptext a {
  color: #fff;
  font-size: 1rem;
}

.site-header .site-header-top-wrapper .topnav {
  z-index: 111;
}

.site-header .site-header-top-wrapper .topnav.topnav--lang {
  z-index: 112;
}

.site-header .social-icons.topnav {
  margin: 0;
  padding: 0;
  position: relative;
}

.site-header .social-icons.topnav li {
  margin: 0;
  margin-left: 10px;
}

.site-header .social-icons.topnav.sc--clean li {
  margin-left: 0;
}

.site-header .social-icons.topnav li:first-child {
  margin-left: 0;
}

.site-header .kl-header-toptext {
  margin-left: 20px;
}

.site-header .social-icons.topnav.sc--clean > li > a {
  color: #fff;
}

.site-header .social-icons.topnav.sc--clean > li > a:hover {
  color: rgba(255, 255, 255, .9);
}

.site-header .kl-cta-lined {
  margin-top: 13px;
}

.site-header .site-logo-img {
  height: 100%;
  padding: 10px 0;
  width: auto;
}

/*** Sticky header ***/
body.sticky-header #header {
  position: fixed !important;
}

/* Social icons */
.social-icons {
  margin-bottom: 0;
  margin-left: 0;
  z-index: 1;

  li {
    display: inline-block;
    margin: 5px 0 5px 10px;
    vertical-align: top;

    a {
      border-radius: 3px;
      color:#151515;
      display: inline-block;
      font-size: 0.9rem;
      font-weight: normal;
      height: 35px;
      opacity: 1;
      position: relative;
      text-align: center;
      transition: all 0.2s ease-out;
      width: 35px;

      &:before {
        line-height: 30px;
        display:none
      }

    }
  }
}

.support_panel .col-lg-3 .m_title {
  margin-left: 12px;
}

@media (min-width:576px) {
  .overlapAbove {
    margin-top:-150px
  }
}

.overlapAbove .kl-title-block {
  padding:30px 30px 50px
}

.no-webp .feature_img_1 {
  background-image: url(../images/home/feature_01.jpg)
}
.webp .feature_img_1 {
  background-image: url(../images/home/feature_01.webp)
}
.no-webp .feature_img_2 {
  background-image: url(../images/home/feature_02.jpg)
}
.webp .feature_img_2 {
  background-image: url(../images/home/feature_02.webp)
}
.no-webp .feature_img_3 {
  background-image: url(../images/home/feature_03.jpg)
}
.webp .feature_img_3 {
  background-image: url(../images/home/feature_03.webp)
}

/* kl-slideshow div styles */
.kl-slideshow-inner {
  position: relative;
  z-index: 1; /* z-index: 100; */
}

.kl-slideshow-inner .container {
  z-index: 1;
}

.kl-slideshow .bgback {
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.kl-slideshow {
  margin-bottom: 0;
}

.kl-ios-selectors-block.bullets2 .item.selected::before,
.kl-ios-selectors-block.bullets2 .item:hover:before {
  background-color: var(--color-primary);
}

/* used in several sliders */
.zn-shadow-lifted {
  box-shadow: 0 40px 20px -25px rgba(0, 0, 0, 0.25);
}

/* For elements that are positioned right under the header, a safe padding is added */
.kl-slideshow .kl-slideshow-safepadding {
  padding-bottom: 35px;
  padding-top: 35px;
}

/*** Page Subheader's defaults  */
.page-subheader {
  background-color: #222222;
  min-height: 300px;
}

.page-subheader[class*="maskcontainer--"] {
  min-height: 330px;
  overflow: hidden;
  padding-bottom: 0;
}

.page-subheader .container {
  padding: 0 15px;
  position: relative;
  z-index: 1;
}

.page-subheader > .shadowUP:after {
  border: 6px solid transparent;
  border-top-color: #fff;
  bottom: -18px;
  content: '';
  left: 50%;
  margin-left: -6px;
  position: absolute;
}

.page-subheader .bgback {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

.review-image picture {
  padding:0;
  margin-top:-10px;
  margin-bottom: -10px
}

#page_header.no-bg {
  background: none;
}

.page-subheader .ph-content-wrap {
  height: 400px;
  min-height: 400px;
  width: 100%;
}

/* Page subheader styles */
.page-subheader.gradient,
.kl-slideshow.gradient {
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
  background-color: #345370;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
}

.page-subheader,
.kl-slideshow {
  position: relative;
  text-shadow: 2px 2px 2px #000;
  top: 0;
  z-index: 0;
}

.page-subheader.nobg,
.kl-slideshow.nobg {
  background: none;
}

.page-subheader.hasMargin,
.kl-slideshow.hasMargin {
  margin-bottom: 40px;
}

.page-subheader canvas#target {
  width: 100%;
}

/* HG Section */
.hg_section {
  padding-bottom: 35px;
  padding-top: 35px;
}

.hg_section--relative {
  position: relative;
}

.hg_section--masked {
  padding-bottom: 55px;
}

.hg_section__source {
  position: relative;
}

.kl-bg-source {
  position: relative;
}

/* PORTFOLIO */
.hg_other_images > div {
  margin-bottom: 15px;
  margin-top: 15px;
}

.hg_other_images {
  margin-top: 20px;
}


/* Background source for elements */
.kl-bg-source {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.kl-bg-source:not(:empty),
.kl-bg-source__overlay,
.kl-bg-source__bgimage {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.kl-bg-source__bgimage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}

.kl-bg-source__bgimage:before {
  background: rgba(0,0,0,.7);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.kl-bg-source__bgimage--fixed {
  background-attachment:fixed
}

.kl-bg-source__overlay {
  pointer-events: none;
}

.kl-bg-source__video {
}

.kl-bg-source__iframe {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.kl-bg-source__iframe iframe {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.kl-bg-source__overlay-gloss {
  background-color: #FFF;
  height: 100%;
  left: -43%;
  opacity: .08;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: skewX(-55deg);
  transform-origin: 100% 0;
  width: 100%;
}


.kl-bgSource-imageParallax {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  will-change: transform;
}

.kl-bgSource-imageParallax .kl-bg-source__bgimage {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-attachment: scroll;
  background-attachment: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  top: 0;
}

.kl-bgSource-imageParallax.is-fixed {
  left: -100vw;
  position: fixed;
}

.kl-bgSource-imageParallax.is-fixed.is-visible {
  left: 0;
}

.kl-bgSource-imageParallax.is-fixed .kl-bg-source__bgimage {
  height: 100vh;
}

/* Source Mask */
.ls-source__mask {
  -webkit-backface-visibility: hidden;
  background: #fff;
  bottom: 0;
  height: 20vh;
  left: 0;
  position: absolute;
  transform: skewY(-2deg);
  transform-origin: 100% 0;
  width: 100%;
}

.ls-source__mask.ls-source__mask-front {
  background: linear-gradient(to bottom, rgba(245, 245, 245, 0.6) 0%, rgba(251, 251, 251, 1) 60%);
}

.ls-source__mask.ls-source__mask-back {
  -webkit-backface-visibility: hidden;
  background: rgba(255, 255, 255, .4);
  bottom: 40px;
  transform: skewY(-3.1deg);
}


/*** Special Bottom masks */
.kl-bottommask {
  z-index: 1;
}

.fake-loading ~ .kl-bottommask {
  z-index: 2;
}

/* Shadow up mask Simple */
.maskcontainer--shadow_simple {
  border-bottom: 0 !important;
}

.kl-bottommask--shadow_simple {
  background: url(../images/shadow-up.png) no-repeat center bottom;
  background: -webkit-gradient(50% 100%, ellipse, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 50%, at 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50%, at 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50% 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: -webkit-radial-gradient(50% 90%, 70% 100%, rgba(0, 0, 0, 0.25) 0%, transparent 70%);
  border-bottom: 0;
  bottom: 0;
  box-sizing: content-box;
  height: 6px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

/* Shadow Down mask Simple */
.maskcontainer--shadow_simple_down {
  border-bottom: 0 !important;
}

.kl-bottommask--shadow_simple_down {
  background: url(../images/shadow-down.png) no-repeat center top;
  background: -webkit-gradient(50% 0%, ellipse, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 50%, at 0%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50%, at 0%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50% 0%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: -webkit-radial-gradient(50% 0%, 70% 100%, rgba(0, 0, 0, 0.25) 0%, transparent 70%);
  bottom: -10px;
  height: 10px;
  left: 0;
  opacity: .6;
  position: absolute;
  width: 100%;
}

/* Shadow up mask - width border and arrow */
.maskcontainer--shadow {
  border-bottom: 0 !important;
}

.kl-bottommask--shadow {
  background: url(../images/shadow-up.png) no-repeat center bottom;
  background: -webkit-gradient(50% 100%, ellipse, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 50%, at 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50%, at 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50% 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: -webkit-radial-gradient(50% 90%, 70% 100%, rgba(0, 0, 0, 0.25) 0%, transparent 70%);
  border-bottom: 6px solid #FFFFFF;
  bottom: 0;
  box-sizing: content-box;
  height: 6px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.kl-bottommask--shadow:after {
  border: 6px solid transparent;
  border-top-color: #fff;
  bottom: -18px;
  content: '';
  left: 50%;
  margin-left: -6px;
  position: absolute;
}

/* Shadow up & down mask */
.maskcontainer--shadow_ud {
  border-bottom: 0 !important;
}

.kl-bottommask--shadow_ud {
  background: url(../images/shadow-up.png) no-repeat center bottom;
  background: -webkit-gradient(50% 100%, ellipse, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 50%, at 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50%, at 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50% 100%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: -webkit-radial-gradient(50% 90%, 70% 100%, rgba(0, 0, 0, 0.25) 0%, transparent 70%);
  border-bottom: 6px solid #FFFFFF;
  bottom: 0;
  height: 12px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.kl-bottommask--shadow_ud:after {
  border: 6px solid transparent;
  border-top-color: #fff;
  bottom: -17px;
  content: '';
  left: 50%;
  margin-left: -6px;
  position: absolute;
}

.kl-bottommask--shadow_ud:before {
  background: url(../images/shadow-down.png) no-repeat center top;
  background: -webkit-gradient(50% 0%, ellipse, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 50%, at 0%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50%, at 0%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: radial-gradient(ellipse at 50% 0%, rgba(0, 0, 0, 0.3) 0, transparent 70%);
  background: -webkit-radial-gradient(50% 0%, 70% 100%, rgba(0, 0, 0, 0.25) 0%, transparent 70%);
  bottom: -16px;
  content: '';
  height: 10px;
  left: 0;
  opacity: .6;
  position: absolute;
  width: 100%;
}

/* Mask 1 */
.maskcontainer--mask1 {
  border-bottom: 0 !important;
  padding-bottom: 30px;
}

.kl-bottommask--mask1 {
  background: url(../images/bottom_mask.png) no-repeat center top;
  bottom: 0;
  height: 27px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 99;
}

/* Mask 2 */
.maskcontainer--mask2 {
  border-bottom: 0 !important;
  padding-bottom: 40px;
}

.kl-bottommask--mask2 {
  background: url(../images/bottom_mask2.png) no-repeat center top;
  bottom: 0;
  height: 33px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 99;
}

/* Mask 3 */
.maskcontainer--mask3 {
  border-bottom: 0 !important;
}

.kl-bottommask--mask3 {
  bottom: 0;
  height: 57px;
  left: 0;
  position: absolute;
  width: 100%;
}

.kl-bottommask--mask3 .svgmask {
  bottom: 0;
  left: 50%;
  margin-bottom: -14px;
  position: absolute;
  transform: translateX(-50%);
}

.kl-bottommask--mask3 .fas {
  bottom: 10px;
  display: block;
  font-size: 30px;
  height: 15px;
  left: 50%;
  margin-left: 1px;
  opacity: 0.2;
  position: absolute;
  text-align: center;
  top: auto;
  transform: translateX(-50%);
  width: 30px;
}

/* Mask 4 */
.maskcontainer--mask4 {
  border-bottom: 0 !important;
  padding-bottom: 40px;
}

.kl-bottommask--mask4 {
  bottom: 0;
  height: 27px;
  left: 0;
  position: absolute;
  width: 100%;
}

.kl-bottommask--mask4 .svgmask {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

/* Mask 3 and 4, left or right aligned */
@media (min-width: 1200px) {
  .kl-bottommask--mask3 .svgmask-right,
  .kl-bottommask--mask3 .svgmask-right + .fas,
  .kl-bottommask--mask4 .svgmask-right {
    transform: translateX(calc(-50% + 510px));
  }

  .kl-bottommask--mask3 .svgmask-left,
  .kl-bottommask--mask3 .svgmask-left + .fas,
  .kl-bottommask--mask4 .svgmask-left {
    transform: translateX(calc(-50% - 510px));
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .kl-bottommask--mask3 .svgmask-right,
  .kl-bottommask--mask3 .svgmask-right + .fas,
  .kl-bottommask--mask4 .svgmask-right {
    transform: translateX(calc(-50% + 410px));
  }

  .kl-bottommask--mask3 .svgmask-left,
  .kl-bottommask--mask3 .svgmask-left + .fas,
  .kl-bottommask--mask4 .svgmask-left {
    transform: translateX(calc(-50% - 410px));
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .kl-bottommask--mask3 .svgmask-right,
  .kl-bottommask--mask3 .svgmask-right + .fas,
  .kl-bottommask--mask4 .svgmask-right {
    transform: translateX(calc(-50% + 300px));
  }

  .kl-bottommask--mask3 .svgmask-left,
  .kl-bottommask--mask3 .svgmask-left + .fas,
  .kl-bottommask--mask4 .svgmask-left {
    transform: translateX(calc(-50% - 300px));
  }
}

@media (max-width: 767px) {
  .kl-bottommask--mask3 .svgmask-right,
  .kl-bottommask--mask3 .svgmask-right + .fas,
  .kl-bottommask--mask4 .svgmask-right,
  .kl-bottommask--mask3 .svgmask-left,
  .kl-bottommask--mask3 .svgmask-left + .fas,
  .kl-bottommask--mask4 .svgmask-left {
    transform: translateX(-50%);
  }
}

/* Mask 5 */
.maskcontainer--mask5 {
  border-bottom: 0 !important;
  padding-bottom: 75px;
}

.kl-bottommask--mask5 {
  bottom: 0;
  height: 64px;
  left: 0;
  position: absolute;
  width: 100%;
}

.kl-bottommask--mask5 .svgmask {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.kl-bottommask--mask5 .bmask-customfill {
  transform: translateY(-1px);
}

/* Hack for safari only to hide shadow */
.is-safari .svgmask .bmask-bgfill,
.is-safari .kl-bottommask--mask3 .bmask-bgfill,
.is-safari .kl-bottommask--mask4 .bmask-bgfill {
  filter: none;
}

.is-safari .kl-bottommask--mask3 .bmask-bgfill,
.is-safari .kl-bottommask--mask4 .bmask-bgfill,
.is-safari .svgmask .bmask-bgfill {
  -webkit-svg-shadow: 0px 0px 7px rgba(0, 0, 0, 0.47);
  transform: translateY(4px);
}

/* Mask 6 */
.maskcontainer--mask6 {
  border-bottom: 0 !important;
  padding-bottom: 70px;
}

.kl-bottommask--mask6 {
  bottom: 0;
  height: 57px;
  left: 0;
  position: absolute;
  width: 100%;
}

.kl-bottommask--mask6 .svgmask {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

/* Mask 7 */
.kl-bottommask--mask7 {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.kl-bottommask--mask7.mask-over {
  z-index: 2;
}

.kl-bottommask--mask7.screffect {
  transform-origin: 0 100%
}


/* Static content with mask, do things a bit differently */
.static-content__slideshow[class*="maskcontainer--"] {
  padding-bottom: 0;
}

.static-content__slideshow[class*="maskcontainer--"] .kl-slideshow-inner {
  padding-bottom: 60px
}

/* Same goes for portfolio slider */
.portfolio-slider__sideshow[class*="maskcontainer--"] {
  padding-bottom: 0;
}

/* Same goes for fancy slider */
/*.fancyslider__slideshow[class*="maskcontainer--"] .kl-slideshow-inner {padding-bottom: 60px}*/
/* Same goes for revolution slider */
.kl-revolution-slider[class*="maskcontainer--"] {
  padding-bottom: 0;
}

/* 3D Cute slider def. padding */
.cute3dslider .kl-slideshow-inner {
  padding-bottom: 50px;
  padding-top: 170px;
}

/*.br-circle-timer .br-timer-dot {-webkit-transform: translate3d(-50%, -50%, 0); -ms-transform: translate3d(-50%, -50%, 0); transform: translate3d(-50%, -50%, 0);}*/


/* Mod Title */
.m_title {
  color: var(--color-primary); /*text-transform: uppercase;*/
  margin-top: 0;
}

/* uppercase disabled, manually uppercase text */
h3.m_title {
  font-size: 20px;
  line-height: 1.4;
}

.smallm_title {
  border-bottom: 1px solid #bcbcbc;
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
  text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
}

.smallm_title span {
  border-bottom: 1px solid #bcbcbc;
  display: inline-block;
  padding-bottom: 9px;
  position: relative;
}

.smallm_title span:before {
  border: 3px solid transparent;
  border-bottom-color: #bcbcbc;
  bottom: 0;
  content: '';
  left: 50%;
  margin-left: -2px;
  position: absolute;
}

.smallm_title.centered {
  text-align: center;
}

.smallm_title.bigger {
  font-size: 18px;
}

.circle_title {
  color: var(--color-primary);
  margin-top: 0;
  position: relative;
  text-shadow: 0 1px 0 #fff;
}

.circle_title:after {
  border-bottom: 1px dotted #bbb;
  bottom: 5px;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
}

.mb_title {
  background: white;
  color: #595959;
  display: inline-block;
  font-size: 20px;
  line-height: 1.5;
  padding: 0 10px;
}

.mb_title img {
  margin-right: 5px;
  margin-top: -3px;
}


/* Breadcrumbs */
.breadcrumbs {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  float: left;
  list-style: none;
  margin: 0 0 10px;
  padding: 7px 15px 6px;
}

.breadcrumbs li {
  color: #eee;
  float: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
  text-transform: uppercase;
}

.breadcrumbs li:first-child {
  background: none;
  margin-left: 0;
  padding-left: 0;
}

.breadcrumbs li a {
  color: #fff;
}

.breadcrumbs li:after,
.breadcrumbs2 li:after {
  border: 4px solid transparent;
  border-left-color: var(--color-primary);
  content: '';
  display: inline-block;
  height: 0;
  margin: 0 5px 2px 10px;
  pointer-events: none;
  width: 0;
}

.breadcrumbs li:last-child:after,
.breadcrumbs2 li:last-child:after {
  display: none;
}

.breadcrumbs2 {
  background-color: inherit;
  list-style: none;
  margin-bottom: 0;
  opacity: 0.4;
  padding: 0;
  transition: opacity .1s ease-out;
}

.breadcrumbs2 > li {
  cursor: pointer;
  display: inline-block;
}

.breadcrumbs2:hover {
  opacity: 1;
}

.breadcrumbs2.white a,
.breadcrumbs2.white span {
  color: #fff;
}

/* Social list  */
.social_icons ul,
ul.social_icons {
  list-style: none;
  margin: 0;
  padding: 0;
}

.social_icons li {
  display: inline-block;
  line-height: 40px;
  margin-left: 10px;
}

.social_icons li:first-child {
  margin-left: 0;
}

.social_icons li a {
  color: #cdcdcd;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  vertical-align: middle;
}

.social_icons li a:hover {
  color: #666;
}

.social_icons a {
  text-decoration: none;
}

.social_icons.socialstyles-extra li {
  float: left;
  margin: 0;
}

.social_icons.socialstyles-extra li a {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-left-width: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  padding: 15px;
}

.social_icons.socialstyles-extra li a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.social_icons.socialstyles-extra li:first-child a {
  border-left-width: 1px;
}


/* Header titles */
.subheader-titles {
  margin-top: -7px;
  text-align: right;
}

.subheader-titles.left {
  text-align: left;
}

.subheader-maintitle {
  color: #fff;
  line-height: 1.3;
  margin: 0 0 5px;
  font-size: 1.9rem;
}

.subheader-subtitle {
  color: #fff;
  display: inline-block;
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.shadowUP {
  background: url(../images/shadow-up.png) no-repeat center bottom;
  bottom: 0;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.shadowDown {
  background: url(../images/shadow-down.png) no-repeat center bottom;
  bottom: 0;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.subheader-currentdate {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  clear: left;
  color: #383838;
  float: left;
  font-size:12px;
  font-family:var(--main-font);
  line-height: 1;
  padding: 6px 10px;
}


/* Title Block Element */
.kl-title-block {
  padding-bottom:0;
  padding-top: 0;
}

.kl-slideshow .tbk__symbol {
  padding-left: 5px;
}

.tbk__title {
  font-family: var(--main-font);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 10px;
  margin-top: 0;
}

.tbk__subtitle {
  font-family: var(--main-font);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6;
  margin-top: 0;
}

.tbk-thinsubtitle {
  font-size: 1.2rem;
  font-weight: 100;
  line-height: 1.5;
}

.tbk-icon-pos--before-title img {
  max-width: 300px;
}


.tbk__symbol {
  margin-bottom: 10px;
  margin-top: 0;
  display: flex;
  width: 100%;
  height: 9px;
}

.tbk-symbol--line .tbk__symbol span {
  background-color: #ccc;
  display: inline-block;
  height: 2px;
  width: 50px;
}

.tbk-symbol--line.tbk-icon-pos--after-title .tbk__symbol {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: -5px;
}

/* Symbol - border */
.tbk-symbol--border .tbk__symbol,
.tbk-symbol--border2 .tbk__symbol {
  margin-bottom: 20px;
}

.tbk-symbol--border .tbk__symbol span,
.tbk-symbol--border2 .tbk__symbol span {
  background-color: #ccc;
  display: block;
  height: 1px;
  width: 100%;
}

.tbk-symbol--border.tbk-icon-pos--after-text .tbk__symbol {
  margin-top: 30px;
}

.tbk-symbol--border2.tbk-icon-pos--after-title .tbk__border-helper {
  border-bottom: 3px solid #D2D2D2;
  display: inline-block;
  margin-bottom: -12px;
  padding-bottom: 15px;
}

/* Symbol - small line and border */
.tbk-symbol--line_border .tbk__symbol span {
  background-color: var(--color-primary);
  display: inline-block;
  height: 3px;
  margin-left: 25px;
  margin-right: 25px;
  vertical-align: middle;
  width: 50px;
}

.tbk-symbol--line_border .tbk__symbol:before,
.tbk-symbol--line_border .tbk__symbol:after {
  background-color: var(--color-primary);
  content: '';
  display: inline-block;
  height: 1px;
  vertical-align: middle;
  width: calc(50% - 50px);
}

.kl-title-block.text-center.tbk-symbol--line_border .tbk__symbol span {
  margin: 0 20px;
}

.kl-title-block.text-center.tbk-symbol--line_border .tbk__symbol:before,
.kl-title-block.text-center.tbk-symbol--line_border .tbk__symbol:after {
  background-color: #bfbfbf;
}

.kl-title-block.text-right.tbk-symbol--line_border .tbk__symbol span {
  margin-right: 0;
}

.kl-title-block.text-right.tbk-symbol--line_border .tbk__symbol:after {
  display: none;
}

.kl-title-block.text-right.tbk-symbol--line_border .tbk__symbol:before {
  background-color: #bfbfbf;
  width: calc(100% - 80px);
}

/* Line Border - aligned left */
.tbk--left.tbk-symbol--line_border .tbk__symbol span {
  margin-left: 0;
}

.tbk--left.tbk-symbol--line_border .tbk__symbol:before {
  display: none;
}

.tbk--left.tbk-symbol--line_border .tbk__symbol:after {
  background-color: #ccc;
  content: '';
  display: inline-block;
  height: 1px;
  width: calc(100% - 80px);
}

/* Line Border - aligned right */
.tbk--right.tbk-symbol--line_border .tbk__symbol span {
  margin-right: 0;
}

.tbk--right.tbk-symbol--line_border .tbk__symbol:before {
  background-color: #ccc;
  content: '';
  display: inline-block;
  height: 1px;
  width: calc(100% - 75px);
}

.tbk--right.tbk-symbol--line_border .tbk__symbol:after {
  display: none;
}

/*Line Border - Positions */
.tbk-symbol--line_border.tbk-icon-pos--after-title .tbk__title {
  margin-bottom: 10px;
}

.tbk-symbol--line_border.tbk-icon-pos--after-subtitle .tbk__title {
  margin-bottom: 10px;
}

.tbk-symbol--line_border.tbk-icon-pos--after-subtitle .tbk__subtitle {
  margin-bottom: 20px;
}

.tbk-symbol--line_border.tbk-icon-pos--after-text .tbk__text {
  margin-bottom: 25px;
}

/* Symbol - icon */
.tbk-symbol--icon.tbk-icon-pos--after-text .tbk__text {
  margin-bottom: 25px;
}

/* Symbol inside title */
.tbk__title .tbk__symbol {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.tbk__title .tbk__icon {
  vertical-align: middle;
}

/* Symbol line separator */
.symbol-line {
  clear: both;
  color: #e6e6e6;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
  vertical-align: middle;
}

.symbol-line .kl-icon {
  font-size: 18px;
}

.symbol-line::before,
.symbol-line::after {
  background: #eaeaea;
  content: '';
  display: inline-block;
  float: right;
  height: 2px;
  margin-top: 7px;
  width: 48%;
}

.symbol-line::before {
  float: left;
}

/* Text colors */
.tbk--text-light {
  color: #f5f5f5;
}

.tbk--text-dark {
  color: #232323;
}

/* Theme Color */
.tbk--color-theme.tbk-symbol--line .tbk__symbol span,
.tbk--color-theme.tbk-symbol--line_border .tbk__symbol span {
  background-color: var(--color-primary);
}

.tbk--color-theme.tbk-symbol--icon .tbk__icon {
  color: var(--color-primary);
}

/* Textbox */
.text_box {
  padding-bottom: 20px;
}

.text_box-title--style1 {
  color: var(--color-primary);
  font-size: 1rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}

.text_box-title--style2 {
  color: #535353;
  font-size: 1.3rem;
  font-weight: normal;
  margin-top: 0;
}

.text_box ul {
  margin: 20px 0;
  padding-left: 25px;
}

.text_box ul>li {
  display: block;
  position: relative;
  text-rendering: auto;
}

.text_box ul>li svg {
  left: -24px;
  position: absolute;
  top: 5px;
}

.text_box ul.fancy-list>li svg {
  left: -24px;
  position: absolute;
  top: 15px;
}

.fancy-list li:hover svg {
  transform:scale(1.1)
}

.tableNotations {
  padding-top: 0;
  font-style:italic
}

.tableNotations ul {
  padding-left:0
}

.text_box .tableNotations ul > li:before {
  content:none
}

.text_box .pricing-table-element ul > li svg {
  left: unset;
  top: 50%;
  transform: translateY(-50%);
}

.text_box .pricing-table-element ul > li svg {
  opacity: 0;
  transition: opacity .5s .5s;
  transform: translateY(-50%);
}

p.tableNotations {
  margin-bottom: 30px;
}

.fontawesome-i2svg-complete .text_box .pricing-table-element ul > li svg {
  opacity: 1;
}

.plan-column ul>li:before {
  content:none;
  font-family:serif;
  font-family:initial;
}

/* BOXED VERSION */
body.boxed-layout {
  overflow-x: hidden;
}

body.boxed-layout #page_wrapper,
body.boxed-layout .support_panel {
  background: #f5f5f5;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin: 0 auto;
}

body.boxed-layout .kl-slideshow + .hg_section .action_box.style3 {
  margin-top: 0 !important;
}

body.boxed-layout ul.thumbs[data-columns="5"] .project-item {
  width: 25%;
  width: calc(100% / 4);
}

body.boxed-layout .kl-bg-source__bgimage {
  background-size: auto auto;
  background-size: initial;
}

body.boxed-layout .fancy-list.w-50 {
  width: 100% !important;
}

body.boxed-layout .kl-contentmaps__panel {
  left: 100px;
}

body.boxed-layout .works_carousel .caroufredsel:hover .cfs--prev {
  left: -30px;
}

body.boxed-layout .works_carousel .caroufredsel:hover .cfs--next {
  right: -25px;
}


/* Sidebars */
.sidebar {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
  padding: 20px 25px;

  img {
    max-width: 223px;
  }
}

.sidebarImages {
  text-align: center;
}

.col-sm-4 .sidebar {
  padding: 25px;
}

.sidebar .widget {
  margin-bottom: 35px;
}

.sidebar .widget .widgettitle {
  color: #050505;
  font-family: var(--heading-font);
  font-size: 24px;
  font-weight:400;
  line-height: 1.4;
  margin: 10px 0 15px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

.sidebar .widget .widgettitle:after {
  border-bottom: 1px solid var(--color-primary);
  bottom: 2px;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  width: 50px;
}

.sidebar .widget .widgettitle:before {
  border-bottom: 1px solid #ececec;
  bottom: 2px;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.sidebar .widget ul.menu,
.sidebar .widget ul.pagenav,
.sidebar .widget_meta > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar .widget ul.menu li,
.sidebar .widget ul.product-categories li,
.sidebar .widget ul.pagenav li,
.sidebar .widget_meta > ul li {
  margin-bottom: 0;
}

.sidebar .widget ul.menu li a,
.sidebar .widget ul.product-categories li a,
.sidebar .widget ul.pagenav li a,
.sidebar .widget_meta > ul a {
  border-radius: 2px;
  color: #747474;
  display: inline-block;
  margin-right: 5px;
  padding: 0;
  position: relative;
}

.sidebar .widget ul.menu li a svg,
.sidebar .widget ul.product-categories li a svg,
.sidebar .widget ul.pagenav li a svg,
.sidebar .widget_meta > ul a svg {
  margin-right: 10px;
  font-size:20px;
}
.svg-inline--fa .fa-primary {
  color:#fff
}
.svg-inline--fa .fa-secondary {
  color:#d71920;
  opacity:1
}
.sidebar li a:hover .svg-inline--fa .fa-primary {
  color:#fff
}
.sidebar li a:hover .svg-inline--fa .fa-secondary {
  color:#242424;
  opacity:1
}
.sidebar .widget ul.menu li.active > a,
.sidebar .widget ul.product-categories li.active > a,
.sidebar .widget ul.pagenav li.active a,
.sidebar .widget ul.menu li.current-cat > a,
.sidebar .widget ul.product-categories li.current-cat > a,
.sidebar .widget ul.pagenav li.current-cat > a,
.sidebar .widget ul.menu li > a:hover,
.sidebar .widget ul.product-categories li > a:hover,
.sidebar .widget ul.pagenav li a:hover,
.sidebar .widget_meta > ul a:hover {
  color: #000000;
}

.sidebar .widget ul.menu li a:hover::before,
.sidebar .widget ul.product-categories li a:hover::before,
.sidebar .widget ul.pagenav li a:hover::before,
.sidebar .widget_meta > ul a:hover::before {
  margin-right: 8px;
  width: 12px;
}

div.cloudBlock a {
  display: block;
  float: left;
  padding: 4px;
}


/* Latest Post from sidebar */
.latest_posts.style2 {
  background-color: #fff;
  padding: 30px 30px 10px 30px;
  position: relative
}

.latest_posts.style2 .m_title {
  margin-bottom: 20px;
}

.latest_posts.style2 .viewall {
  color: #757575;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 28px;
}

.latest_posts.style2 .viewall:hover {
  color: #111;
}

.latest_posts.style2 ul.posts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.latest_posts.style2 ul.posts li {
  border-bottom: 1px solid #ECECEC;
  margin-top: 25px;
  padding-bottom: 25px;
}

.latest_posts.style2 ul.posts li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.latest_posts.style2 ul.posts .title {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  vertical-align: middle;
}

.latest_posts.style2 ul.posts .details {
  float: left;
  width: 110px;
}

.latest_posts.style2 ul.posts .details > span {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 2px
}

.latest_posts.style2 ul.posts .details span.date {
  background: var(--color-primary);
  color: #fff;
  margin: 0 0 5px;
  padding: 3px 10px;
  position: relative;
}

.latest_posts.style2 ul.posts .details span.date:after {
  border: 4px solid transparent;
  border-top-color: var(--color-primary);
  bottom: -8px;
  content: '';
  left: 10px;
  position: absolute;
}

.latest_posts.style2 ul.posts .text {
  margin-left: 110px;
}

.latest_posts.style2 ul.posts .details > span.cat {
  display: block;
}

.latest_posts.style3 {
  position: relative;
}

.latest_posts.style3 .m_title {
  margin-bottom: 20px;
}

.latest_posts.style3 .hoverBorder {
  margin-right: 20px;
  margin-top: 5px;
}

.latest_posts.style3 .viewall {
  color: #757575;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
}

.latest_posts.style3 .viewall:hover {
  color: #111;
}

.latest_posts.style3 ul.posts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.latest_posts.style3 ul.posts li {
  border-bottom: 1px solid #E3E3E3;
  font-size: 0.9rem;
  margin-bottom: 15px;
  overflow: hidden;
  padding-bottom: 15px;
}

.latest_posts.style3 ul.posts li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.latest_posts.style3 ul.posts .title {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 5px;
  margin: 0 0 5px;
  padding: 0;
  text-shadow: 1px 1px 0 white;
  text-shadow: 1px 1px 0 white;
  vertical-align: middle;
  vertical-align: middle;
}

/*.latest_posts.style3 ul.posts .title a{font-size: 13px; font-weight: normal; font-weight: 600; }*/
.lp-post .hoverBorder ~ .text,
.lp-post .hoverBorder ~ .lp-post-comments-num {
  margin-left: 74px;
}

.lp-post .lp-post-comments-num {
  color: #bababa
}

.lp-post .hoverBorder + .title {
  overflow: hidden;
}

.widget .latest_posts ul li.lp-post .hoverBorder {
  margin-bottom: 0;
}


.hoverBorder {
  display: inline-block;
  margin-bottom: 25px;
  position: relative;
}

.hoverBorder:hover img {
  border-radius: 2px;
  opacity: 0.8;
}

.hoverBorderWrapper {
  display: block;
  position: relative;
}

.hoverBorderWrapper img {
  border-radius: 2px;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.theHoverBorder {
  border-radius: 2px;
  bottom: 0;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.15) inset;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.theHoverBorder:hover {
  box-shadow: 0 0 0 3px var(--color-primary) inset;
}

.plus .theHoverBorder {
  background-position: center center;
}

.plus .theHoverBorder:hover {
  background-color: rgba(35, 35, 35, 0.5);
  background-image: url(../images/plus01.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.hoverBorder:after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: inset 0 0 0 3px hsla(0, 0%, 100%, .15);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow .2s ease-out, background-color .2s ease-out;
}

.hoverBorder:hover:after {
  box-shadow: inset 0 0 0 3px var(--color-primary);
}


/* Image Boxes */
.image-boxes {
  margin-bottom: 30px;
}

.image-boxes .hoverBorder {
  margin-bottom: 20px;
}

.image-boxes .box {
  margin-bottom: 20px;
}

.image-boxes img {
  height: auto;
  width: 100%;
}

.image-boxes a {
  display: block;
}

/* Style 1 */
.imgboxes_style1 {
  overflow: visible;
}

.imgboxes_style1 .hoverBorder h6 {
  background: var(--color-primary);
  border-radius: 2px;
  bottom: 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  left: -10px;
  line-height: 1;
  margin: 0;
  padding: 11px 15px;
  position: absolute;
}

.imgboxes_style1 .hoverBorder:hover h6 {
  left: 20px;
}

.imgboxes_style1 img {
  box-shadow: 0 1px 1px rgba(0, 0, 0, .22)
}

.imgboxes_style1 .hoverBorderWrapper {
  height: 270px;
}

/* Style 2 */
.image-boxes.ib_style2 .hoverBorder {
  margin-bottom: 0;
}

.imgboxes_style2 .imgboxes-wrapper {
  height: 270px;
}

/* Style 3 */
.slidingDetails {
  display: block;
  overflow: hidden;
  position: relative;
}

.slidingDetails img {
  position: relative;
}

.slidingDetails,
.slidingDetails .details {
  transition: all .2s cubic-bezier(0.680, 0, 0.265, 1);
}

.slidingDetails .details {
  background: #232323;
  background: rgba(35, 35, 35, 0.8);
  bottom: 0;
  color: #fff;
  left: 0;
  opacity: 0;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 100%;
  visibility: hidden;
}

.slidingDetails:hover .details {
  opacity: 1;
  top: 0;
  visibility: visible;
}

.slidingDetails:hover {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.slidingDetails .details h4 {
  margin-bottom: 20px;
}

/* Image Boxes 1 Style 4 */
.imgboxes_style4 .imgboxes-wrapper {
  display: inline-block;
  height: 270px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.imgboxes_style4 .imgboxes-title {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 30px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 0;
}

.imgboxes_style4 .imgboxes-title:before {
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 1) 100%);
  content: '';
  height: 200%;
  left: 0;
  opacity: .8;
  position: absolute;
  top: -100%;
  width: 100%;
  z-index: -1;
}

.imgboxes_style4 .imgboxes-title:after {
  background: var(--color-primary);
  content: '';
  display: block;
  height: 2px;
  margin: 0 auto;
  margin-top: 10px;
  transition: all 0.2s ease-out;
  width: 30px;
  z-index: 1;
}

.imgboxes_style4:hover .imgboxes-title:after {
  width: 60px;;
}

.imgboxes_style4 .imgbox_image {
  -webkit-backface-visibility: hidden;
  transition: opacity .2s ease-out;
}

.imgboxes_style4:hover .imgbox_image {
  opacity: .8
}

.cover-fit-img {
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}

.imgbox_image.cover-fit-img {
  height: 100%;
}

.contain-fit-img {
  -o-object-fit: contain;
  object-fit: contain;
  overflow: hidden;
}

/* Style bottom */
.imgboxes_style4.kl-title_style_bottom .imgboxes-title {
  padding: 0 20px 30px;
  text-align: left;
  transition: padding-bottom .2s ease-out;
}

.imgboxes_style4.kl-title_style_bottom .imgboxes-title:before {
  transition: opacity .2s ease-out;
}

.imgboxes_style4.kl-title_style_bottom .imgboxes-title:after {
  display: none;
}

.imgboxes_style4.kl-title_style_bottom .imgboxes-border-helper {
  border-bottom: solid 5px var(--color-primary);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  transition: all .2s ease-out;
  z-index: 1;
}

.imgboxes_style4.kl-title_style_bottom:hover .imgboxes-border-helper {
  border-bottom: solid 10px var(--color-primary);
}

.imgboxes_style4.kl-title_style_bottom:hover .imgboxes-title:before {
  opacity: .5;
}

.imgboxes_style4.kl-title_style_bottom:hover .imgboxes-title {
  padding-bottom: 25px;
  text-align: left;
}

.imgboxes_style4.kl-title_style_left .imgboxes-title {
  padding: 0 20px 30px;
  text-align: left;
}

.imgboxes_style4.kl-title_style_left .imgboxes-title:after {
  margin: 0;
  margin: initial;
  margin-top: 10px;
  width: 40px;
}

.imgboxes_style4.kl-title_style_left:hover .imgboxes-title:after {
  width: 80px;
}

.about_text .custom_container {
  width:100%
}

/*-----------------------------------------------------------------------------------*/
/*  Blog Comments
/*-----------------------------------------------------------------------------------*/
.comment-form-wrapper {
  margin-top: 50px;
}

/* recent comments */
#recentcomments {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

#recentcomments li {
  margin-bottom: 0;
  padding-left: 10px;
  position: relative;
}

#recentcomments li:before {
  color: #ddd;
  content: '\201C';
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}


/* process boxes */
.process_box {
  box-shadow: none;
  margin-bottom: 80px;
  padding: 0;
  position: relative;
}

.process_box:after {
  border-color: transparent;
  border-top-color: #eee;
  border-width: 10px;
  left: 10%;
  margin-left: -10px;
}

.process_box:before {
  border-color: transparent;
  border-top-color: #ccc;
  border-width: 13px;
  left: 10%;
  margin-left: -13px;
}

.process_box[data-align=right]:after,
.process_box[data-align=right]:before {
  left: auto;
  margin-left: 0;
  right: 10%;
}

.process_box[data-align=right]:before {
  margin-right: -13px;
}

.process_box[data-align=center]:after,
.process_box[data-align=center]:before {
  left: 50%;
}

.process_box[data-align=center]:after {
  border-top-color: #fff;
}

.process_box .number {
  bottom: 0;
  float: left;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 240px;
}

.process_box .number span {
  color: #333;
  display: block;
  font-family: var(--heading-font);
  font-size: 100px;
  font-weight: 900;
  line-height: 1;
  position: relative;
  top: 50%;
  vertical-align: middle;
}

.process_box .content {
  background-color: #fff;
  color: #929292;
  float: left;
  font-size: 14px;
  line-height: 30px;
  margin-left: 240px;
  padding: 25px 35px 20px 35px
}

.process_box .stp_title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.process_box[data-align=right] .number {
  border-right: 0;
  left: auto;
  right: 0;
}

.process_box[data-align=right] .content {
  color: #929292;
  font-size: 14px;
  line-height: 30px;
  margin-left: auto;
  margin-right: 240px;
  padding: 25px 35px 20px 35px;
}

/* end Process box element */


/* Price list element */
.priceListElement h3.menu-categ {
  color: #ccc;
  font-size: 14px;
  letter-spacing: 2px;
  text-align: center;
}

.priceListElement ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.priceListElement ul li {
  margin-bottom: 25px;
  margin-top: 25px;
}

.priceListElement-itemMain {
  margin-bottom: 10px;
}

.priceListElement .priceListElement-itemMain {
  display: flex;
}

.priceListElement-itemTitle {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.7;
  margin: 0;
}

.priceListElement .priceListElement-dottedSeparator {
  flex: 1 1 auto;
  margin-bottom: calc((24px - 14px) / 2);
  margin-left: 3px;
  margin-right: 5px;
}

.priceListElement .priceListElement-dottedSeparator,
.priceListElement-dash--separator > ul > li {
  background-image: radial-gradient(circle closest-side, rgba(0, 0, 0, .2) 99%, transparent 1%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 5px 3px;
}

.priceListElement-itemPrice {
  color: var(--color-primary);
  text-align: right;
}

.priceListElement .priceListElement-itemTitle,
.priceListElement .priceListElement-itemPrice {
  flex: 0 1 auto;
}

.priceListElement-itemPrice {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

/* end Price list element */


/* Counter element */
.fun-fact {
  text-align: center;
}

.fun-fact .fun-number {
  color: #23262d;
  display: block;
  font-size: 80px;
  font-weight: 300;
  height: 80px;
  line-height: 80px;
}

.fun-fact .fun-thing {
  color: #45474d;
  display: block;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 40px;
  position: relative;
}

.fun-fact .fun-thing.topline::before {
  background: #dcddde;
  content: '';
  height: 1px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: -15px;
  width: 80px;
}

/* end Counter element */


/* PROGRESS BARS SKILLS ELEMENT */
.skills_wgt ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.skills_wgt ul li {
  margin-top: 15px;
}

.skills_wgt ul li:first-child {
  margin-top: 0;
}

.skills_wgt .skill-title {
  color: #888888;
  font-size: 13px;
  line-height: 1;
  margin: 0 0 10px;
  margin-bottom: 13px;
}

.skills_wgt.stg-dark .skill-title {
  color: #45474d;
}

.skills_wgt.stg-light .skill-title {
  color: #f5f5f5;
}

.skills_wgt .skill-bar {
  background: #efefef;
  border-radius: 2px;
  display: block;
  height: 13px;
}

.skills_wgt.whitebars .skill-bar {
  background: #fff;
}

.skills_wgt .skill-bar > i {
  background: #999;
  border-radius: 2px;
  display: block;
  height: 13px;
  position: relative;
  transition: all 0.5s ease-out;
  width: 0;
}

.skills_wgt li:nth-child(1) .skill-bar > i {
  transition-delay: 0.3s;
}

.skills_wgt li:nth-child(2) .skill-bar > i {
  transition-delay: 0.4s;
}

.skills_wgt li:nth-child(3) .skill-bar > i {
  transition-delay: 0.5s;
}

.skills_wgt li:nth-child(4) .skill-bar > i {
  transition-delay: 0.6s;
}

.skills_wgt li:nth-child(5) .skill-bar > i {
  transition-delay: 0.7s;
}

.skills_wgt li:nth-child(6) .skill-bar > i {
  transition-delay: 0.8s;
}

.skills_wgt li:nth-child(7) .skill-bar > i {
  transition-delay: 0.9s;
}

.skills_wgt li:nth-child(8) .skill-bar > i {
  transition-delay: 1.1s;
}

.skills_wgt li:nth-child(9) .skill-bar > i {
  transition-delay: 1.1s;
}

.skills_wgt li:nth-child(10) .skill-bar > i {
  transition-delay: 1.2s;
}

.skills_wgt.stg-rounded .skill-bar,
.skills_wgt.stg-rounded .skill-bar > i {
  border-radius: 9px;
}

.skills_wgt.started .skill-bar[data-loaded="10"] > i {
  width: 10%;
}

.skills_wgt.started .skill-bar[data-loaded="10"] > i::before {
  content: "10%";
}

.skills_wgt.started .skill-bar[data-loaded="20"] > i {
  width: 20%;
}

.skills_wgt.started .skill-bar[data-loaded="20"] > i::before {
  content: "20%";
}

.skills_wgt.started .skill-bar[data-loaded="30"] > i {
  width: 30%;
}

.skills_wgt.started .skill-bar[data-loaded="30"] > i::before {
  content: "30%";
}

.skills_wgt.started .skill-bar[data-loaded="40"] > i {
  width: 40%;
}

.skills_wgt.started .skill-bar[data-loaded="40"] > i::before {
  content: "40%";
}

.skills_wgt.started .skill-bar[data-loaded="50"] > i {
  width: 50%;
}

.skills_wgt.started .skill-bar[data-loaded="50"] > i::before {
  content: "50%";
}

.skills_wgt.started .skill-bar[data-loaded="60"] > i {
  width: 60%;
}

.skills_wgt.started .skill-bar[data-loaded="60"] > i::before {
  content: "60%";
}

.skills_wgt.started .skill-bar[data-loaded="70"] > i {
  width: 70%;
}

.skills_wgt.started .skill-bar[data-loaded="70"] > i::before {
  content: "70%";
}

.skills_wgt.started .skill-bar[data-loaded="80"] > i {
  width: 80%;
}

.skills_wgt.started .skill-bar[data-loaded="80"] > i::before {
  content: "80%";
}

.skills_wgt.started .skill-bar[data-loaded="90"] > i {
  width: 90%;
}

.skills_wgt.started .skill-bar[data-loaded="90"] > i::before {
  content: "90%";
}

.skills_wgt.started .skill-bar[data-loaded="100"] > i {
  width: 100%;
}

.skills_wgt.started .skill-bar[data-loaded="100"] > i::before {
  content: "100%";
}

.skills_wgt .skill-bar[data-color="t-red"] > i {
  background: var(--color-primary);
}

.skills_wgt .skill-bar[data-color="blue"] > i {
  background: #5476a9;
}

.skills_wgt .skill-bar[data-color="red"] > i {
  background: #f46e6e;
}

.skills_wgt.started .skill-bar i::before {
  background: #1a1c25;
  border-radius: 3px;
  bottom: 100%;
  color: #fff;
  display: block;
  font-size: 11px;
  font-weight: bold;
  height: 22px;
  left: 100%;
  line-height: 22px;
  margin-left: -25px;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 35px;
}

.skills_wgt.started .skill-bar i::after {
  border: 4px solid transparent;
  border-top-color: #1a1c25;
  bottom: 100%;
  content: '';
  left: 100%;
  margin-left: -12px;
  position: absolute;
}

.skills_wgt.started .skill-bar i::before,
.skills_wgt.started .skill-bar i::after {
  margin-bottom: 30px;
  opacity: 0;
  visibility: hidden;
}

.skills_wgt.started .skill-bar:hover i::before,
.skills_wgt.started .skill-bar:hover i::after {
  margin-bottom: 15px;
  opacity: 1;
  visibility: visible;
}

.skills_wgt.started .skill-bar:hover i::after {
  margin-bottom: 7px;
}

/* end PROGRESS BARS SKILLS ELEMENT */


/* Accordions elements */
/* Accordion Default Style / Re-Styling / */
.hg_accordion_element.default-style > h3 {
  font-size: 18px;
  line-height: 1.4;
  margin-top: 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}

.hg_accordion_element.default-style .th-accordion {
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}

.hg_accordion_element.default-style .th-accordion .acc-group {
  border-bottom: 1px solid #E3E3E3;
  margin-bottom: 0;
}

.hg_accordion_element.default-style .th-accordion .acc-group:last-child {
  border-bottom: 0;
}

.hg_accordion_element.default-style .th-accordion .acc-group a {
  background-color: #F8F8F8;
  border: 0;
  color: var(--color-primary);
  cursor: pointer;
  display: block;
  font-size: 0.95rem;
  font-weight: 600;
  padding: 4px 10px;
  padding: 20px 60px 20px 25px;
  position: relative;
  text-align: left;
  width: 100%;
}

.hg_accordion_element.default-style .th-accordion .acc-group a.collapsed {
  background-color: #fff;
  color: #505050;
}

.hg_accordion_element.default-style .th-accordion .acc-group a:hover {
  background: #F8F8F8;
  color: #111;
}

.hg_accordion_element.default-style .th-accordion .acc-group .content {
  background-color: #F8F8F8;
  padding: 20px 25px;
}

.hg_accordion_element.default-style .th-accordion .acc-group a:not(.collapsed) {
  border-bottom: 1px solid #EEEEEE;
}

.hg_accordion_element.default-style .th-accordion .acc-group a:before,
.hg_accordion_element.default-style .th-accordion .acc-group a:after {
  background: #ddd;
  content: ' ';
  display: block;
  height: 2px;
  position: absolute;
  transition: all .3s ease-in-out;
  width: 10px;
}

.hg_accordion_element.default-style .th-accordion .acc-group a:before {
  right: 31px;
  top: 50%;
  transform: rotate(-45deg);
}

.hg_accordion_element.default-style .th-accordion .acc-group a:after {
  right: 25px;
  top: 50%;
  transform: rotate(45deg);
}

.hg_accordion_element.default-style .th-accordion .acc-group a.collapsed:before {
  transform: rotate(45deg);
}

.hg_accordion_element.default-style .th-accordion .acc-group a.collapsed:after {
  transform: rotate(-45deg);
}

/* Accordions style2 */
.hg_accordion_element.style2 .th-accordion .acc-group {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 2px;
  padding: 0;
}

.hg_accordion_element.style2 .th-accordion .acc-group a {
  background: transparent;
  background-color: #FFFFFF;
  border: 0;
  color: var(--color-primary);
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 15px;
  position: relative;
  text-align: left;
  width: 100%;
}

.hg_accordion_element.style2 .th-accordion .acc-group a.collapsed {
  background-color: transparent;
  color: #505050;
}

.hg_accordion_element.style2 .th-accordion .acc-group .content {
  background-color: #FFFFFF;
  border-top: 1px solid #E5E5E5;
  padding: 10px 15px;
}

.hg_accordion_element.style2 .th-accordion .acc-group .content p:last-child {
  margin-bottom: 0;
}

.hg_accordion_element.style2 .th-accordion .acc-group a:before,
.hg_accordion_element.style2 .th-accordion .acc-group a:after {
  background: #BDBDBD;
  content: ' ';
  display: block;
  height: 2px;
  position: absolute;
  transition: all .3s ease-in-out;
  width: 8px;
}

.hg_accordion_element.style2 .th-accordion .acc-group a:before {
  right: 20px;
  top: 50%;
  transform: rotate(-45deg);
}

.hg_accordion_element.style2 .th-accordion .acc-group a:after {
  right: 15px;
  top: 50%;
  transform: rotate(45deg);
}

.hg_accordion_element.style2 .th-accordion .acc-group a.collapsed:before {
  transform: rotate(45deg);
}

.hg_accordion_element.style2 .th-accordion .acc-group a.collapsed:after {
  transform: rotate(-45deg);
}

/* Accordions style3 */
.hg_accordion_element.style3 .th-accordion {
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 15px;
}

.hg_accordion_element.style3 .th-accordion .acc-group {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 8px;
  margin-left: 54px;
  padding-bottom: 8px;
}

.hg_accordion_element.style3 .th-accordion .acc-group:last-child {
  border-bottom: 0;
}

.hg_accordion_element.style3 .th-accordion .acc-group a {
  background: none;
  border: 0;
  color: var(--color-primary);
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin: 0;
  padding: 10px 10px 5px 0;
  position: relative;
  text-align: left;
  width: 100%;
}

.hg_accordion_element.style3 .th-accordion .acc-group a.collapsed {
  color: #505050;
}

.hg_accordion_element.style3 .th-accordion .acc-group a:after {
  height: auto;
  padding-right: 14px;
  width: auto;
}

.hg_accordion_element.style3 .th-accordion .acc-group .content {
  padding: 10px 15px 10px 0;
}

.hg_accordion_element.style3 .th-accordion .acc-group .content p:last-child {
  margin-bottom: 0;
}

/* Accordions - Style 4 */
.hg_accordion_element.style4 .th-accordion {
  border: solid 1px #CE2123;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
}

.hg_accordion_element.style4 .th-accordion .acc-group {
  margin-bottom: 0px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a {
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  padding: 4px 10px;
  position: relative;
  text-align: left;
  width: 100%;
}

.hg_accordion_element.style4 .th-accordion .acc-group a:hover {
  background: #dcdcdc;
  color: #111;
}

.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="true"] {
  color: var(--color-primary);
}

.hg_accordion_element.style4 .th-accordion .acc-group .content {
  padding: 10px;
}

.hg_accordion_element.style4 > h3 {
  background-color: var(--color-primary);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 15px 20px;
  text-shadow: none;
  text-transform: uppercase;
}

.hg_accordion_element.style4 .th-accordion .acc-group {
  overflow: hidden;
}

.hg_accordion_element.style4 .th-accordion .acc-group:last-child a[aria-expanded="true"] {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="true"] {
  background: #ffffff;
  border-bottom: solid 1px #E6E6E6;
}

.hg_accordion_element.style4 .th-accordion .acc-group a {
  background-color: #f9f9f9;
  border-top: solid 1px #E6E6E6;
  overflow: hidden;
  padding: 12px 20px;
}

.hg_accordion_element.style4 .th-accordion .acc-group:first-child a {
  border-top: none;
}

.hg_accordion_element.style4 .th-accordion .acc-group:first-child a[aria-expanded="true"] {
  border-top: none;
}

.hg_accordion_element.style4 .th-accordion .acc-group a:hover {
  background: #F5F5F5;
}

.hg_accordion_element.style4 .th-accordion .acc-group .content {
  background: #fff;
  border-bottom: none;
  padding: 20px;
}

.hg_accordion_element.style4 .th-accordion .acc-group:last-child .content {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="false"] span.acc-icon {
  border: 7px solid #000;
  border-radius: 50%;
  height: 70px;
  position: absolute;
  right: -31px;
  top: -7px;
  width: 70px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="true"] span.acc-icon:before,
.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="true"] span.acc-icon:after {
  background-color: #000;
  content: '';
  display: inline-block;
  left: 50%;
  line-height: 63px;
  position: absolute;
  text-align: center;
  top: 50%;
}

.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="true"] span.acc-icon:before {
  height: 6px;
  margin-left: -9px;
  margin-top: -3px;
  width: 20px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="true"] span.acc-icon:after {
  height: 19px;
  margin-left: -2px;
  margin-top: -10px;
  width: 6px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a span.acc-icon {
  border: 7px solid var(--color-primary);
  border-radius: 50%;
  height: 70px;
  opacity: .05;
  position: absolute;
  right: -20px;
  top: -7px;
  transition: all .25s ease-in-out;
  width: 70px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a span.acc-icon:before {
  background-color: var(--color-primary);
  content: '';
  display: inline-block;
  height: 6px;
  left: 50%;
  line-height: 63px;
  margin-left: -9px;
  margin-top: -3px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px;
}

.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded="true"]:hover span.acc-icon {
  opacity: .05;
}

/* Accordions - Style 5 */
.hg_accordion_element.style5 .th-accordion {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 3px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.hg_accordion_element.style5 .th-accordion .acc-group {
  background: transparent;
  border-radius: 0;
  border-width: 1px 0 0;
  box-shadow: none;
  margin-bottom: 0;
}

.hg_accordion_element.style5 .th-accordion .acc-group {
  border-top: 1px solid #efefef;
}

.hg_accordion_element.style5 .th-accordion .acc-group:first-child {
  border: 0;
}

.hg_accordion_element.style5 .th-accordion a {
  background: none;
  border: 0;
  color: #474747;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  line-height: 2;
  min-height: 55px;
  outline: none;
  padding: 12px 20px 10px 55px;
  position: relative;
  text-align: left;
  width: 100%;
}

.hg_accordion_element.style5 .th-accordion a:hover {
  color: #909090;
}

.hg_accordion_element.style5 .th-accordion a::before {
  background: var(--color-primary);
  border-radius: 2px;
  color: #fff;
  content: attr(data-number);
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: 22px;
  left: 17px;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 17px;
  width: 22px;
}

.hg_accordion_element.style5 .th-accordion a.collapsed::before {
  background: #d3d3d3;
}

.hg_accordion_element.style5 .th-accordion .acc-group > div:last-child {
  color: #888;
  font-size: 0.95rem;
  line-height: 1.7;
  margin: 0 17px 0 55px;
}

.hg_accordion_element.style5 .th-accordion ul {
  margin-bottom: 20px;
}

.hg_accordion_element.style5 .th-accordion .inner-acc {
  padding: 15px 0 0;
}

.hg_accordion_element.style5 .th-accordion.filled-bg {
  background-color: #f4f4f4;
}


/* 404 error page */
.error404 .page-subheader {
  height: 200px;
  min-height: 0;
}

.error404-content {
  line-height: 1;
  margin: 20px auto;
  text-align: center;
}

.error404-content h2 {
  color: transparent;
  font-size: 260px;
  font-weight: bold;
  line-height: 1;
  margin: 20px 0;
  text-align: center;
  text-shadow: 0 0 0 #f3f3f3;
  text-transform: uppercase;
}

.error404-content h3 {
  font-size: 30px;
  opacity: 0.2;
  text-align: center;
}

.error404 .search {
  margin: 40px auto;
  text-align: center;
}

.error404 .gensearch__form {
  display: inline-block;
  position: relative;
  width: 300px;
}


/* Historic timeline */
.timeline_bar {
  position: relative;
}

.timeline_bar:before {
  background-color: rgba(0, 0, 0, 0.09);
  content: '';
  display: block;
  height: 97%;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: 26px;
  width: 1px;
  z-index: 0;
}

.timeline_bar > .row {
  margin-bottom: 0;
}

.timeline_box {
  background: #fff;
  border-radius: 3px;
  margin: 30px 30px 30px 0;
  padding: 30px;
  position: relative;
}

div[data-align="right"] .timeline_box {
  margin: 30px 0 30px 30px;
}

.timeline_box:after {
  border: 10px solid transparent;
  border-left-color: #fff;
  content: '';
  margin-top: -10px;
  position: absolute;
  right: -20px;
  top: 50%;
}

div[data-align=right] .timeline_box:after {
  border-left-color: transparent;
  border-right-color: #fff;
  left: -20px;
  right: auto;
}

div[data-align=right] .timeline_box:before {
  left: -70px;
  right: auto;
}

.end_timeline {
  text-align: center;
}

.timeline_box,
.timeline_box .date {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.end_timeline > span {
  background: #fff;
  border-radius: 3px;
  display: inline-block;
  line-height: 2;
  padding: 5px 20px;
}

.timeline_box:hover:before {
  background-color: var(--color-primary);
}

.timeline_box .date {
  background: #fff;
  border-radius: 2px;
  color: #787878;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  left: calc(100% + 90px);
  margin-top: -14px;
  padding: 5px 10px;
  position: absolute;
  right: -100px;
  right: auto;
  top: 50%;
  white-space: nowrap;
}

.timeline_box .htitle {
  font-weight: bold;
}

div[data-align=right] .timeline_box .date {
  left: auto;
  right: calc(100% + 90px)
}


/* BLOG */
.eBlog.itemListView {
  margin-bottom: 35px;
}

.eBlog .itemContainer {
  background: #FFF;
  border-radius: 3px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
  margin-bottom: 35px;
  padding: 30px 30px 15px 30px;
  position: relative;
}

.eBlog .itemContainer:after {
  border-bottom-color: #ccc !important;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  bottom: 0;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  width: 0;
}

.eBlog .post_details {
  color: #9a9a9a;
  font-size: 0.8rem;
  margin-bottom: 15px;
  padding: 10px 0 10px;
  position: relative;
  text-transform: uppercase;
  transition: opacity 150ms ease-in;
}

.eBlog .post_details [class^="icon-"],
.eBlog .itemLinks li [class^="icon-"],
.eBlog .itemTagsBlock [class^="icon-"] {
  margin: 0 5px 0 0;
  padding: 0;
}

.eBlog .itemTitle {
  margin: 0 0 10px
}

.eBlog .itemBody {
  line-height: 1.8
}

.eBlog.itemView .itemBody {
  margin-bottom: 50px
}

.eBlog .post_details:before {
  background-color: #ddd;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 60px
}

.eBlog .post_details .infSep {
  display: inline-block;
  margin: 0 5px;
}

.eBlog .itemBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  margin-top: 20px;
  padding-bottom: 15px;
}

.eBlog .readMore {
  font-size: 14px;
  font-weight: bold;
}

.eBlog .itemLinks {
  color: #AAA;
  float: left;
  font-size: 0.8rem;
  font-weight: 600;
  list-style: none !important;
  margin-bottom: 4px;
  margin-left: 0;
  margin-top: 0;
  padding-left: 0;
  text-transform: uppercase;
}

.eBlog .itemComments {
  float: right;
}

.eBlog .itemComments a {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.eBlog .itemLinks li {
  float: left;
  margin-left: 0;
  padding-left: 0;
}

.eBlog .itemLinks li:first-child {
  margin-left: 0;
}

.eBlog .itemCategory {
  padding-left: 0;
}

.eBlog .itemDateCreated {
  padding-left: 0;
}

.eBlog .itemTagsBlock,
.eBlog .itemTagsBlock,
.eBlog .userItemTagsBlock {
  color: #AAA;
  font-size: 0.72rem;
  font-weight: 600;
  padding-left: 0;
  text-transform: uppercase;
}

.eBlog .itemCategory span.fa-folder {
  margin-right: 3px;
}

.eBlog .itemTagsBlock,
.eBlog .itemTagsBlock span.fa-tag {
  margin-right: 3px;
}

.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock {
  float: left
}

.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock ul {
  display: inline;
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock ul li {
  display: inline;
}

.itemView .itemTagsBlock {
  margin: 0 0 20px
}

.itemView .itemTagsBlock > a, .eBlog .itemContainer:not(.hg_columns) .itemTagsBlock > a, .tagcloud a {
  background-color: #EAEAEA;
  color: #9B9B9B;
  display: inline-block;
  margin: 0 5px 5px 10px;
  padding: 4px 10px;
}

div.cloudBlock a {
  display: block;
  float: left;
  padding: 4px;
}

.eBlog .itemReadMore {
  float: right;
}

.kl-blog-post-socsharing {
  list-style: none;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0;
}

.kl-blog-post-socsharing li {
  float: left;
  margin-right: 20px;
  width: 70px;
}

.blog-item-share {
  display: inline-block
}

.blog-item-share:before {
  color: #8a8a8a;
  content: attr(data-share-title);
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}

.blog-item-share-link {
  background-color: transparent;
  border: 0;
  border-bottom: 0;
  border-color: #e2e2e2;
  border-radius: 3px;
  border-right: 0;
  border-top: 0;
  color: rgba(0, 0, 0, .25);
  display: inline-block;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  margin-right: 5px;
  margin-top: -5px;
  text-align: center;
  width: 30px
}

.eBlog .itemList .blog-isotope-item {
  padding-left: 10px;
  padding-right: 10px
}

.itemContainer.hg_columns {
  padding: 0 0 20px;
}

.itemContainer.hg_columns .itemHeader,
.itemContainer.hg_columns .itemBody,
.itemContainer.hg_columns .itemLinks,
.itemContainer.hg_columns .itemTagsBlock {
  padding: 0 15px;
}

.itemContainer.hg_columns .itemIntroText {
  border-bottom: 1px solid #F5F5F5;
  font-size: 0.95rem;
  margin: 0 0 15px;
  padding: 0 0 15px;
}

.itemContainer .theHoverBorder {
  border-radius: 0;
  box-shadow: none
}

.itemContainer.hg_columns .hoverBorder,
.itemContainer.hg_columns .hoverBorder img {
  border-radius: 0;
  height: auto;
  width: 100%
}

.itemContainer.hg_columns .readMore {
  font-weight: normal
}

.itemContainer.hg_columns h3.itemTitle {
  font-size: 1.2rem;
  line-height: 1.6;
  padding: 20px 0 0;
}

.itemContainer.hg_columns.hg_columns4 .readMore,
.itemContainer.hg_columns.hg_columns3 .readMore {
  font-size: 13px;
}

.itemThumbnail {
  position: relative;
}

.itemThumbnail img {
  display: block;
  height: auto;
  width: 100%;
}

.itemThumbnail > a {
  display: block;
}

.itemThumbnail .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
}

.itemContainer.hg_columns:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.itemThumbnail .overlay__inner {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.itemThumbnail .overlay__inner a {
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  display: block;
  height: 48px;
  line-height: 46px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: all .3s ease-in-out;
  width: 48px;
}

.itemThumbnail .overlay__inner a:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
  width: 120px;
}

.itemThumbnail .overlay__inner a:before {
  content: "+";
  font-size: 20px;
  font-weight: bold;
  left: 50%;
  margin-left: -6px;
  opacity: 1;
  position: absolute;
  transition: opacity .25s ease-in-out;
}

.itemThumbnail .overlay__inner a:hover:before {
  opacity: 0;
}

.itemThumbnail .overlay__inner a:after {
  content: attr(data-readmore);
  font-size: 11px;
  opacity: 0;
  transition: opacity .25s ease-in-out;
}

.itemThumbnail .overlay__inner a:hover:after {
  opacity: 1;
}

.eBlog .featured-post {
  padding: 0;
  position: relative;
}

.eBlog .itemContainer.featured-post:after {
  content: none;
}

.eBlog .featured-post .hg_full_image {
  border-bottom: none;
  min-height: 220px;
  position: relative;
}

.eBlog .featured-post .hg_full_image img {
  width: 100%;
}

.eBlog .featured-post .zn_sticky_no_image {
  background-color: #333;
  min-height: 400px;
}

.eBlog .featured-post .itemFeatContent {
  bottom: 0;
  left: 0;
  padding: 30px;
  position: absolute;
  right: 0;
}

.eBlog .featured-post .itemFeatContent:before {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  content: '';
  height: 150%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: -50%;
  visibility: hidden;
  width: 100%;
}

.eBlog .featured-post .itemFeatContent-inner {
  position: relative;
}

.eBlog .featured-post .itemHeader {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.eBlog .featured-post .itemTitle {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.6;
  margin: 0 0 15px;
  text-transform: uppercase;
}

.eBlog .featured-post .post_details {
  color: rgba(255, 255, 255, 0.4);
  margin: 0;
  padding: 0;
}

.eBlog .featured-post .post_details:before {
  content: none;
}

.eBlog .featured-post .itemLinks {
  color: rgba(255, 255, 255, 0.4);
}

.eBlog .featured-post a {
  color: #fff;
}

.eBlog .featured-post a:hover {
  color: rgb(199, 199, 199);
}

.hg_post_image {
  float: left;
  margin-bottom: 10px;
  margin-right: 30px;
}

.hg_post_image,
.hg_full_image {
  border-bottom: 5px solid var(--color-primary);
}

#th-content-post .page-title {
  border-bottom: 0;
  padding-bottom: 0
}

/* Post Author */
.eBlog .post-author .author-avatar {
  float: left;
  margin-right: 20px;
  width: 100px;
}

.eBlog .post-author .author-avatar img {
  border-radius: 50%;
  display: block;
  height: auto;
  max-width: 100%;
}

.eBlog .post-author {
  border-bottom: 1px solid #E9E9E9;
  border-top: 1px solid #E9E9E9;
  color: #535353;
  margin-bottom: 50px;
  overflow: hidden;
  padding: 30px 0;
}

.eBlog .author-details > h4 {
  text-transform: uppercase;
}

.masonry-columns {
  height: auto !important;
}

.bigblog-page #footer {
  top: 100%;
}


/* Blockquote */
blockquote {
  margin: 25px 40px
}

/* Related Articles */
.eBlog .related-articles .rta-title {
  font-size: 14px;
  margin: 0 0 30px;
  text-align: center;
  text-transform: uppercase;
}

.eBlog .related-articles .rta-post > a {
  display: block;
  position: relative;
}

.eBlog .related-articles .rta-post > a:after {
  background-color: var(--color-primary);
  bottom: 0;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
  transition: height .25s ease-in-out;
}

.eBlog .related-articles .rta-post:hover > a:after {
  height: 8px;
}

.eBlog .related-articles .rta-post h5 {
  margin-top: 10px;
  text-transform: uppercase;
}

/* Pagination */
.pagination-nav {
  text-align: center;
}

.pagination-nav .pagination {
  display: inline-block;
}

.pagination {
  color: #888;
  display: block;
  font-size: 10px;
  line-height: 1.6;
  margin: 50px 0 0;
  text-align: center;
  text-transform: uppercase;
}

/* Do some bootstrap resetting */
.pagination ul,
ul.pagination {
  list-style-type: none;
  padding: 10px 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination a,
.pagination li > a,
.pagination li > a:focus,
.pagination li > a:hover,
.pagination li > span,
.pagination li > span:focus,
.pagination li > span:hover {
  background: none;
  border: 0;
  text-shadow: none;
}

/* Normal state */
.pagination a,
.pagination li a,
.pagination li > span,
.pagination p.counter {
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  float: none;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 40px;
}

/* Move left and right arrows */
.pagination li:first-child {
  margin-right: 50px;
}

.pagination li:last-child {
  margin-left: 50px;
}

/* hide first or last if no pages */
.pagination li:first-child > span,
.pagination li:last-child > span {
  display: none;
}

/* Add left & right glyphs */
.pagination li:first-child span.zn_icon,
.pagination li:last-child span.zn_icon {
  line-height: 40px;
}

/* Hover state */
.pagination a,
.pagination li a:hover,
.pagination li:first-child > a:hover:before,
.pagination li:last-child > a:hover:before {
  color: rgba(0, 0, 0, 0.5);
}

/* Active */
.pagination .active_page,
.pagination .active > a,
.pagination .active > a:focus,
.pagination .active > a:hover,
.pagination .active > span,
.pagination .active > span:focus,
.pagination .active > span:hover {
  background: none;
  border: 2px solid rgba(0, 0, 0, 0.18);
  color: rgba(0, 0, 0, 0.5);
  height: 40px;
  line-height: 38px;
  width: 40px;
}

.pagination a.next_link {
  margin-left: 20px;
}

.pagination a.previous_link {
  margin-right: 20px;
}

a.grayHover {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

a.grayHover img {
  position: relative;
}


/* Blog posts for latest news */
.blog-posts {
  margin-left: -15px;
  margin-right: -15px;
  *zoom: 1;
}

.blog-posts::before, .blog-posts::after {
  content: "";
  display: table;
  line-height: 0;
}

.blog-posts::after {
  clear: both;
}

.blog-posts + hr {
  border-bottom: 2px solid #ededed;
}

.blog-posts .bpost {
  margin-bottom: 60px;
}

.blog-posts .bpost .post-head {
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}

.blog-posts .bpost .post-head > [data-role="title"] {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  margin: 20px 35px 15px 0;
}

.blog-posts .bpost .post-head > [data-role="title"],
.blog-posts .bpost .post-head > [data-role="title"] a {
  color: #2b2d30;
}

.blog-posts .bpost .post-head > [data-role="title"] a:hover {
  color: #fe6060;
  text-decoration: none;
}

.blog-posts .bpost.col2 .post-head > [data-role="title"] {
  font-size: 24px;
}

.blog-posts .bpost.col3 .post-head > [data-role="title"] {
  font-size: 20px;
}

.blog-posts .bpost.col4 .post-head > [data-role="title"] {
  font-size: 16px;
}

.blog-posts .bpost.col5 .post-head > [data-role="title"] {
  font-size: 14px;
}

.blog-posts .bpost .post-head .date {
  bottom: 15px;
  color: #fe6060;
  float: right;
  font-size: 15px;
  line-height: 1;
  margin-top: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 30px;
}

.blog-posts .bpost .post-head .date strong {
  display: block;
  font-size: 25px;
}

.blog-posts .bpost .post-details {
  color: #a2a2a2;
  font-size: 12px;
  font-style: italic;
  line-height: 1.6;
  margin: 15px 0;
}

.blog-posts .bpost .post-details a {
  color: #808080;
}

.blog-posts .bpost .post-content {
  color: #777777;
  margin-bottom: 20px;
}

.blog-posts .bpost .readmore-btn {
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
}

/* Blog Post Widget Style for latest news*/
.bpost.bp-widget .post-head {
  margin-bottom: 20px;
}

.bpost.bp-widget .post-head .post-image {
  margin-bottom: 15px;
}

.bpost.bp-widget [data-role="category"] a {
  color: #c1c1c1;
  text-decoration: none;
}

.bpost.bp-widget [data-role="title"] {
  margin: 10px 0 15px;
}

.bpost.bp-widget [data-role="title"],
.bpost.bp-widget [data-role="title"] a {
  color: #45474d;
}

.bpost.bp-widget [data-role="title"] a:hover {
  color: #fe6060;
  text-decoration: none;
}

.bpost.bp-widget .post-content,
.bpost.bp-widget .post-details,
.bpost.bp-widget .post-details a {
  color: #888888;
}

.bpost.bp-widget .post-details {
  font-size: 13px;
  font-style: normal;
  margin-bottom: 20px;
}

.bpost.bp-widget .post-details a:hover {
  color: #fe6060;
  text-decoration: none;
}

/* EventBox */
.eventbox {
  background: #f4f4f4;
  color: #45474d;
}

.eventbox .vs-icon {
  color: #c8c9ca;
}

.eventbox .eventdate {
  font-size: 14px;
  margin-bottom: 30px;
  padding: 20px 0;
  position: relative;
}

.eventbox .eventdate::before,
.eventbox .eventdate::after {
  background: #d1d1d3;
  content: '';
  height: 1px;
  left: 50%;
  margin-left: -26px;
  position: absolute;
  top: 0;
  width: 52px;
}

.eventbox .eventdate::after {
  bottom: 0;
  top: auto;
}

.eventbox .eventdate > .date {
  display: block;
}

.eventbox .eventpic {
  margin-top: 40px;
  position: relative;
}

.eventbox .eventpic:before {
  border: 12px solid rgba(0, 0, 0, 0);
  border-top-color: #f4f4f4;
  content: '';
  left: 50%;
  margin-left: -6px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.eventbox .eventpic img {
  position: relative;
  z-index: 0;
}

.eventbox .eventpic .btn {
  bottom: 40px;
  left: 20%;
  position: absolute;
  right: 20%;
  z-index: 1;
}


/* PORTFOLIO */

/* Portfolio Item */
.hg-portfolio-item {
  position: relative;
}

.portfolio-item-title {
  color: #4D4D4D;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;
}

.portfolio-item-desc {
  margin-bottom: 30px
}

.portfolio-item-desc-inner {
  max-height: 150px;
  overflow: hidden;
  position: relative;
}

.portfolio-item-desc-inner:after {
  background: linear-gradient(to bottom, rgba(245, 245, 245, 0) 0, #fbfbfb 100%);
  bottom: 0;
  content: '';
  display: block;
  height: 100px;
  left: 0;
  position: absolute;
  width: 100%;
}

.portfolio-item-more-toggle {
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
}

.portfolio-item-more-toggle .fas {
  font-size: 11px;
  margin-right: 4px;
}

.portfolio-item-more-toggle:after {
  content: attr(data-more-text);
}

/* when opened */
.portfolio-item-desc.is-opened .portfolio-item-desc-inner {
  max-height: none;
  overflow: hidden;
}

.portfolio-item-desc.is-opened .portfolio-item-desc-inner:after {
  display: none;
}

.portfolio-item-desc.is-opened .portfolio-item-more-toggle:after {
  content: attr(data-less-text);
}

.portfolio-item-desc.is-opened .portfolio-item-more-toggle .fas:before {
  content: "\f077";
  font-weight: 900;
}

/* no-toggle */
.portfolio-item-desc.no-toggle .portfolio-item-desc-inner {
  max-height: none;
  overflow: hidden;
}

.portfolio-item-desc.no-toggle .portfolio-item-more-toggle {
  display: none;
}

.portfolio-item-desc.no-toggle .portfolio-item-desc-inner:after {
  display: none;
}

/* when in affix */
.portfolio-item-content.affixcontent .portfolio-item-desc.is-opened:not(.no-toggle) .portfolio-item-desc-inner {
  max-height: 35vh;
  overflow: auto;
}

/* details */
.portfolio-item-details {
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
}

.portfolio-item-details li {
  margin-bottom: 15px;
}

.portfolio-item-details-label {
  color: #ababab;
  float: left;
  font-weight: 300;
  min-width: 100px;
}

.portfolio-item-details-item {
  float: left;
  overflow: hidden;
}

/* Other details */
.portfolio-item-otherdetails {
  margin-bottom: 30px;
}

.portfolio-item-livelink {
  display: inline-block;
  margin-top: 5px;
}

.portfolio-item-share {
  display: block;
  margin-bottom: 30px;
}

.portfolio-item-share:before {
  color: #8A8A8A;
  content: attr(data-share-title);
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}

.portfolio-item-share a {
  background-color: transparent;
  border: 0;
  border-bottom: 0;
  border-color: #E2E2E2;
  border-radius: 3px;
  border-right: 0;
  border-top: 0;
  color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  margin-right: 5px;
  margin-top: -5px;
  text-align: center;
  width: 30px;
}

.portfolio-item-share a:hover {
  color: #fff;
}

.portfolio-item-share .portfolio-item-share-twitter {
  font-size: 13px;
}

.portfolio-item-share-twitter:hover {
  background: #55ACEE;
}

.portfolio-item-share-facebook:hover {
  background: #3B5998;
}

.portfolio-item-share-gplus:hover {
  background: #CC3732;
}

.portfolio-item-share-pinterest:hover {
  background: #CC2127;
}

.portfolio-item-share-mail:hover {
  background: #565656;
}

/* Right part */
.hg-portfolio-item .portfolio-item-right {
  margin-bottom: 20px;
}

.portfolio-item {
  margin-bottom: 50px;
}

/* Affixed Portfolio content */
.portfolio-item-content.affixcontent {
  -webkit-backface-visibility: hidden;
  transition: top .2s ease-out;
}

/* MQ's */
@media (min-width: 992px) and (max-width: 1199px) {
  .page-title.portfolio-item-title {
    font-size: 24px;
    line-height: 1.3;
  }
}

@media (min-width: 992px) {
  .portfolio-item-content.pinned {
    top: 60px !important;
  }
  .pricing-table-element .plan-column {
    width: 17%;
  }
}

@media (max-width: 991px) {
  .portfolio-item-content.affixcontent {
    position: static !important;
  }

  .page-title.portfolio-item-title {
    font-size: 24px;
    line-height: 1.3;
  }

  .portfolio-item-details-label {
    display: block;
    float: none;
    margin-bottom: 5px;
  }

  .portfolio-item-details-item {
    display: block;
    float: none;
  }

  .portfolio-item-livelink {
    margin-top: 15px;
  }

  .portfolio-item-details {
    margin: 0 0 20px;
  }
}

.portfolio-row {
  margin-bottom: 40px;
}

/* Overlay */
.kl-has-overlay .img-intro {
  position: relative;
  z-index: 0;
}

.kl-has-overlay .img-intro > a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.kl-has-overlay .img-intro .overlay {
  background-color: rgba(31, 34, 37, 0.25);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .3s ease-in-out;
  visibility: hidden;
  width: 100%;
}

.kl-has-overlay .img-intro:hover .overlay {
  box-shadow: inset 0 -8px 0 0 var(--color-primary);
  opacity: 1;
  visibility: visible;
}

.hg-portfolio-carousel .kl-has-overlay .img-intro:hover .overlay {
  box-shadow: none;
}

.kl-has-overlay .img-intro .overlay-inner {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: transform .3s ease-in-out;
}

.kl-has-overlay .img-intro .overlay-inner > span {
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 60px;
  line-height: 58px;
  transform: scale(.9);
  transition: transform .3s ease-in-out;
  width: 60px;
}

.kl-has-overlay .img-intro:hover .overlay-inner > span {
  transform: scale(1);
}

.portfolio-entry .title {
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 20px;
  text-transform: uppercase;
}

.portfolio-entry .pt-cat-desc {
  font-size: 0.9rem;
  line-height: 1.8;
}

.hg-portfolio-carousel .portfolio-item:before,
.hg-portfolio-carousel .portfolio-item:after {
  content: " ";
  display: table;
}

.hg-portfolio-carousel .portfolio-item:after {
  clear: both;
}

.hg-portfolio-carousel .portfolio-item {
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 70px;
  padding-bottom: 70px;
}

.hg-portfolio-carousel .portfolio-item:last-child {
  border: 0;
  margin-bottom: 50px;
  padding-bottom: 0;
}

.hg-portfolio-carousel .pt-content-title {
  margin-bottom: 30px;
  margin-top: 20px;
}

.hg-portfolio-carousel .pt-cat-desc {
  margin-bottom: 30px;
}

.hg-portfolio-carousel .portfolio-item-details {
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.pt-itemlinks {
  margin-bottom: 20px;
}

.pt-itemlinks .btn:first-child {
  margin-right: 10px;
}


/* SORTABLE PORTFOLIO */
.hg-portfolio-sortable h1.title {
  font-weight: bold;
}

.hg-portfolio-sortable #sorting {
  border-radius: 2px;
  color: #888;
  float: right;
  font-family: var(--heading-font);
  font-size: 0.7rem;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
  padding: 8px 10px;
  text-transform: uppercase;
}

.hg-portfolio-sortable #sorting ul {
  display: inline-block;
  list-style: none;
  margin: 0 25px 0 0;
  padding: 0;
}

.hg-portfolio-sortable #sort-direction {
  margin-right: 0 !important;
}

.hg-portfolio-sortable #sorting ul li {
  display: inline-block;
  line-height: 1;
  margin-bottom: 0;
  margin-left: 10px;
}

.hg-portfolio-sortable #sorting ul li a {
  color: #B7B7B7;
  line-height: 1;
}

.hg-portfolio-sortable #sorting ul li a.selected {
  color: #131313
}

.hg-portfolio-sortable #sorting .sortTitle {
  line-height: 1;
}

.hg-portfolio-sortable #portfolio-nav {
  float: left;
  list-style: none;
  margin: 5px 0 40px;
  padding: 0;
}

.hg-portfolio-sortable #portfolio-nav li {
  display: inline-block;
}

.hg-portfolio-sortable #portfolio-nav li a {
  background: #fff;
  color: #000;
  display: inline-block;
  font-family: var(--heading-font);
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  margin-right: 0;
  padding: 15px;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
}

.hg-portfolio-sortable #portfolio-nav li a:hover,
.hg-portfolio-sortable #portfolio-nav li.current a {
  background: var(--color-primary);
  color: #fff;
}

.hg-portfolio-sortable li.kl-has-overlay .img-intro:hover .overlay {
  box-shadow: none
}

.portfolio-item .img-intro img, .hg-portfolio-sortable .item .img-intro img {
  width: 100%;
}

.hg-portfolio-sortable ul#thumbs {
  list-style: none;
  margin-left: -10px;
  margin-right: -10px;
  overflow: hidden;
  padding: 0;
}

.hg-portfolio-sortable ul#thumbs li {
  float: left;
  margin-bottom: 20px;
  padding: 0 10px;
  width: 100%;
}

.hg-portfolio-sortable ul#thumbs[data-columns="2"] li {
  width: 50%;
}

.hg-portfolio-sortable ul#thumbs[data-columns="3"] li {
  width: 33.333%;
}

.hg-portfolio-sortable ul#thumbs[data-columns="4"] li {
  width: 25%;
}

.hg-portfolio-sortable ul#thumbs[data-columns="5"] li {
  width: 20%;
}

.hg-portfolio-sortable ul#thumbs li .inner-item {
  background: #fff;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
  opacity: 0.9;
  transition: all .3s ease;
}

.hg-portfolio-sortable ul#thumbs li .inner-item:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  opacity: 1;
}

.hg-portfolio-sortable ul#thumbs li .inner-item h4 {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.17);
  display: block;
  font-size: 0.9rem;
  line-height: 1.6;
  margin: 0;
  padding: 15px;
  text-transform: uppercase
}

.hg-portfolio-sortable ul#thumbs li .inner-item .moduleDesc {
  color: #777;
  display: block;
  font-size: 0.85rem;
  margin: 15px 0 0;
  padding: 0 15px 15px;
}

.hg-portfolio-sortable ul#thumbs li .inner-item .moduleDesc p {
  margin-bottom: 10px;
}

.hg-portfolio-sortable ul#thumbs li.isotope-item {
  z-index: 2;
}

.hg-portfolio-sortable ul#thumbs li.isotope-hidden {
  z-index: 1;
}

.hg-portfolio-sortable .inner-item iframe {
  display: block;
}


/* portfolio Category */
.zn_portfolio_category.ptfcat-one-col > .row {
  margin-bottom: 50px;
}


/* Services Boxes */
.services_box--classic .services_box__inner {
  margin-bottom: 30px
}

.services_box--classic .services_box__content {
  overflow: hidden;
}

.services_box--classic .services_box__title {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
  margin: 15px 0;
  vertical-align: middle;
}

.services_box--classic .services_box__icon {
  background: #646464;
  border-radius: 50%;
  color: #fff;
  float: left;
  height: 80px;
  line-height: 80px;
  margin-right: 20px;
  position: relative;
  text-align: center;
  transition: background .15s ease-out;
  vertical-align: middle;
  width: 80px;
}

.services_box--classic:hover .services_box__icon {
  background: var(--color-primary);
}

.services_box--classic .services_box__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.services_box--classic .services_box__list li:hover::before {
  color: #454545;
}

.services_box--classic .services_box__list-text {
  vertical-align: middle;
}

/* image */
.services_box--classic .services_box__iconimg {
  left: 50%;
  max-width: 70px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity .15s ease-out;
}

.services_box--classic .services_box__iconimg-hover {
  opacity: 0;
}

.services_box--classic:hover .sb--hashover .services_box__iconimg-main {
  opacity: 0;
}

.services_box--classic:hover .services_box__iconimg-hover {
  opacity: 1;
}

/* icon */
.services_box--classic .services_box__fonticon {
  color: #fff;
  font-size: 30px;
  line-height: 82px;
  transition: color .2s ease-out .1s;
}

.services_box--classic:hover .services_box__fonticon {
  color: #fff;
}

/* Modern Services Boxes */
.services_box--modern .services_box__inner {
  margin-bottom: 30px;
  position: relative;
}

.services_box--modern .services_box__title {
  color: #424242;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  margin: 15px 0;
  vertical-align: middle;
}

.services_box--modern .services_box__icon {
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px var(--color-primary);
  float: left;
  height: 80px;
  line-height: 80px;
  margin-right: 20px;
  text-align: center;
  transition: all .15s ease-out;
  vertical-align: middle;
  width: 80px;
}

.services_box--modern:hover .services_box__icon {
  box-shadow: inset 0 0 0 40px var(--color-primary);
}

.services_box--modern .services_box__icon-inner {
  height: 100%;
  position: relative;
}

.services_box--modern .services_box__icon:after {
  background-color: rgba(0, 0, 0, 0.07);
  content: '';
  height: calc(100% - 60px);
  left: 40px;
  position: absolute;
  top: 80px;
  width: 1px;
}

/* image */
.services_box--modern .services_box__iconimg {
  left: 50%;
  max-width: 70px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity .15s ease-out;
}

.services_box--modern .services_box__iconimg-hover {
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0);
  transition: all .15s ease-out
}

.services_box--modern:hover .sb--hashover .services_box__iconimg-main {
  opacity: 0;
}

.services_box--modern:hover .services_box__iconimg-hover {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale(1);
}

/* icon */
.services_box--modern .services_box__fonticon {
  color: var(--color-primary);
  font-size: 40px;
  line-height: 83px;
  transition: color .2s ease-out .1s;
}

.services_box--modern:hover .services_box__fonticon {
  color: #fff;
}

/* list */
.services_box--modern .services_box__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.services_box--modern .services_box__list li {
  position: relative;
}

.services_box--modern .services_box__list li:before {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--color-primary);
  content: '';
  display: block;
  height: 5px;
  left: 38px;
  position: absolute;
  top: 9px;
  transition: all .15s;
  width: 5px
}

.services_box--modern .services_box__list li:hover:before {
  box-shadow: 0 0 0 3px var(--color-primary);
}

.services_box--modern .services_box__list-text {
  vertical-align: middle;
}

.services_box--modern.sb--hasicon .services_box__title {
  margin-left: 100px;
}

.services_box--modern.sb--hasicon .services_box__desc {
  margin-left: 100px;
}

.services_box--modern.sb--hasicon .services_box__list li {
  padding-bottom: 15px;
  padding-left: 100px;
}


/* Boxed Services Boxes */
.services_box--boxed {
  background: #eee;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  z-index: 1;
}

.services_box--boxed:hover {
  z-index: 2;
}

.services_box--boxed .services_box__inner {
  position: relative;
}

.services_box--boxed .services_box__title {
  color: #424242;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 0 0 15px;
  position: relative;
  vertical-align: middle;
  z-index: 2;
}

.services_box--boxed .services_box__desc {
  position: relative;
  z-index: 2;
}

.services_box--boxed .services_box__icon {
  max-height: 80px;
  min-height: 50px;
  position: relative;
  z-index: 2;
}

/* image */
.services_box--boxed .services_box__iconimg {
  transition: opacity .2s ease-out;
}

.services_box--boxed .services_box__iconimg-hover {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.services_box--boxed:hover .sb--hashover .services_box__iconimg-main {
  opacity: 0;
}

.services_box--boxed:hover .services_box__iconimg-hover {
  opacity: 1
}

/* icon */
.services_box--boxed .services_box__fonticon {
  color: var(--color-primary);
  font-size: 50px;
  transition: color .2s ease-out .1s;
}

.services_box--boxed:hover .services_box__fonticon {
  color: #454545;
}

/* list */
.services_box--boxed .services_box__list-wrapper {
  opacity: 0;
  position: absolute;
  top: -30px;
  transition: all .2s ease-out;
  visibility: hidden;
  width: 100%;
}

.services_box--boxed:hover .services_box__list-wrapper {
  opacity: 1;
  visibility: visible;
}

.services_box--boxed .services_box__list-bg {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  height: calc(100% + 30px);
  left: -30px;
  opacity: 1;
  position: absolute;
  width: calc(100% + 60px);
  z-index: 1;
}

.services_box--boxed .services_box__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 250px;
  position: relative;
  z-index: 1;
}

.services_box--boxed .services_box__list li {
  border-top: 1px solid #eee;
  color: var(--color-primary);
  line-height: 26px;
  opacity: 0;
  padding: 10px 0;
  position: relative;
  transition: all .25s ease-out;
  visibility: hidden;
}

.services_box--boxed .services_box__list li:nth-child(1) {
  transform: translateY(10px);
  transition-delay: 0.025s;
}

.services_box--boxed .services_box__list li:nth-child(2) {
  transform: translateY(20px);
  transition-delay: 0.05s;
}

.services_box--boxed .services_box__list li:nth-child(3) {
  transform: translateY(30px);
  transition-delay: 0.075s;
}

.services_box--boxed .services_box__list li:nth-child(4) {
  transform: translateY(40px);
  transition-delay: 0.1s;
}

.services_box--boxed .services_box__list li:nth-child(5) {
  transform: translateY(50px);
  transition-delay: 0.125s;
}

.services_box--boxed .services_box__list li:nth-child(6) {
  transform: translateY(60px);
  transition-delay: 0.15s;
}

.services_box--boxed .services_box__list li:nth-child(7) {
  transform: translateY(70px);
  transition-delay: 0.175s;
}

.services_box--boxed .services_box__list li:nth-child(8) {
  transform: translateY(80px);
  transition-delay: 0.2s;
}

.services_box--boxed .services_box__list li:nth-child(9) {
  transform: translateY(90px);
  transition-delay: 0.225s;
}

.services_box--boxed .services_box__list li:nth-child(10) {
  transform: translateY(100px);
  transition-delay: 0.25s;
}

.services_box--boxed:hover .services_box__list li {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}


/* CIRCLE HOVER */
.circlehover {
  background-color: var(--color-primary);
  border-radius: 50%;
  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.2);
  color: white;
  display: table;
  font-size: 18px;
  font-weight: 300;
  height: 130px;
  line-height: 1.4;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 130px;
}

.circlehover[data-size="small"] {
  font-size: 14px;
  height: 80px;
  padding: 8px;
  width: 80px;
}

.circlehover[data-size="medium"] {
  font-size: 16px;
  height: 95px;
  padding: 10px;
  width: 95px;
}

.circlehover > span {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  z-index: 1;
}

.circlehover:before {
  background: var(--color-primary);
  border-radius: 3px;
  content: '';
  height: 30px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 30px;
}

.circlehover[data-position="top-left"]:before {
  left: 7px;
  top: 7px;
}

.circlehover[data-position="top-right"]:before {
  left: auto;
  right: 7px;
  top: 7px;
}

.circlehover[data-position="bottom-left"]:before {
  bottom: 7px;
  left: 7px;
  top: auto;
}

.circlehover[data-position="bottom-right"]:before {
  bottom: 7px;
  left: auto;
  right: 7px;
  top: auto;
}

.circlehover[data-position="top"]:before,
.circlehover[data-position="right"]:before,
.circlehover[data-position="left"]:before,
.circlehover[data-position="bottom"]:before {
  transform: rotate(45deg);
}

.circlehover[data-position="top"]:before {
  left: 50%;
  margin-left: -15px;
  top: -10px;
}

.circlehover[data-position="right"]:before {
  left: auto;
  margin-top: -15px;
  right: -10px;
  top: 50%;
}

.circlehover[data-position="left"]:before {
  left: -10px;
  margin-top: -15px;
  top: 50%;
}

.circlehover[data-position="bottom"]:before {
  bottom: -10px;
  left: 50%;
  margin-left: -15px;
  top: auto;
}

.circlehover[data-position="bottom-right"] {
  box-shadow: -3px -3px 1px rgba(0, 0, 0, 0.5);
}

.circlehover:hover {
  box-shadow: 5px 5px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
  transform: scale(1.1);
}

.circlehover[data-position="bottom-right"]:hover {
  box-shadow: -5px -5px 1px rgba(0, 0, 0, 0.5);
}

.circlehover[data-align="left"] {
  float: left;
}

.circlehover[data-align="right"] {
  float: right;
}

.circlehover {
  transition: all 400ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
}

.circlehover.with-symbol > span.symbol {
  bottom: 0;
  display: block;
  left: 0;
  line-height: 130px;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}

.circlehover.with-symbol:hover > span.text {
  opacity: 0;
}

.circlehover.with-symbol:hover > span.symbol {
  opacity: 1;
}

.call-out-banner .m_title {
  font-size: 28px;
  margin-top: 20px;
  position: relative;
  text-transform: none;
}

.call-out-banner .m_title:before {
  background-color: #ddd;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  top: -20px;
  width: 80px;
}


/* Callout Banner */
.callout-banner {
  margin-bottom: 40px;
  margin-top: 20px;
}

.callout-banner .m_title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.6;
  margin-top: 0;
  padding-top: 20px;
  position: relative;
}

.callout-banner .m_title strong {
  font-weight: 600;
}

.callout-banner .m_title:before {
  background: #000;
  content: '';
  height: 3px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 80px;
}


/* Callout Banner style 2 */
.circlehover.style2 {
  background-color: var(--color-primary);
  border-radius: 4px;
  box-shadow: none;
  color: white;
  display: table;
  font-size: 18px;
  font-weight: 300;
  height: 75px;
  line-height: 1.3;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-shadow: none;
  width: 100%;
}

.circlehover.style2:before {
  background-color: var(--color-primary);
  border-radius: 3px;
  bottom: -4px;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: auto;
  top: initial;
  transform: skewY(3deg);
  width: 100%;
  z-index: 1;
}

.circlehover.style2:after {
  background-color: #dcdcdc;
  border-radius: 3px;
  bottom: -9px;
  content: '';
  height: 16px;
  left: 11px;
  position: absolute;
  top: auto;
  top: initial;
  transform: skewY(-3deg);
  width: 90%;
}

.circlehover.style2.with-symbol > span.symbol {
  line-height: 73px;
}

.circlehover.style2.with-symbol > span.symbol img {
  max-height: 48px;
}

/* Callout Banner style 3 */
.circlehover.style3 {
  box-shadow: none;
  height: 150px;
  width: 150px;
}

.circlehover.style3:before {
  bottom: 25px;
  left: 3px;
  top: inherit;
  transform: skewY(-28deg) rotate(27deg);
}

.circlehover.style3 .text {
  background: #000;
  border-radius: 4px;
  bottom: 15px;
  color: #fff;
  display: block;
  font-size: 13px;
  font-style: italic;
  line-height: 20px;
  min-width: 100px;
  padding: 3px 10px;
  position: absolute;
  right: -23px;
}

.circlehover.style3.with-symbol > span.symbol {
  line-height: 150px;
}

.triangle {
  display: none;
}

.circlehover.style3 .triangle {
  display: block;
  left: 72px;
  position: absolute;
  top: 65px;
  transform: rotate(90deg);
}

.circlehover.style3 .play-icon {
  background-color: #fff;
  display: block;
  position: relative;
  text-align: left;
  transform: rotate(-60deg) skewX(-30deg) scale(1, .866);
}

.circlehover.style3 .play-icon:before,
.circlehover.style3 .play-icon:after {
  background-color: inherit;
  content: '';
  position: absolute;
}

.circlehover.style3 .play-icon,
.circlehover.style3 .play-icon:before,
.circlehover.style3 .play-icon:after {
  border-top-right-radius: 30%;
  height: 16px;
  width: 16px;
}

.circlehover.style3 .play-icon {
  transform: rotate(-60deg) skewX(-30deg) scale(1, .866);
}

.circlehover.style3 .play-icon:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
}

.circlehover.style3 .play-icon:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
}

.circlehover.style3:hover .triangle {
  display: none;
}

/* team members */
.team_member {
  background: #fff;
  padding: 0 0 5px;
  position: relative;
}

.team_member:hover {
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.team_member .details {
  background: #fff;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
  opacity: 0;
  overflow: hidden;
  padding: 0 20px 20px;
  position: absolute;
  transition: all 0.2s ease-out;
  visibility: hidden;
}

.team_member:hover .details {
  opacity: 1;
  visibility: visible;
}

.team_member:hover {
  z-index: 2;
}

.team_member img {
  width: 100%;
}

.team_member > h4,
.team_member > h6 {
  padding: 10px 20px 0 20px;
}

.team_member .details .social-icons {
  margin-left: -4px;
  margin-right: -4px;
  padding: 0;
}

.team_member .details .social-icons li {
  margin: 0 4px;
}

.team_member .details .social-icons li:first-child {
  margin-left: 0;
}

.team_member .details {
  width: 100%;
}

.sctb-icon-nocolor {
  background-color: #121212;
}


/* Latest Posts Default */
.latest_posts.default-style .hoverBorder {
  margin-bottom: 10px;
}

.latest_posts.default-style .hoverBorder h6 {
  background: var(--color-primary);
  border-radius: 2px;
  bottom: 16px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  left: -10px;
  line-height: 1;
  margin: 0;
  padding: 11px 14px;
  position: absolute;
}

.latest_posts.default-style .hoverBorder:hover h6 {
  background: #fff;
  color: var(--color-primary);
  left: 15px;
}

.latest_posts.default-style a + em {
  color: #7d7d7d;
  font-size: 0.9rem;
}

.latest_posts.default-style .post {
  margin-bottom: 30px;
}

.latest_posts.default-style .post .m_title {
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.4;
  margin-top: 10px;
}

.latest_posts.default-style .post em {
  display: block;
}

.latest_posts.default-style img {
  box-shadow: 0 1px 1px rgba(0, 0, 0, .22);
}

/* latest posts default style - style 2 */
.latest_posts.default-style.kl-style-2 .lp-title {
  background-color: var(--color-primary);
  height: 120px;
  margin: 0 0 20px;
  padding: 20px 40px;
  text-align: center;
  width: 100%;
}

.latest_posts.default-style.kl-style-2 .lp-title .m_title {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  position: relative;
  text-shadow: none;
  top: 50%;
  transform: translateY(-50%);
}

.latest_posts.default-style.kl-style-2 .post {
  margin-bottom: 20px;
  position: relative;
}

.latest_posts.default-style.kl-style-2 .post-details {
  bottom: 0;
  padding: 20px 15px;
  position: absolute;
}

.latest_posts.default-style.kl-style-2 .m_title a {
  color: #fff;
  font-style: normal;
  margin-bottom: 5px;
  text-shadow: none;
}

.latest_posts.default-style.kl-style-2 .post-details em {
  color: #d6d6d6;
  font-size: 0.7rem;
  font-style: normal;
}

.latest_posts.default-style.kl-style-2 .post-details em a {
  color: #fff;
}

.latest_posts.default-style.kl-style-2 .m_title {
  margin: 0 0 20px;
  position: relative;
}

.latest_posts.default-style.kl-style-2 .post:not(.lp-title) .m_title:after {
  background-color: var(--color-primary);
  bottom: -10px;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  transition: width .25s ease-in-out;
  width: 88px;
}

.latest_posts.default-style.kl-style-2 .post:not(.lp-title):hover .m_title:after {
  width: 160px;
}

.latest_posts.default-style.kl-style-2 .theHoverBorder {
  background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.8) 100%);
  border-radius: 0;
  box-shadow: none;
  transition: opacity .25s ease-in-out;
}

.latest_posts.default-style.kl-style-2 .hoverBorderWrapper img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 0;
  width: 100%;
}

.latest_posts.default-style.kl-style-2 .hoverBorder {
  margin: 0;
}

.latest_posts.default-style.kl-style-2 .hoverBorder:hover img {
  opacity: 1;
}

.latest_posts.default-style.kl-style-2 .post:hover .theHoverBorder {
  opacity: 0;
}


.latest_posts.style2 {
  background-color: #fff;
  padding: 30px 30px 10px 30px;
  position: relative
}

.latest_posts.style2 .m_title {
  margin-bottom: 20px;
}

.latest_posts.style2 .viewall {
  color: #757575;
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 28px;
}

.latest_posts.style2 .viewall:hover {
  color: #111;
}

.latest_posts.style2 ul.posts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.latest_posts.style2 ul.posts li {
  border-bottom: 1px solid #ECECEC;
  margin-top: 25px;
  padding-bottom: 25px;
}

.latest_posts.style2 ul.posts li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.latest_posts.style2 ul.posts .title {
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6;
  vertical-align: middle;
}

.latest_posts.style2 ul.posts .details {
  float: left;
  width: 110px;
}

.latest_posts.style2 ul.posts .details > span {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 2px;
}

.latest_posts.style2 ul.posts .details span.date {
  background: var(--color-primary);
  color: #fff;
  font-weight: bold;
  margin: 0 0 5px;
  padding: 3px 10px;
  position: relative;
}

.latest_posts.style2 ul.posts .details span.date:after {
  border: 4px solid transparent;
  border-top-color: var(--color-primary);
  bottom: -8px;
  content: '';
  left: 10px;
  position: absolute;
}

.latest_posts.style2 ul.posts .text {
  margin-left: 110px;
}

.latest_posts.style2 ul.posts .details > span.cat {
  display: block;
}

.latest_posts.style3 {
  position: relative;
}

.latest_posts.style3 .m_title {
  margin-bottom: 20px;
}

.latest_posts.style3 .hoverBorder {
  float: left;
  margin-bottom: 50px;
  margin-right: 20px;
  margin-top: 5px;
}

.latest_posts.style3 .viewall {
  color: #757575;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
}

.latest_posts.style3 .viewall:hover {
  color: #111;
}

.latest_posts.style3 ul.posts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.latest_posts.style3 ul.posts li {
  border-bottom: 1px solid #E3E3E3;
  margin-bottom: 15px;
  overflow: hidden;
  padding-bottom: 15px;
}

.latest_posts.style3 ul.posts li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.latest_posts.style3 ul.posts .title {
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.6;
  margin: 0 0 5px;
  margin: 0 0 5px;
  padding: 0;
  text-shadow: 1px 1px 0 white;
  text-shadow: 1px 1px 0 white;
  vertical-align: middle;
  vertical-align: middle;
}

.lp-post .hoverBorder ~ .text,
.lp-post .hoverBorder ~ .lp-post-comments-num {
  margin-left: 74px;
}

.lp-post .lp-post-comments-num {
  color: #bababa
}

.lp-post .hoverBorder + .title {
  overflow: hidden;
}

.widget .latest_posts ul li.lp-post .hoverBorder {
  margin-bottom: 0;
}

/* Latest Posts - Accordion Style */
.latest_posts-acc {
  position: relative;
}

.latest_posts-acc-elm-title {
  margin: 0;
  position: absolute;
  top: 14px;
  z-index: 1;
}

.latest_posts-acc-viewall {
  color: #757575;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 12px;
  z-index: 1;
}

.latest_posts-acc-item,
.latest_posts-acc-inner,
.latest_posts-acc-link {
  transition: width 0.2s ease-in-out 0.2s;
}

.latest_posts-acc-wrapper {
  margin: 0 auto 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.latest_posts-acc-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.latest_posts-acc-item {
  border-top: 4px solid #E7E7E7;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  padding-top: 50px;
  position: relative;
  width: calc(25% - 20px);
}

.latestposts--dark .latest_posts-acc-item {
  border-top-color: #323232;
}

.latest_posts-acc-inner,
.latest_posts-acc-item:hover ~ .latest_posts-acc-item > .latest_posts-acc-inner {
  width: calc(200% + 40px);
}

.latest_posts-acc-item:hover > .latest_posts-acc-inner,
.latest_posts-acc-item.last > .latest_posts-acc-inner {
  width: 100%;
}

.latest_posts-acc-link {
  float: left;
  margin-right: 20px;
  opacity: .8;
  width: calc(50% - 20px);
}

.latest_posts-acc-item.last,
.latest_posts-acc-item:hover:not(.last) {
  width: 50%;
}

.latest_posts-acc-item:hover ~ .latest_posts-acc-item {
  width: calc(25% - 20px);
}

.latest_posts-acc-item:hover .latest_posts-acc-link {
  opacity: 1;
}

.latest_posts-acc-item.last {
  margin-right: 0;
}

.latest_posts-acc-details {
  font-size: 0.8rem;
}

.latest_posts-acc-title {
  font-size: 1rem;
  line-height: 1.5;
  margin: 10px 0;
}

.latest_posts-acc-text {
  font-size: 0.85rem;
  line-height: 1.6;
}

.latest_posts-acc-more {
  color: #000;
  display: inline-block;
  font-size: 0.7rem;
  font-weight: bold;
  margin-top: 5px;
}

/*** SearcBox Element */
.elm-searchbox__form {
  border-radius: 4px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  position: relative;
}

.elm-searchbox__input,
.elm-searchbox__submit {
  border: 0;
  float: left;
  height: 50px;
  margin: 0;
  outline: none;
  top: 0;
  vertical-align: middle;
}

.elm-searchbox__input {
  background: #fff;
  border-radius: 4px 0 0 4px;
  padding-left: 20px;
  transition: padding-left .15s ease-out;
  width: calc(100% - 130px);
}

.elm-searchbox__input:focus {
  padding-left: 30px;
}

.elm-searchbox__submit {
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  width: 130px;
}

.elm-searchbox__submit:not(.glyphicon) {
  font-size: 14px;
  font-weight: 600;
}

/* Normal Style */
.elm-searchbox--normal .elm-searchbox__submit {
  background-color: var(--color-primary);
  color: #fff;
}

.elm-searchbox--normal .elm-searchbox__submit:hover {
  background-color: #000;
}

/* Normal with transparent button */
.elm-searchbox--normal2 .elm-searchbox__submit {
  background-color: #fff;
  color: #999;
  position: relative;
}

.elm-searchbox--normal2 .elm-searchbox__submit:after {
  background-color: rgba(0, 0, 0, 0.1);
  content: '';
  height: 86%;
  left: 0;
  position: absolute;
  top: 7%;
  width: 1px
}

.elm-searchbox--normal2 .elm-searchbox__submit:hover {
  color: #767676;
}

/* Transparent */
.elm-searchbox--transparent .elm-searchbox__input {
  background: rgba(255, 255, 255, .3);
  border: 1px solid rgba(255, 255, 255, .7);
  border-right: 0;
  color: #fff;
}

.elm-searchbox--transparent .elm-searchbox__input::-webkit-input-placeholder {
  color: #fff;
}

.elm-searchbox--transparent .elm-searchbox__input:-moz-placeholder {
  color: #fff;
}

.elm-searchbox--transparent .elm-searchbox__input::-moz-placeholder {
  color: #fff;
}

.elm-searchbox--transparent .elm-searchbox__input:-ms-input-placeholder {
  color: #fff;
}

.elm-searchbox--transparent .elm-searchbox__submit {
  background-color: var(--color-primary);
  color: #fff;
}

.elm-searchbox--transparent .elm-searchbox__submit:hover {
  background-color: #000;
}

/* Transparent with transparent button */
.elm-searchbox--transparent2 .elm-searchbox__input {
  background: rgba(255, 255, 255, .1);
  border: 1px solid rgba(255, 255, 255, .7);
  border-right: 0;
  color: #fff;
}

.elm-searchbox--transparent2 .elm-searchbox__input::-webkit-input-placeholder {
  color: #fff;
}

.elm-searchbox--transparent2 .elm-searchbox__input:-moz-placeholder {
  color: #fff;
}

.elm-searchbox--transparent2 .elm-searchbox__input::-moz-placeholder {
  color: #fff;
}

.elm-searchbox--transparent2 .elm-searchbox__input:-ms-input-placeholder {
  color: #fff;
}

.elm-searchbox--transparent2 .elm-searchbox__submit {
  background: rgba(255, 255, 255, .1);
  border: 1px solid rgba(255, 255, 255, .7);
  border-left: 0;
  color: #fff;
  cursor: pointer;
  position: relative;
}

.elm-searchbox--transparent2 .elm-searchbox__submit:after {
  background-color: rgba(255, 255, 255, 0.7);
  content: '';
  height: 86%;
  left: 0;
  position: absolute;
  top: 7%;
  width: 1px
}

.elm-searchbox--transparent2 .elm-searchbox__submit:hover {
  color: rgba(255, 255, 255, .8);
}

/* Typing effect */
.elm-searchbox__input-text {
  animation: elm-searchbox__inputtext 4s steps(50, end);
  color: #595959;
  display: block;
  font-size: 14px;
  left: 0;
  line-height: 1.2;
  max-width: 400px;
  overflow: hidden;
  padding-left: 20px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}

.elm-searchbox__input-text:after {
  animation: blink 1s infinite;
  content: ' |';
}

.elm-searchbox__input:focus + .elm-searchbox__input-text {
  opacity: 0;
  visibility: hidden;
}

@keyframes elm-searchbox__inputtext {
  from {
    max-width: 0;
  }
}

@keyframes blink {
  to {
    opacity: .0;
  }
}

.elm-searchbox--transparent .elm-searchbox__input-text,
.elm-searchbox--transparent2 .elm-searchbox__input-text {
  color: #fff;
}


/** Action Box **/
.action_box {
  background-color: #252525;
  box-shadow: 0 0 5px #000;
  clear: both;
  color: #fff;
  padding: 40px 30px;
  position: relative;
}

.action_box h4.text {
  font-size: 1.7rem;
  font-weight: 200;
  line-height: 1.4;
  margin-bottom: 0;
}

.action_box .ac-subtitle {
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.6;
}

/* Style 2 */

.action_box.style2 {
  padding: 20px;
}
.action_box.style2 .action_box_content {
  display: block;
}

.action_box.style2 .ac-content-text,
.action_box.style2 .ac-buttons {
  display: block;
  padding-left: 0;
  text-align: center;
}

.action_box.style2 .ac-content-text {
  margin-bottom: 25px;
}

.action_box .ac-content-text.inline-content {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}

.action_box.style2 .ac-btn,
.action_box.style2 .ac-btn:last-child {
  display: inline-block;
}

.ac-content-text.inline-content picture {
  width: 100%;
  padding: 0 25px 0 0;
}

/* Action Box style 3 */
.style3 .action_box_inner {
  background-color: var(--color-primary);
  border-radius: 6px;
  padding: 35px;
  position: relative;
  z-index: 1;
}

.style3 .action_box_inner:before {
  background-color: var(--color-primary);
  border-radius: 6px;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: -6px;
  transform: skewY(1.5deg);
  transform-origin: right top;
  width: 100%;
}

.style3 .action_box_inner:after {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  content: '';
  display: block;
  height: 30%;
  position: absolute;
  right: 5%;
  top: -20px;
  transform: skewY(-2deg);
  width: 90%;
  z-index: -1;
}

.action_box.style3 {
  border-radius: 6px;
  padding: 0;
}

.action_box.style3:before {
  background-color: var(--color-primary);
  border-radius: 4px;
  bottom: -6px;
  content: '';
  display: block;
  height: 32px;
  left: 20%;
  position: absolute;
  transform: skewY(35deg);
  width: 30px;
}

.action_box.style3:after {
  display: none;
}

.action_box .ac-btn {
  display: inline;
  margin: 0;
}

.action_box .ac-btn:last-child {
  margin-left: 15px;
}

.inline-image-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.inline-image-box picture {
  padding: 0 25px 0 0;
}

.inline-image-box h4.text {
  font-size: 1.8rem;
  font-weight: 800;
}

@media (min-width:768px) {
  .inline-image-box picture {
    width: 30%;
    border-right: 1px solid #aaa;
  }

  .inline-image-box h4.text {
    width: 67%;
    margin-left: 3%;
  }
}

@media (max-width:767.99px) {
  .inline-image-box picture,
  .inline-image-box h4.text {
    width: 100%;
    text-align:center
  }

  .inline-image-box {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    padding: 30px 0;
  }
}

/*** Big Social Block Element */
.bigsocialblock {
  display: flex;
  width: 100%;
}

.bigsocialblock__item {
  -webkit-backface-visibility: hidden;
  flex: 1;
  height: 150px;
  overflow: hidden;
  padding: 25px;
  position: relative;
  text-align: right;
  transition: all .2s ease;
}

.bigsocialblock__link {
  display: block;
  height: 100%;
  width: 100%;
}

.bigsocialblock__bg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bigsocialblock__bg a {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 2;
}

.bigsocialblock__title {
  bottom: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  position: absolute;
  right: 25px;
  text-transform: uppercase;
  transition: all .2s ease;
  z-index: 1;
}

.bigsocialblock__count {
  bottom: 40px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  right: 25px;
}

.bigsocialblock__follow {
  bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: 25px;
  text-transform: uppercase;
}

.bigsocialblock__count,
.bigsocialblock__follow {
  -webkit-backface-visibility: hidden;
  display: block;
  opacity: 0;
  transition: opacity .2s ease-out, visibility .2s ease-out, transform .2s ease-out;
  visibility: hidden;
}

.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__count,
.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__follow {
  transform: scale(.6);
}

.bigsocialblock__social-icon {
  border: solid 5px #fff;
  border-radius: 50%;
  bottom: -30px;
  color: #fff;
  font-size: 80px;
  height: 150px;
  left: -21px;
  line-height: 140px;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  transition: opacity .2s ease-out;
  width: 150px;
  z-index: 1;
}

/* White */
.bsb--theme-light .bigsocialblock__title,
.bigsocialblock__item:hover .bigsocialblock__title,
.bsb--theme-light .bigsocialblock__count,
.bigsocialblock__item:hover .bigsocialblock__count,
.bsb--theme-light .bigsocialblock__follow,
.bigsocialblock__item:hover .bigsocialblock__follow {
  color: #fff;
}

.bsb--theme-light .bigsocialblock__social-icon,
.bigsocialblock__item:hover .bigsocialblock__social-icon {
  border-color: #fff;
  color: #fff;
}

/* Dark */
.bsb--theme-dark .bigsocialblock__title,
.bsb--theme-dark .bigsocialblock__count,
.bsb--theme-dark .bigsocialblock__follow {
  color: #8A8A8A;
}

.bsb--theme-dark .bigsocialblock__social-icon {
  border-color: #8A8A8A;
  color: #8A8A8A;
}

/* Style 1 */
.bigsocialblock__item:hover {
  box-shadow: 0 6px 22px 4px rgba(0, 0, 0, .4);
  z-index: 1;
}

.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__title {
  bottom: 70px;
}

.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__count,
.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__follow {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
  z-index: 1;
}

.bigsocialblock__item:hover .bigsocialblock__social-icon {
  opacity: 1;
}

.bigsocialblock.type-chover .bigsocialblock__bg {
  opacity: 0;
  transition: opacity .2s ease-out;
}

.bigsocialblock.type-chover .bigsocialblock__item:hover .bigsocialblock__bg {
  opacity: 1;
}

/* Style 2 */
.bigsocialblock--style2 .bigsocialblock__social-icon {
  border: solid 15px #fff;
  bottom: -140px;
  font-size: 200px;
  height: 400px;
  left: -130px;
  line-height: 400px;
  width: 400px;
}

.bigsocialblock--style2 .bigsocialblock__item:hover {
  box-shadow: none;
}

.bigsocialblock--style2 .bigsocialblock__count {
  bottom: auto;
  display: block;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  position: relative;
  right: auto;
  text-align: right;
  z-index: 1;
}

.bigsocialblock--style2 .bigsocialblock__follow {
  bottom: auto;
  color: #fff;
  display: block;
  font-weight: 400;
  position: relative;
  right: auto;
  text-align: right;
}

.bigsocialblock--style2 .bigsocialblock__item:hover .bigsocialblock__count,
.bigsocialblock--style2 .bigsocialblock__item:hover .bigsocialblock__follow {
  opacity: 1;
  visibility: visible;
}

/* NO FLEXBOX */
.no-flexbox .bigsocialblock {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.no-flexbox .bigsocialblock__item {
  display: table-cell;
  height: 150px;
  overflow: hidden;
  padding: 25px;
  position: relative;
  text-align: right;
  transition: all .2s ease;
}

/* End big social element */


/* Circle Title text box */
.circle-text-box .wpk-circle-span {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  height: 60px;
  line-height: 60px;
  position: relative;
  text-align: center;
  transition: all 0.2s cubic-bezier(0.680, 0, 0.265, 1);
  width: 60px;
}

.circle-text-box .wpk-circle-span > span {
  position: relative;
  z-index: 1;
}

.circle-text-box .wpk-circle-span:after {
  background-color: var(--color-primary);
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 60px;
  left: 0;
  line-height: 60px;
  position: absolute;
  top: 0;
  transition: all 0.2s cubic-bezier(0.680, 0, 0.265, 1);
  width: 60px;
}

.circle-text-box .wpk-circle-span {
  float: left;
  margin-right: 20px;
}

.circle-text-box .wpk-circle-title {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
  color: var(--color-primary);
  font-size: 20px;
  font-weight: 600;
  height: 55px;
  margin: 0 0 30px;
}

.circle-text-box .wpk-circle-span + .wpk-circle-title {
  line-height: 60px;
  overflow: hidden;
}

.circle-text-box.style1:hover .wpk-circle-span:after {
  transform: scale(1.2)
}

/* Circle Title text box - Style 2 */
.circle-text-box.style2 .wpk-circle-span::before {
  background: var(--color-primary);
  border-radius: 2px;
  bottom: 5px;
  content: '';
  height: 14px;
  position: absolute;
  right: 5px;
  transition: all .2s cubic-bezier(0.680, 0, 0.265, 1);
  width: 14px;
}

.circle-text-box.style2:hover .wpk-circle-span {
}

.circle-text-box.style2:hover .wpk-circle-span::before {
  transform: translate3d(5px, 5px, 0);
}

.circle-text-box.style2:hover .wpk-circle-span::after {
  transform: scale(1.2)
}

/* Circle Title text box - Style 3 */
.circle-text-box.style3 .wpk-circle-span {
  background: var(--color-primary);
  border-radius: 3px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  height: 45px;
  line-height: 45px;
  margin-left: -25px;
  padding-left: 25px;
  padding-right: 10px;
  text-align: right;
  width: auto;
  z-index: 1;
}

.circle-text-box .wpk-circle-span > span {
  position: relative;
  z-index: 1;
}

.circle-text-box.style3 .wpk-circle-span:after {
  display: none;
}

.circle-text-box.style3 .wpk-circle-title {
  height: 42px;
  margin: 0 0 35px;
}

.circle-text-box.style3 .wpk-circle-span + .wpk-circle-title {
  line-height: 45px;
}

.circle-text-box.style3:hover .wpk-circle-span {
  margin-left: -35px;
  padding-left: 35px;
}


/* Hover Box Style 1 */
.hover-box {
  background: #969696;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  color: #fff;
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 15px;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.hover-box:hover {
  background-color: var(--color-primary) !important;
  color: #fff;
}

.hover-box.centered {
  text-align: center;
}

.hover-box > img {
  float: left;
  margin: 15px 15px 8px 10px;
}

.hover-box.centered > img {
  float: none;
}

.hover-box > h3 {
  line-height: 1.1;
  margin-top: 0;
}

.hover-box > h4 {
  line-height: 1.1;
  margin-top: 0;
}

.hover-box > p {
  line-height: 1.1;
  margin-bottom: 0;
}

/* Hover Box Style 2 & 3 */
.hover-box.hover-box-2,
.hover-box.hover-box-3 {
  border: none;
  margin-bottom: 0;
  min-height: 180px;
  padding: 25px;
  position: relative;
  text-shadow: none;
}

.hover-box.hover-box-2:hover,
.hover-box.hover-box-3:hover {
  box-shadow: 0 10px 2px -6px rgba(0, 0, 0, 0.8);
  transform: translateY(-4px);
}

.hover-box.hover-box-3 img {
  display: none;
}

.hover-box.hover-box-2 > h3,
.hover-box.hover-box-3 > h3 {
  margin-bottom: 20px;
}

.hover-box.hover-box-2 > h4,
.hover-box.hover-box-3 > h4 {
  font-weight: bold;
}

.hover-box.hover-box-2 .hb-img {
  float: none;
  margin: 0;
  max-width: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .2s ease-out;
  width: 110px;
}

.hover-box.hover-box-2 .hb-img.rb-right {
  right: 0;
}

.hover-box.hover-box-2 > p,
.hover-box.hover-box-3 > p {
  line-height: 1.4;
}

.hover-box.hover-box-2 > p:after,
.hover-box.hover-box-3 > p:after {
  background: #fff;
  content: '';
  display: block;
  height: 3px;
  margin-top: 22px;
  width: 50px;
}

.hover-box.hover-box-2:hover .hb-img {
  opacity: 0;
  right: -100%;
}

.hover-box.hover-box-2 .hb-circle {
  background: url(../images/circle-arr-hover.svg) no-repeat left top;
  bottom: -40px;
  display: block;
  height: 187px;
  opacity: 0;
  position: absolute;
  right: -40px;
  transform: translateX(100%);
  transition: all .3s ease-out;
  width: 187px;
}

.hover-box.hover-box-2:hover .hb-circle {
  opacity: 1;
  transform: translateX(0);
}


/*** Icon Box - Default element */
.kl-iconbox__title {
  color: var(--color-primary);
  font-family: Open Sans;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.7;
}

.hover-activity {
  /** TODO: add animation to make the dogs shake like they are excited on hover **/
}

.contentBoxBorders {
  border-top:2px solid #333;
  border-bottom:2px solid #333;
}

.kl-iconbox {
  margin-bottom: 30px;
}

.kl-iconbox__inner {
}

.kl-iconbox__icon-wrapper,
.kl-iconbox__title-wrapper,
.kl-iconbox__desc-wrapper,
.kl-iconbox__cta-wrapper {
  margin-bottom: 15px;
  margin-top: 0;
}

.kl-iconbox__content-wrapper {
  overflow: hidden;
}

.kl-iconbox__link {
}

.kl-iconbox__title,
.kl-iconbox__desc {
  margin: 0;
}

.kl-iconbox__desc {
  color: #3e3f3d;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
}

.kl-iconbox__icon {
  font-size: 42px;
  line-height: 1;
  transition: color .2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.kl-iconbox__icon.custom-size {
  font-size: 14px !important;
}

.kl-iconbox--theme-light,
.kl-iconbox--theme-light a {
  color: #fff;
}

.kl-iconbox--theme-dark,
.kl-iconbox--theme-dark a {
  color: #323232;
}

/* Floated Icons  */
.kl-iconbox--fleft .kl-iconbox__icon-wrapper {
  float: left;
  margin-right: 20px;
}

.kl-iconbox--fright .kl-iconbox__icon-wrapper {
  float: right;
  margin-left: 20px;
}

/* Shaped Icons - Resized */
.kl-iconbox--sh .kl-iconbox__icon-wrapper {
  position: relative;
}

.kl-iconbox--align-left.kl-iconbox--sh span.kl-iconbox__icon,
.kl-iconbox--align-right.kl-iconbox--sh span.kl-iconbox__icon {
  text-align: center;
}

/* Shaped icons - Square */
.kl-iconbox--sh-square span.kl-iconbox__icon {
  background-color: #3498db;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  padding: 22px;
  position: relative;
  vertical-align: middle;
  z-index: 1;
}

.kl-iconbox--sh-square span.kl-iconbox__icon:after {
  border-radius: 3px;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width .2s cubic-bezier(0.215, 0.610, 0.355, 1.000), left .2s cubic-bezier(0.215, 0.610, 0.355, 1.000), right .2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
  width: 0;
  z-index: -1;
}

.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after {
  background-color: #2980b9;
  left: -10%;
  width: 120%;
}

.kl-iconbox--align-left.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after,
.kl-iconbox--fleft.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after {
  left: -20%;
  width: 120%;
}

.kl-iconbox--align-right.kl-iconbox--sh-square span.kl-iconbox__icon:after,
.kl-iconbox--fright.kl-iconbox--sh-square span.kl-iconbox__icon:after {
  left: auto;
  right: 0;
}

.kl-iconbox--align-right.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after,
.kl-iconbox--fright.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after {
  left: auto;
  right: -20%;
  width: 120%;
}

/* Shaped icons - Circle */
.kl-iconbox--sh-circle span.kl-iconbox__icon {
  background-color: #e74c3c;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 46px;
  padding: 22px;
  position: relative;
  vertical-align: middle;
  z-index: 1;
}

.kl-iconbox--sh-circle span.kl-iconbox__icon:after {
  border-radius: 50%;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: transform .2s cubic-bezier(0.215, 0.610, 0.355, 1.000), opacity .2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
  width: 100%;
  z-index: -1;
}

.kl-iconbox--sh-circle:hover span.kl-iconbox__icon:after {
  background-color: #c0392b;
  opacity: 1;
  transform: scale(1.15);
}

.kl-iconbox.stage .kl-iconbox__icon {
  color: #d1d1d1;
}

.kl-iconbox.stage:hover .kl-iconbox__icon {
  color: var(--color-primary);
}

img.kl-iconbox__icon.agency-icons {
  max-width: 150px;
  width: 100%;
}

.default-icon-style .kl-iconbox__icon {
  font-size: 16px;
}

.default-icon-style .kl-iconbox__title {
  font-size: 17px;
  line-height: 25px;
}

.grid-ibx__item {
  float: left;
  min-height: 280px;
  padding: 30px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}

.grid-ibx__item[onclick] {
  cursor: pointer;
}

.grid-ibx--cols-1 .grid-ibx__item {
  width: 100%;
}

.grid-ibx--cols-2 .grid-ibx__item {
  width: 50%;
}

.grid-ibx--cols-3 .grid-ibx__item {
  width: 33.3333%;
}

.grid-ibx--cols-5 .grid-ibx__item {
  width: 20%;
}

.grid-ibx__item-inner {
  left: 0;
  position: relative;
  width: 100%;
}

.grid-ibx__link {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.grid-ibx__title-wrp,
.grid-ibx__icon-wrp,
.grid-ibx__desc-wrp {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-filter: blur(0);
  margin-bottom: 20px;
  margin-top: 0;
}

.grid-ibx__title,
.grid-ibx__desc {
  margin: 0;
}

.grid-ibx__icon {
  color: var(--color-dark);
  font-size: 24px;
  line-height: 1;
  transition: color .2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.text_box h3 {
  margin: 35px 0 10px;
}

.grid-ibx--style-lined-full {
  border-width: 1px 0 0 1px;
}

.grid-ibx--style-lined-full .grid-ibx__item {
  border-width: 0 1px 1px 0;
}

.grid-ibx--style-lined-full,
.grid-ibx--style-lined-full .grid-ibx__item {
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
}

.grid-ibx--style-lined-center,
.grid-ibx--style-lined-center .grid-ibx__item {
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
}

.grid-ibx--style-lined-center .grid-ibx__item {
  border-width: 0 1px 1px 0;
}

.grid-ibx--style-lined-center.grid-ibx--cols-1 .grid-ibx__item:nth-child(1) {
  border-right-width: 0;
}

.grid-ibx--style-lined-center.grid-ibx--cols-2 .grid-ibx__item:nth-child(2) {
  border-right-width: 0;
}

.grid-ibx--style-lined-center.grid-ibx--cols-3 .grid-ibx__item:nth-child(3) {
  border-right-width: 0;
}

.grid-ibx--style-lined-center.grid-ibx--cols-4 .grid-ibx__item:nth-child(4) {
  border-right-width: 0;
}

.grid-ibx--style-lined-center.grid-ibx--cols-5 .grid-ibx__item:nth-child(5) {
  border-right-width: 0;
}

.grid-ibx--style-lined-center .grid-ibx__row:last-child .grid-ibx__item {
  border-bottom-width: 0;
}

.grid-ibx--style-lined-center {
  border: 0;
}

/* GridIconBox Styles - Gradient */
.grid-ibx--style-lined-gradient .grid-ibx__item {
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.grid-ibx--style-lined-gradient.grid-ibx--cols-1 .grid-ibx__item {
  width: calc(100% - 30px);
}

.grid-ibx--style-lined-gradient.grid-ibx--cols-2 .grid-ibx__item {
  width: calc(50% - 30px);
}

.grid-ibx--style-lined-gradient.grid-ibx--cols-3 .grid-ibx__item {
  width: calc(33.3333% - 30px);
}



.grid-ibx--style-lined-gradient.grid-ibx--cols-5 .grid-ibx__item {
  width: calc(20% - 30px);
}

.grid-ibx--style-lined-gradient .grid-ibx__ghelper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.grid-ibx--style-lined-gradient .grid-ibx__item:before,
.grid-ibx--style-lined-gradient .grid-ibx__item:after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 60%, transparent 100%);
  content: "";
  height: 100%;
  position: absolute;
  top: 4px;
  width: 1px;
}

.grid-ibx--style-lined-gradient .grid-ibx__item:before {
  left: 0;
}

.grid-ibx--style-lined-gradient .grid-ibx__item:after {
  right: 0;
}

.grid-ibx--style-lined-gradient .grid-ibx__item:hover .grid-ibx__ghelper {
  border-bottom: 0;
  border-color: var(--color-primary);
}

.grid-ibx--style-lined-gradient .grid-ibx__item:hover:before,
.grid-ibx--style-lined-gradient .grid-ibx__item:hover:after {
  background: linear-gradient(to bottom, var(--color-primary) 0%, transparent 100%);
}

/* Hover Effects - Shadow */
.grid-ibx--hover-shadow .grid-ibx__item {
  transition: box-shadow .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.grid-ibx--hover-shadow .grid-ibx__item:hover,
.grid-ibx:not(:hover) .default-hover {
  box-shadow: 0 40px 120px -20px rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.grid-ibx__hover-bg {
  background-size:cover;
  background-position:center;
  background-repeat: no-repeat;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity:0;
  transition:opacity .3s
}

.grid-ibx__hover-bg:before {
  content:"";
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:var(--color-primary);
  opacity:.7
}

.grid-ibx--hover-img .grid-ibx__item:hover,
.grid-ibx:not(:hover) .default-hover {
  color:#fff
}

.grid-ibx--hover-img .grid-ibx__item:hover svg,
.grid-ibx:not(:hover) .default-hover svg {
  color:#fff
}

.grid-ibx__item:hover .grid-ibx__hover-bg,
.grid-ibx:not(:hover) .default-hover .grid-ibx__hover-bg {
  opacity:1
}

.grid-ibx--hover-shadow.grid-ibx--style-lined-gradient .grid-ibx__item:hover {
  box-shadow: 0 -10px 30px -10px rgba(0, 0, 0, 0.29);
}

.grid-ibx--hover-img .grid-ibx__item:hover .btn-dark,
.grid-ibx:not(:hover) .default-hover .btn-dark {
  color:#fff;
}

.grid-ibx--hover-img .grid-ibx__item:hover .btn-dark:before,
.grid-ibx:not(:hover) .default-hover .btn-dark:before {
  border-color:#fff
}

.grid-ibx--hover-img .grid-ibx__item:hover .btn-dark:hover:before,
.grid-ibx:not(:hover) .default-hover .btn-dark:hover:before {
  border-color:transparent
}


/* Hover Effects - Scale */
.grid-ibx--hover-scale .grid-ibx__item {
  transition: transform .25s cubic-bezier(0.215, 0.610, 0.355, 1.000), background .15s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.grid-ibx--hover-scale .grid-ibx__item:hover {
  background: #fff;
  border-radius: 3px;
  border-width: 1px !important;
  transform: scale3d(1.05, 1.05, 1);
  z-index: 1;
}

.grid-ibx--hover-scale.grid-ibx--style-lined-gradient .grid-ibx__item:hover {
  background: none;
}

/* Hover Effects - Shadow & Scale */
.grid-ibx--hover-shadowscale .grid-ibx__item {
  transition: transform .25s cubic-bezier(0.215, 0.610, 0.355, 1.000), background .15s cubic-bezier(0.215, 0.610, 0.355, 1.000), box-shadow .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.grid-ibx--hover-shadowscale .grid-ibx__item:hover {
  background: #fff;
  border-radius: 2px;
  border-width: 1px !important;
  box-shadow: 0 40px 120px -20px rgba(0, 0, 0, 0.6);
  transform: scale3d(1.06, 1.06, 1);
  z-index: 1;
}

.grid-ibx--hover-shadowscale.grid-ibx--style-lined-gradient .grid-ibx__item:hover {
  background: none;
  box-shadow: 0 -10px 30px -10px rgba(0, 0, 0, 0.29);
}

/*** end GRID ICON BOXES */


/* Process steps */
.process_steps {
  border-radius: 2px;
  margin-bottom: 35px;
  min-height: 0;
  overflow: hidden;
  width: 100%;
}

/* Process steps style1 */

.process_steps--style1 {
  margin-right: 0;
}

.process_steps--style1 .process_steps__step {
  color: #fff;
  min-height: 240px;
  padding: 20px 10px;
  position: relative;
  text-align: center;
}

.process_steps--style1 .process_steps__step:first-child {
  border-radius: 3px 0 0 3px;
}

.process_steps--style1 .process_steps__step:last-child {
  border-radius: 0 3px 3px 0;
}

.process_steps--style1 .process_steps__step:after {
  border: 7px solid transparent;
  content: '';
  position: absolute;
  right: -14px;
  top: 15px;
  z-index: 1;
}

.process_steps--style1 .process_steps__intro {
  background: var(--color-primary);
  padding: 20px;
  text-align: left;
}

.process_steps--style1 .process_steps__intro:after {
  border-left-color: var(--color-primary);
}

.process_steps--style1 .process_steps__intro-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0
}

.process_steps--style1 .process_steps__intro-title strong {
  display: block;
  font-size: 120%;
}

.process_steps--style1 .process_steps__intro-link {
  color: #eee;
  font-size: 11px;
  font-weight: 700;
}

.process_steps--style1 .process_steps__intro-link:hover {
  color: #fff;
}

.process_steps--style1 .process_steps__step-desc {
  margin-bottom: 0;
}

.process_steps--style1 .process_steps__step-icon {
  font-size: 56px;
  line-height: 50px;
  margin: 20px 0 15px;
  min-height: 50px;
}

.process_steps--style1 .process_steps__step-typeimg,
.process_steps--style1 .process_steps__step-typeimg img {
  display: inline-block;
  max-height: 90px;
}

.process_steps--style1 [class*="col-"]:first-child {
  overflow: visible;
}

.process_steps--style1 [class*="col-"] {
  overflow: hidden;
}

.process_steps--style1 [class*="col-"]:nth-child(2) {
  background-color: #5a5a5a;
}

.process_steps--style1 [class*="col-"]:nth-child(2):after {
  border-left-color: #5a5a5a;
}

.process_steps--style1 [class*="col-"]:nth-child(3) {
  background-color: #4a4a4a;
}

.process_steps--style1 [class*="col-"]:nth-child(3):after {
  border-left-color: #4a4a4a;
}

.process_steps--style1 [class*="col-"]:nth-child(4) {
  background-color: #3e3e3e;
}

.process_steps--style1 [class*="col-"]:nth-child(4):after {
  border-left-color: #3e3e3e;
}

.process_steps--style1 [class*="col-"]:nth-child(5) {
  background-color: #383838;
}

.process_steps--style1 [class*="col-"]:nth-child(5):after {
  border-left-color: #383838;
}

.process_steps--style1 [class*="col-"]:nth-child(6) {
  background-color: #303030;
}

.process_steps--style1 [class*="col-"]:nth-child(6):after {
  border-left-color: #303030;
}

.process_steps--style1 [class*="col-"]:nth-child(7) {
  background-color: #252525;
}

.process_steps--style1 [class*="col-"]:nth-child(7):after {
  border-left-color: #252525;
}

.process_steps--style1 [class*="col-"]:last-child:after {
  border: 0;
}


/* Process steps style2 */
.process_steps--style2 {
  overflow: hidden;
  padding: 11px 0;
}

.container .process_steps--style2 {
  overflow: visible;
}

.process_steps--style2 .process_steps__container,
.process_steps--style2 .process_steps__height {
  min-height: 235px;
}

.process_steps--style2 .process_steps__container {
  border-radius: 5px;
  float: left;
  min-height: 310px;
  padding-right: calc(((100vw - 1170px) / 2) + 15px);
  position: relative;
  width: 66%;
}

.container .process_steps--style2 .process_steps__container {
  padding-right: 0;
}

.process_steps--style2 .process_steps__container:before {
  border: solid 1px #E0E0E0;
  border-radius: 5px;
  box-sizing: content-box;
  content: '';
  height: 108%;
  left: -30px;
  padding-right: calc(((100vw - 1170px) / 2) + 60px);
  position: absolute;
  top: -4%;
  transform: skewX(-10deg) translate3d(0, 0, 0);
  width: 102%;
}

.process_steps--style2 .process_steps__container:after {
  background: -webkit-gradient(100% 50%, ellipse, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 100%, at 50%, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 100%, at 50%, rgba(0, 0, 0, .5), transparent 70%);
  background: radial-gradient(ellipse at 100% 50%, rgba(0, 0, 0, .5), transparent 70%);
  background: -webkit-radial-gradient(100% 50%, 100% 70%, rgba(0, 0, 0, 0.5) 0%, transparent 70%);
  content: '';
  height: 106%;
  left: -40px;
  position: absolute;
  top: -3%;
  transform: skewX(-10deg) translate3d(0, 0, 0);
  width: 10px;
  z-index: 1;
}

.process_steps--style2.kl-bgc-light .process_steps__container:before {
  background: #fff;
}

.process_steps--style2.kl-bgc-gray .process_steps__container:before {
  background: #F1F1F1;
  border: solid 1px #F1F1F1;
}

.process_steps--style2 .process_steps__step {
  color: #fff;
  overflow: hidden;
  padding: 20px;
  position: relative;
  text-align: center;
}

.process_steps--style2 .process_steps__container .process_steps__step:after {
  background: url(../images/step-separator.svg) no-repeat left top;
  content: '';
  height: 70%;
  left: 100%;
  opacity: 0.05;
  position: absolute;
  top: 15%;
  transform: skew(-10deg);
  width: 10px;
}

.process_steps--style2 .process_steps__container .process_steps__step:last-child:after {
  display: none;
}

.process_steps--style2 .process_steps__step-title {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
}

.process_steps--style2 .process_steps__step-desc {
  color: #888888;
  margin-bottom: 0;
}

.process_steps--style2 .process_steps__step-icon {
  font-size: 48px;
  line-height: 50px;
  margin: 20px 0 15px;
  min-height: 50px;
}

.process_steps--style2 .process_steps__step-typeimg,
.process_steps--style2 .process_steps__step-typeimg .process_steps__step-icon-src {
  display: inline-block;
  margin: 0;
  max-height: 90px;
}

.process_steps--style2 .process_steps__intro {
  background: var(--color-primary);
  float: left;
  padding: 30px 40px 30px 30px;
  padding-left: calc(((100vw - 1170px) / 2) + 15px);
  text-align: left;
  width: 34%;
}

.container .process_steps--style2 .process_steps__intro {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  padding-left: 30px;
}

.process_steps--style2 .process_steps__intro:after {
  border-left-color: var(--color-primary);
}

.process_steps--style2 .process_steps__intro-title {
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  padding-bottom: 10px;
  position: relative;
}

.process_steps--style2 .process_steps__intro-title::before {
  background: #fff;
  bottom: 0;
  content: '';
  display: inline-block;
  height: 3px;
  position: absolute;
  width: 30px;
}

.process_steps--style2 .process_steps__intro-title strong {
  display: block;
  font-size: 120%;
}

.process_steps--style2 .process_steps__intro-link {
  color: #eee;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
}

.process_steps--style2 .process_steps__intro-link:hover {
  color: #fff;
}

/* steps boxes #5 style 1 */
.process-list {
  position: relative;
}

.process-list .processitems {
  list-style: none;
  padding: 0;
  position: relative;
}

.process-list .processitems li {
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  text-align: center;
  width: 20%;
  z-index: 1;
}

.process-list .processitems li .ico-wrapper {
  margin-bottom: 30px;
}

.process-list .processitems li .ico-wrapper > span {
  background: #f2f3f4;
  border-radius: 50%;
  color: #45474d;
  display: inline-block;
  font-size: 55px;
  height: 120px;
  line-height: 120px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 120px;
}

.process-list .processitems li .ico-wrapper > span::after {
  background: #f2f3f4;
  content: '';
  height: 2px;
  left: 100%;
  opacity: 0;
  position: absolute;
  top: 60px;
  visibility: hidden;
  width: 100%;
}

.process-list .processitems li.lined .ico-wrapper > span::after {
  opacity: 1;
  visibility: visible;
}

.process-list .processitems li.last .ico-wrapper > span::after {
  display: none;
}

.process-list .processitems li h4 {
  font-size: 20px;
}

.process-list .processitems li p {
  color: #888;
}

.process-list .processitems li:hover .ico-wrapper > span {
  background: var(--color-primary);
  color: #fff;
}


/* Animations for Process Icons */
.process_steps__step:hover .process_steps__step-icon-src[data-animation] {
  display: inline-block;
}

.process_steps__step:hover .process_steps__step-icon-src[data-animation=tada] {
  animation: tada ease-in-out 1 normal 500ms;
}

.process_steps__step:hover .process_steps__step-icon-src[data-animation=pulse] {
  animation: pulse ease-in-out 1 normal 500ms;
}

.process_steps__step:hover .process_steps__step-icon-src[data-animation=fadeOutRightBig] {
  animation: fadeOutRightBig ease-out 1 normal 1s;
}

/* Pulse */
.pulse {
  animation-name: pulse;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Tada */
.tada {
  animation-name: tada;
}

@keyframes tada {
  0% {
    transform: scale(1);
  }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

/* Fadeout right big */
.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutRightBig {
  0% {
    transform: translate(2px, 1px);
  }
  3% {
    transform: translate(-1px, -2px);
  }
  6% {
    transform: translate(-3px, 0);
  }
  8% {
    transform: translate(0, 2px);
  }
  10% {
    transform: translate(1px, -1px);
  }
  20% {
    transform: translate(20px, -20px);
  }
  100% {
    transform: translate(120px, -120px);
  }
}


/* Process Steps 4 */
.step-boxes-4 {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.process_box4 {
  display: table-cell;
  position: relative;
  text-align: center;
}

.process_box4 .stp_title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.process_box4 .number {
  display: block;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: -40px;
  width: 80px;
}

.process_box4 .number:before {
  background: var(--color-primary);
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  transition: all .2s ease-out;
  width: 20px;
}

.process_box4:hover .number:before {
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
}

.process_box4 .number span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 80px;
  line-height: 80px;
  opacity: 0;
  position: relative;
  text-align: center;
  transition: opacity .2s ease-out .1s, font-size .2s ease-out .1s, visibility .2s ease-out .1s;
  visibility: hidden;
  width: 80px;
}

.process_box4:hover .number span {
  font-size: 30px;
  opacity: 1;
  visibility: visible;
}

.pb__line {
  background: #d8d8d8;
  display: block;
  height: 1px;
  margin: 50px 0;
  margin-left: -10px;
  position: relative;
  width: 100%;
}

.process_box4 .content {
  color: #a3a3a3;
  padding: 0 10px;
}

a.step_link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}


/* Goboxes (stepsbox2) */
.gobox {
  background-color: #EDEDED;
  border-radius: 5px;
  color: #535353;
  line-height: 1.6;
  margin-bottom: 25px;
  min-height: 100px;
  padding: 25px;
  position: relative;
}

.gobox-content {
  position: relative;
  z-index: 1;
}

.gobox-content p {
  font-size: 0.9rem;
}

.gobox-content p:last-child {
  margin-bottom: 0;
}

.gobox h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0;
  text-transform: uppercase;
}

.gobox:before,
.gobox:after {
  content: '';
  position: absolute;
  top: 50%;
}

.gobox:before {
  background-color: #EDEDED;
  border-radius: 5px;
  height: 100%;
  left: -5px;
  margin-top: 0;
  top: 0;
  transform: skewX(-12deg);
  transform-origin: top left;
  width: 40%;
}

.gobox:after {
  background-color: #EDEDED;
  border-radius: 5px;
  height: 100%;
  margin-top: 0;
  right: -5px;
  top: 0;
  transform: skewX(-12deg);
  transform-origin: bottom right;
  width: 40%;
}

.gobox.gobox-first:before,
.gobox.gobox-last:after {
  display: none
}

.gobox.ok:before,
.gobox.ok:after,
.gobox.ok {
  background-color: var(--color-primary);
  color: #fff;
  font-size: 1rem;
}

.gobox.ok h4 {
  color: #fff;
}

.gobox.ok > .far {
  color: rgba(255, 255, 255, 0.6);
  font-size: 42px;
  left: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.gobox.ok > .far + .gobox-content {
  margin-left: 30px;
  overflow: hidden;
}


/* info text box */
.info-text {
  background: #767676;
  border-radius: 2px;
  box-shadow: 3px 3px 0 white;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.4;
  overflow: hidden;
  padding: 25px 30px;
  position: relative;
}

.info-text p:only-child {
  margin-bottom: 0
}

.info-text:after {
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  bottom: -15px;
  color: rgba(255, 255, 255, 0.5);
  content: 'i';
  font-family: Georgia;
  font-size: 44px;
  font-style: italic;
  font-weight: bold;
  height: 90px;
  line-height: 82px;
  position: absolute;
  right: -15px;
  text-align: center;
  width: 90px;
}

.infobox1 {
  background: #ececec;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  margin-bottom: 30px;
  padding: 50px 30px 30px 30px;
  text-align: center;
}

.infobox1 .m_title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.6;
  margin: 0 0 10px;
}

.infobox1 .m_title strong {
  font-weight: 600;
}

.infobox1:hover {
  background: #e9e9e9;
}

.infobox2 {
  background: #ececec;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  margin-bottom: 30px;
  padding: 40px 40px 30px;
}

.infobox2 .m_title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.6;
  margin: 0 0 10px;
}

.infobox2 .m_title strong {
  font-weight: 600;
}

.infobox2 .ib-button {
  float: right;
  margin-top: 5px;
  text-align: right;
}

.infobox2 .ib-button .btn {
  font-size: 14px;
}

.infobox2:hover {
  background: #e9e9e9;
}

.infobox3 {
  margin-bottom: 20px;
  padding: 100px 30px;
  text-align: center;
}

.infobox3 .m_title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.6;
  margin: 0 0 10px;
  text-shadow: none;
}

.infobox3 .m_title strong {
  font-weight: 600;
}

.infobox3 .ib-button.ib-button-1,
.infobox3 .ib-button.ib-button-2 {
  display: inline-block;
  margin-top: 30px;
  text-transform: uppercase;
}

.infobox3 .ib-button.ib-button-1 {
  margin-right: 20px;
}

.infobox3--dark,
.infobox3--dark .m_title {
  color: #343434;
}

.infobox3--light,
.infobox3--light .m_title {
  color: #FFF;
}

/* info box style 2 */
.ib2-custom .ib2-inner {
  padding: 40px;
  position: relative;
  z-index: 1;
}

.ib2-text-color-light-theme .ib2-inner {
  border-left: 4px solid var(--color-primary);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.ib2-info-message {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 20px 42px;
  padding: 0 20px 5px 0;
  position: relative;
}

.ib2-info-message:before {
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  color: var(--color-primary);
  content: 'i';
  font-size: 17px;
  font-weight: 900;
  height: 32px;
  left: -42px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: -5px;
  width: 32px;
}

.ib2-text-color-light-theme .ib2-info-message {
  border-bottom: 1px solid #E4E4E4;
}

.ib2-text-color-dark-theme .ib2-info-message {
  color: #fff;
}

.ib2-style2.ib2-text-color-dark-theme .ib2-info-message {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ib2-style3.ib2-text-color-dark-theme .ib2-info-message {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.ib2-style2.ib2-text-color-dark-theme .ib2-info-message:before {
  border-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
}

.ib2-style3.ib2-text-color-dark-theme .ib2-info-message:before {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
}

.ib2-content--title {
  font-size: 22px;
  font-weight: 100;
  line-height: 1.4;
  margin: 0 0 10px;
}

.ib2-content--text {
  line-height: 1.8;
}

.ib2-content--text p:last-child {
  margin-bottom: 0;
}

.ib2-text-color-light-theme .ib2-content--title {
  color: #828282;
}

.ib2-text-color-light-theme .ib2-content--text {
  color: #535353;
}

.ib2-style2.ib2-text-color-dark-theme .ib2-content--title {
  color: #fff;
}

.ib2-style2.ib2-text-color-dark-theme .ib2-content--text {
  color: rgba(255, 255, 255, 0.6);
}

.ib2-style3.ib2-text-color-dark-theme .ib2-content--title {
  color: #fff;
}

.ib2-style3.ib2-text-color-dark-theme .ib2-content--text {
  color: rgba(255, 255, 255, 0.6);
}

.ib2-style3 {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.ib2-style3:after {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ib2-style3.ib2-text-color-dark-theme:after {
  background-color: rgba(37, 41, 43, 0.7);
}

.ib2-style3.ib2-text-color-light-theme:after {
  background-color: rgba(255, 255, 255, 0.85);
}


/* Statistics element */
/* General */
.statistic-box__title {
  color: #fff;
  font-size: 27px;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 10px;
}

.statistic-box__content {
  color: #fff;
  font-size: 14px;
}

.statistic-box__details {
  margin: 0;
  position: relative;
  width: 100%;
}

.statistic-box__icon-holder {
  margin: 0;
  vertical-align: middle;
  width: 100%;
}

.statistic-box__icon {
  color: #fff;
  line-height: 60px;
  margin: 0;
  opacity: .8;
  transition: opacity .2s ease-out;
}

span.statistic-box__icon {
  font-size: 80px;
}

.statistic-box__icon-holder--img {
  line-height: 60px;
}

.statistic-box__iconimg {
  max-height: 80px;
  width: auto;
}

.statistic-box:hover .statistic-box__icon {
  opacity: 1;
}

.statistic-box__line {
  border-bottom: dotted 1px var(--color-primary);
  height: 1px;
  margin: 0;
  position: relative;
  width: 100%;
}

.statistic-box__line::before {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 5px 0 -1px #fff, 0px 10px 0 -1px #fff, 0px 15px 0 -1px #fff, 0px 20px 0 -1px #fff;
  content: '';
  display: block;
  height: 3px;
  left: 50%;
  position: absolute;
  top: -1px;
  width: 3px;
}

/* Style 1 - horiontal odd/even */
.statistic-box--stl-style1.statistic-box__container {
  display: table;
  margin-bottom: 120px;
  margin-top: 120px;
  table-layout: fixed;
  width: 100%;
}

.statistic-box--stl-style1 .statistic-box {
  display: table-cell;
  position: relative;
  text-align: center;
}

.statistic-box--stl-style1 .statistic-box__icon-holder {
  padding-bottom: 35px;
  padding-top: 0;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.statistic-box--stl-style1 .statistic-box__details {
  min-height: 90px;
  padding-bottom: 0;
  padding-top: 35px;
  position: absolute;
  top: 0;
}

.statistic-box--odd .statistic-box__icon-holder {
  padding-bottom: 0;
  padding-top: 35px;
  transform: translateY(0);
}

.statistic-box--odd .statistic-box__details {
  padding-bottom: 35px;
  padding-top: 0;
  transform: translateY(-100%);
}

.statistic-box--odd .statistic-box__line::before {
  bottom: -2px;
  box-shadow: 0px -5px 0 -1px #fff, 0px -10px 0 -1px #fff, 0px -15px 0 -1px #fff, 0px -20px 0 -1px #fff;
}

/* Style 1 - horiontal normal */
.statistic-box--stl-style2.statistic-box__container {
  display: table;
  margin-bottom: 120px;
  margin-top: 120px;
  table-layout: fixed;
  width: 100%;
}

.statistic-box--stl-style2 .statistic-box {
  display: table-cell;
  position: relative;
  text-align: center;
}

.statistic-box--stl-style2 .statistic-box__icon-holder {
  color: #f1f1f1;
  font-size: 55px;
  padding-bottom: 35px;
  padding-top: 0;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.icon-color {
  color: #d71920;
}

.statistic-box--stl-style2 .statistic-box__details {
  min-height: 90px;
  padding-bottom: 0;
  padding-top: 35px;
  position: absolute;
  top: 0;
}

.css3panels.css3panels_splitBg {
  filter: grayscale(1);
  left: 50%;
  opacity: .1;
  position: absolute;
  top: 53%;
  transform: translate(-50%, -50%);
  width: 486px;
}

.css3panel--2 .css3panels.css3panels_splitBg {
  filter: grayscale(1);
  left: unset;
  right: 100%;
  transform: translate(50%, -50%);
}

.kl-services {
  overflow: hidden;
}

/* Dark */
.statistic-box--dark .statistic-box__title {
  color: #121212;
}

.statistic-box--dark .statistic-box__content {
  color: #121212;
}

.statistic-box--dark .statistic-box__icon {
  color: #121212;
}

.statistic-box--dark .statistic-box__line {
  border-bottom-color: #d6d6d6;
}

.statistic-box--dark .statistic-box__line::before {
  background-color: #121212;
  box-shadow: 0px 5px 0 -1px #121212, 0px 10px 0 -1px #121212, 0px 15px 0 -1px #121212, 0px 20px 0 -1px #121212;
}

.statistic-box--dark .statistic-box--odd .statistic-box__line::before {
  box-shadow: 0px -5px 0 -1px #121212, 0px -10px 0 -1px #121212, 0px -15px 0 -1px #121212, 0px -20px 0 -1px #121212;
}

/* end Statistics element */


/*** STAGE Image Box */
.stage-ibx__stage {
  position: relative;
}

.stage-ibx__point {
  display: none;
}

/* tabs style 1 */
.tabs_style1 {
  background: #fff;
  border: 1px solid #ececec;
}

.tabs_style1 > ul.nav {
  background: rgb(255, 255, 255);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(249, 249, 249, 1) 100%);
  border-bottom: 1px solid #ececec;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=0);
  margin-bottom: 0;
}

.tabs_style1 > ul.nav > li {
  display: inherit;
  margin-bottom: -3px;
}

.tabs_style1 > ul.nav > li > a {
  border-right: 1px solid #ececec;
  color: #343434;
  line-height: 1.4;
  margin-bottom: 2px;
  padding: 14px 20px 15px;
}

.tabs_style1 > ul.nav > li > a.active,
.tabs_style1 > ul.nav > li > a.focus {
  background-color: #fff !important;
  box-shadow: inset 0 3px 0 var(--color-primary);
}

.tabs_style1 > ul.nav > li > a:hover {
  background-color: #fff !important;
}

.tabs_style1 > ul.nav > li > a.active {
  border-left: 0;
  border-top: none;
  font-weight: 700;
  margin-top: -2px;
  padding: 17px 20px 15px;
}

.tabs_style1 .tab-content {
  margin-top: 10px;
  padding: 0 15px 15px;
  width: 100%;
  width: auto;
}

.tabs_style1 .tab-content > .active {
  padding-top: 10px;
}

/* tabs style 2 */
.tabs_style2 {
  margin: 10px 0;
}

.tabs_style2 > ul.nav {
  margin-bottom: 10px;
}

.tabs_style2 > ul.nav > li {
  border-left: 1px dotted #ccc;
  float: left;
  line-height: 18px;
}

.tabs_style2 > ul.nav > li:first-child {
  border-left: 0;
}

.tabs_style2 > ul.nav > li:first-child a {
  border-left: 0;
  padding-left: 0;
}

.tabs_style2 > ul.nav > li > a {
  color: #555;
  line-height: 18px;
  padding: 4px 20px;
}

.tabs_style2 > ul.nav > li > a.active {
  font-weight: bold;
}

.tabs_style2 > ul.nav > li > a.active,
.tabs_style2 > ul.nav > li > a:hover {
  background: none;
  color: #222;
}

.tabs_style2 .tab-content {
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

/* tabs style 3 */
.tabs_style3 {
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 5px;
  overflow: hidden;
  position: static;
}

.tabs_style3 > ul.nav {
  background: rgb(255, 255, 255);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(249, 249, 249, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=0);
  margin-bottom: 0;
}

.tabs_style3 > ul.nav > li {
  float: left;
  margin-bottom: -1px;
}

.tabs_style3 > ul.nav > li > a {
  border: 1px solid transparent;
  border-left: 1px solid #fff;
  border-right: 1px solid #ececec;
  color: #343434;
  display: inline-block;
  line-height: 18px;
  padding: 15px 20px;
}

.tabs_style3 > ul.nav > li > a.active,
.tabs_style3 > ul.nav > li > a:hover,
.tabs_style3 > ul.nav > li > a:focus {
  background-color: #fff !important;
}

.tabs_style3 > ul.nav > li > a.active {
  border-left: 0;
  font-weight: 700;
}

.tabs_style3 .tab-content {
  margin-top: 10px;
  padding: 10px 15px 15px;
  width: 100%;
}

/* tabs style 4 */
.tabs_style4 > ul.nav {
  margin-bottom: 0;
}

.tabs_style4 > ul.nav > li {
  float: left;
  margin-bottom: -1px;
}

.tabs_style4 > ul.nav > li > a {
  color: #343434;
  line-height: 18px;
  padding: 13px 20px;
}

.tabs_style4 > ul.nav > li > a.active,
.tabs_style4 > ul.nav > li > a:hover {
  color: #222;
}

.tabs_style4 > ul.nav > li > a.active,
.tabs_style4 > ul.nav > li > a:focus {
  background: #fff !important;
  border: 1px solid #ececec;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabs_style4 .tab-content {
  background: #fff;
  border: 1px solid #ececec;
  margin-top: 10px;
  padding: 10px 15px 15px;
  width: 100%;
}

/* tabs style 5 */
.tabs_style5 > ul.nav {
  border-bottom: 2px solid #dfdfdf;
}

.tabs_style5 > ul.nav > li {
  float: left;
  margin-bottom: -2px;
}

.tabs_style5 > ul.nav li > a {
  color: #909090;
}

.tabs_style5 > ul.nav li > a:hover {
  color: #535353;
}

.tabs_style5 > ul.nav > li > a.active {
  background: none;
  border-bottom: 2px solid var(--color-primary);
  color: #535353;
}

.tabs_style5 > ul.nav > li:first-child > a {
  padding: 8px 20px 8px 0;
}

.tabs_style5 > ul.nav > li > a {
  display: inline-block;
  line-height: 1.7;
  padding: 8px 20px;
}

.tabs_style5 > ul.nav > li > a:hover {
  background: none;
}

.tabs_style5 .tab-content h5 {
  color: #4d4d4d;
}

.tabs_style5 .tab-content {
  margin-top: 10px;
  padding: 10px 0 15px;
  width: 100%;
}

/* Pricing Tables element (new style) */

.pricing-table-element {
  border: 1px solid #e6e7e8;
  border-left: none;
  border-radius: 4px;
  color: #999;
  font-size: .9rem;
  display: flex;
  margin: 40px 0;
  padding: 0;
}

picture {
  padding: 20px 0;
  display: block;
}

.text_box picture img {
  max-width: 100%;
}

.pricing-table-element::before, .pricing-table-element::after {
  content: "";
  display: table;
  line-height: 0;
}

.pricing-table-element::after {
  clear: both;
}

.pricing-table-element ul {
  display: table;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.inner-cell.table--subheading {
  font-weight: 700;
  color: #333;
}

.pricing-table-element .features-column {
  background: #eee;
  border-radius: 4px 0 0 4px;
  text-align: right;
  width: 28%;
}

.pricing-table-element .features-column ul li {
  border-bottom: 1px solid #e6e7e8;
  height: 44px;
  line-height: 1;
  padding: 15px 40px 15px 0;
}

.pricing-table-element .plan-column,
.pricing-table-element .features-column {
  float: left;
}

.pricing-table-element[data-columns="2"] .plan-column {
  width: 50%;
}

.pricing-table-element[data-columns="3"] .plan-column {
  width: 33.3333%;
}

.pricing-table-element[data-columns="1"] .features-column ~ .plan-column {
  width: 72%;
}

/* with features-col */
.pricing-table-element[data-columns="2"] .features-column ~ .plan-column {
  width: calc(72% / 2);
}

/* with features-col */
.pricing-table-element[data-columns="3"] .features-column ~ .plan-column {
  width: calc(72% / 3);
}

/* with features-col */
.pricing-table-element[data-columns="5"] .features-column ~ .plan-column {
  width: calc(72% / 5);
}

@media (min-width:1200px) {
  .pricing-table-element[data-columns="4"] .plan-column {
    width: 25%;
  }
  /* with features-col */
  .pricing-table-element[data-columns="4"] .features-column ~ .plan-column {
    width: calc(72% / 4);
  }
}

@media (max-width:1199.99px) {
  .pricing-table-element {
    flex-wrap: wrap;
  }
}

@media (max-width:1199.99px) and (min-width:768px) {
  .pricing-table-element[data-columns="4"] .plan-column {
    width: 50%;
  }
  /* with features-col */
  .pricing-table-element[data-columns="4"] .features-column ~ .plan-column {
    width: 52%;
  }
}

@media (max-width:767.99px) {
  .bgback.mobile-bg-right {
    background-position: 81%;
  }
  .bgback.mobile-bg-slightRight {
    background-position: 81%;
  }
  .pricing-table-element[data-columns="4"] .plan-column {
    width: 100%;
  }
  /* with features-col */
  .pricing-table-element[data-columns="4"] .features-column ~ .plan-column {
    width: 100%;
  }
  .grid-ibx__item {
    margin-top:50px
  }
}

/* with features-col */
.pricing-table-element .plan-column {
  border-left: 1px solid #e6e7e8;
}

.pricing-table-element .plan-column ul {
  display: table;
  height: 100%;
}

.pricing-table-element .plan-column ul li {
  display: table-row;
}

.pricing-table-element .plan-column .inner-cell {
  border-bottom:1px solid #e6e7e8;
  display: table-cell;
  height: 80px;
  line-height: 1.5rem;
  text-align: center;
  vertical-align: middle;
  position: relative;
  padding: 15px 20px;

  &.subtitle-cell {
    font-weight: 800;
    border-bottom: 0;
    height:70px;

    & svg {
      position: absolute;
      left: 50%;
      bottom: -1px;
    }
  }
}

.tableWrapper {
  display: flex;
}

.packagePrice {
  width: 100%;
  display: inline-block;
  font-size: 14px;
}

.packagePrice .price {
  font-weight: 700;
  font-size: 1.3em;
}

.pricing-table-element .plan-column .plan-title {
  color: var(--color-primary);
  font-size: 25px;
  height: auto;
  padding: 0;
}

.pricing-table-element .plan-title .inner-cell {
  height: 75px
}

.pricing-table-element .plan-column .subscription-price {
  color: #999999;
  font-size: 13px;
  height: auto;
  padding: 0;
}

.pricing-table-element .subscription-price .inner-cell {
  height: 120px;
}

.pricing-table-element .plan-column .subscription-price .currency {
  color: #434343;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  margin-right: -2px;
  vertical-align: bottom
}

.pricing-table-element .plan-column .subscription-price .price {
  color: #434343;
  font-size: 45px;
  font-weight: 700;
  line-height: 1;
}

hr.mid-divider {
  padding-top: 20px;
  margin-top: 30px;
  border-width: 3px;
  border-color: var(--color-primary);
}

.pricing-table-element .plan-column.featured {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.06);
  margin: -20px 0;
  margin-top: -20px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.pricing-table-element .plan-column.featured + .plan-column {
  border-left-color: transparent;
}

.pricing-table-element .plan-column.featured .plan-title {
  color: #45474d;
  padding: 0;
}

.pricing-table-element .plan-column.featured .plan-title .inner-cell {
  height: 95px;
  color:var(--color-primary);
}

.subtext {
  font-size: .9rem;
  font-style: italic;
}

.pricing-table-element .plan-column.featured .subscription-price .inner-cell {
  background: var(--color-primary);
  color: #fff;
}

.pricing-table-element .plan-column.featured .subscription-price span {
  color: #fff;
}

.pricing-table-element .plan-column.featured ul li:last-child .inner-cell {
  height: 58px;
}

/* Blue Color */
.pricing-table-element.blue .btn-fullcolor, .pricing-table-element.blue .plan-column.featured .subscription-price .inner-cell {
  background-color: #1e73be;
}

.pricing-table-element.blue .btn-fullcolor:hover {
  background-color: #185c98;
}

.pricing-table-element.blue .plan-column .plan-title {
  color: #1e73be;
}

.pricing-table-element.blue .plan-column.featured .plan-title .inner-cell {
  color: #45474d;
}

.pricing-table-element.blue {
  color: #999999 !important;
}

/* vertical tabs */
.vertical_tabs .tabbable .nav > li {
  display: inherit;
  position: relative;
  width: 100%;
}

.vertical_tabs {
  overflow: hidden;
}

.vertical_tabs .tabbable .nav > li > a,
.vertical_tabs .tabbable .nav > li > a > span {
  transition: color .2s ease-out;
}

.vertical_tabs .tabbable .nav > li > a > span span {
  margin: 0;
}

.vertical_tabs .tabbable .nav > li > a:focus,
.vertical_tabs .tabbable .nav > li > a:hover {
  background-color: transparent;
  border-right: 0;
}

.vertical_tabs .tabbable .nav > li > a.active,
.vertical_tabs .tabbable .nav > li > a.active > span,
.vertical_tabs .tabbable .nav > li > a:hover,
.vertical_tabs .tabbable .nav > li > a:hover > span {
  color: var(--color-primary) !important;
}

.vertical_tabs .tabbable .nav > li:first-child {
  border-top: 0;
}

.vertical_tabs .tabbable .tab-content > .tab-pane > h4 {
  margin: 0 0 25px;
}

/* Style1 specific */
.vertical_tabs.kl-style-1 .tabbable .nav > li > a.active {
  box-shadow: inset -3px 0 0 0 var(--color-primary);
}

.vertical_tabs.kl-style-1 .tabbable .nav > li > a {
  color: #8f8f8f;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: -1px;
  padding: 10px 20px 10px 0;
  width: 100%;
}

.vertical_tabs.kl-style-1 .tabbable .nav > li > a > span {
  border-radius: 50%;
  color: #C7C7C7;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  margin-right: 8px;
  margin-right: 10px;
  margin-top: -1px;
  padding: 6px 0 7px;
  vertical-align: middle;
}

.vertical_tabs.kl-style-1 .tabbable .nav > li > a.active > span,
.vertical_tabs.kl-style-1 .tabbable .nav > li > a:hover > span {
  color: #767676;
}

/* Style 2 */
.vertical_tabs.kl-style-2 .tabbable .nav {
  border-bottom: 0;
  border-left: 0;
  margin-right: -1px;
  overflow: hidden;
}

.vertical_tabs.kl-style-2 .tabbable .nav > li > a {
  background-color: #fff;
  background-image: linear-gradient(to right, #fff 70%, rgba(239, 239, 239, 0.5) 100%);
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  color: #858585;
  font-weight: normal;
  line-height: 22px;
  margin-right: 0;
  padding: 20px 45px 20px 25px;
  position: relative;
  text-transform: none;
  width: 100%;
}

.vertical_tabs.kl-style-2 .tabbable .nav > li:first-child > a {
  border-radius: 3px 0 0 0;
  border-top: 1px solid #ddd;
}

.vertical_tabs.kl-style-2 .tabbable .nav > li:last-child > a {
  border-radius: 0 0 0 3px;
}

.vertical_tabs.kl-style-2 .tabbable .nav > li.active > a {
  box-shadow: none;
  color: #131313;
  font-weight: 600;
}

.vertical_tabs.kl-style-2 .tabbable .nav > li > a > span {
  border-radius: 50%;
  color: #999;
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  line-height: 1;
  margin-right: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.vertical_tabs.kl-style-2 .tabbable .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  padding: 40px 25px;
}


/* Keywords box */
.keywordbox {
  border-top: 4px solid #ddd;
  min-height: 100px;
  position: relative;
}

.keywordbox:after {
  border: 6px solid transparent;
  border-top-color: #ddd;
  content: '';
  left: 50%;
  margin-left: -3px;
  position: absolute;
  top: 0;
}

.keywordbox .title-text {
  color: #8D8D8D;
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.5;
}

/* style2 */
.keywordbox.keywordbox-2 h4,
.keywordbox.keywordbox-3 h4,
.keywordbox.keywordbox-4 h4 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.keywordbox.keywordbox-2,
.keywordbox.keywordbox-3 {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: solid 5px var(--color-primary);
  border-top: none;
  min-height: 150px;
  padding: 0 15px;
  position: relative;
  z-index: 2;
}

.keywordbox.keywordbox-3 {
  border-bottom: solid 10px var(--color-primary);
  font-style: normal;
  text-transform: uppercase;
}

.keywordbox.keywordbox-2::after,
.keywordbox.keywordbox-3::after {
  content: none;
}

.keywordbox.keywordbox-2::after,
.keywordbox.keywordbox-3::after {
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(1, 1, 1, 0.8) 94%, rgba(0, 0, 0, 0.8) 100%);
  border: 0;
  content: '';
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d161616', endColorstr='#cc000000', GradientType=0);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.keywordbox.keywordbox-4 {
  border-top: none;
  color: #fff;
  font-style: normal;
  padding: 60px 0;
  text-transform: uppercase;
}

.keywordbox.keywordbox-4::after {
  border: 22px solid transparent;
  border-top-color: #FFF;
  margin-left: -22px;
}


/* Search form */
.search-form-wrapper {
  margin: 0 0 30px;
}

.gensearch__form {
  position: relative;
}

.gensearch__input {
  border: 1px solid #ddd;
  font-size: 0.8rem;
  height: 42px;
  outline: none;
  padding: 0 20px;
  width: 98%;
}

.gensearch__submit {
  background-color: transparent;
  border: 0;
  color: #BBB;
  cursor: pointer;
  font-size: 12px;
  height: 100%;
  outline: none;
  padding: 0 20px;
  position: absolute;
  right: 0;
  transition: color .2s ease-in-out;
}

.gensearch__submit:focus {
  outline: none;
}

.gensearch__submit:hover {
  color: #131313;
}

.gensearch__submit:after {
  background-color: #ddd;
  content: '';
  display: block;
  height: 24px;
  left: 0;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  width: 1px;
}


/* Offline/Comming soon page */
.offline-page #background {
  background: #ffffff;
  background: radial-gradient(ellipse at center, #ffffff 0%, #e5e5e5 100%);
  bottom: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=1);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.offline-page .containerbox {
  background: rgba(0, 0, 0, 0.7);
  border-bottom: 5px solid var(--color-primary);
  box-shadow: 0 10px 20px #CCC;
  color: #eee;
  margin: 100px auto 0;
  margin-bottom: 100px;
  padding: 25px;
  position: relative;
  width: 700px;
  z-index: 1;
}

.offline-page .containerbox__logo {
  height: 100%;
  left: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 175px;
}

.offline-page .site-logo {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.offline-page .site-logo .logo-img {
  max-width: 175px !important;
}

.offline-page .logo-img-sticky {
  display: none;
}

.offline-page .content {
  border-left: 1px solid #777;
  margin-left: 200px;
  padding-left: 20px;
}

.offline-page .containerbox:after {
  border-right: 20px solid transparent;
  border-top: 20px solid var(--color-primary);
  bottom: -25px;
  content: '';
  left: 100px;
  position: absolute;
  z-index: 2;
}

.offline-page .ud_counter {
  margin: 40px 0;
}

.offline-page .ud_counter ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0
}

.offline-page .ud_counter ul li {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 0 12px;
  padding: 15px 10px;
  text-align: center;
  width: 60px;
}

.offline-page .ud_counter ul li:first-child {
  margin-left: 0;
}

.offline-page .ud_counter ul li span {
  display: block;
  font-size: 22px;
  line-height: 1;
  margin-bottom: 15px;
}

.offline-page .ud_counter ul li.till_lauch {
  height: 80px;
  padding: 0;
  vertical-align: top;
  width: 80px;
}

.offline-page .mail_when_ready {
  float: left;
  margin: 0;
}

.offline-page .mail_when_ready input[type=submit] {
  vertical-align: top;
}

.offline-page ul.social-icons {
  margin-left: 0;
  padding-left: 0;
}

.offline-page ul.social-icons li:first-child {
  margin-left: 0;
}

.sccsoon-icon-nocolor {
  background-color: #121212;
}

.till_lauch {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: inline-block;
  height: 80px;
  margin: 0 20px 18px 22px;
  text-align: center;
  vertical-align: top;
  width: 80px;
}

.offline-page .ud_counter ul li p {
  margin-bottom: 0;
}

.offline-page .till_lauch {
  height: 80px;
}

.mc-field-group {
  display:flex;
  position: relative;
}

.newsletter-signup .mc-field-group input[type=submit] {
  background: var(--color-primary);
  border: none;
  height: 100%;
  color: #fff;
}

div.mce_inline_error {
  position: absolute;
  top: 100%;
}

.newsletter-signup .mc-field-group input.email,
.newsletter-signup .mc-field-group input[type=submit]{
  padding: 3px 16px;
  font-size: 1.1rem;
}

@media (max-width:991.99px) {
  .text-center-md .tbk__symbol,
  .text-center-md .mc-field-group{
    justify-content: center;
  }
}

/* Footer Styles*/
/* Footer Default style */
#footer {

  background: #2f2f2f;
  border-top: 4px solid #fff;
  color: #d5d5d5;
  padding: 60px 0 20px;
  position: relative;

  a {
    color: #fff;
    position: relative;
    overflow: hidden;
    display: inline-flex;

    &:before {
      content:"";
      position: absolute;
      bottom:0;
      left:0;
      width:100%;
      height:2px;
      transform:translateX(-100%);
      transition:transform .3s ease-out;
      background:#eee;
    }

    &:hover {
      color: #d5d5d5;

      &:before {
        transform:translateX(0)
      }
    }
  }

  .m_title {
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
    text-shadow: none;
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      padding-right: 10px;
    }
  }

  .sbs .menu li {
    float: left;
    width: 50%;
  }

  .bottom {
    border-top: 5px solid #484848;
    color: #8b8c8d;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width:992px) {
    .bottom {
      justify-content: space-between;
    }
  }

  @media (max-width:991.99px) {
    .bottom {
      justify-content: center;
    }
  }

  .footer-area--bottom {
    border-top: 5px solid #484848;
    color: #8b8c8d;
    padding-top: 30px;
  }

  .social-icons {
    @media (min-width:992px) {
      margin-left: auto;
    }

    a:hover {
      color: var(--color-primary);
    }

    li {
      margin: 0 0 0 10px;

      &.title {
        line-height: 30px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .contact-details {
    min-height: 64px;

    a {
      margin-bottom: 10px;
    }
  }

  .copyright img {
    float: left;
    margin-right: 20px;
  }
}

.scfooter-icon-nocolor {
  background-color: #121212;
}

.map-link > span {
  margin-top: 2px;
}

/* contact form errors */
.cf_response {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
}

.ui-state-error {
  border: 1px solid #EB3034 !important;
  position: relative;
}

.ui-state-valid {
  border: none !important;
  position: relative;
}

.ui-state-error + span::after {
  color: var(--color-primary);
  content: "\e01b";
}

.ui-state-valid + span::after {
  color: #0EAB55;
  content: "\e013";
}

.formMessages {
  border-left: 2px solid var(--color-primary);
  margin-bottom: 15px;
  margin-left: 15px
}


#notification_container .alert {
  display: inline-block;
  padding: 10px 20px;
  position: relative;
}

#notification_container .close::before {
  color: #000;
  content: "\00d7";
  outline: none;
}

#notification_container .close {
  font-size: 15px;
  opacity: 1;
  position: absolute;
  right: 2px;
  top: 2px;
}


/* css transitions - reducing transitions lines */
.topnav .topnav-item a,
.topnav .topnav-item span,
.topnav .topnav-item i,
#totop,
.playVideo span,
.theHoverBorder,
.hoverBorder h6,
.partners_carousel li a,
.hoverBorder:hover img,
.feature_box.style3 .box,
.services_box .icon,
.gobox, .hover-box,
.circlehover span,
.process_box,
#sidebar ul.menu li a,
.zn_sidebar ul.menu li a,
[id*='sidebar-widget-'] ul.menu li a,
.timeline_box,
.timeline_box:hover .date,
.services_box.style2 .box,
.team_member,
.ptcarousel .controls > a,
.timeline_box:before,
.support_panel {
  transition: all 0.2s ease-out;
}


/* RangerDT Videos */
.kl-video-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.kl-video {
  position: absolute;
  z-index: 1;
}

.kl-video video {
  display: block;
  max-width: none;
}

.kl-video img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0 !important;
  right: 0;
  top: 0;
}

.video-container {
  height: 0;
  overflow: hidden;
  padding-bottom: 55.25%;
  position: relative;
}

.video-container iframe,
.video-container object,
.video-container embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.csstransforms .kl-video.valign video {
  top: 50% !important;
  transform: translateY(-50%);
}

.csstransforms .kl-video.halign video {
  left: 50% !important;
  transform: translateX(-50%) scale(1.002);
}

.csstransforms .kl-video.halign.valign video,
.csstransforms .kl-video.halign.valign iframe {
  left: 50% !important;
  top: 50% !important;
  transform: translateY(-50%) translateX(-50%) scale(1.002);
}

/* Video Grid Overlay */
.video-grid-overlay:after {
  background-image: url(../images/video-pattern-1.png);
  content: '';
  height: 100%;
  left: 0;
  opacity: .7;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.video-grid-overlay.ovrl2:after {
  background-image: url(../images/video-pattern-2.png);
}

/* Video with subtle top gradient */
.video-subtle-gradient:before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
  content: '';
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d000000', endColorstr='#00000000', GradientType=0);
  height: 270px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

/* Video Controls */
.kl-video--controls {
  list-style: none;
  margin: 0;
  opacity: .1;
  padding: 0;
  position: absolute;
  right: 50px;
  top: 30px;
  transition: opacity .2s ease-in;
  width: 50px;
  z-index: 3;
}

.kl-video--controls[data-position="top-left"] {
  left: 50px;
  right: auto;
  top: 120px;
}

.kl-video--controls[data-position="top-right"] {
  left: auto;
  right: 50px;
  top: 120px;
}

.kl-video--controls[data-position="top-center"] {
  left: 50%;
  margin-left: -25px;
  right: auto;
}

.kl-video--controls[data-position="bottom-right"] {
  bottom: 5px;
  right: 20px;
  top: auto;
}

.kl-video--controls[data-position="bottom-left"] {
  bottom: 5px;
  left: 50px;
  right: auto;
  top: auto;
}

.kl-video--controls[data-position="bottom-center"] {
  bottom: 5px;
  left: 50%;
  margin-left: -25px;
  right: auto;
  top: auto;
}

div[class*="maskcontainer--"] .kl-video--controls[data-position="bottom-right"],
div[class*="maskcontainer--"] .kl-video--controls[data-position="bottom-left"],
div[class*="maskcontainer--"] .kl-video--controls[data-position="bottom-center"] {
  bottom: 80px;
}

.kl-video--controls[data-position="middle-right"] {
  margin-top: -25px;
  top: 50%;
}

.kl-video--controls[data-position="middle-left"] {
  left: 50px;
  margin-top: -25px;
  right: auto;
  top: 50%;
}

.kl-video--controls[data-position="middle-center"] {
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  right: auto;
  top: 50%;
}

.kl-video-container:hover .kl-video--controls,
#page-header:hover .kl-video--controls {
  opacity: 1;
}

.kl-video-container .kl-video--controls a {
  color: #fff;
  cursor: pointer;
  display: block;
  height: 50px;
  line-height: 50px;
  opacity: .5;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-out;
  width: 50px;
}

.kl-video--controls .btn-audio {
  opacity: 0;
  transform: translateY(-30px);
  visibility: hidden;
}

.kl-video--controls:hover .btn-audio {
  opacity: .7;
  transform: translateY(-10px);
  visibility: visible;
}

.kl-video--controls .btn-toggleplay {
  transform: translateY(0);
}

.kl-video--controls .btn-toggleplay .paused:before {
  content: "\f04c";
}

.kl-video--controls:hover .btn-toggleplay {
  transform: translateY(-10px);
}

.kl-video--controls a:hover {
  opacity: 1;
}

.kl-video--controls .btn-audio .kl-icon {
  height: 28px;
  line-height: 28px;
  width: 28px;
}

.kl-video--controls .btn-audio .kl-icon:before {
  line-height: 29px;
}

.kl-video--controls .btn-audio .mute:before {
  content: "\f026";
}

.csstransforms .kl-video.halign.valign video {
  -o-object-fit: cover;
  object-fit: cover;
  transform: translateY(-50%) translateX(-50%) scale(1.000);
}

.kl-video video {
  display: block;
  height: 100% !important;
  max-width: none;
  position: relative !important;
  width: 100% !important;
}


/* FLICKR FEED */
.flickr_feeds {
  list-style: none;
  margin: 0 0 0 -20px;
  padding: 0;
  position: relative;
}

.flickrfeed.loading:after {
  content: 'Loading..';
}

.flickr_feeds li {
  float: left;
  margin: 0 0 20px 20px;
}

.flickr_feeds li a {
  display: block;
  position: relative;
  width: 73px;
}

.flickr_feeds[data-size=small] li a {
  width: 60px;
}

.flickr_feeds li a .hov1 {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}


/*** Testimonials Partners */
.testimonials-partners__separator {
  background: #fff;
  height: 1px;
  margin-bottom: 70px;
  opacity: .2;
  width: 100%;
}

.ts-pt-testimonials {
  margin-left: -50px;
}

/* Testimonial Item */
.ts-pt-testimonials__item {
  color: #fff;
  float: left;
  margin-bottom: 50px;
  margin-left: 50px;
}

.ts-pt-testimonials__item--size-1 {
  width: calc(25% - 50px);
}

.ts-pt-testimonials__item--size-2 {
  width: calc(50% - 50px);
}

.ts-pt-testimonials__item--size-3 {
  width: calc(75% - 50px);
}

.ts-pt-testimonials__item--size-4 {
  wwidth: calc(100% - 50px);
}

/* Testimonial content */
.ts-pt-testimonials__text {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.7;
  padding: 40px 25px 40px;
  position: relative;
}

.ts-pt-testimonials__text:after {
  border: 13px solid transparent;
  content: '';
  left: 55px;
  position: absolute;
}

.ts-pt-testimonials__item--normal .ts-pt-testimonials__text:after {
  border-top-color: rgba(0, 0, 0, 0.2);
  top: 100%;
}

.ts-pt-testimonials__item--reversed .ts-pt-testimonials__text:after {
  border-bottom-color: rgba(0, 0, 0, 0.2);
  bottom: 100%;
}

.ts-pt-testimonials__item--normal .ts-pt-testimonials__infos {
  margin-top: 15px;
}

.ts-pt-testimonials__item--reversed .ts-pt-testimonials__infos {
  margin-bottom: 15px;
}

.ts-pt-testimonials__item--normal .ts-pt-testimonials__infos--noimg {
  margin-left: 20px;
  margin-top: 25px;
}

.ts-pt-testimonials__item--reversed .ts-pt-testimonials__infos--noimg {
  margin-bottom: 25px;
  margin-left: 20px;
}

.ts-pt-testimonials__img {
  background-clip: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  float: left;
  height: 110px;
  margin-left: 25px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  width: 110px;
}

.ts-pt-testimonials__item--normal .ts-pt-testimonials__img {
  margin-top: -35px;
}

.ts-pt-testimonials__item--reversed .ts-pt-testimonials__img {
  margin-top: -15px;
}

.ts-pt-testimonials__item--size-1 .ts-pt-testimonials__img {
  height: 60px;
  margin-left: 5px;
  margin-top: 0;
  width: 60px;
}

.ts-pt-testimonials__name {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.ts-pt-testimonials__item--size-1 .ts-pt-testimonials__name {
  font-size: 14px;
}

.ts-pt-testimonials__position {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 5px;
  opacity: 0.5;
}

.ts-pt-testimonials__stars span {
  font-size: 11px;
  opacity: .6;
}

.ts-pt-testimonials__stars--1 span:nth-child(1) ~ span,
.ts-pt-testimonials__stars--2 span:nth-child(2) ~ span,
.ts-pt-testimonials__stars--3 span:nth-child(3) ~ span,
.ts-pt-testimonials__stars--4 span:nth-child(4) ~ span {
  opacity: .3;
}


/* Video AdBox */
.adbox.video {
  position: relative;
}

.adbox.video:after {
  background-color: rgba(23, 23, 25, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.adbox.video > img {
  display: block;
  height: auto;
  width: 100%;
}

.adbox.video .video_trigger_wrapper {
  height: 100%;
  left: auto;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.adbox_container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.adbox_container > h5 {
  color: white;
  display: block;
  font-size: 11px;
  margin: 0 auto;
  min-width: 150px;
  text-align: center;
}

.fluid-width-video-wrapper {
  padding: 0;
  position: relative;
  width: 100%;
}

.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* To Next Button */
.tonext-btn {
  bottom: 40px;
  color: #FFF;
  display: block;
  left: 50%;
  margin-left: -12px;
  position: absolute;
  text-decoration: none;
  z-index: 101;
}

.tonext-btn.has-nav {
  bottom: 70px;
}

.mouse-anim-icon {
  background-color: rgba(255, 255, 255, .1);
  border-radius: 12px;
  display: block;
  height: 40px;
  position: relative;
  transition: background-color .2s ease-out;
  width: 24px;
}

.mouse-anim-icon:after {
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: animScroll;
  animation-timing-function: ease-in-out;
  background-color: rgba(0, 0, 0, 0.25);
  content: '';
  height: 9px;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  width: 2px;
}

.mouse-anim-icon:hover {
  background-color: rgba(255, 255, 255, .4);
}

.mouse-anim-icon:hover:after {
  top: 24px;
}

@keyframes animScroll {
  from {
    top: 8px;
  }
  to {
    top: 24px;
  }
}


/*** Media Container */
.media-container {
  position: relative;
}

.media-container__link--wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.media-container__link--btn {
  -webkit-backface-visibility: hidden;
  left: 50%;
  outline: none !important;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.media-container__link .kl-icon {
  color: #fff;
}

.media-container__link--btn .kl-icon:not(.circled-icon) {
  margin-right: 5px;
  transition: margin-right .2s ease-out;
}

.media-container__link--btn:hover .kl-icon:not(.circled-icon) {
  margin-right: 15px;
}

.media-container .kl-title-block {
  position:relative;
  z-index: 1
}

.even_ends>div:first-child {
  padding-left: 0;
}
.even_ends>div:last-child {
  padding-right: 0;
}
.even_ends>div:nth-child(2) {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.about_detail {
  font-size: 1.4rem;
}
/* Bodered animated 1 */
.media-container__link--style-borderanim1 {
  color: #fff;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.2;
  padding: 10px 30px;
  text-align: center;
  transition: color .2s ease-out;
  white-space: nowrap;
}

.media-container__link--style-borderanim1 > i {
  background-color: #fff;
  display: block;
  opacity: .8;
  position: absolute;
  transition: all .2s ease-out
}

.media-container__border-tt {
  height: 7px;
  left: 0;
  top: 0;
  width: 30%;
}

.media-container__border-tl {
  height: 80%;
  left: 0;
  top: 7px;
  width: 7px;
}

.media-container__border-bb {
  bottom: 0;
  height: 7px;
  right: 0;
  width: 30%;
}

.media-container__border-br {
  bottom: 7px;
  height: 80%;
  right: 0;
  width: 7px;
}

.media-container:hover > i {
  opacity: 1;
}

.media-container:hover .media-container__border-tt {
  height: 3px;
  left: 0;
  top: 0;
  width: 100%;
}

.media-container:hover .media-container__border-tl {
  height: 100%;
  left: 0;
  top: 0;
  width: 3px;
}

.media-container:hover .media-container__border-bb {
  bottom: 0;
  height: 3px;
  right: 0;
  width: 100%;
}

.media-container:hover .media-container__border-br {
  bottom: 0;
  height: 100%;
  right: 0;
  width: 3px;
}

/* Alternative animated border */
.media-container__link--style-borderanim2 .media-container__text {
  color: #fff;
  font-family: var(--heading-font);
  font-size: 30px;
  font-weight: 700;
  height: 100%;
  left: 0;
  letter-spacing: 4px;
  line-height: 1;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.borderanim2-svg {
  position: relative;
}

.borderanim2-svg__shape {
  fill: transparent;
  stroke: #FFF;
  stroke-width: 8px;
  transition: stroke-width .7s, stroke-dashoffset .7s, stroke-dasharray .7s;
  transition-timing-function: ease-out;
}

.media-container:hover .borderanim2-svg__shape {
  stroke-dashoffset: 0;
  stroke-width: 3px;
}

.borderanim2-svg {
  width: 400px;
}

.borderanim2-svg .media-container__text {
  line-height: 70px;
}

.borderanim2-svg__shape {
  stroke: var(--color-primary);
  stroke-dasharray: 100px 1000px;
  stroke-dashoffset: -620px;
}

.style2:hover .borderanim2-svg__shape {
  stroke-dasharray: 940px;
}

/* Circle animation */
.circleanim-svg {
  height: 108px;
  width: 108px;
}

.circleanim-svg__circle-front,
.circleanim-svg__circle-back {
  fill: none;
  stroke: #fff;
}

.circleanim-svg__circle-front {
  stroke-dasharray: 60px 1000px;
  stroke-dashoffset: 0;
  stroke-opacity: .5;
  stroke-width: 5px;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s, stroke-opacity 1s;
}

.media-container:hover .circleanim-svg__circle-front {
  stroke-dasharray: 340px;
  stroke-opacity: 1;
  stroke-width: 2px;
}

/* Media container with custom content */
.media-container.media-container--type-pb {
  display: flex;
}

.media-container-pb {
  margin: 10px 25px;
  width: calc(100% - 50px);
}

.media-container-pb.media-container-pb--alg-top {
  align-self: flex-start;
}

.media-container-pb.media-container-pb--alg-center {
  align-self: center;
}

.media-container-pb.media-container-pb--alg-bottom {
  align-self: flex-end;
}

.prt-hover-slidein,
.prt-hover-slideout {
  transition: all .2s ease-out;
}

.media-container:hover .prt-hover-slidein {
  max-height: 200px;
  opacity: 1;
}

.prt-hover-slidein .tbk__subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
  line-height: 30px;
}

/*** End Media Container Element */

/* Custom container */
.custom_container.p-5 {
  padding: 0 !important;
  padding-top: 5% !important;
}


/* photo gallery */
ul.photo_gallery {
  clear: both;
  list-style: none outside none;
  margin: 8px auto;
  overflow: hidden;
  padding: 8px 0;
}

ul.photo_gallery li {
  background: none !important;
  float: left;
  list-style: none;
  margin: 5px;
  padding: 0;
}


/*** Grid Photo Gallery */
.gridPhotoGallery {
  margin-left: -5px;
  margin-right: -5px;
}

.gridPhotoGallery__item {
  float: left;
  padding: 0;
  position: relative;
}

.gridPhotoGallery__link {
  display: block;
  height: 0;
  margin-bottom: 0;
  padding: 0;
  padding-bottom: 100%;
  width: 100%;
}

.gridPhotoGallery__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 5px;
  display: block;
  left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

/* Icons */
.gridPhotoGallery .gridPhotoGallery__item .kl-icon {
  border-color: #fff;
  color: #fff;
  left: 50%;
  margin-top: 15px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all .25s ease-in-out
}

.gridPhotoGallery .gridPhotoGallery__link:hover .kl-icon {
  display: block;
  margin-top: 0;
  opacity: 1;
}

/* Title */
.gridPhotoGallery__link {
  position: relative;
}

.gridPhotoGallery__link:after {
  color: #fff;
  content: attr(title);
  font-family: var(--heading-font);
  font-size: 16px;
  left: 15%;
  margin-top: -65px;
  opacity: 0;
  position: absolute;
  right: 15%;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  transition: all .25s ease-in-out;
}

.gridPhotoGallery__link:hover:after {
  opacity: 1;
}

/* BG Overlay */
.gridPhotoGallery__img:after {
  background-color: rgba(28, 31, 32, 0.25);
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .25s ease-in-out;
  width: 100%;
}

.gridPhotoGallery__link:hover .gridPhotoGallery__img:after {
  opacity: 1;
}

/* Cols Width */
.gridPhotoGallery--cols-1 .gridPhotoGallery__item {
  width: 100%;
}

.gridPhotoGallery--cols-2 .gridPhotoGallery__item {
  width: 50%;
}

.gridPhotoGallery--cols-3 .gridPhotoGallery__item {
  width: 33.3333%;
}

.gridPhotoGallery--cols-4 .gridPhotoGallery__item {
  width: 25%;
}

.gridPhotoGallery--cols-5 .gridPhotoGallery__item {
  width: 20%;
}

.gridPhotoGallery--cols-6 .gridPhotoGallery__item {
  width: 16.6666%;
}

/* 2 x width */
.gridPhotoGallery--cols-2 .gridPhotoGalleryItem--w2 {
  width: 100%;
}

.gridPhotoGallery--cols-3 .gridPhotoGalleryItem--w2 {
  width: 66.6666%;
}

.gridPhotoGallery--cols-4 .gridPhotoGalleryItem--w2 {
  width: 50%;
}

.gridPhotoGallery--cols-5 .gridPhotoGalleryItem--w2 {
  width: 40%;
}

.gridPhotoGallery--cols-6 .gridPhotoGalleryItem--w2 {
  width: 33.3333%;
}

/* height rations w1 + h1 & w2 + h2 */
.gridPhotoGallery--ratio-short .gridPhotoGalleryItem--w1 .gridPhotoGalleryItem--h1,
.gridPhotoGallery--ratio-short .gridPhotoGalleryItem--w2 .gridPhotoGalleryItem--h2 {
  padding-bottom: 75%;
}

.gridPhotoGallery--ratio-square .gridPhotoGalleryItem--w1 .gridPhotoGalleryItem--h1,
.gridPhotoGallery--ratio-square .gridPhotoGalleryItem--w2 .gridPhotoGalleryItem--h2 {
  padding-bottom: 100%;
}

.gridPhotoGallery--ratio-tall .gridPhotoGalleryItem--w1 .gridPhotoGalleryItem--h1,
.gridPhotoGallery--ratio-tall .gridPhotoGalleryItem--w2 .gridPhotoGalleryItem--h2 {
  padding-bottom: 125%;
}

/* height rations w2 + h1 */
.gridPhotoGallery--ratio-short .gridPhotoGalleryItem--w2 .gridPhotoGalleryItem--h1 {
  padding-bottom: 37.5%;
}

.gridPhotoGallery--ratio-square .gridPhotoGalleryItem--w2 .gridPhotoGalleryItem--h1 {
  padding-bottom: 50%;
}

.gridPhotoGallery--ratio-tall .gridPhotoGalleryItem--w2 .gridPhotoGalleryItem--h1 {
  padding-bottom: 62.5%;
}

/* height rations w1 + h2 */
.gridPhotoGallery--ratio-short .gridPhotoGalleryItem--w1 .gridPhotoGalleryItem--h2 {
  padding-bottom: 150%;
}

.gridPhotoGallery--ratio-square .gridPhotoGalleryItem--w1 .gridPhotoGalleryItem--h2 {
  padding-bottom: 200%;
}

.gridPhotoGallery--ratio-tall .gridPhotoGalleryItem--w1 .gridPhotoGalleryItem--h2 {
  padding-bottom: 250%;
}


/* Photo Gallery alternative element */
.photogallery-widget .photogallery-wrapper {
  overflow: hidden;
  position: relative;
}

.photogallery-widget .caroufredsel {
  width: 74.35%;
}

.photogallery-widget ul.slides {
  list-style: none;
  margin: 0;
  padding: 0;
}

.photogallery-widget .caroufredsel .slides li {
  position: relative;
}

.photogallery-widget .caroufredsel .slides li img {
  width: 100%;
}

.photogallery-widget .cfs--navigation {
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.photogallery-widget .cfs--navigation a {
  color: #fff;
  margin-top: 0;
  top: auto;
}

.photogallery-widget .cfs--navigation a::before {
  font-size: 35px;
}

.photogallery-widget .cfs--prev {
  left: 20px;
}

.photogallery-widget .cfs--next {
  right: 20px;
}

.photogallery-widget .caroufredsel:hover .cfs--prev {
  left: 30px;
}

.photogallery-widget .caroufredsel:hover .cfs--next {
  right: 30px;
}

.photogallery-widget .caroufredsel .pg-caption,
.photogallery-widget .caroufredsel .pg-caption a {
  color: #FFF;
  font-size: 16px;
  line-height: 1.8;
}

.photogallery-widget .caroufredsel .pg-caption {
  bottom: 50px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.photogallery-widget .caroufredsel .pg-caption a {
  font-style: italic;
  text-transform: none;
}

.photogallery-widget .caroufredsel .pg-caption.blacklined {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  color: #FFF;
  display: inline;
  padding: 2px 7px;
}

.photogallery-widget .cfs-counter {
  bottom: 30px;
  color: rgba(255, 255, 255, 0.3);
  font-style: italic;
  font-weight: 700;
  left: 50px;
  position: absolute;
  z-index: 2;
}

.photogallery-widget .cfs--pagination {
  bottom: auto;
  height: 100%;
  left: auto;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 25.65%;
}

.photogallery-widget .pg-thumb {
  -webkit-backface-visibility: hidden;
  background-color: #1A1C25;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  float: left;
  margin: 0;
  position: relative;
  width: 50%;
}

.photogallery-widget .pg-thumb > a {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  opacity: .8;
  position: relative;
  width: 100%;
}

.photogallery-widget .pg-thumb:hover > a,
.photogallery-widget .pg-thumb.selected > a {
  opacity: 1;
}

.photogallery-widget .pg-thumb:hover {
  z-index: 2;
}

.photogallery-widget .pg-thumb .pg-caption {
  background: var(--color-primary);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 20px 5px 0 20px;
  position: absolute;
  text-align: left;
  top: 0;
  visibility: hidden;
  width: 100%;
}

.photogallery-widget .pg-thumb:nth-child(even) .pg-caption {
  right: 0;
}

.photogallery-widget .pg-thumb:nth-child(odd) .pg-caption {
  left: 0
}

.photogallery-widget .pg-thumb:nth-child(even):hover .pg-caption {
  opacity: 1;
  right: 100%;
  visibility: visible;
}

.photogallery-widget .pg-thumb:nth-child(odd):hover .pg-caption {
  left: 100%;
  opacity: 1;
  visibility: visible;
}

.photogallery-widget .pg-thumb .pg-caption::before {
  border: 6px solid transparent;
  content: '';
  margin-top: -6px;
  position: absolute;
  top: 50%;
}

.photogallery-widget .pg-thumb:nth-child(even) .pg-caption::before {
  border-left-color: var(--color-primary);
  right: -12px;
}

.photogallery-widget .pg-thumb:nth-child(odd) .pg-caption::before {
  border-right-color: var(--color-primary);
  left: -12px;
}

.photogallery-widget .pg-thumb .pg-caption a {
  color: #fff;
  font-size: 12px;
  opacity: .8;
}

.photogallery-widget .pg-thumb .pg-caption a:hover {
  opacity: 1;
}

.photogallery-widget .pg-thumb:nth-child(9):last-child {
  width: 100%;
}

.photogallery-widget .pg-thumb:nth-child(9):last-child .pg-caption {
  left: 0;
  right: auto;
}

.photogallery-widget .pg-thumb .pg-caption {
  transition: all 0.15s ease-out;
}

/* bottom-thumbs */
.photogallery-widget.bottom-thumbs .caroufredsel {
  position: relative;
  width: 100%;
}

.photogallery-widget.bottom-thumbs .cfs--navigation {
  width: 100%;
}

.photogallery-widget.bottom-thumbs .cfs--pagination {
  height: auto;
  margin-left: -2px;
  margin-top: 2px;
  position: static;
  width: 100%;
  width: calc(100% + 2px);
}

.photogallery-widget.bottom-thumbs .pg-thumb {
  height: auto;
  margin-bottom: 2px;
  margin-left: 2px;
  width: 20%;
  width: calc(20% - 2px);
}

/* end Photo Gallery alternative element */


/* Counter */
.ud_counter {
  margin: 0 0 50px;
}

.ud_counter:hover img[data-animation=fadeOutRightBig] {
  animation: fadeOutRightBig ease-out 1 normal 1s;
}

.ud_counter ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0
}

.ud_counter ul li {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: inline-block;
  font-size: 28px;
  font-weight: 700;
  margin: 0 20px 15px 0;
  padding: 2px 0;
  text-align: center;
  width: 70px;
}

.ud_counter ul li p {
  display: block;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 15px;
  margin-top: 5px;
}

.ud_counter ul li.till_lauch {
  height: 80px;
  padding: 0;
  vertical-align: top;
  width: 80px;
}

.till_lauch {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: inline-block;
  height: 84px;
  margin: 0 20px 14px 22px;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  width: 80px;
}

.mail_when_ready {
  float: left;
  margin: 0 0 15px 0;
  width: 100%;
}

/* skills diagram (shortcode) */
#skills_diagram {
  float: right;
  position: relative;
  width: 600px;
}

#skills_diagram .legend {
  position: absolute;
  right: 0;
  text-align: right;
  top: 40px;
}

#skills_diagram #thediagram {
  float: right;
  height: 500px;
  margin-top: -50px;
  width: 600px;
}

#skills_diagram .skills {
  list-style: none;
  margin: 0;
  padding: 0;
}

#skills_diagram .skills li {
  clear: both;
  color: white;
  float: right;
  font-size: 14px;
  height: 25px;
  line-height: 25px;
  margin-bottom: 1px;
  padding: 0 15px;
}

/* Skills diagram element */
.kl-skills-diagram {
  position: relative;
}

.skills-responsive-diagram {
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.kl-diagram {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.kl-diagram svg {
  height: 100%;
  overflow: visible !important;
  width: 100%;
}

.kl-skills-legend {
  position: absolute;
}

.kl-skills-legend.legend-topright {
  left: 100%;
  text-align: left;
  top: 40px;
}

.kl-skills-legend.legend-bottomright {
  bottom: 40px;
  left: 100%;
  text-align: left;
}

.kl-skills-legend.legend-topleft {
  right: 100%;
  text-align: right;
  top: 40px;
}

.kl-skills-legend.legend-bottomleft {
  bottom: 40px;
  right: 100%;
  text-align: right;
}

.kl-skills-list {
  direction: ltr;
  list-style: none;
  margin: 0;
  padding: 0;
}

.kl-skills-list li {
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  height: 25px;
  line-height: 25px;
  margin-bottom: 2px;
  padding: 0 13px;
}

.kl-skills-legend > h4 {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
}

/* end Skills diagram element */


/* Custom height for carousels */
.carousel-item--height190 {
  height: 190px;
}

.carousel-item--height200 {
  height: 200px;
}

.carousel-item--height240 {
  height: 240px;
}

.carousel-item--height280 {
  height: 280px;
}

.carousel-item--height300 {
  height: 300px;
}

.carousel-item--height500 {
  height: 500px;
}

/* Recent Work Carousel 1 - Style 1 */
.recentwork_carousel__img {
  height: 100% !important;
  width: 100% !important;
}

.recentwork_carousel__crsl {
  height: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

.recentwork_carousel__crsl .recent-work_carousel-item {
  float: left;
  margin: 0;
  padding: 0 20px 0 0;
  text-align: center;
}

.recentwork_carousel__link {
  display: block;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.recentwork_carousel__hover {
  display: block;
  overflow: hidden;
  position: relative;
}

.recentwork_carousel__hov {
  transition: all 0.2s ease-out;
}

.recentwork_carousel--1 .recentwork_carousel__hov {
  background-color: rgba(35, 35, 35, 0.8);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
}

.recentwork_carousel--1 .recentwork_carousel__hov:after {
  color: #fff;
  content: "\2b";
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  left: 50%;
  line-height: 1;
  margin: -10px 0 0 -10px;
  opacity: 0;
  position: relative;
  position: absolute;
  top: 50%;
}

.recentwork_carousel__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.recentwork_carousel__details {
  transition: all 0.2s ease-out;
}

.recentwork_carousel--1 .recentwork_carousel__details {
  display: block;
  position: relative;
}

.recentwork_carousel--1 .recentwork_carousel__bg {
  transition: all 0.2s ease-out;
}

.recentwork_carousel--1 .recentwork_carousel__bg {
  background: var(--color-primary);
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.recentwork_carousel__crsl-title {
  color: var(--color-primary);
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  padding: 10px 0;
  position: relative;
  z-index: 1;
}

.recentwork_carousel__cat {
  color: #343434;
  display: block;
  font-size: 0.8rem;
  line-height: 1.4;
  padding-bottom: 15px;
  position: relative;
  z-index: 1;
}

.recentwork_carousel__link:hover .recentwork_carousel__hov {
  top: 0;
}

.recentwork_carousel__link:hover .recentwork_carousel__hov:after {
  opacity: 1;
}

.recentwork_carousel--1 .recentwork_carousel__link:hover .recentwork_carousel__bg {
  height: 100%;
}

.recentwork_carousel__link:hover .recentwork_carousel__crsl-title,
.recentwork_carousel__link:hover .recentwork_carousel__cat {
  color: #fff;
}

.recentwork_carousel__controls .complete {
  display: inline-block;
  height: 16px;
  margin: 0 10px;
  position: relative;
  width: 16px;
}

.recentwork_carousel__controls .complete:before {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 2px;
  box-shadow: 6px 0 0 rgba(0, 0, 0, .5), 12px 0 0 rgba(0, 0, 0, .5), 0 6px 0 rgba(0, 0, 0, .5), 6px 6px 0 rgba(0, 0, 0, .5), 12px 6px 0 rgba(0, 0, 0, .5), 0 12px 0 rgba(0, 0, 0, .5), 6px 12px 0 rgba(0, 0, 0, .5), 12px 12px 0 rgba(0, 0, 0, .5);
  content: '';
  display: block;
  height: 4px;
  width: 4px;
}

.recentwork_carousel__controls {
  margin-bottom: 20px;
}

.recentwork_carousel__controls > a {
  opacity: 0.5;
}

.recentwork_carousel__controls > a:hover {
  opacity: 1;
}

.recentwork_carousel__details.nopointer {
  pointer-events: none;
}

/* Recent Work Carousel 1 - Style 2 */
.recentwork_carousel--2 li {
  padding: 0 0 0 3px;
  text-align: left;
}

.recentwork_carousel--2 .recentwork_carousel__link {
  position: relative;
}

.recentwork_carousel--2 .recentwork_carousel__img {
  height: 190px;
  transform: scale(1.05);
  transition: transform .2s ease-out
}

.recentwork_carousel--2 .recentwork_carousel__link:hover .recentwork_carousel__img {
  transform: scale(1);
}

.recentwork_carousel--2 .recentwork_carousel__hov {
  -webkit-backface-visibility: hidden;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
}

.recentwork_carousel--2 .recentwork_carousel__link:hover .recentwork_carousel__hov {
  opacity: 1;
  visibility: visible;
}

.recentwork_carousel--2 .recentwork_carousel__details {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  bottom: -15px;
  left: 0;
  opacity: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  transition: bottom .25s ease, opacity .25s ease, visibility .25s ease;
  visibility: hidden;
}

.recentwork_carousel--2 .recentwork_carousel__link:hover .details {
  bottom: 0;
  opacity: 1;
  visibility: visible
}

.recentwork_carousel--2 .recentwork_carousel__cat {
  background-color: var(--color-primary);
  border-radius: 2px;
  display: inline-block;
  font-size: 11px;
  line-height: 1;
  padding: 6px;
  text-transform: uppercase;
  z-index: 1;
}

.recentwork_carousel--2 .recentwork_carousel__crsl-title {
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 0;
  text-transform: uppercase;
}

.recentwork_carousel--2 .recentwork_carousel__crsl-title,
.recentwork_carousel--2 .recentwork_carousel__cat {
  color: #fff
}

.recentwork_carousel--2 .recentwork_carousel__title {
  color: #595959;
  font-weight: bold;
  margin: 0 0 30px;
  padding: 0 0 15px;
  position: relative;
}

.recentwork_carousel--2 .recentwork_carousel__title:after {
  background-color: var(--color-primary);
  bottom: 0;
  content: '';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 48px;
}

.recentwork_carousel--2 .recentwork_carousel__desc {
  margin-bottom: 30px;
}

.recentwork_carousel--2 .btn {
  font-size: 11px
}

.recentwork_carousel--2 .recentwork_carousel__controls {
  float: right;
  margin-top: 6px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.recentwork_carousel--2:hover .recentwork_carousel__controls {
  opacity: 1;
}

.recentwork_carousel--2 .recentwork_carousel__controls .glyphicon {
  display: none;
}

.recentwork_carousel--2 .hgSlickNav-arr {
  display: inline-block !important;
  height: 24px;
  position: relative;
  width: 24px
}

.recentwork_carousel--2 .recentwork_carousel__next:before,
.recentwork_carousel--2 .recentwork_carousel__prev:after,
.recentwork_carousel--2 .recentwork_carousel__next:after,
.recentwork_carousel--2 .recentwork_carousel__prev:before {
  background-color: #2C2C2C;
  content: '';
  height: 2px;
  left: 7px;
  position: absolute;
  width: 10px;
}

.recentwork_carousel--2 .recentwork_carousel__next:before,
.recentwork_carousel--2 .recentwork_carousel__prev:after {
  transform: rotate(45deg);
}

.recentwork_carousel--2 .recentwork_carousel__next:after,
.recentwork_carousel--2 .recentwork_carousel__prev:before {
  transform: rotate(-45deg);
}

.recentwork_carousel--2 .recentwork_carousel__next:after,
.recentwork_carousel--2 .recentwork_carousel__prev:after {
  top: 14px;
}

.recentwork_carousel--2 .recentwork_carousel__next:before,
.recentwork_carousel--2 .recentwork_carousel__prev:before {
  top: 8px;
}

/* Recent work 1 - Style 3 */
.rwc--3 .recentwork_carousel__title {
  font-size: 28px;
  letter-spacing: -1px;
}

.container-fluid .recentwork_carousel--2 {
  margin-bottom: 0;
}

.container-fluid .recentwork_carousel--2 .recentwork_carousel__crsl-wrapper {
  padding-right: 15px;
}


/* Recent work carousel 2 - style1 */
.recentwork_carousel_v2 {
  margin-bottom: 40px;
  position: relative;
}

.recentwork_carousel_v2 .recentwork_carousel__controls {
  position: absolute;
  right: 0;
  top: 5px;
}

.recentwork_carousel_v2 .recentwork_carousel__crsl-wrapper {
  margin-left: -10px;
  margin-right: -10px;
}

.recentwork_carousel_v2 li {
  padding: 0 10px;
}

.recentwork_carousel_v2 .recentwork_carousel__link {
  overflow: hidden;
  position: relative;
}

.recentwork_carousel_v2 .recentwork_carousel__details {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 1%, rgba(0, 0, 0, 0.65) 100%);
  bottom: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#40000000', endColorstr='#a6000000', GradientType=1);
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.recentwork_carousel_v2 .recentwork_carousel__link:hover .recentwork_carousel__details {
  opacity: 1;
}

.recentwork_carousel_v2 .recentwork_carousel__plus {
  background: var(--color-primary);
  border-radius: 2px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: bold;
  height: 24px;
  left: 50%;
  line-height: 24px;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  text-align: center;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
  top: 50%;
  width: 24px;
}

.recentwork_carousel_v2 .recentwork_carousel__crsl-title {
  color: #fff;
  font-size: 1rem;
  left: 0;
  margin: 0;
  padding: 0; /*text-shadow: -1px -1px 0 rgba(0,0,0,0.3);*/
  position: absolute;
  right: 0;
  top: 65%;
}

.recentwork_carousel_v2 .recentwork_carousel__cat {
  color: #fff;
  font-size: 0.8rem;
  left: 0;
  padding: 0; /*text-shadow: -1px -1px 0 rgba(0,0,0,0.3);*/
  position: absolute;
  right: 0;
  top: 80%;
}

.recent_works3 .carousel-item--height {
  height: 165px;
}

/* Recent work carousel 3 */
.recentwork_carousel_v3 {
  margin-bottom: 40px;
  position: relative;
}

.recentwork_carousel_v3 .recentwork_carousel__crsl-wrapper {
  margin-left: -5px;
  margin-right: -5px;
}

.recentwork_carousel_v3 li {
  padding: 0 5px;
}

.recentwork_carousel_v3 .recentwork_carousel__link {
  overflow: hidden;
  position: relative;
}

[dir="ltr"] .recentwork_carousel_v3 .recentwork_carousel__details {
  text-align: left;
}

[dir="rtl"] .recentwork_carousel_v3 .recentwork_carousel__details {
  text-align: right;
}

.recentwork_carousel_v3 .recentwork_carousel__details {
  -webkit-backface-visibility: hidden;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  bottom: -15px;
  left: 0;
  opacity: 0;
  padding: 20px;
  padding-top: 80px;
  position: absolute;
  right: 0;
  transition: bottom .25s ease, opacity .25s ease, visibility .25s ease;
}

.recentwork_carousel_v3 .recentwork_carousel__cat {
  background-color: var(--color-primary);
  border-radius: 2px;
  display: inline-block;
  font-size: 11px;
  line-height: 1.6;
  padding: 6px;
  text-transform: uppercase;
  z-index: 1;
}

.recentwork_carousel_v3 .recentwork_carousel__crsl-title {
  color: #595959;
  font-size: 1.2rem;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.2;
  margin: 15px 0 0;
  padding: 0;
  padding-bottom: 0;
  position: relative;
  text-transform: uppercase;
}

.recentwork_carousel_v3 .recentwork_carousel__crsl-title,
.recentwork_carousel_v3 .recentwork_carousel__cat {
  color: #fff;
}

.recentwork_carousel_v3 .recentwork_carousel__top-container {
  padding-bottom: 35px;
  padding-top: 25px;
}

.recentwork_carousel_v3 .recentwork_carousel__title {
  color: #343434;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
}

.recentwork_carousel_v3 .btn {
  color: #585858;
  line-height: 1;
  padding: 0;
  text-decoration: none;
}

.recentwork_carousel_v3 .btn:hover {
  color: #343434;
}

.recentwork_carousel_v3 .btn::before {
  background-color: var(--color-primary);
  content: '';
  display: inline-block;
  height: 3px;
  margin-right: 5px;
  margin-top: -1px;
  vertical-align: middle;
  width: 10px
}

.recentwork_carousel_v3 .recentwork_carousel__link:hover .recentwork_carousel__details {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.recentwork_carousel_v3 .recentwork_carousel__img {
  transform: scale(1.05);
  transition: transform .2s ease-out
}

.recentwork_carousel_v3 .recentwork_carousel__link:hover .recentwork_carousel__img {
  transform: scale(1);
}

.recentwork_carousel_v3 .recentwork_carousel__controls {
  bottom: 0;
  margin-bottom: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity .3s ease-in-out;
}

.recentwork_carousel_v3:hover .recentwork_carousel__controls {
  opacity: 1;
}

.recentwork_carousel_v3 .recentwork_carousel__controls .glyphicon {
  display: none;
}

.recentwork_carousel_v3 .recentwork_carousel__next,
.recentwork_carousel_v3 .recentwork_carousel__prev {
  display: inline-block !important;
  height: 24px;
  position: relative;
  width: 24px
}

.recentwork_carousel_v3 .recentwork_carousel__next:before,
.recentwork_carousel_v3 .recentwork_carousel__prev:after,
.recentwork_carousel_v3 .recentwork_carousel__next:after,
.recentwork_carousel_v3 .recentwork_carousel__prev:before {
  background-color: #2C2C2C;
  content: '';
  height: 2px;
  left: 7px;
  position: absolute;
  width: 10px;
}

.recentwork_carousel_v3 .recentwork_carousel__next:before,
.recentwork_carousel_v3 .recentwork_carousel__prev:after {
  transform: rotate(45deg);
}

.recentwork_carousel_v3 .recentwork_carousel__next:after,
.recentwork_carousel_v3 .recentwork_carousel__prev:before {
  transform: rotate(-45deg);
}

.recentwork_carousel_v3 .recentwork_carousel__next:after,
.recentwork_carousel_v3 .recentwork_carousel__prev:after {
  top: 14px;
}

.recentwork_carousel_v3 .recentwork_carousel__next:before,
.recentwork_carousel_v3 .recentwork_carousel__prev:before {
  top: 8px;
}

/* Full-Width V3 */
.container-fluid .recentwork_carousel_v3 .recentwork_carousel__crsl-wrapper {
  margin-left: -10%;
  margin-right: -10%;
}

/*cfs-nav*/
.works_carousel .cfs--navigation a {
  color: #eee;
  color: rgba(255, 255, 255, 0.15);
  cursor: pointer;
  display: block;
  height: 43px;
  line-height: 40px;
  margin: -20px 0 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transition: all .15s ease;
  width: 43px;
  z-index: 10;
}

.works_carousel .cfs--prev {
  left: 10px;
  text-align: left;
}

.works_carousel .cfs--next {
  right: 10px;
  text-align: right;
}

.works_carousel .bottom-nav .cfs--prev {
  bottom: 0;
  left: auto;
  right: 30px;
  text-align: center;
  top: auto;
}

.works_carousel .bottom-nav .cfs--next {
  bottom: 0;
  margin: 0;
  right: 0;
  text-align: center;
  top: auto;
}

/*cfs--pagination*/
.works_carousel .cfs--pagination {
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.works_carousel .cfs--pagination > a {
  background: #d9d9d9;
  border-radius: 50%;
  box-shadow: none;
  display: inline-block;
  height: 9px;
  margin: 2px 3px;
  text-decoration: none;
  width: 9px;
}

.works_carousel .cfs--pagination > a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.5);
}

.works_carousel .cfs--pagination > a.selected {
  background: #f4f4f4;
  box-shadow: 0 0 0 3px #fe6060;
  height: 8px;
  margin: 3px 4px;
  width: 8px;
}

.works_carousel .cfs--pagination {
  bottom: -50px;
}

.works_carousel .cfs--pagination a {
  background: #FFF;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
  height: 6px;
  margin: 0 2px;
  width: 6px;
}

.works_carousel .cfs--pagination a.selected {
  background: #AFAFAF;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: none;
  cursor: default;
  height: 6px;
  margin: 0 2px;
  width: 6px;
}

.works_carousel .cfs--navigation a {
  box-sizing: content-box;
  cursor: pointer;
  margin: -40px 0 0;
  opacity: 0;
}

.works_carousel .cfs--navigation a::before {
  color: rgba(255, 255, 255, 0.2);
  font-family: 'Glyphicons Halflings';
  font-size: 50px;
}

.works_carousel .cfs--prev {
  left: -70px;
  padding-right: 40px;
}

.works_carousel .cfs--next {
  padding-left: 40px;
  right: -70px;
  text-align: center;
  width: 40px;
}

.works_carousel .caroufredsel:hover .cfs--prev {
  left: -80px;
  opacity: 1;
}

.works_carousel .caroufredsel:hover .cfs--next {
  opacity: 1;
  right: -80px;
}

.works_carousel .cfs--navigation a:hover::before {
  color: var(--color-primary);
}

.works_carousel .cfs--prev::before {
  content: "\e257";
}

.works_carousel .cfs--next::before {
  content: "\e258";
}

.works_carousel .fancy-title {
  color: #fff;
  margin-bottom: 0;
}

.works_carousel .details [data-role="title"] {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

/* Hoverlay style */
.works_carousel.stl-hoverlay .caroufredsel_wrapper {
  margin: 0;
}

.works_carousel.stl-hoverlay .inner-slide {
  color: #FFF;
  margin: 0;
  position: relative;
}

.works_carousel.stl-hoverlay .inner-slide .imgprev {
  margin: 0;
}

.works_carousel.stl-hoverlay .inner-slide .imgprev img {
  display: block;
  width: 100%;
}

.works_carousel.stl-hoverlay .inner-slide .imgprev::after {
  display: none;
}

.works_carousel.stl-hoverlay .inner-slide .details {
  background: #fe6060;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
}

.works_carousel.stl-hoverlay .inner-slide:hover .details {
  opacity: 1;
  visibility: visible;
}

.works_carousel.stl-hoverlay .dt-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.works_carousel.stl-hoverlay .inner-slide [data-role="title"] {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

/* simple style */
.works_carousel.stl-simple .caroufredsel_wrapper {
  margin: 0;
}

.works_carousel.stl-simple .inner-slide {
  margin: 0;
  position: relative;
}

.works_carousel.stl-simple .inner-slide .imgprev {
  background: #242731;
  margin: 0;
}

/*.works_carousel.stl-simple .inner-slide .imgprev img {width: 100%; display: block;}*/
.works_carousel.stl-simple .inner-slide:hover .imgprev img {
  opacity: .6;
}

.works_carousel.stl-simple .inner-slide .imgprev::after {
  display: none;
}

.works_carousel.stl-simple .inner-slide .details {
  border-left: 1px solid #eeeeee;
  display: block;
  min-height: 105px;
  padding: 30px 0 0;
}

.works_carousel.stl-simple .inner-slide [data-role="title"] {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.works_carousel.stl-simple .inner-slide [data-role="title"] a {
  color: #242731;
  text-decoration: none
}

.works_carousel.stl-simple .inner-slide:hover [data-role="title"] a,
.works_carousel.stl-simple .inner-slide [data-role="title"] a:hover {
  color: var(--color-primary);
}

.works_carousel.stl-simple .inner-slide [data-role="category"] {
  color: #CECED0;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 0;
}

.stg-textcenter.caroufredsel .inner-slide .details {
  text-align: center;
}

/* Navigation style for full-width style */
.works_carousel.stl-fullwidth .cfs--navigation a {
  background: url(../images/transparent_png/white40.png);
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  height: 100%;
  margin: 0;
  opacity: .2;
  padding: 0;
  position: absolute;
  top: 0;
  width: 35px;
}

.works_carousel.stl-fullwidth .cfs--navigation a:hover {
  background: url(../images/transparent_png/white70.png);
  background: rgba(255, 255, 255, 0.7);
  width: 50px;
}

.works_carousel.stl-fullwidth .cfs--navigation a::before {
  color: #333;
  color: rgba(0, 0, 0, 0.4);
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  position: absolute;
  top: 50%;
  width: 50px;
}

.works_carousel.stl-fullwidth .cfs--navigation a:hover::before {
  color: #fe6060;
}

.works_carousel.stl-fullwidth .cfs--prev,
.works_carousel.stl-fullwidth .cfs--prev:hover {
  left: 0;
  text-align: center;
}

.works_carousel.stl-fullwidth .cfs--next,
.works_carousel.stl-fullwidth .cfs--next:hover {
  right: 0;
  text-align: center;
}

/* end Image boxes carousel element */


/*contact form */
form .row {
  margin-left: -5px;
  margin-right: -5px;
}

/*   form .row [class*="col-"] { padding-left: 5px; padding-right: 5px; }   */
.inline-form {
  font-size: 0;
}

.contact_form textarea {
  height: 100px;
}

.inline-form .form-group, .contact_form .form-group {
  padding: 0 8px;
}

.contact_form .form-control {
  border: 1px solid #D9D9D9;
  box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.06);
  font-size: 13px;
  height: auto;
  outline: none;
  overflow-x: hidden;
  padding: 14px;
}

.form-control:focus {
  border: none;
  box-shadow: inherit;
}

.kl-fancy-form {
  position: relative;
}

.inline-form .kl-fancy-form label, .contact_form .kl-fancy-form label {
  left: 25px;
}

.kl-fw-form .form-control, .kl-fw-form.form-control {
  width: 100%;
}

.kl-hw-field {
  display: inline-block;
  width: 50%;
}

.kl-fancy-form label {
  color: #969696;
  cursor: text;
  font-size: 10px;
  font-weight: 700;
  left: 16px;
  position: absolute;
  text-transform: uppercase;
  top: 6px;
  transition: all .25s ease;
}

.kl-fancy-form input:focus + label,
.kl-fancy-form textarea:focus + label {
  color: #c0c0c0;
}

/* Selectbox fix for Chrome/Safari on OSX */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .kl-fancy-form select.form-control {
    -webkit-appearance: none;
    background-image: url(../images/select-arrow.svg);
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 6px 12px;
    box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.06);
    height: 52px;
  }
}

.kl-fancy-form input::-webkit-input-placeholder {
  color: #bababa;
}

.kl-fancy-form input:focus::-webkit-input-placeholder {
  color: #D7D7D7;
}

/* Firefox < 19 */
.kl-fancy-form input:-moz-placeholder {
  color: #bababa;
}

.kl-fancy-form input:focus:-moz-placeholder {
  color: #D7D7D7;
}

/* Firefox > 19 */
.kl-fancy-form input::-moz-placeholder {
  color: #bababa;
}

.kl-fancy-form input:focus::-moz-placeholder {
  color: #D7D7D7;
}

/* Internet Explorer 10 */
.kl-fancy-form input:-ms-input-placeholder {
  color: #bababa;
}

.kl-fancy-form input:focus:-ms-input-placeholder {
  color: #D7D7D7;
}

textarea.form-control {
  max-width: 100%;
  width: 100%;
}

/* TWITTER FEED */
#twitterFeed {
  background: url(../images/twitter-bird.png) no-repeat 15px center #444;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 15px;
  padding: 15px 75px 15px 60px;
  position: relative;
}

#twitterFeed:after {
  border: 5px solid transparent;
  border-top-color: #444;
  bottom: -10px;
  content: '';
  left: 15px;
  position: absolute;
}

#twitterFeed ul {
  font-size: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

#twitterFeed .twTime {
  border-left: 1px solid #707070;
  bottom: 0;
  color: #9d9d9d;
  display: inline-block;
  font-size: 11px;
  line-height: 1;
  padding-left: 10px;
  position: absolute;
  right: -65px;
  top: 0;
  vertical-align: middle;
  width: 45px;
}

#twitterFeed > div:not(:only-child) {
  margin-bottom: 10px;
}

.twitterFeed {
  margin-bottom: 30px;
}

.twitter-feed > iframe {
  max-height: 20px;
}

#twitterFeed .timePosted {
  border-left: 1px solid #707070;
  bottom: 15px;
  color: #9d9d9d;
  display: inline-block;
  display: flex !important;
  font-size: 0.9rem;
  line-height: 1;
  padding-left: 10px;
  position: absolute;
  right: 15px;
  top: 15px;
  vertical-align: middle;
  width: 60px;
}

#twitterFeed .timePosted a {
  align-self: center !important;
}

#twitterFeed p {
  margin-bottom: 0;
}

#twitterFeed p.tweet a {
  text-decoration: underline;
}


/* SOCIAL SHARING */
.social-share {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}

.social-share li {
  display: inline-block;
  margin-left: 10px;
  max-height: 20px;
  vertical-align: top;
  width: 90px;
}

.social-share li.sc-gplus {
  width: 70px;
}

.social-share li.sc-pinterest > a {
  margin-left: -40px !important;
  vertical-align: top !important;
  width: 40px !important;
}

.social-share.sc--colored {
  margin-top: 15px;
}

.social-share.sc--colored li {
  border-radius: 3px;
  padding: 10px;
}

.social-share.sc--colored li.sc-facebook {
  background: #3B5998;
}

.social-share.sc--colored li.sc-twitter {
  background: #3CF;
}

.social-share.sc--colored li.sc-gplus {
  background: #D34836;
  width: 70px;
}

.social-share.sc--colored li.sc-pinterest {
  background: #CB2027;
}

.social-share div {
  margin-right: 10px;
}

.social-share div:last-child {
  margin-right: 0;
}

.social-share2 {
  list-style: none;
  margin: 0px 0 30px;
  padding: 0;
  text-align: left;
}

#fb-root {
  margin: 0;
}

/* TESTIMONIALS BOX */
.testimonial_box {
  margin: 15px 0;
  padding: 0 0 0 25px;
  padding-left: 0;
  position: relative;
  width: 100%;
}

.testimonial_box[data-size=small] {
  width: 30%;
}

.testimonial_box[data-size=medium] {
  width: 50%;
}

.testimonial_box[data-size=large] {
  width: 70%;
}

.testimonial_box[data-size=full] {
  width: 100%;
}

.testimonial_box[data-size=onethird] {
  width: 33%;
}

.testimonial_box[data-size=twothirds] {
  width: 66%;
}

.testimonial_box[data-theme=light] blockquote {
  background: #ddd;
  color: #343434;
}

.testimonial_box[data-theme=dark] blockquote {
  background: #707070;
  color: #f5f5f5;
}

.testimonial_box[data-theme=light][data-align=left] blockquote:after {
  border-right-color: #fff;
}

.testimonial_box[data-theme=dark][data-align=left] blockquote:after {
  border-right-color: #707070;
}

.testimonial_box[data-theme=light][data-align=right] blockquote:after {
  border-left-color: #ddd;
}

.testimonial_box[data-theme=dark][data-align=right] blockquote:after {
  border-left-color: #707070;
}

.testimonial_box[data-theme=light][data-align=top] blockquote:after {
  border-bottom-color: #ddd;
}

.testimonial_box[data-theme=dark][data-align=top] blockquote:after {
  border-bottom-color: #707070;
}

.testimonial_box[data-theme=light][data-align=bottom] blockquote:after {
  border-top-color: #ddd;
}

.testimonial_box[data-theme=dark][data-align=bottom] blockquote:after {
  border-top-color: #707070;
}

.testimonial_box[data-align=left] .details {
  float: left;
  margin-right: 10px;
  width: 90px;
}

.testimonial_box[data-align=left] blockquote {
  margin-left: 90px;
}

.testimonial_box[data-align=left] blockquote:after {
  left: -14px;
  top: 50%;
}

.testimonial_box[data-align=right] .details {
  float: right;
  margin-left: 10px;
  text-align: right;
  width: 90px;
}

.testimonial_box[data-align=right] blockquote {
  margin-right: 90px;
}

.testimonial_box[data-align=right] blockquote:after {
  right: -14px;
  top: 50%;
}

.testimonial_box[data-align=top] .details {
  float: none;
  margin-left: 10px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.testimonial_box[data-align=top] .details img {
  position: absolute;
  right: -5px;
  top: -5px
}

.testimonial_box[data-align=top] .details h6 strong {
  display: block;
}

.testimonial_box[data-align=top] .details h6 {
  margin-top: 0;
}

.testimonial_box[data-align=top] blockquote {
  clear: both;
  margin-top: 14px;
}

.testimonial_box[data-align=top] blockquote:after {
  left: 30px;
  top: -14px;
}

.testimonial_box[data-align=bottom] {
  margin-bottom: 35px;
}

.testimonial_box[data-align=bottom] .details {
  left: 25px;
  position: absolute;
  right: 5px;
  top: 100%;
  z-index: 1;
}

.testimonial_box[data-align=bottom] .details img {
  float: right;
  margin-top: -20px;
}

.testimonial_box[data-align=bottom] .details h6 strong {
  display: block;
}

.testimonial_box[data-align=bottom] blockquote {
  float: none;
  margin-bottom: 0;
  position: relative;
  z-index: 0;
}

.testimonial_box[data-align=bottom] blockquote:after {
  bottom: -14px;
  left: 30px;
}

.testimonial_box .details h6 {
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1.4;
  margin: 10px 0 0;
}

.testimonial_box .details h6 strong {
  display: block;
}

.testimonial_box blockquote {
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-style: italic;
  margin: 0;
  padding: 20px;
  position: relative;
  transition: all .2s;
}

.testimonial_box blockquote:after {
  border-color: transparent;
  border-style: solid;
  border-width: 7px;
  content: '';
  position: absolute;
}

.testimonial_box:hover blockquote {
  box-shadow: 0 2px 13px rgba(0, 0, 0, .4);
}

.testimonial_box .details img {
  border-radius: 50%;
}


/* Stats boxes */
.stats_box .mb_title {
  background: none;
}

.statbox {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  padding: 15px;
}

.statbox img {
  margin-right: 15px;
}

.statbox__fonticon {
  color: #8f8f8f;
  font-size: 46px;
  height: 48px;
  line-height: 51px;
  margin-right: 15px;
  vertical-align: top;
}

.statbox h4 {
  color: var(--color-primary);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 0;
  text-transform: uppercase;
}

.statbox h6 {
  color: #969696;
  font-size: 0.8rem;
  line-height: 1.4;
  margin-bottom: 0;
  margin-top: 0;
}


/* Services boxes widget */
.services_boxes_wgt {
  position: relative;
  z-index: 1;
}

.services_boxes_wgt ul[class*="sbox-"] {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.services_boxes_wgt ul[class*="sbox-"] > li {
  float: left;
}

.services_boxes_wgt .intro-col + ul[class*="sbox-"] {
  width: 75%;
}

.services_boxes_wgt .sbox-1 > li {
  width: 100%;
}

.services_boxes_wgt .sbox-2 > li {
  width: 50%;
}

.services_boxes_wgt .sbox-3 > li {
  width: 33.33%;
}

.services_boxes_wgt .sbox-4 > li {
  width: 25%;
}

.services_boxes_wgt .sbox-5 > li {
  width: 20%;
}

.services_boxes_wgt .intro-col {
  color: #888888;
  float: left;
  width: 25%;
}

.services_boxes_wgt .inner-box {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin: 0 0 0 10px;
  padding: 35px 30px;
  position: relative;
}

.services_boxes_wgt li [data-role="title"] {
  color: #4d4d4d;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

.services_boxes_wgt li [data-role="title"] .kl-icon {
  color: #c5c5c5;
  display: block;
  float: left;
  font-size: 40px;
  line-height: 1;
  margin-right: 20px;
  transition: color 0.3s ease-out;
  vertical-align: middle;
}

.services_boxes_wgt .hover-details {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  left: 0;
  margin-top: 0;
  opacity: 0;
  padding: 35px 30px;
  position: absolute;
  top: 100%;
  transition: all 0.2s ease-out;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}

.colored-area .services_boxes_wgt .hover-details,
.colored-area .services_boxes_wgt .inner-box {
  background: #fff;
}

.services_boxes_wgt .hover-details::after {
  background: #e8e8e8;
  content: '';
  height: 1px;
  left: 0;
  margin-left: 35px;
  margin-right: 35px;
  position: absolute;
  top: 15px;
  width: calc(100% - 70px);
}

.services_boxes_wgt li:hover .hover-details {
  margin-top: -30px;
  opacity: 1;
  visibility: visible;
}

.services_boxes_wgt li:hover [data-role="title"] .kl-icon {
  color: #fe6060;
}

.read-more-link {
  border-bottom: 1px solid #d1d1d1;
  color: #a3a4a4;
  font-size: 12px;
  line-height: 1.6;
  padding-bottom: 3px;
  text-decoration: none;
}

.read-more-link:hover {
  border-bottom-color: #fe6060;
  color: #888;
  text-decoration: none;
}


/* Logos / clients list */
.logos-bar {
  bottom: 100%;
  width: 100%;
}

.logos-bar--relative {
  position: relative;
  width: 100%;
}

.logolist {
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.logolist li {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  zoom: 1;
}

.logolist li img {
  opacity: .8;
}

.logolist li:hover img {
  opacity: 1;
}

.no-flexbox .logolist {
  text-align: justify;
}

.no-flexbox .logolist li {
  min-height: 75px;
}

.no-flexbox .logolist:after {
  content: '';
  display: inline-block;
  font-size: 0;
  line-height: 0;
  min-height: 0;
  width: 100%;
}


/* Fluid Container with normal width support */
/* Does not work with nesting columns, so basically can be used only for first level of grid columns */
.col-normal {
  margin-left: -15px;
  margin-right: -15px;
  min-height: 1px;
  position: relative;
  transition: padding .2s ease-out;
}

html:not(.js) .col-normal {
  margin: 0;
}

/* Animated Charts */
.chart {
  display: inline-block;
  height: 110px;
  margin-bottom: 50px;
  margin-top: 50px;
  position: relative;
  text-align: center;
  width: 110px;
}

.chart canvas {
  left: 0;
  position: absolute;
  top: 0;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
}

.percent::after {
  content: '%';
  font-size: .8em;
  margin-left: 0.1em;
}

/* funding percentage */
.fundedperc {
  background-image: url(../images/bgfunded.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fundedperc .chart {
  height: auto;
  margin: 64px auto;
  min-height: 300px;
  width: auto;
}

.fundedperc .chart canvas {
  position: static;
}

.fundedperc .percent {
  color: rgba(255, 255, 255, 0.7);
  display: block;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 11px;
  line-height: 1;
  margin-top: 81px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fundedperc .percent::after {
  content: '';
  display: none;
}

.fundedperc .percent span {
  color: #fff;
  display: block;
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
}

.fundedperc .percent span::after {
  content: '%';
  font-weight: 300;
  margin-left: 0.1em;
}

/* World Map Offices */
.world-map-offices {
  position: relative;
}

.world-map-offices > img {
  opacity: .2;
}

.world-map-offices .world-office {
  min-height: 7px;
  min-width: 7px;
  padding-bottom: 20px;
  position: absolute;
  z-index: 1;
}

.world-map-offices .world-office:hover {
  z-index: 2;
}

.world-map-offices .world-office::before {
  background: #f0d228;
  border-radius: 50%;
  bottom: 0;
  content: '';
  cursor: pointer;
  display: block;
  height: 7px;
  left: 0;
  margin-top: 0;
  position: absolute;
  width: 7px;
  z-index: 1;
}

.world-map-offices .world-office:hover::before,
.world-map-offices .world-office.selected::before {
  box-shadow: 0 0 0 4px #fe6060;
  transform: scale(1.5);
}

.world-map-offices .office-details {
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  left: -3999px;
  min-width: 310px;
  opacity: 0;
  padding-bottom: 30px;
  position: relative;
  position: absolute;
  top: -140px;
  transition: opacity .3s ease-in, visibility .3s ease-in;
  visibility: hidden;
}

.world-map-offices .world-office:hover .office-details,
.world-map-offices .world-office.selected .office-details {
  left: -118px;
  opacity: 1;
  visibility: visible;
}

.world-map-offices .office-details::before {
  border-right: 12px solid rgba(0, 0, 0, 0);
  border-top: 12px solid #FFF;
  bottom: 18px;
  content: '';
  left: 120px;
  position: absolute;
}

.world-map-offices .office-content {
  background: #fff;
  border-radius: 0 2px 2px 0;
  max-height: 130px;
  min-height: 130px;
  overflow: hidden;
  overflow-y: visible;
  padding: 7px 25px 0;
  position: relative;
}

.world-map-offices .office-content h5 {
  color: #1a1c25;
  font-size: 1rem;
  font-weight: bold;
  margin: 5px 0 7px;
  text-transform: uppercase;
}

.world-map-offices .office-content p {
  font-size: 0.9rem;
}

.world-map-offices .office-content p:last-child {
  margin-bottom: 0;
}

.world-map-offices .office-details > a {
  float: left;
}

.world-map-offices .office-details > a img {
  border-radius: 2px 0 0 2px;
}

/* World Map Trigger Links */
.world-map-triggers {
  margin-bottom: 150px;
  max-width: 400px;
  overflow: hidden;
}

ul.world-map-triggers[data-columns] li {
  display: inline-block;
  float: left;
  margin-left: 20px;
}

ul.world-map-triggers[data-columns="3"] li {
  width: calc(33.3333% - 20px);
}

.world-map-triggers .office-name {
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 5px;
  padding: 5px 15px;
  text-decoration: none;
}

.world-map-triggers .office-name.selected,
.world-map-triggers .office-name:hover {
  border-color: #f0d228;
}


/* Fake Loading */
.fake-loading {
  animation: fake-loading .4s ease-in 2s 1 normal forwards;
  background: #fff;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.fake-loading.fl--nobg {
  background: none;
}

.fake-loading.loading-1s {
  animation-delay: 1s;
}

.fake-loading.loading-2s {
  animation-delay: 2s;
}

.fake-loading.loading-4s {
  animation-delay: 4s;
}

.fake-loading.loading-8s {
  animation-delay: 8s;
}

.fake-loading.loading-10s {
  animation-delay: 10s;
}

.fake-loading:after {
  animation: fake-loading-circle .5s infinite linear;
  border: 2px solid rgba(205, 33, 34, 0.16);
  border-radius: 50%;
  border-right-color: var(--color-primary);
  border-top-color: var(--color-primary);
  content: '';
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
  width: 30px;
}

@keyframes fake-loading {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fake-loading-circle {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}


/* Bootstrap code examples
-------------------------------------------------- */

/* Base class */
.bs-docs-example {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 15px 0;
  padding: 39px 19px 14px;
  *padding-top: 19px;
  position: relative;
}

/* Echo out a label for the example */
.bs-docs-example:after {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px 0 4px 0;
  color: #9da0a4;
  content: "Example";
  font-size: 12px;
  font-weight: bold;
  left: -1px;
  padding: 3px 7px;
  position: absolute;
  top: -1px;
}

/* Remove spacing between an example and it's code */
.bs-docs-example + .prettyprint {
  margin-top: -20px;
  padding: 0;
  padding-top: 15px;
}

/* Tweak examples  */
.bs-docs-example > p:last-child {
  margin-bottom: 0;
}

/* Special grid styles  */
.show-grid {
  margin-bottom: 20px;
  margin-top: 10px;
}

.show-grid [class*="span"] {
  background-color: #eee;
  border-radius: 3px;
  line-height: 40px;
  min-height: 40px;
  text-align: center;
}

.show-grid:hover [class*="span"] {
  background: #ddd;
}

.show-grid .show-grid {
  margin-bottom: 0;
  margin-top: 0;
}

.show-grid .show-grid [class*="span"] {
  background-color: #ccc;
}

/* Icon list (for demo purposes) */
.kl-icon-list {
  list-style: none;
  margin: 0 0 30px;
  padding: 0px;
}

.kl-icon-list li {
  border: 1px solid transparent;
  border-radius: 3px;
  color: #999;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 32px;
  margin: 0 6px 10px;
  padding: 30px 0;
  position: relative;
  text-align: center;
  transition: all .15s ease-out;
  width: 13%;
}

.kl-icon-list li:hover {
  border-color: #ddd;
  color: #535353;
}

.kl-icon-list li:after {
  background-color: rgba(0, 0, 0, .8);
  border-radius: 3px;
  color: #fff;
  content: 'Click to copy "' attr(class) '"';
  display: none;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: bold;
  left: 50%;
  line-height: 1;
  min-width: 270px;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 80%;
  transform: translateX(-50%);
  transition: background-color .2s ease-out, min-width .2s ease-out;
  z-index: 10;
}

.kl-icon-list li:hover:after {
  display: block;
}

.kl-icon-list li.is-copied:after {
  background-color: #4CAF50;
  content: "Copied!";
  min-width: 0;
}


/* Caroufredsel Default */
/* Caroufredsel default styles */
.caroufredsel {
  margin: 0;
  position: relative;
  zoom: 1;
}

.caroufredsel ul.slides {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cfs--default {
  margin: 0;
  padding-left: 0;
  position: relative;
  zoom: 1;
}

.cfs--default > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cfs--default .cfs--item,
.cfs--default > ul > li {
  display: block;
  float: left;
  position: relative;
}

/* CFS SVG navigation */
.cfs-svg--next,
.cfs-svg--prev {
  cursor: pointer;
  display: block !important;
  height: 60px;
  width: 40px;
}

.cfs-svg--next svg,
.cfs-svg--prev svg {
  display: inline-block;
}

.cfs-svg--next polyline,
.cfs-svg--prev polyline {
  transition: stroke-width 250ms ease-in-out;
}

.cfs-svg--prev:hover polyline,
.cfs-svg--prev:focus polyline {
  stroke-width: 3px;
}

.cfs-svg--prev:active polyline {
  stroke-width: 6px;
  transition: stroke-width 100ms ease-in-out;
}

.cfs-svg--next:hover polyline,
.cfs-svg--next:focus polyline {
  stroke-width: 3px;
}

.cfs-svg--next:active polyline {
  stroke-width: 10px;
  transition: stroke-width 100ms ease-in-out;
}

/*cfs-nav*/
.cfs--navigation a {
  color: #eee;
  color: rgba(255, 255, 255, 0.15);
  cursor: pointer;
  display: block;
  height: 40px;
  line-height: 40px;
  margin: -20px 0 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transition: all .15s ease;
  width: 40px;
  z-index: 10;
}

.cfs--prev {
  left: 10px;
  text-align: left;
}

.cfs--next {
  right: 10px;
  text-align: right;
}

.caroufredsel:hover .cfs--prev {
  opacity: 0.7;
}

.caroufredsel:hover .cfs--next,
.caroufredsel:hover ~ .cfs--navigation .cfs--next {
  opacity: 0.7;
}

.caroufredsel:hover .cfs--next:hover,
.caroufredsel:hover .cfs--prev:hover,
.caroufredsel:hover ~ .cfs--navigation .cfs--next:hover,
.caroufredsel:hover ~ .cfs--navigation .cfs--prev:hover {
  opacity: 1;
}

/* caroufredsel hoverstyle */
.stl-hoverstyle .cfs--navigation a {
  color: #fff;
  height: 70px;
  line-height: 70px;
  margin: -35px 0 0;
  opacity: 0.7;
  text-align: center;
  width: 76px;
}

.stl-hoverstyle:hover .cfs--navigation a {
  color: #fff;
  height: 70px;
  line-height: 70px;
  opacity: 1;
  text-align: center;
  width: 76px;
}

.stl-hoverstyle .cfs--navigation a:before {
  font-size: 32px;
}

.stl-hoverstyle .cfs--prev {
  border-radius: 0 3px 3px 0;
  left: 0;
}

.stl-hoverstyle .cfs--next {
  border-radius: 3px 0 0 3px;
  right: 0;
}

.stl-hoverstyle .cfs--navigation a:hover {
  background-color: var(--color-primary);
}

.caroufredsel_wrapper .slides li {
  display: block;
  float: left;
  position: relative;
}

.caroufredsel_wrapper .playVideo,
.js-slick .playVideo {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.static-content__wrapper {
  overflow: hidden;
  width: 100%;
}

.static-content__source {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* Safe padding */
.kl-slideshow .kl-slideshow-safepadding {
  padding-bottom: 50px;
  padding-top: 195px;
}

.static-content--fullscreen {
  height: 100vh;
}

.static-content--fullscreen .th-google_map {
  height: 100vh !important;
}

/* Static Content - default */
.static-content {
  color: #fff;
  padding-top: 20px;
}

.static-content__title {
  font-size: 4rem;
  line-height: 1.3;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.static-content__subtitle {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.static-content__subtitle .stronger {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
  cursor: help;
  font-weight: 600;
  padding-bottom: 2px;
}

.static-content.video-style .playVideo {
  margin-bottom: 20px;
}

/* Infopop balloon */
.static-content__infopop {
  background: rgba(254, 254, 254, 0.8);
  background: linear-gradient(to bottom, rgba(254, 254, 254, 0.8) 0%, rgba(209, 209, 209, 0.8) 100%);
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 10px 11px -10px rgba(0, 0, 0, 0.8), inset 0 -1px 0 rgba(255, 255, 255, .8), inset 0 0 6px rgba(255, 255, 255, .8);
  color: #343434;
  margin: 50px auto 0;
  padding: 20px;
  position: relative;
  width: 350px;
}

.static-content__infopop:after {
  border: 7px solid transparent;
  content: "";
  position: absolute;
}

.static-content__infopop[data-arrow=top]:after {
  border-bottom-color: rgba(254, 254, 254, 0.8);
  border-bottom-width: 7px;
  left: 50%;
  margin-left: -7px;
  top: -13px;
}

.static-content__infopop[data-arrow=bottom]:after {
  border-top-color: rgba(254, 254, 254, 0.8);
  border-top-width: 7px;
  bottom: -14px;
  left: 50%;
  margin-left: -7px;
}

.static-content__infopop[data-arrow=left]:after {
  border-right-color: rgba(254, 254, 254, 0.8);
  border-right-width: 7px;
  left: -14px;
  margin-top: -7px;
  top: 50%;
}

.static-content__infopop[data-arrow=right]:after {
  border-left-color: rgba(254, 254, 254, 0.8);
  border-left-width: 7px;
  margin-top: -7px;
  right: -14px;
  top: 50%;
}

.sc-infopop__text {
  color: rgba(37, 37, 37, 0.7);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  margin: 5px 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
}

.static-content__infopop .sc-infopop__btn {
  background: rgba(0, 0, 0, 0.1);
  border: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  color: var(--color-primary);
  float: right;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin: 13px -20px 0 0;
  padding: 10px 15px 10px;
  text-align: center;
  transition: all .2s ease-in-out;
}

.static-content__infopop:hover .sc-infopop__btn {
  padding: 10px 30px 10px 15px;
}

.static-content__infopop .sc-infopop__btn:hover {
  background: rgba(0, 0, 0, 0.2);
  color: #222;
}

.static-content__infopop.sc-infopop--left {
  margin-left: 0;
}

.static-content__infopop.sc-infopop--left[data-arrow=top]:after {
  left: 0;
  margin-left: 25px;
}

.static-content__infopop.sc-infopop--right {
  margin-right: 0;
}

.static-content__infopop.sc-infopop--right[data-arrow=top]:after {
  margin-right: 25px;
  right: 0;
}

@keyframes fadeBoxIn {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}

.fadeBoxIn {
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-name: fadeBoxIn;
}

/* Static Content - boxes */
.sc__boxes {
  margin-top: 70px;
}

.sc__boxes .static-content__infopop {
  -webkit-backface-visibility: hidden;
  border-radius: 4px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3), inset 0 -1px 0 rgba(255, 255, 255, .8), inset 0 0 6px rgba(255, 255, 255, .8);
  margin: 0;
  transition: opacity .2s ease-out, transform .2s ease-out;
  width: auto;
}

.sc__boxes:hover .static-content__infopop {
  opacity: .4;
}

.sc__boxes:hover .static-content__infopop:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(255, 255, 255, .8), inset 0 0 6px rgba(255, 255, 255, .8);
  opacity: 1;
  transform: translateY(-10px);
}

.sc__boxes .sc__box-title {
  margin-bottom: 10px;
}

.sc__boxes .static-content__infopop .sc__img {
  float: left;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-top: 10px;
  max-width: 36px;
}

.sc__boxes .static-content__infopop .sc__img--flex {
  width: 36px;
}

.sc__boxes .static-content__infopop .sc__img ~ p {
  margin-left: 52px
}

/* Static content - Video Background */
.sc__video-line {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  display: inline-block;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 1px;
  padding: 5px 10px;
}

.sc__iframe-video {
  width: 100% !important;
}

.static-content__wrapper .sc--captpos-bottom {
  bottom: 10px;
  top: auto;
  transform: translateY(100%);
}

.static-content__wrapper .sc--captpos-top {
  top: auto;
  transform: none;
}

/* Static Content - textpop */
.textpop-style {
  padding-bottom: 20px;
  padding-top: 40px;
}

.textpop__texts > span {
  color: #eee;
  cursor: default;
  display: block;
  line-height: 1;
  padding: 7px 0;
  position: relative;
  text-align: center;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  transform-origin: center bottom;
  transition: all 0.2s ease-out;
}

.textpop__line1 {
  font-size: 20px;
  letter-spacing: 10px;
  word-spacing: 5px;
}

.textpop__line2 {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 5px;
  word-spacing: 5px;
}

.textpop__line3 {
  font-size: 40px;
}

.textpop__line4 {
  font-size: 30px;
  letter-spacing: 3px;
  margin-top: 5px;
  word-spacing: 5px;
}

.textpop__texts .stronger {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
  font-weight: 900;
  padding-bottom: 2px;
}

.textpop__texts > span:hover {
  color: #fff;
  text-shadow: 0 4px 1px rgba(0, 0, 0, 0.6);
  transform: scale(1.15);
}

/* Static Content - event style */
.static-content.event-style .static-content__subtitle {
  font-size: 28px;
  margin-bottom: 50px;
  text-align: left;
}

.static-content .or {
  line-height: 30px;
  margin: 0 20px 15px 20px;
}

.static-content.event-style .or {
  float: left;
}

.static-content.event-style .social-icons li {
  margin: 0 0 15px 10px;
  vertical-align: top;
}

.black_border {
  border: 10px solid rgba(0, 0, 0, 0.32);
  border-radius: 3px;
  box-shadow: 0 20px 10px -10px rgba(0, 0, 0, 0.5);
}

.white_border {
  border: 10px solid white;
  border-radius: 3px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.scev-icon-nocolor {
  background-color: #121212;
}

.sc__screenshot {
  background-color: #C8D0D2;
  border-radius: 3px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  padding: 24px 0 7px;
  position: relative;
  width: 100%;
}

.sc__screenshot:before {
  background: #EAAE2A;
  border-radius: 30px;
  box-shadow: -12px 0 0 #ed4529, 12px 0 0 #59c626;
  content: "";
  display: block;
  height: 8px;
  left: 25px;
  margin-top: 0;
  position: absolute;
  top: 8px;
  width: 8px;
}

.sc__screenshot .image {
  position: relative;
}

.sc__screenshot .image img {
  width: 100%;
}

.review-details h4 {
  padding-left: 30px;
  position: relative;
}

.review-details svg {
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  width: 25px;
  height: 25px;
}

/* Loupe */
.sc__loupe {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #000;
  display: none;
  margin: 0px;
  padding: 0px;
  position: absolute;
  z-index: 1000;
}

.overlay_white {
  background-color: #fff;
}

.shadow {
  box-shadow: 5px 5px 10px #000;
}

.glossy {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, .6) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, .6)), color-stop(100%, rgba(255, 255, 255, 0)));
  display: block;
  margin: 0px;
}

.sc__loupe {
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 0 5px #777, 0 0 10px #aaa inset;
  cursor: url(../addons/jquery_loupe/blank.png), url(../addons/jquery_loupe/blank.cur), none;
  display: none;
  height: 150px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 150px;
  z-index: 1000;
}

.sc__loupe .glossy {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, .6) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, .6)), color-stop(100%, rgba(255, 255, 255, 0)));
  display: block;
  margin: 0px;
}

.static-content__slideshow .static-content .sc__features {
  list-style: none;
  margin: 30px 0;
  padding-left: 0;
}

.static-content .sc__features li {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.4);
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.static-content .sc__features li > span {
  margin: 7px 10px 0 0;
}

.static-content .btn .button-icon {
  margin-right: 5px;
}

.static-content .btn span {
  vertical-align: middle;
}

.static-content--with-login .static-content__title {
}

.fancy_register_form {
  background: rgba(255, 255, 255, 0.3);
  color: #272727;
  padding: 30px 25px;
  position: relative;
}

.fancy_register_form form {
  margin: 0;
}

.fancy_register_form > h4 {
  color: #464444;
  font-size: 32px;
  font-weight: 500;
  margin: 10px 0 45px;
}

.fancy_register_form label {
  align-self: flex-end;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  text-align: left !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.fancy_register_form .form-control {
  height: 38px;
  padding: 10px 15px;
}

.fancy_register_form div#login_error {
  color: #000;
  text-align: center;
  text-shadow: none !important;
}

.fancy_register_form:after {
  border-right: 25px solid transparent;
  border-top: 25px solid #fff;
  bottom: -25px;
  content: '';
  left: 100px;
  opacity: .3;
  position: absolute;
}

/* Static Content - simple */
.static-content--simple {
  box-shadow: none;
  color: #8b8b8b; /*text-shadow: 0 1px 0 rgba(255,255,255,.4); */
}

.static-content--simple .can_be_used {
  color: #b7b7b7;
  font-weight: 500;
  text-shadow: none;
}

.sc__simpleaction {
  margin-top: 30px;
  display:flex;
  align-items:center
}

.heroAction a {
  width:100px
}

.heroCta {
  max-width:75vw
}

.static-content--simple .sc__line {
  position: relative;
  width: 100%
}

.static-content--simple .sc__line:after {
  background: #c6c6c6;
  content: '';
  display: block;
  height: 1px;
  left: 20px;
  position: absolute;
  top: 50%;
  width: 100%;
}

/* Faded Bottom Mask */
.sc__fade-boxed-mask {
  bottom: 0;
  height: 110px;
  left: 0;
  position: absolute;
  top: auto;
  width: 100%;
}

.sc__fade-mask {
  background: linear-gradient(to bottom, rgba(245, 245, 245, 0) 0%, #fbfbfb 100%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.sc__boxed-mask {
  background: #f5f5f5;
  border-radius: 4px;
  bottom: auto;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
  height: 105%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 1200px;
}

.sc--has-fade-mask .static-content__inner {
  padding-bottom: 140px;
}

.sc--has-fade-mask {
  margin-bottom: -100px;
  min-height: auto;
  overflow: hidden;
}

/* Text color theme */
.scs--theme-light {
  color: #fff;
}

.scs--theme-light h2,
.scs--theme-light h3,
.scs--theme-light .can_be_used {
  color: #fff;
}

/* dark */
.scs--theme-dark {
  color: #232323;
}

.scs--theme-dark h2,
.scs--theme-dark h3,
.scs--theme-dark .can_be_used {
  color: #232323;
}

.scs--theme-dark .sc__line:after {
  background: #343434;
}

/* Static Content - Weather box */
.sc--wb-style .static-content__title {
  letter-spacing: -1px;
  text-shadow: none;
}

.sc--wb-style .static-content__subtitle {
  font-weight: 300;
  text-shadow: none;
}

.sc--wb-style .sc__actionarea {
  margin-top: 40px;
  text-align: center;
}

.sc--wb-style .sc-infopop__text {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
  text-shadow: none;
}

.sc__weather {
  min-height: 120px;
  position: relative;
}

/* Weather list */
ul.scw_list {
  list-style: none;
  margin: 30px 80px 0;
}

.scw_list li {
  color: rgba(255, 255, 255, 0.9);
  float: left;
  text-align: center;
  width: 17%;
}

.scw_list .wt-icon {
  display: block;
  font-size: 140px;
  height: 110px;
  line-height: 1;
  margin-bottom: 15px;
}

.scw__degs {
  font-size: 28px;
}

.scw__high {
  font-weight: 700;
}

.scw__low {
  margin-left: 15px;
  opacity: .7;
}

.scw__day {
  display: block;
  font-size: 22px;
  font-weight: 300;
}

.scw__alt {
  font-size: 16px;
}

.scw_list li:first-child {
  margin-top: -60px;
  width: 32%;
}

.scw_list li:first-child .wt-icon {
  font-size: 230px;
  height: 180px;
  margin-bottom: 20px;
}

.scw_list li:first-child .scw__degs {
  font-size: 34px;
}

.scw_list li:first-child .scw__high {
  font-weight: 700;
}

.scw_list li:first-child .scw__day {
  font-size: 28px;
}

.scw_list li:first-child .scw__alt {
  font-size: 18px;
}

/* Static Content - Reservation Form */
.sc--resform-style .static-content__title {
  letter-spacing: -1px;
  text-shadow: none;
}

.sc--resform-style .static-content__subtitle {
  font-weight: 300;
  text-shadow: none;
}

.sc--resform-style .sc__actionarea {
  margin-top: 40px;
  text-align: center;
}

.sc--resform-style .sc-infopop__text {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
  text-shadow: none;
}

/* Reservation form */
.sc__res-form {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  margin: 70px auto 0;
  position: relative;
  width: 540px;
}

.sc__form-reservation {
}

.rf__block {
  float: left;
  width: 70%;
}

.rfblock--submit {
  width: 30%;
}

.rfblock--fields {
  background-color: #345370;
  border-radius: 3px 0 0 3px;
  padding: 30px 20px;
}

.rfblock--fields .rf__field {
  width: calc(25% - 10px);
}

.rf__field {
  border-left: 1px solid rgba(255, 255, 255, 0.06);
  float: left;
  margin-left: 20px;
  padding-left: 20px;
}

.rfblock--fields .rf__field:first-child {
  border: 0;
  margin-left: 0;
  padding-left: 0;
  width: calc(50% - 20px);
}

.rf__label {
  display: block;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
}

.rf__select {
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #2a425a;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RUY4QjAxMDMyMDEzMTFFNTlCOTRBMEJGNDg4OEVBOTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RUY4QjAxMDQyMDEzMTFFNTlCOTRBMEJGNDg4OEVBOTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFRjhCMDEwMTIwMTMxMUU1OUI5NEEwQkY0ODg4RUE5OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFRjhCMDEwMjIwMTMxMUU1OUI5NEEwQkY0ODg4RUE5OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgLLZzQAAAA7SURBVHjaYvz//z8DGkiD0rOQBZkYiASMSCam4VAzi2QT04hRSJYbqWMi9RWy4Ak/hkEUM9RNPQABBgBHYhbX6Sf7oQAAAABJRU5ErkJggg==);
  background-position: 86% 50%;
  background-repeat: no-repeat;
  background-size: 5px 8px;
  border: 0;
  color: #fff;
  cursor: pointer;
  height: 35px;
  outline: none;
  padding: 0 0 0 15px;
  width: 100%;
}

.rf__select:hover,
.rf__select:focus {
  background-color: #263645;
  outline: none;
}

.rffield--bigger .rf__select {
  float: left;
  margin-left: 10px;
  width: calc(50% - 5px);
}

.rf__select.rf__checkinmonth {
  margin-left: 0;
}

.rf__submit {
  background-color: #518ac0;
  border: 0;
  border-radius: 0 3px 3px 0;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 100%;
  line-height: 1.6;
  outline: none;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: left;
  transition: background-color .2s ease-out, padding-left .2s ease-out;
  width: 30%;
}

.rf__submit:active,
.rf__submit:focus {
  outline: none;
}

.rf__submit:hover {
  background-color: #30689d;
  padding-left: 15px;
}

.rf__submit:hover:after {
  right: 15px;
}

.rf__submit span {
  position: relative;
}

/* Showroom Carousel */
.sc--showroomcrs-style .sc__textcontent h2 {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1;
}

.sc--showroomcrs-style .sc__textcontent h3 {
  font-size: 2rem;
  font-weight: 700;
}

.sc--showroomcrs-style {
  max-width: 75%;
}

.sc__textcontent {
  margin-bottom: 40px;
}

.sc__actionarea {
  margin-bottom: 70px;
}

.sc__secbtn {
  margin-left: 20px;
}

.sc__shcar-wrapper {
  overflow: hidden;
  position: relative;
}

.sc__shcar-wrapper .caroufredsel_wrapper {
  margin-left: -20px !important;
  width: calc(100% - 10px) !important;
}

.sc__shcar-item {
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 13px;
}

.shc__item-link {
  display: block;
}

.shc__item-link[data-lightbox="iframe"] {
  position: relative;
}

.shc__item-link[data-lightbox="iframe"]:hover:after {
  border: 2px solid #fff;
  color: #fff;
  transform: scale(1.1);
}

.shc__item-title {
  font-size: 14px;
  font-weight: 700;
}

/* Huge Arrow */
.sc-huge-arrow {
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-name: arrow_entry;
  display: block;
  left: calc(45% - 200px);
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  z-index: 0;
}

.sc-huge-arrow:before,
.sc-huge-arrow:after {
  background-color: rgba(255, 255, 255, 0.17);
  content: '';
  display: block;
  height: 200px;
  left: 0;
  position: absolute;
  top: -200px;
  transform: rotate(-45deg) skew(45deg);
  transform-origin: 100% 100%;
  width: 900px;
}

.sc-huge-arrow:before {
  top: 0%;
  transform: rotate(45deg) skew(-45deg);
  transform-origin: 100% 0;
}

.no-csstransforms .sc-huge-arrow {
  display: none;
}

@keyframes arrow_entry {
  0% {
    margin-left: -50px;
    opacity: 0;
    transform: scale(.9);
  }
  100% {
    margin-left: 0px;
    opacity: 1;
    transform: scale(1);
  }
}

/* Text play */
#textplay {
  text-align: center;
}

.textplay-line {
  color: #fff;
  font-size: 40px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 20px;
}

.textplay-sep {
  font-weight: 300;
  opacity: 0.2;
}

.textplay-word {
  -webkit-backface-visibility: hidden;
  opacity: 0.4;
  transition: opacity 0.3s ease-out;
}

.textplay-word.active {
  opacity: 1;
}

/*** Content Maps */
.scontent__maps {
  position: relative;
}

.th-google_map {
  width: 100%;
}

.th-google_map .gmap_marker p {
  font-size: 1rem !important;
}

/* Start with the defualt 700px in height but if the viewport
height is lower than 700, make it 90vh (90% of the viewport) */
.scontent__maps:not(.static-content--fullscreen) {
  height: 750px;
}

@media only screen and (max-height: 700px) {
  .scontent__maps:not(.static-content--fullscreen) {
    height: 90vh;
  }

  #map {
    height: 700px;
    width: 100%;
  }
}

.scontent__maps.static-content--fullscreen {
  height: 100vh;
}

.scontent__maps .static-content__infopop {
  bottom: 50px;
  position: absolute;
  right: 15px;
  z-index: 1;
}

.kl-contentmaps__panel {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  position: absolute;
  right: calc((100vw - 1170px) / 2);
  top: 10%;
  width: 350px;
  z-index: 1;
}

.kl-contentmaps__panel-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 200px;
  opacity: 1;
  transition: opacity .2s ease-out, height .3s ease-out;
  width: 100%;
}

.kl-contentmaps__panel-img:hover {
  opacity: .7;
}

.kl-contentmaps__panel-info {
  color: #343434;
  max-height: 350px;
  min-height: 50px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 30px;
}

.kl-contentmaps__panel-info-text {
  max-height: 350px;
  padding-top: 20px;
  transition: max-height .3s ease-out, padding-top .3s ease-out;
}

.kl-contentmaps__panel-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}

.kl-contentmaps__linkapp {
  display: block;
  margin: 20px;
}

.kl-contentmaps__panel-tgg {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  border-radius: 50%;
  display: block;
  height: 30px;
  margin: 0;
  opacity: .8;
  position: absolute;
  right: 20px;
  top: 20px;
  transform: rotate(45deg);
  transition: opacity .2s ease-out, transform .4s ease-out;
  width: 30px;
  z-index: 1;
}

.kl-contentmaps__panel-tgg:hover {
  opacity: 1;
}

.kl-contentmaps__panel-tgg:before,
.kl-contentmaps__panel-tgg:after {
  background: #fff;
  content: '';
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -1px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  transform-origin: 50%;
  width: 2px;
}

.kl-contentmaps__panel-tgg:after {
  transform: rotate(90deg);
}

/* Hide panel if checked */
.is-closed.kl-contentmaps__panel {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}

.is-closed .kl-contentmaps__panel-info-text {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
}

.is-closed .kl-contentmaps__panel-img {
  height: 80px;
}

.is-closed .kl-contentmaps__panel-tgg {
  transform: rotate(0);
}

.scontent__maps.maskcontainer--mask3 .kl-bottommask--mask3 {
  bottom: -8px;
}

/* Static content - Heat distortion */
.wrap-heat {
  height: 100vh;
  position: relative;
}

.Background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Background canvas {
  height: 100vh !important;
}


/* Static content - Isometric grids */
body.isometric #page_wrapper {
  background: linear-gradient(90deg, #58A8FB, #465683);
}

.pseudo-scroller {
  pointer-events: none;
}

.isolayer {
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
}

.js .grid,
.js .grid__item,
.js .grid__link {
  transform-style: preserve-3d;
}

.grid {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.grid__item {
  width: 300px;
}

.js .grid__item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid__link {
  display: block;
  position: relative;
  z-index: 1;
}

.grid__img {
  display: block;
  max-width: 100%;
}

.grid__title {
  bottom: 0;
  color: #fff;
  font-size: 0.65em;
  font-weight: 600;
  letter-spacing: 2px;
  opacity: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  transform: translate3d(0, -20px, 0);
  transition: transform 0.3s, opacity 0.3s;
  width: 100%;
  z-index: -1;
}

.grid__item:hover .grid__title {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.layer {
  display: block;
  position: relative;
}

.layer:not(:first-child) {
  left: 0;
  position: absolute;
  top: 0;
}

.section--intro {
  height: 100vh;
  max-height: 1200px;
  min-height: 800px;
  overflow: hidden;
  position: relative;
}

/* Shadow effect */
.isolayer--shadow .grid__link::before {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  bottom: 5px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
  content: '';
  left: 5px;
  opacity: 0.6;
  position: absolute;
  right: 5px;
  top: 5px;
  transform: translateZ(-1px) scale(0.95);
  transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
  z-index: -1;
}

.isolayer--shadow .grid__item:hover .grid__link::before {
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8);
  opacity: 0.2;
  transform: translateZ(-1px) scale(1);
}

/* All individual isometric grid layouts (static and scrollable) */
.isolayer--scroll1 {
  height: calc(100vh - 280px);
  max-width: 1200px;
  width: 70vw;
}

.isolayer--scroll1 .grid__item {
  padding: 15px;
  width: 300px;
}

.isolayer--deco1 {
  height: 900px;
  width: 1200px;
}

.isolayer--deco1 .grid__link .layer:first-child:not(img) {
  background: #ac5cf5;
}

.isolayer--deco1 .grid__link .layer:nth-child(2):not(img) {
  background: #5db4eb;
}

.isolayer--deco1 .grid__link .layer:nth-child(3):not(img) {
  background: #5debb4;
}

.isolayer--deco1 .grid__link div.layer {
  height: 270px;
  opacity: 0.4;
  width: 370px;
}

.isolayer--deco1 .grid__item {
  height: 300px;
  padding: 15px;
  width: 400px;
}

.isolayer--deco1 .grid__item:nth-child(2) {
  margin-top: 150px;
}

.isolayer--deco2 {
  height: 100vh;
  width: 1200px;
}

.isolayer--deco2 .grid__item {
  padding: 20px;
}

.isolayer--deco2 .grid__link .layer {
  border: 20px solid #fff;
  border-width: 40px 20px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.08);
}

.isolayer--deco3 {
  height: 500px;
  width: 960px;
}

.isolayer--deco3 .grid__item {
  height: 320px;
  padding: 0;
  width: 240px;
}

.isolayer--deco3 .grid__item:nth-child(2),
.isolayer--deco3 .grid__item:nth-child(4) {
  margin-top: -160px;
}

.isolayer--deco3 .grid__link,
.isolayer--deco3 .layer {
  height: 100%;
  width: 100%;
}

.isolayer--deco3 .layer {
  background: #fff;
  text-align: center;
  transform-origin: 0% 50%;
}

.isolayer--deco3 .grid__link:hover .layer:not(:first-child) {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1);
}

.isolayer--deco3 .grid__item:nth-child(2n) .layer {
  background: #f7d2dc;
}

.isolayer--deco3 .grid__item:nth-child(3n) .layer {
  background: #fff0f3;
}

.isolayer--deco3 .grid__item:nth-child(4n) .layer {
  background: #e9c5cc;
}

.isolayer--deco3 .grid__item:nth-child(5n) .layer {
  background: #d6cbcd;
}

.isolayer--deco3 .grid__item:nth-child(7n) .layer {
  background: #ffc9d8;
}

.isolayer--deco3 .layer {
  height: 100%;
  width: 100%;
}

.isolayer--deco3 .decoletter {
  color: #000;
  display: block;
  font-size: 6em;
  font-weight: 900;
  line-height: 1;
  padding: 70px 0 0 0;
}

.isolayer--deco3 .deconumber {
  color: #000;
  display: block;
  font-size: 3em;
}

.isolayer--deco4 {
  height: 900px;
  width: 1000px;
}

.isolayer--deco4 .grid__item {
  padding: 15px;
}

.isolayer--deco4 .grid__link .layer:first-child:not(img) {
  background: #ac5cf5;
}

.isolayer--deco4 .grid__link .layer:nth-child(2):not(img) {
  background: #5db4eb;
}

.isolayer--deco4 .grid__link .layer:nth-child(3):not(img) {
  background: #5debb4;
}

.isolayer--deco4 .grid__link .layer {
  transform-origin: 50% 100%;
}

.isolayer--deco4 .grid__link div.layer {
  height: 203px;
  opacity: 0.4;
  width: 270px;
}

.isolayer--deco4 .grid__item {
  height: 225px;
  padding: 15px;
  width: 300px;
}

/* Flip effect for the scrollable grid */
.js .grid--effect-flip .grid__item {
  opacity: 0;
}

.js .grid--effect-flip .grid__item.shown {
  opacity: 1;
}

.grid--effect-flip .grid__item.animate {
  animation: moveUp 1s cubic-bezier(0.2, 1, 0.3, 1) forwards;
  transform: translateY(800px);
}

@keyframes moveUp {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* offer-banners */
.offer-banners .offer-banners-link {
  display: block;
  margin-bottom: 30px;
}

.offer-banners.ob--resize-cover .offer-banners-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.style3 .action_box_inner {
  background-color: #243775;
}

.style3 .action_box_inner:before {
  background-color: #243775;
}

.action_box.style3:before {
  background-color: #243775;
}

.tbg {
  background-color: #243775 !important;
}

.kl-icon img {
  margin: 0 auto;
  width: 60px;
}

.media-container.media-container--type-pb {
  margin-top: 0
}

@media (max-width: 992px) and (min-width:768px) {
  .media-container.media-container--type-pb {
    margin-bottom: 180px;
  }
  form button {
    align-self: center;
    margin-left: 15px;
  }
}

img.kl-iconbox__icon {
  width: 100%;
}

/* Magnific Popup CSS */
.mfp-bg {
  background: #0b0b0b;
  filter: alpha(opacity=80);
  height: 100%;
  left: 0;
  opacity: 0.8;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1042;
}

.mfp-wrap {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1043;
}

.mfp-container {
  height: 100%;
  left: 0;
  padding: 0 8px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  cursor: auto;
  width: 100%;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  left: 8px;
  margin-top: -0.8em;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 50%;
  width: auto;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  outline: none;
  overflow: visible;
  padding: 0;
  z-index: 1046;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  color: #FFF;
  filter: alpha(opacity=65);
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  height: 44px;
  line-height: 44px;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 44px;
}

.mfp-close:hover,
.mfp-close:focus {
  filter: alpha(opacity=100);
  opacity: 1;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  padding-right: 6px;
  right: -6px;
  text-align: right;
  width: 100%;
}

.mfp-counter {
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
}

.mfp-arrow {
  filter: alpha(opacity=65);
  height: 110px;
  margin: 0;
  margin-top: -55px;
  opacity: 0.65;
  padding: 0;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 50%;
  width: 90px;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  filter: alpha(opacity=100);
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  border: medium inset transparent;
  content: '';
  display: block;
  height: 0;
  left: 0;
  margin-left: 35px;
  margin-top: 35px;
  position: absolute;
  top: 0;
  width: 0;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
  border-bottom-width: 13px;
  border-top-width: 13px;
  top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
  border-bottom-width: 21px;
  border-top-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
  border-right: 27px solid #3F3F3F;
  margin-left: 25px;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-bottom: 40px;
  padding-top: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  max-width: 900px;
  width: 100%;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  width: 100%;
}

.mfp-iframe-scaler iframe {
  background: #000;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Main image in popup */
img.mfp-img {
  display: block;
  height: auto;
  line-height: 0;
  margin: 0 auto;
  max-width: 100%;
  padding: 40px 0 40px;
  width: auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  background: #444;
  bottom: 40px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  content: '';
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 40px;
  width: auto;
  z-index: -1;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  cursor: auto;
  left: 0;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  width: 100%;
}

.mfp-title {
  color: #F3F3F3;
  line-height: 18px;
  padding-right: 36px;
  text-align: left;
  word-wrap: break-word;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

/* Fade Effect for opening */
.mfp-fade.mfp-bg {
  background: #333;
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.94;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* Zoom effect */
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.2s ease-in-out;
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  opacity: 0;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /** * Remove all paddings around the image on small screen */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    bottom: 0;
    top: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    background: rgba(0, 0, 0, 0.6);
    height: 35px;
    line-height: 35px;
    padding: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    width: 35px;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}


/* Bubble Box */
.bubble-box {
  background: rgba(0, 0, 0, 0.8);
  bottom: 50px;
  color: #fff;
  left: -100%;
  max-width: 305px;
  position: fixed;
  transition: all .2s ease-in-out;
  z-index: 1000;
}

.bubble-box.bb--anim-show {
  left: 50px;
}

.bubble-box .bb--inner {
  padding: 25px;
}

.bubble-box p:last-child {
  margin-bottom: 0;
}

.bubble-box .bb--close {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: 10px;
  top: 10px;
}

.bubble-box .bb--close:hover {
  color: #fff;
}

.bubble-box.bb--anim-show.bb--anim-hide {
  left: -100%;
}

/* Notification Style */
.notification-box {
  border-radius: 7px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, .15);
  max-width: 305px;
}

.notification-box a {
  color: rgba(113, 189, 116, 0.75);
}

.notification-box .bb--close {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
}

/* Ad box */
.ads-box {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.18);
  color: #4F5152;
  font-size: 13px;
  line-height: 1.5;
  max-width: 190px;
  text-align: center;
}

.ads-box .pp-adimg {
  border-radius: 2px;
  margin-bottom: 10px;
}

.ads-box .bb--close {
  right: 6px;
  top: 5px;
}

.ads-box .bb--close:hover {
  color: #000;
}

/* RangerDT Popup Box */
.kl-pp-box {
  margin: 0 auto;
  position: relative;
  width: 400px;
}

.kl-pp-box .kl-pp-inner {
  background-color: #fff;
  border-radius: 5px;
}

.kl-pp-box[data-ppbox-timeout] .dontshow {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  margin-top: 5px;
}

/* Portfolio Full Width */
/* Project list */
ul.thumbs {
  -webkit-backface-visibility: hidden;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.thumbs .project-item {
  display: block;
  float: left;
  font-size: 13px;
  text-align: center;
  vertical-align: top;
}

ul.thumbs .inner-item {
  margin-bottom: 2%;
  padding: 0 15px;
}

ul.thumbs[data-columns="1"] .project-item {
  margin-bottom: 0;
  width: 100%;
}

ul.thumbs[data-columns="2"] .project-item {
  margin-bottom: 0;
  width: 50%;
  width: calc(100% / 2);
}

ul.thumbs[data-columns="3"] .project-item {
  margin-bottom: 0;
  width: 33.3333%;
}

ul.thumbs[data-columns="4"] .project-item {
  margin-bottom: 0;
  width: 25%;
  width: calc(100% / 4);
}

ul.thumbs[data-columns="5"] .project-item {
  margin-bottom: 0;
  width: 20%;
  width: calc(100% / 5);
}

ul.thumbs[data-columns="6"] .project-item {
  margin-bottom: 1.8%;
  width: 16.66666666666667%;
  width: calc(100% / 6);
}

@media (max-width: 419px) {
  ul.thumbs .project-item {
    width: 100% !important;
  }
}

@media (min-width: 420px) and (max-width: 580px) {
  ul.thumbs[data-columns="2"] .project-item,
  ul.thumbs[data-columns="3"] .project-item,
  ul.thumbs[data-columns="4"] .project-item,
  ul.thumbs[data-columns="5"] .project-item,
  ul.thumbs[data-columns="6"] .project-item {
    width: 100%;
  }
}

@media (min-width: 580px) and (max-width: 767px) {
  ul.thumbs[data-columns="2"] .project-item,
  ul.thumbs[data-columns="3"] .project-item,
  ul.thumbs[data-columns="4"] .project-item,
  ul.thumbs[data-columns="5"] .project-item,
  ul.thumbs[data-columns="6"] .project-item {
    width: 50%;
    width: calc(100% / 2);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  ul.thumbs[data-columns="3"] .project-item,
  ul.thumbs[data-columns="4"] .project-item,
  ul.thumbs[data-columns="5"] .project-item,
  ul.thumbs[data-columns="6"] .project-item {
    width: 33.3333%;
    width: calc(100% / 3);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  ul.thumbs[data-columns="4"] .project-item,
  ul.thumbs[data-columns="5"] .project-item,
  ul.thumbs[data-columns="6"] .project-item {
    width: 33.333%;
    width: calc(100% / 3);
  }
}

ul.thumbs li {
}

ul.thumbs li [data-role="title"] {
  color: #686b78;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 10px;
  text-transform: uppercase;
}

ul.thumbs li .prj-info {
  margin-top: 20px
}

ul.thumbs li .img-placeholder {
  position: relative;
}

ul.thumbs .overlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
}

ul.thumbs .overlay-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

ul.thumbs .overlay a {
  border: 1px solid #fff;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  margin-left: 5px;
  margin-right: 5px;
  padding: 15px 30px;
  position: relative;
  text-decoration: none;
}

ul.thumbs .overlay a:hover {
  background: #fe6060;
  border-color: transparent;
}

ul.thumbs .overlay a:nth-child(1) {
  top: -50px;
}

ul.thumbs .overlay a:nth-child(2) {
  top: 50px;
}

ul.thumbs li:hover .overlay {
  opacity: 1;
  visibility: visible;
}

ul.thumbs li:hover .overlay a {
  top: 0;
}

ul.thumbs li .appreciate {
  background: #dddddd;
  border-radius: 18px;
  display: inline-block;
  height: 36px;
  text-decoration: none;
  width: 36px;
}

ul.thumbs li .appreciate::before {
  color: #fff;
  font-size: 18px;
  line-height: 39px;
  text-align: center;
}

ul.thumbs li .appreciate:hover {
  background: #fe6060;
}

/* project list - style2 */
ul.thumbs.style2 .inner-item {
  overflow: hidden;
  position: relative;
}

ul.thumbs.style2 .prj-info {
  background: rgba(255, 255, 255, 0.95);
  margin: 0;
  opacity: 0;
  padding: 25px;
  position: absolute;
  text-align: left;
  top: 100%;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}

ul.thumbs.style2 .prj-info::before {
  border: 6px solid transparent;
  border-bottom-color: rgba(255, 255, 255, 0.95);
  bottom: 100%;
  content: '';
  left: 30px;
  position: absolute;
}

ul.thumbs.style2 .prj-info > div {
  float: left;
  width: calc(100% - 40px);
}

ul.thumbs.style2 [data-role="title"] {
  color: #242731;
  font-size: 16px;
  text-transform: none;
}

ul.thumbs.style2 [data-role="cat"] {
  color: #888;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}

ul.thumbs.style2 li:hover .prj-info {
  opacity: 1;
  transform: translateY(-100%);
  visibility: visible;
}

ul.thumbs.style2 .appreciate {
  background: none;
  color: #888;
  float: right;
  height: auto;
  line-height: 1;
  text-align: center;
}

ul.thumbs.style2 .appreciate::before,
ul.thumbs.style2 .appreciate:hover::before {
  color: var(--color-primary);
  font-size: 18px;
  line-height: 1
}

ul.thumbs.style2 .appreciate::after {
  display: block;
  font-size: 13px;
  line-height: 1;
  margin-top: 5px;
  text-align: center;
  vertical-align: bottom;
}

ul.thumbs.style2 .appreciate:hover::before {
  color: #565656;
}

ul.thumbs.style2 .appreciate:hover {
  background: none;
}

ul.thumbs.style2 .overlay {
  background: rgba(54, 54, 54, 0.85);
}

ul.thumbs.style2 .overlay a {
  color: #fff;
  padding: 10px 15px;
  top: 0;
}

ul.thumbs.style2 .overlay a:hover {
  background: var(--color-primary);
  border-color: transparent;
}

ul.thumbs.style2 .overlay a:nth-child(1) {
  top: -50px;
}

ul.thumbs.style2 .overlay a:nth-child(2) {
  top: -55px;
  transition-delay: 0.05s !important;
}

ul.thumbs.style2 li:hover .overlay a {
  top: 0;
}

ul.thumbs .overlay-inner {
  margin-top: -50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

ul.project-list.style2 li:hover .prj-info {
  opacity: 1;
  transform: translateY(-100%);
  visibility: visible;
}

ul.project-list.style2 .prj-info {
  transition: all 0.15s ease-out;
}

.heart-count {
  display: block;
  font-family: var(--main-font);
  font-size: 16px;
  margin-top: 5px;
}

.full-portfolio {
  background-color: #1a1c25;
  padding: 40px 0;
}

.full-portfolio h3 {
  color: #fff;
  text-transform: uppercase;
}

.full-portfolio .hg-portfolio-sortable #portfolio-nav {
  float: right;
  margin: 0;
}

.full-portfolio .hg-portfolio-sortable #portfolio-nav li a {
  background: none;
  color: #fff;
  font-size: 12px;
}

.full-portfolio .hg-portfolio-sortable #portfolio-nav li a:hover,
.full-portfolio .hg-portfolio-sortable #portfolio-nav li.current a {
  background: none;
  color: var(--color-primary);
}

.fullwidthportfolio.portfolio-items {
  padding: 0;
  width: 100%;
}

.fullwidthportfolio.portfolio-items > .row {
  margin-left: 0;
  margin-right: 0;
}

.fullwidthportfolio.portfolio-items > .row > div {
  float: none;
  padding: 0;
}

.fullwidthportfolio ul.thumbs {
  margin: 0;
}

.fullwidthportfolio ul.thumbs .project-item {
  margin-bottom: 0;
}

.fullwidthportfolio ul.thumbs .inner-item {
  margin-bottom: 0;
  padding: 0;
}

.fullwidthportfolio ul.thumbs li {
  transition-property: transform, opacity, box-shadow
}

.fullwidthportfolio ul.thumbs li:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.project-item .overlay a {
  transition: all 0.15s ease-out;
}

/* FreeWall Gallery */
.photo-wall .isotope-item,
.photo-wall .grid-sizer {
  width: 380px;
  width: calc(100% / 5);
}

.photo-wall .isotope-item {
  float: left;
  overflow: hidden;
  padding-bottom: 380px;
  padding-bottom: calc(100% / 5);
  position: relative;
}

.photo-wall .isotope-item.width2 {
  width: 760px;
  width: calc((100% / 5) * 2);
}

.photo-wall .isotope-item.height2 {
  padding-bottom: 760px;
  padding-bottom: calc((100% / 5) * 2);
}

.photo-wall .pw-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.15s ease-out;
}

.photo-wall .pw-item::after {
  background-color: #fe6060;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
}

.photo-wall .isotope-item:hover .pw-item {
  transform: scale(1.03);
}

.photo-wall .isotope-item:hover .pw-item::after {
  opacity: .5;
  visibility: visible;
}

.photo-wall .pw-details {
  bottom: -200px;
  color: #fff;
  left: 5%;
  opacity: 0;
  padding-bottom: 40px;
  padding-left: 0;
  position: absolute;
  transition: all 0.15s ease-out;
  visibility: hidden;
  width: 100%;
}

.photo-wall .isotope-item:hover .pw-details {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.photo-wall .pw-details [data-role="title"] {
  color: inherit;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}

.photo-wall .pw-details [data-role="category"] {
  color: inherit;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  opacity: .7;
}

.photo-wall .pw-details i {
  float: left;
}

.photo-wall .pw-details h5 {
  margin-top: 0;
}

/* end FreeWall Gallery */

#captchaEl {
  display: inline-block;
  margin-bottom: 15px;
  overflow: hidden;
  padding-left: 15px;
}

.formResult {
  font-size: 15px;
  margin: 20px 0 0 0;
  padding: 10px 0;
  text-align: center;
}

.formSuccess {
  border: 2px solid #568f43;
}

.formFail {
  border: 2px solid #ff4d4d;
}

/* One Page */
.section-video-icons .left-side {
  background-color: rgba(61, 61, 61, 0.7) !important;
  padding-right: 50px !important;
}

.section-video-icons .right-side {
  background-color: rgba(205, 33, 34, 0.7) !important;
  padding-left: 50px !important;
}

.section-video-icons img {
  width: 100%;
}

.custom-position3 {
  background-color: rgba(132, 105, 16, 0.7) !important;
  padding-bottom: 13%;
  padding-left: 10%;
  padding-right: 15%;
  padding-top: 15%;
}

.mtop-50 {
  margin-top: 50px;
}

.ptop-30 {
  padding-top: 30px;
}

.lh-24 {
  line-height: 24px !important;
}

.fa-facebook-f.color {
  color: #3b5998;
}

.fa-youtube.color {
  color: #cd201f;
}

.fa-twitter.color {
  color: #55acee;
}

.fa-pinterest.color {
  color: #cc2127;
}

.fa-facebook-f.color:hover,
.fa-youtube.color:hover,
.fa-twitter.color:hover,
.fa-pinterest.color:hover {
  color: #4C4C4C;
}

.custom-height .caroufredsel_wrapper,
.custom-height .recentwork_carousel__img {
  height: 500px !important;
}

.footer_nav {
  margin-top: 0 !important;
}

.kl-contentmaps__panel {
  top: 245px;
}

/* Social Icons Element */
.elm-social-icons {
  display: inline-block;
  list-style: none;
  margin-left: 0;
  padding: 0;
}

.elm-social-icons li {
  display: inline-block;
  margin: 5px;
  margin-left: 3px;
  margin-right: 3px;
}

.elm-social-icons .elm-sc-icon {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  opacity: 1;
  padding: 23px;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-out;
  width: auto;
}

.elm-social-icons .elm-sc-icon:before {
  -webkit-backface-visibility: hidden;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.elm-social-icons.sc--normal .elm-sc-icon {
  background-color: #111;
}

.elm-social-icons.sc--normal .elm-sc-icon:hover {
  background-color: var(--color-primary);
}

/* Colored social icons */
.elm-social-icons.sc--colored .elm-sc-icon:hover {
  background-color: #111;
}

/* Colored social icons */
.elm-social-icons.sc--colored_hov .elm-sc-icon {
  background-color: #111;
}

.elm-social-icons.sc--colored_hov .elm-sc-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Social icons - clean style */
.elm-social-icons.sc--clean .elm-sc-icon {
  background: none !important;
}

.elm-social-icons.sc--clean .elm-sc-icon:hover {
  background: none !important;
  box-shadow: none;
}

/* Shape */
.elm-social-icons.sh--rounded .elm-sc-icon {
  border-radius: 3px;
}

.elm-social-icons.sh--circle .elm-sc-icon {
  border-radius: 50%;
}

.elm-social-icons.sh--special1 .elm-sc-icon:before {
  left: 75%;
  top: 75%;
}

.elm-socialicons {
  margin-bottom: 35px;
}

.fa-facebook-f.bg-icon {
  background: #3B5998;
}

.fa-twitter.bg-icon {
  background: #55ACEE;
}

.fa-dribbble.bg-icon {
  background: #ea4c89;
}

.fa-pinterest.bg-icon {
  background: #CC2127;
}

.fa-linkedin.bg-icon {
  background: #0077B5;
}

.chaser .main-menu li a {
  background: transparent;
}

.nav > li > a:focus, .nav > li > a:hover {
  background: none !important;
}

/* LOGIN / REGISTER POPUPS */
.kl-fw-input {
  width: 100%;
}

.loginbox-popup .kl-fancy-form {
  margin-bottom: 15px;
}

.loginbox-popup .kl-fancy-form .form-control {
  padding: 26px 15px 6px 15px;
}

.auth-popup-panel {
  padding: 45px;
}

.auth-popup-title {
  color: #4a4a4a;
  font-size: 20px !important;
  font-weight: 400;
  line-height: 1.9;
  margin-bottom: 20px;
  text-shadow: none;
}

.auth-popup-createacc {
  background: none !important;
  margin-right: 8px;
}

.auth-popup-createacc + .auth-popup-sep {
  border-left: 1px solid #ccc;
  margin-right: 8px;
}

.auth-popup-links {
  color: #4A4A4A;
  font-size: 10px;
  font-weight: 600;
  margin-top: 20px;
}

.auth-popup-link {
  color: #898989;
  font-size: 13px;
  font-weight: 400;
}

.auth-popup-link:hover {
  color: #585858;
}

.auth-popup-remember {
  display: block;
  font-size: 11px;
  font-weight: 300;
  margin: 0 0 20px;
  text-shadow: none;
  text-transform: uppercase;
}

.auth-popup-remember-chb {
  margin: 0;
}

.mfp-bg.mfp-fade.mfp-bg-lighter {
  background: #000;
}

.mfp-bg.mfp-fade.mfp-bg-lighter.mfp-ready {
  opacity: .75;
}

.auth-popup .mfp-close {
  color: #828282;
}

.auth-popup {
  background: #f9f9f9;
  border: 1px solid #fff;
  border-radius: 2px;
  box-shadow: 0 0 20px #000;
  margin: 0 auto;
  position: relative;
  width: 420px;
}

.register-popup {
  width: 420px;
}


/* FANCYFORMS */
.kl-fancy-form {
  margin-bottom: 20px;
  position: relative;
}

.kl-fancy-form-label {
  color: #969696;
  cursor: text;
  font-size: 10px;
  left: 16px;
  position: absolute;
  text-transform: uppercase;
  top: 6px;
  transition: all .25s ease;
}

.kl-fancy-form-input:focus + .kl-fancy-form-label,
.kl-fancy-form-textarea:focus + .kl-fancy-form-label {
  color: #c0c0c0;
}

.kl-fancy-form .form-control {
  height: auto;
  padding: 26px 16px 6px 9px;
}

/* Selectbox fix for Chrome/Safari on OSX */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .kl-fancy-form-select {
    -webkit-appearance: none;
    background-image: url(../images/select-arrow.svg);
    background-position: calc(100% - 10px) center;
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 6px 12px;
    box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.06);
    height: 52px;
  }
}

.kl-fancy-form-input::-webkit-input-placeholder {
  color: #bababa;
}

.kl-fancy-form-input:focus::-webkit-input-placeholder {
  color: #D7D7D7;
}

/* Firefox < 19 */
.kl-fancy-form-input:-moz-placeholder {
  color: #bababa;
}

.kl-fancy-form-input:focus:-moz-placeholder {
  color: #D7D7D7;
}

/* Firefox > 19 */
.kl-fancy-form-input::-moz-placeholder {
  color: #bababa;
}

.kl-fancy-form-input:focus::-moz-placeholder {
  color: #D7D7D7;
}

/* Internet Explorer 10 */
.kl-fancy-form-input:-ms-input-placeholder {
  color: #bababa;
}

.kl-fancy-form-input:focus:-ms-input-placeholder {
  color: #D7D7D7;
}

.reset-list,
.reset-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.inline-list {
  display: inline-block;
}

.inline-list--items > li {
  display: inline-block;
}

.fancy-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.contact-popup {
  background: #f9f9f9;
  border: 1px solid #fff;
  border-radius: 2px;
  box-shadow: 0 0 20px #000;
  margin: 0 auto;
  position: relative;
  width: 650px;
}

.contact-popup-panel .contactForm {
  padding: 50px;
}

.contact-popup-title {
  font-size: 22px !important;
}

/* Niche Agency demo updates */
.agency .ls__arrow:before,
.agency .ls__arrow:after {
  background-color: #989898;
}

.agency .ls__arrow:hover:before,
.agency .ls__arrow:hover:after {
  background-color: rgb(93, 93, 93);
}

.hover-scale {
  display: inline-block;
  transform-origin: 50% 50%;
  transition: transform .1s ease-out;
}

.hover-scale:hover {
  transform: scale(1.4, 1.4);
}

.kl-icon.ico-klmid {
  vertical-align: middle;
}

.kl-icon.ico-size-xxl {
  font-size: 32px;
}

.stg-alignleft .kl-icon {
  color: #fff;
  float: left;
  font-size: 28px;
  margin-right: 25px;
}

.testimonials-partners--dark .ts-pt-testimonials__text.gray-bg {
  background: rgba(165, 165, 165, 0.2);
}

.cms-icon-section {
  overflow: hidden;
  position: relative;
}

.cms-icon-container {
  left: 50%;
  margin-left: -200px;
  position: absolute;
  text-align: center;
  top: 0;
}

.cms-icon-container i {
  color: rgba(0, 0, 0, 0.05);
  font-size: 400px;
  height: 400px;
  line-height: 400px;
  opacity: 0;
  position: absolute;
  top: 10px;
  transform: scale(0);
  transition: transform .2s ease-out, opacity .2s ease-out;
  vertical-align: middle;
}

.cms-icon-container .kl-icon.icon-github-circled {
  font-size: 520px;
  height: 520px;
  line-height: 520px;
  margin-left: -40px;
  top: -50px
}

.cms-icon-container .kl-icon.hovered {
  opacity: 1;
  transform: scale(1);
}

.colored-area {
  background: linear-gradient(135deg, #360384, #da0b68) !important;
  background-clip: border-box;
  background-image: linear-gradient(135deg, rgb(54, 3, 132), rgb(218, 11, 104)) !important;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto auto;
}


.socialbox {
  background: #efefef;
  border-color: #dbdbdb;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  color: #bfbfbf;
  display: block;
  margin: 0 -15px;
  padding: 70px 0;
  text-align: center;
  text-decoration: none;
}

.socialbox:hover {
  text-decoration: none;
}

.socialbox .kl-icon {
  display: inline-block;
  font-size: 44px
}

.socialbox .fan-count {
  color: rgba(0, 0, 0, 0.25);
  display: block;
  font-size: 12px;
  font-weight: 800;
  height: 0;
  line-height: 1.3;
  margin: 0;
  opacity: 0;
  transform: translateY(-20px);
  visibility: hidden
}

.socialbox .fan-count > span {
  color: #fff;
  display: block;
  font-size: 30px;
  font-weight: 400;
}

.socialbox:hover .kl-icon,
.touch .socialbox:active .kl-icon {
  color: #fff;
  transform: translateY(-20px)
}

.socialbox:hover .fan-count,
.socialbox:active .fan-count {
  opacity: 1;
  visibility: visible;
}

.socialbox,
.socialbox .kl-icon,
.socialbox .fan-count {
  transition: all .2s ease;
}

.socialbox.sb-twitter:hover {
  background: #00BDEC;
}

.socialbox .fa-twitter {
  color: #00BDEC;
}

.socialbox.sb-facebook:hover {
  background: #3B5998;
}

.socialbox .fa-facebook-f {
  color: #3B5998;
}

.socialbox.sb-dribbble:hover {
  background: #EF5B92;
}

.socialbox .fa-dribbble {
  color: #EF5B92;
}

.socialbox.sb-envato:hover {
  background: #7fb841;
}

.socialbox .icon-envato {
  color: #7fb841;
}

.socialbox.sb-vimeo:hover {
  background: #01557A;
}

.socialbox.sb-google-plus:hover {
  background: #D94A39;
}

.socialbox.sb-linkedin:hover {
  background: #1B92BD;
}

.socialbox.sb-behance:hover {
  background: #026afa;
}

.socialbox.sb-pinterest:hover {
  background: #CB2027;
}

.socialbox.sb-instagram:hover {
  background: #517FA4;
}

.socialbox.sb-youtube:hover {
  background: #D20800;
}

.socialbox.sb-skype:hover {
  background: #18B7F1;
}

.socialbox.sb-flickr:hover {
  background: #EA2698;
}

.socialbox.sb-yelp:hover {
  background: #AF0606;
}

.socialbox.sb-lastfm:hover {
  background: #D2013A;
}

.el-devimages {
  position: relative;
}

/* Eos Homepage */
.poppins {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.custom-p {
  color: #868686;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.find-out .kl-icon {
  font-size: 32px;
  margin-right: 12px;
}

.find-out span {
  display: inline-block;
  vertical-align: middle;
}

.find-out .text {
  font-weight: 700;
}

/* Parallax Effects in static content - product presentation alternative */
.parallax-effects {
  bottom: 0;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.parallax-effects .layer {
  height: 100%;
  width: 100%;
}

.parallax-effects .layer-element {
  position: absolute;
}

#page_wrapper .parallax-effects .slide-image {
  height: 110%;
  left: -5%;
  top: -5%;
  width: 110%;
}

.pos-vertical-middle {
  left: 0;
  margin: auto;
  position: relative;
  right: 0;
  top: 50%;
  z-index: 1;
}

.pos-vertical-middle > .container {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

.rotating {
  display: inline-block;
  transform: rotateX(0) rotateY(0) rotateZ(0);
  -webkit-transform-origin-x: 50%;
  transform-style: preserve-3d;
  transition: 0.5s;
}

.rotating.flip {
  position: relative;
}

.rotating .front, .rotating .back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 0;
  top: 0;
}

.rotating .front {
  display: inline-block;
  position: absolute;
  transform: translate3d(0, 0, 1px);
}

.rotating.flip .front {
  z-index: 1;
}

.rotating .back {
  display: block;
  opacity: 0;
}

.rotating.spin {
  transform: rotate(360deg) scale(0);
}

.rotating.flip .back {
  display: block;
  opacity: 1;
  transform: rotateY(180deg) translate3d(0, 0, 0);
  z-index: 2;
}

.rotating.flip.up .back {
  transform: rotateX(180deg) translate3d(0, 0, 0);
}

.rotating.flip.cube .front {
  transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
}

.rotating.flip.cube .back {
  transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
}

.rotating.flip.cube.up .back {
  transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
}


/* Team Boxes Carousel element*/
.team-carousel {
  margin-left: -15px;
  margin-right: -15px;
}

/* Team Member Block */
.team-member {
  -webkit-backface-visibility: hidden;
  margin: 0 15px 3px;
  margin-bottom: 40px;
  padding: 0 0 15px;
}

.team-boxes__carousel-wrapper {
  position: relative;
}

.team-boxes__carousel-wrapper .team-carousel .team-member {
  margin: 0 15px 3px;
}

.team-member .mmb-title {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 25px;
  padding: 0 15px;
}

.team-member .mmb-position {
  color: #2a83ed;
  font-size: 12px;
  margin-bottom: 20px;
  padding: 0 15px;
}

.team-member .mmb-desc {
  color: #888;
  padding: 0 15px;
}

.team-member .separator {
  background: #E8E8E8;
  display: block;
  height: 1px;
  margin: 0 auto 20px;
  width: 100%;
}

.team-member.text-center .separator {
  width: 80%;
}

.team-member .mmb-social-list {
  display: inline-block;
  margin: 0 auto;
  overflow: hidden;
}

.team-member .mmb-social-list li {
  display: inline-block;
  margin: 0 5px;
}

.team-member .mmb-social-list a {
  color: #bababb;
  font-size: 17px;
  text-decoration: none;
}

.team-member:hover .mmb-social-list a,
.team-member .mmb-social-list a:hover {
  color: #828282;
}

.team-member.tm-hover:hover {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.team-boxes__carousel-wrapper .hgSlickNav {
  position: absolute;
  right: 30px;
  top: -30px;
}

.team-boxes__carousel-wrapper .hgSlickNav-arr,
.team-boxes__carousel-wrapper .hgSlickNav-arr svg {
  width: 25px;
}

/* end Team Boxes Carousel element*/

/* Static content - Immersive video */
.kl-slideshow.immersive-video {
  background-color: #ececec;
}

.cd-overflow-hidden {
  /* this class is added to the body while the video is playing - used on desktop version only */
  height: 100vh;
}

.cd-immersive-video {
  height: 100vh;
  margin: 0 auto;
  padding: 3em 5%;
  position: relative;
  width: 100%;
}

.cd-loader {
  display: none;
}

.cd-btn {
  background: #2D60D5;
  box-shadow: 0 2px 10px rgba(45, 96, 213, 0.2);
  color: #ffffff;
  display: inline-block;
  padding: 1em 2em;
  transition: all .2s;
}

.cd-btn:hover {
  box-shadow: 0 6px 30px rgba(45, 96, 213, 0.3);
  color: #fff;
}


/* Slick slider default styles */
.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
}

.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url('../images/ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick--showOnMouseover .hgSlickNav {
  opacity: 0;
  transition: opacity .15s ease-out;
}

.slick--showOnMouseover:hover .hgSlickNav {
  opacity: 1;
}

.hgSlickNav-arr,
.hgSlickNav-arr svg {
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.hgSlickNav-arr polyline {
  stroke: #141414;
  transition: all .15s ease-out;
}

.hgSlickNav-arr:hover polyline {
  stroke-width: 35px;
}

.hgSlickNav-arr:active polyline {
  stroke-width: 50px;
}

.hgSlickNav-arr.hgSlickNav-prev {
  text-align: left;
}

.hgSlickNav-arr.hgSlickNav-next {
  text-align: right;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots li {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.slick-dots .slickBtn {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  outline: none;
  padding: 5px;
  width: 20px;
}

.slick-dots li button,
.hgSlickNav--light .slick-dots li button {
  background-color: #fff;
  border-radius: 50%;
  height: 3px;
  margin-right: 5px;
  opacity: .5;
  padding: 5px;
  transition: opacity .2s;
  width: 3px;
}

.hgSlickNav--light polyline {
  stroke: #ececec
}

.hgSlickNav--light .slick-dots li.slick-active button {
  opacity: .8;
}

/* end Slick slider default styles */


/**** Slider element with Slick Slider styles ****/

/* Screenshot-box element */
.screenshot-box {
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 6px #00000017;
  position: relative;
}

.screenshot-box .left-side h3.title {
  color: var(--color-primary);
  font-size: 1.4rem;
  line-height: 1.4;
}

.screenshot-box .left-side ul.features li:first-child {
  margin-top: 0;
}

.screenshot-box .left-side ul.features li h4 {
  color: #323232;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.review-image {
  text-align:center
}

.screenshot-box .left-side ul.features li h4 + p {
  font-size: 0.9rem;
  line-height: 1.5;
}

.screenshot-box .left-side {
  padding: 22px 20px 0 20px;
}

.screenshot-box .left-side ul.features {
  list-style: none;
  margin: 25px 0;
  padding: 0;
}

.screenshot-box .left-side ul.features li {
  background: url(../images/ok3.png) no-repeat left center;
  margin-top: 20px;
  padding-left: 50px;
}

.screenshot-box .left-side > .btn {
  margin-left: 50px;
}

@media (max-width:767.99px) {
  .screenshot-box:nth-child(even) .review-image {
    margin-bottom: 30px;
  }
}

/* Slick slider resets */
.slick-dots li button:before {
  display: none;
}

.screenshot-slider .js-slick {
  margin-bottom: 0;
}

.screenshot-slider .slick-dots {
  bottom: 0;
}

.slick-slide {
  outline: none;
}

/* Screenshot slider */
.screenshot-box .screenshot-slider {
  background-color: #dcdee7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-top: -10px;
  padding: 36px 0 0;
  position: relative;
}

.screenshot-box .screenshot-slider:before {
  background: #ababab;
  border-radius: 50%;
  box-shadow: -12px 0 0 #ababab, 12px 0 0 #ababab;
  content: "";
  display: block;
  height: 7px;
  left: 30px;
  margin-top: 0;
  position: absolute;
  top: 15px;
  width: 7px;
}

.screenshot-box .screenshot-slider img {
  height: 100%;
  width: 100%;
}

.screenshot-slider .screenshotBox-slickNav li {
  display: inline-block;
  height: auto;
  margin: 5px;
  width: auto;
}

.screenshot-slider .screenshotBox-slickNav .slickBtn {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0;
  display: inline-block;
  height: 3px;
  margin: 0;
  padding: 0;
  transition: color .2s ease;
  width: 35px;
}

.screenshot-slider .screenshotBox-slickNav .slick-active .slickBtn {
  background-color: #fff;
}

.screenshot-slider .screenshotBox-slickNav {
  bottom: 20px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
}

/* end Screenshot-box element */


/* Partners carousel element */
.ts-pt-partners__title {
  color: #fff;
  float: left;
  font-size: 18px;
  line-height: 1.2;
  opacity: .6;
  position: relative;
  width: 22%;
}

.ts-pt-partners__title:after {
  background: #fff;
  bottom: -15px;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  width: 50px;
}

.ts-pt-partners__carousel-wrapper {
  width: 100%;
}

.ts-pt-partners--y-title .ts-pt-partners__carousel-wrapper {
  float: left;
  width: 78%;
}

.ts-pt-partners__carousel-item {
  float: left;
  height: 60px;
  line-height: 60px;
  text-align: center;
  vertical-align: middle;
}

.ts-pt-partners__carousel.non-slick {
  display: flex;
}

.ts-pt-partners__carousel.non-slick .ts-pt-partners__carousel-item {
  flex: 1;
}

.ts-pt-partners__img {
  margin: 0 auto;
  max-height: 60px;
  width: auto;
}

.slick-initialized .ts-pt-partners__img {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease 0s, transform 0.2s ease 0s;
}

.slickSlider--activated .slick-active .ts-pt-partners__img {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease 0.1s, transform 0.6s ease 0s;
}

.slickSlider--activated .slick-active ~ .slick-active .ts-pt-partners__img {
  transition-delay: 0.2s, 0.1s;
}

.slickSlider--activated .slick-active ~ .slick-active ~ .slick-active .ts-pt-partners__img {
  transition-delay: 0.3s, 0.2s;
}

.slickSlider--activated .slick-active ~ .slick-active ~ .slick-active ~ .slick-active .ts-pt-partners__img {
  transition-delay: 0.4s, 0.3s;
}

/* Dark version */
.testimonials-partners--dark .testimonials-partners__separator {
  background: #181818;
}

.testimonials-partners--dark .ts-pt-testimonials__item {
  color: #181818;
}

.testimonials-partners--dark .ts-pt-testimonials__text {
  background: rgba(255, 255, 255, 0.2);
}

.testimonials-partners--dark .ts-pt-testimonials__item--normal .ts-pt-testimonials__text:after {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.testimonials-partners--dark .ts-pt-testimonials__item--reversed .ts-pt-testimonials__text:after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.testimonials-partners--dark .ts-pt-partners__title {
  color: #181818;
}

.testimonials-partners--dark .ts-pt-partners__title:after {
  background: #181818;
}

.testimonials-partners--dark .ts-pt-partners__title.no-border:after {
  background: none;
}

/* end Partners carousel element */


/* Testimonials carousel quoter-style style element */
.testimonial_slider__carousel-wrapper {
  margin: 0 180px 20px;
  position: relative;
}

.testimonial_slider__carousel-wrapper::before,
.testimonial_slider__carousel-wrapper::after {
  color: #e9eaea;
  font-family: "Trebuchet MS";
  font-size: 120px;
  line-height: 0;
  position: absolute;
  top: 50px;
}

.testimonial_slider__carousel-wrapper::before {
  content: '\201C';
  left: -50px;
}

.testimonial_slider__carousel-wrapper::after {
  content: '\201D';
  right: -50px;
}

.testimonial_slider__carousel-wrapper .testimonialbox {
  color: #595959;
  font-size: 16px;
  line-height: 1.8;
  text-align: center;
}

.testimonial_slider__carousel-wrapper .thead img {
  margin: 0 auto 20px;
  width: auto;
}

.testimonial_slider__carousel-wrapper .testimonialbox .who {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
}

.testimonial_slider__carousel-wrapper .testimonialbox .who strong {
  color: #fe6060;
  display: block;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 5px
}

.testimonial_slider__carousel-wrapper .thead {
  margin-bottom: 20px;
}

.testimonial_slider__carousel-wrapper .testimonialbox.biggertext .tcontent {
  font-size: 16px;
}

/*no quotes*/
.testimonial_slider__carousel-wrapper.stg-no-quotes::before,
.testimonial_slider__carousel-wrapper.stg-no-quotes::after {
  display: none;
}

.testimonial_slider__carousel-wrapper.stl-light .testimonialbox .who {
  color: #ACACAC;
}

.testimonial_slider__carousel-wrapper.stl-light .testimonialbox .tcontent {
  color: #E7E7E7;
}

.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav {
  bottom: -20px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
}

.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 20px;
}

.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav li .slickBtn {
  background: #e5e5e5;
  border-radius: 3px;
  box-shadow: none;
  display: inline-block;
  height: 6px;
  margin: 0 3px;
  padding: 3px;
  width: 6px;
}

.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav li .slickBtn:hover {
  background-color: #ccc;
}

.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav li.slick-active .slickBtn {
  background-color: #fe6060;
  box-shadow: none;
}

.testimonial_slider__carousel-wrapper.slim-margin {
  margin: 70px 100px;
}

/* end Testimonials carousel quoter-style style element */


/* Testimonials carousel fader style element */
.testimonials_fader__carousel-wrapper {
  margin-bottom: 40px;
}

.testimonial_fader__carousel {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.testimonials_fader__carousel-wrapper .has-left-border {
  border-left: 1px solid #e2e2e2;
}

.testimonial_fader__carousel .testimonials_fader__carousel-item {
  padding: 0 0 20px 100px;
  position: relative
}

.testimonial_fader__carousel .testimonials_fader__carousel-item:after {
  color: #e2e2e2;
  content: '\201C';
  font-family: tahoma;
  font-size: 150px;
  left: 30px;
  line-height: 1;
  position: absolute;
  top: -10px;
}

.testimonials_fader__carousel-wrapper blockquote {
  border: 0;
  font: italic 20px/1.4 Georgia, Arial, sans-serif;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.testimonials_fader__carousel-wrapper blockquote + h6 {
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
}

.testimonials_fader__carousel-wrapper .testimonial-author--photo {
  height: 30px;
  width: 30px;
}

.testimonials_fader__carousel-wrapper .testimonial-author h6 {
  float: left;
  margin: 5px 0;
}

/* end Testimonials carousel fader style element */


/* Simple Testimonials carousel element */
.testimonial_simple__carousel blockquote {
  background: #fff;
  border-left: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  color: #898989;
  font-size: 14px;
  font-style: italic;
  line-height: 2;
  margin: 0 10px 10px 10px;
  padding: 25px;
  position: relative;
}

.testimonial_simple__carousel blockquote:after,
.testimonial_simple__carousel blockquote:before {
  border: 7px solid transparent;
  border-top-color: #fff;
  bottom: -14px;
  content: '';
  left: 14px;
  position: absolute;
}

.testimonial_simple__carousel blockquote:before {
  border-top-color: #D9D9D9;
  bottom: -16px;
}

.testimonial-author h5 {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin: 12px 0;
}

.testimonials-author blockquote + h5 {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin: 12px 0;
}

.testimonial-author {
  margin-top: 15px;
}

.testimonial-author--photo {
  border-radius: 50%;
  float: left;
  height: 40px;
  margin-right: 10px;
  overflow: hidden;
  width: 40px;
}

.testimonial-author--photo img {
  display: block;
  height: auto;
  width: 100%;
}

/* end Simple Testimonials carousel element */


/* Portfolio carousel element */
.pt-content-title {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 20px;
}

.pt-content-title.small {
  font-size: 1.5rem;
}

.ptcarousel--frames-classic {
  border: 4px solid #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 2px #ddd, 0 0 6px rgba(0, 0, 0, 0.2);
  position: relative;
}

.ptcarousel--frames-classic:after {
  border: 15px solid transparent;
  border-right: 15px solid #ddd;
  content: '';
  left: -34px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.ptcarousel--frames-classic:before {
  border: 13px solid transparent;
  border-right: 13px solid #fff;
  content: '';
  left: -30px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
  z-index: 2;
}

.ptcarousel--frames-modern {
  background-color: #dcdee7;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 4px 4px 2px 2px;
  box-shadow: 0 16px 10px -11px rgba(0, 0, 0, 0.12);
  padding-bottom: 0;
  padding-top: 30px;
  position: relative;
}

.ptcarousel--frames-modern:before {
  background: #ababab;
  border-radius: 30px;
  box-shadow: -12px 0 0 #ababab, 12px 0 0 #ababab;
  content: "";
  display: block;
  height: 7px;
  left: 25px;
  margin-top: 0;
  position: absolute;
  top: 12px;
  width: 7px;
}

.ptcarousel--frames-minimal {
  position: relative
}

.ptcarousel ul {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.ptcarousel li {
  float: left;
}

.ptcarousel .controls > a {
  background: #000;
  border-radius: 2px;
  display: inline-block !important;
  height: 31px;
  left: 15px;
  margin: 0;
  margin-top: -12px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 30px;
  z-index: 11;
}

.itemLinks {
  margin-top: 20px;
}

.itemLinks span a {
  background: #eee;
  border-radius: 2px;
  color: #888;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  margin-right: 15px;
  padding: 7px 10px;
  text-transform: uppercase;
}

.itemLinks span a:hover {
  background: var(--color-primary);
  color: #fff;
}

.hg_general__carousel .hgSlickNav-arr {
  background: rgba(0, 0, 0, .2);
  border-radius: 2px;
  height: 50px;
  left: 15px;
  margin: 0;
  margin-top: -25px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 50px;
  z-index: 11;
}

.hg_general__carousel .hgSlickNav-arr svg {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.hg_general__carousel .hgSlickNav-arr polyline {
  stroke: #fff;
}

.hg_general__carousel .hgSlickNav-arr.hgSlickNav-prev {
  text-align: left;
}

.hg_general__carousel .hgSlickNav-arr.hgSlickNav-next {
  left: auto;
  right: 15px;
}

.hg_general__carousel:hover .hgSlickNav-arr {
  opacity: 1;
}

/* end Portfolio carousel element */


/* Partners carousel simple element */
.partners_carousel h5.title {
  font-weight: 500;
  margin-top: 0;
  text-shadow: 1px 1px 0 #fff
}

.partners_carousel__simple .partners_carousel-item {
  float: left;
  margin: 0;
  padding: 0 0 0 10px;
  text-align: center;
  width: 250px;
}

.partners_carousel__simple .partners_carousel-item a {
  background: #ffffff;
  border: 4px solid #f9f9f9;
  border-radius: 3px;
  display: block;
  line-height: 46px;
  margin: 0 5px;
  min-height: 46px;
  padding: 10px 0;
}

.partners_carousel__simple .partners_carousel-img {
  display: inline-block;
  opacity: .8;
}

.partners_carousel__simple .partners_carousel-item a:hover {
  background: #f9f9f9;
  border: 4px solid #eee;
}

.partners_carousel__simple .partners_carousel-item a:hover .partners_carousel-img {
  opacity: 1;
}

/* end Partners carousel simple element */


/* Image boxes carousel element */
.image-boxes__carousel-wrapper {
  border-bottom: solid 1px #ddd;
}

.image-boxes__carousel-wrapper.no-border {
  border: none;
}

.image-boxes__carousel-wrapper .image-boxes__carousel .inner-slide a {
  transition: all 0.15s ease-out;
}

.image-boxes__carousel-wrapper .inner-slide {
  color: #FFF;
  color: rgba(255, 255, 255, 0.3);
  display: block;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

.image-boxes__carousel-wrapper .inner-slide:hover {
  color: #fff;
}

.image-boxes__carousel-wrapper .inner-slide:hover a {
  color: var(--color-primary);
}

.image-boxes__carousel-wrapper .inner-slide:hover .btn-fullcolor {
  color: #fff;
}

.image-boxes__carousel-wrapper .inner-slide .imgprev {
  background: #242731;
}

.image-boxes__carousel-wrapper .inner-slide:hover .imgprev img {
  opacity: .6;
}

.image-boxes__carousel-wrapper .inner-slide .details {
  border-left: 1px solid #eeeeee;
  display: block;
  min-height: 105px;
  padding: 30px 0 0;
}

.image-boxes__carousel-wrapper .inner-slide .details {
  text-align: center;
}

.image-boxes__carousel-wrapper .inner-slide [data-role="title"] {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.image-boxes__carousel-wrapper .inner-slide [data-role="category"] {
  color: #CECED0;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 0;
}

.image-boxes__carousel-wrapper .slick-dots {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
}

.image-boxes__carousel-wrapper .slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 20px;
}

.image-boxes__carousel-wrapper .slick-dots li .slickBtn {
  background: #e5e5e5;
  border-radius: 3px;
  box-shadow: none;
  display: inline-block;
  height: 6px;
  margin: 0 3px;
  padding: 3px;
  width: 6px;
}

.image-boxes__carousel-wrapper .slick-dots li .slickBtn:hover {
  background-color: #ccc;
}

.image-boxes__carousel-wrapper .slick-dots li.slick-active .slickBtn {
  background-color: #fe6060;
  box-shadow: none;
}

.no-touch .image-boxes__carousel-wrapper .inner-slide .imgprev::after {
  background: transparent;
  border-bottom: 0;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.no-touch .image-boxes__carousel-wrapper .inner-slide:hover .imgprev::after {
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 8px solid var(--color-primary);
}

/* Hoverlay style */
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide {
  color: #FFF;
  cursor: pointer;
  margin: 0;
  position: relative;
}

.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .imgprev {
  margin: 0;
}

.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .imgprev img {
  display: block;
  width: 100%;
}

.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .imgprev::after {
  display: none;
}

.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .details {
  background: #fe6060;
  background: rgba(0, 0, 0, 0.5);
  border-left: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
}

.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide:hover .details {
  opacity: 1;
  visibility: visible;
}

.image-boxes__carousel-wrapper.stl-hoverlay .dt-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide [data-role="title"] {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}

/* Full-width style */
.image-boxes__carousel-wrapper.full-width,
.image-boxes__carousel-wrapper.full-width .inner-slide .details {
  border: none;
}

.image-boxes__carousel-wrapper.full-width .inner-slide {
  margin: 0 12px;
}

.image-boxes__carousel-wrapper.full-width .inner-slide:hover .imgprev img {
  opacity: 1;
}

/* end Image boxes carousel element */


/* Fancy Carousel element */
.fancy-carousel__wrapper .hgSlickNav {
  position: absolute;
  top: 50%;
  width: 100%;
}

.fancy-carousel__wrapper .hgSlickNav-arr,
.fancy-carousel__wrapper .hgSlickNav-arr svg {
  height: 30px;
  width: 30px;
}

.fancy-carousel__wrapper .hgSlickNav-arr polyline {
  stroke: #fff;
}

.fancy-carousel__wrapper .hgSlickNav-prev {
  float: left;
  margin-left: 25px;
}

.fancy-carousel__wrapper .hgSlickNav-next {
  float: right;
  margin-right: 25px;
}

/* end Fancy Carousel element */

/* Showroom carousel styles */
.shcar__pagination {
  display: inline-block;
  height: 20px;
  line-height: 20px;
}

.shcar__pagination li button {
  background-color: rgba(255, 255, 255, 0.6);
}

.shcar__pagination li.slick-active button {
  background-color: #fff;
}

.shcar__nav_pag {
  left: 30px;
  padding: 0;
  position: absolute;
  top: 0;
}

.shcar__nav_pag .hgSlickNav-arr polyline {
  stroke: #fff;
}

/* Restaurant menu carousel */
.restaurant-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.restaurant-menu li {
  font-size: 17px;
  overflow: hidden;
}

.restaurant-menu-title {
  color: #ddd;
  text-align: center;
}

.restaurant-menu-item {
  float: left;
}

.restaurant-menu-price {
  float: right;
}

.menu-carousel .hgSlickNav {
  margin-top: 50px;
  text-align: center;
}

.menu-carousel .hgSlickNav-arr.hgSlickNav-prev {
  margin-right: 20px;
}

/* ==========================================================================

   Responsive CSS Stylesheet.

   This stylesheet contains responsive styles for RangerDT Template.
   We'll just add tweaks however this will contains the future
   responsive updates styles of this template.

   Do not change nothing as it will be constantly updated and your work might
   get overwritten.

 ========================================================================== */


.clearfix {
  *zoom: 1;
}

.hide-text {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

.input-block-level {
  display: block;
  min-height: 30px;
  width: 100%;
}

.visible-xxs {
  display: none !important;
}

.hidden-xxs {
  display: block !important;
}

@-ms-viewport {
  width: device-width;
}

.hidden {
  display: none;
  visibility: hidden;
}

.visible-phone {
  display: none !important;
}

.visible-tablet {
  display: none !important;
}

.hidden-desktop {
  display: none !important;
}

.visible-desktop {
  display: inherit !important;
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: inherit !important;
  }

  .hidden-print {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hidden-desktop {
    display: inherit !important;
  }

  .visible-desktop {
    display: none !important;
  }

  .visible-tablet {
    display: inherit !important;
  }

  .hidden-tablet {
    display: none !important;
  }

  .form-horizontal .control-group {
    margin-bottom: 10px;
  }

  .contact_form .form-horizontal .controls {
    margin-left: 0;
  }
}


/*-----------------------------------------------------------------------------------*/
/*  RangerDT EDIT
 /*-----------------------------------------------------------------------------------*/

/* Higher screens+ */
@media only screen and (min-width: 1921px) {
  /* Force full width images on higher res like 1921+ wide */
  .force-img-fullwidth .slides li img {
    width: 100%;
  }
}

.about_images, .about_text {
  padding:0 15px;
  width:50%
}

@media (min-width:1500px) {
  .about_cont {
    width: 33.33333%;
  }
  .about_images, .about_text {
    width:50%
  }
}

@media (min-width: 1200px) and (max-width:1499.99px) {
  .about_cont {
    width: 33.33333%;
  }

  .about_images {
    width: 60%
  }

  .about_text {
    width: 40%
  }
}

@media (min-width: 992px) and (max-width:1199.99px) {
  .about_cont {
    width: 33.33333%;
  }
}

@media (min-width: 768px) and (max-width:991.99px) {
  .about_cont {
    width: 33.33333%;
  }
}

@media (min-width:1400px) {
  /* Grid icon boxes */
  .grid-ibx--cols-4 .grid-ibx__item,
  .grid-ibx--cols-5 .grid-ibx__item {
    width: 25%;
  }

  .grid-ibx--style-lined-gradient.grid-ibx--cols-4 .grid-ibx__item,
  .grid-ibx--style-lined-gradient.grid-ibx--cols-5 .grid-ibx__item {
    width: calc(25% - 30px);
  }
}

@media (max-width:1399.99px) {
  /* Grid icon boxes */
  .grid-ibx--cols-4 .grid-ibx__item,
  .grid-ibx--cols-5 .grid-ibx__item {
    width: 50%;
  }

  .grid-ibx--style-lined-gradient.grid-ibx--cols-4 .grid-ibx__item,
  .grid-ibx--style-lined-gradient.grid-ibx--cols-5 .grid-ibx__item {
    width: calc(50% - 30px);
  }
}

/** Desktop+ **/
@media (min-width: 1200px) {

  body input,
  body textarea,
  body .uneditable-input {
    margin-left: 0;
  }

  body .thumbnails {
    margin-left: -30px;
  }

  body .thumbnails > li {
    margin-left: 30px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
  /**** Header ****/

  .kl-slideshow .kl-slideshow-safepadding {
    padding-top: 310px;
  }

  .chaser .main-menu .hg_mega_container {
    width: 1110px;
  }

  body.boxed-layout.sticky-header #header {
    left: auto;
    width: 1194px;
  }

  /**** end Header ****/
  /**** Elements ****/
  /* Button element Width presets */
  .btn-fullwidth {
    width: 100%;
  }

  .btn-halfwidth {
    width: 50%;
  }

  .btn-third {
    width: 33.33%;
  }

  .btn-forth {
    width: 25%;
  }


  .rev_slider,
  .rev_slider_wrapper {
    height: 780px;
  }

  .latest_posts.default-style.kl-style-2 .lp-title {
    height: 350px;
  }

  .latest_posts.default-style.kl-style-2 .lp-title .m_title {
    font-size: 3.3rem;
  }

  .latest_posts.default-style.kl-style-2 .post {
    margin-bottom: 20px;
  }

  /* Action box */
  .action_box .action_box_content.partners-carousel {
    display: inline;
    display: initial;
  }


  /* Stage image box */
  .stage-ibx__point {
    display: block;
    height: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 1s ease-out;
    width: 0;
  }

  .stage-ibx__point:after {
    background: rgba(255, 255, 255, 0.6);
    background: rgba(40, 204, 229, 0.6);
    border-radius: 50%;
    box-shadow: 0 0 0 3px #FFFFFF;
    box-shadow: 0 0 0 3px #28cce5;
    content: '';
    display: block;
    height: 10px;
    left: 0;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 0;
    transition: box-shadow .2s ease-out, transform .2s ease-out;
    width: 10px;
  }

  .stage-ibx__point:hover:after,
  .stage-ibx__point.kl-ib-point-active:after {
    box-shadow: 0 0 0 5px #28cce5, 0 4px 10px #000;
    transform: scale(1.4);
  }

  .stage-ibx__point[data-title]:before {
    background: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    bottom: 100%;
    color: #fff;
    content: attr(data-title);
    font-size: 12px;
    left: 50%;
    line-height: 1.4;
    margin-bottom: 30px;
    min-width: 100px;
    opacity: 0;
    padding: 6px 3px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    transition: opacity .2s ease-out .1s, visibility .2s ease-out .1s, margin-bottom .2s ease-out .1s;
    visibility: hidden;
  }

  .stage-ibx__point[data-title]:hover:before,
  .stage-ibx__point[data-title].kl-ib-point-active:before {
    margin-bottom: 20px;
    opacity: 1;
    visibility: visible
  }

  .screenshot-box .left-side h3.title {
    font-size: 1.5rem;
    font-weight: 100;
    margin-top: 20px;
  }

  .media-container {
    height: 300px;
  }

  /* Recent work carousel */
  .container-fluid .recentwork_carousel__left {
    padding-left: calc((100vw - (1200px - 15px)) / 2) !important;
  }

  /* Static content If it has height */
  .static-content--height .static-content__inner {
    position: relative;
  }

  .static-content--height .static-content__inner:not(.container) {
    position: absolute;
    width: 100%;
  }

  /* Immersive video */
  .cd-immersive-video {
    padding: 0 calc(50% - 510px);
  }


  /* Recent work v2 style element */
  .recentwork_carousel--2.container {
    max-width: 100%;
  }

  /* Video section */
  .section-video-icons .left-side .kl-iconbox__desc {
    padding-left: 100px;
  }

  .section-video-icons .right-side .kl-iconbox__desc {
    padding-right: 100px;
  }

  /* Devices element */
  .tablet-device img {
    padding: 14.4% 12% 1%;
  }

  /**** end Elements ****/
  /* BOXED VERSION tweaks */
  body.boxed-layout #page_wrapper,
  body.boxed-layout .support_panel {
    background: #f5f5f5;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    width: 1200px;
  }

  body.boxed-layout .support-panel-close {
    border-radius: 0;
    right: 0;
    top: 0;
  }

  body.boxed-layout .support_panel {
    background-color: #fff;
  }

  body.boxed-layout .screenshot-box .screenshot-slider,
  body.boxed-layout .screenshot-box .screenshot-slider {
    height: 364px !important;
  }

  body.boxed-layout .hg_col_eq_last {
    padding-right: 0;
  }

  body.boxed-layout .container-fluid .recentwork_carousel__left {
    padding-left: 30px;
  }

  body.boxed-layout .kl-iconbox__content-wrapper {
    clear: both;
  }

  body.boxed-layout .skills-responsive-diagram {
    padding-bottom: 70%;
  }

  body.boxed-layout .kl-skills-legend.legend-topright {
    left: 80%;
  }

  body.boxed-layout .world-office.stockholm {
    left: 331px !important;
    top: 7px !important;
  }

  body.boxed-layout .world-office.bucharest {
    left: 343px !important;
    top: 87px !important;
  }

  body.boxed-layout .world-office.sydney {
    left: 545px !important;
    top: 225px !important;
  }

  body.boxed-layout .world-office.botswana {
    left: 340px !important;
    top: 196px !important;
  }

  body.boxed-layout .world-office.quebec {
    left: 152px !important;
    top: 62px !important;
  }

  body.boxed-layout .world-office.newyork {
    left: 163px !important;
    top: 83px !important;
  }

  body.boxed-layout.isometric #page_wrapper {
    background: linear-gradient(90deg, #58A8FB, #465683);
  }

  /* end BOXED VERSION tweaks */
  /*-----------------------------------------------------------------------------------*/
  /*  end RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}


/** Laptop **/
@media (max-width: 1200px) {
  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
  .container_fluid .row {
    padding-left: 15px;
    padding-right: 15px;
  }


  /**** Header ****/
  .support-panel-close {
    border-radius: 0;
    height: 40px;
    line-height: 40px;
    right: 5px;
    top: 5px;
    width: 40px;
  }

  .about_images {
    order: 2;
  }

  .about_images,.about_text {
    width: 100%
  }

  .about_text .hg_col_eq_last {
    padding-right:0;
  }

  .about_text .kl-title-block {
    text-align: center;
  }

  /**** end Header ****/
  /**** Elements ****/
  .kl-iconbox--fright .kl-iconbox__icon-wrapper {
    float: right;
  }

  .world-map-offices {
    max-height: 550px;
    max-width: calc(100% - 30px);
    overflow: auto;
  }

  .world-map-offices .world-office {
    position: static;
  }

  .world-map-offices .world-office::before {
    display: none
  }

  .world-map-offices .office-details {
    opacity: 1;
    padding-bottom: 0;
    position: static;
    visibility: visible;
  }

  .logos-bar {
    position: static;
    width: auto;
  }

  .media-container.custom-oh {
    overflow: hidden;
  }

  .kl-bg-source__bgimage.parallax {
    background-size: auto auto;
    background-size: initial;
  }


  /* Action box */
  .action_box {
    text-align: center;
  }


  /* Big social */
  .bigsocialblock--style2 .bigsocialblock__social-icon {
    opacity: 0.25;
  }

  .bigsocialblock--style2 .bigsocialblock__item:hover .bigsocialblock__social-icon {
    opacity: 0.4;
  }

  /* Skills diagram */
  .kl-skills-diagram .kl-skills-legend {
    left: auto;
    position: static;
    top: auto;
  }

  /* Video background caption */
  .sc__video-line {
    font-size: 30px;
  }

  .sc--videobg:not(.static-content--fullscreen) {
    min-height: 500px;
  }

  .sc--videobg .static-content__inner {
    bottom: 30px;
    position: absolute;
    width: 100%;
  }


  .section-video-icons .right-side,
  .section-video-icons .left-side {
    padding: 0 !important;
  }

  .section-video-icons .kl-iconbox__icon-wrapper {
    max-width: 220px;
  }

  /* Buttons */
  .btn {
    white-space: normal;
  }

  .btn-forth {
    min-width: 25%;
  }

  /**** end Elements ****/

}


/** Laptop+ **/
@media (min-width: 992px) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }

  .prt-hover-slidein {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .v-hidden {
    visibility: hidden;
  }

  /*** Menu ***/
  .menu-item-mega-parent.dropdown {
    position: inherit !important;
  }

  body.one-page .main-nav > ul > li > a {
    font-size: 13px;
  }

  /*** end Header Styles ***/
  /**** end Header ****/
  /**** Elements ****/
  .media-container.custom-height {
    height: 800px;
  }

  .left_sidebar {
    float: right;
  }

  /* Pricing table */

  .pricing-table-element[data-columns="1"] .plan-column.featured {
    width: calc(100% + 40px);
  }

  .pricing-table-element[data-columns="2"] .plan-column.featured {
    width: calc(50% + 40px);
  }

  .pricing-table-element[data-columns="3"] .plan-column.featured {
    width: calc(33.3333% + 40px);
  }

  .pricing-table-element[data-columns="5"] .plan-column.featured {
    width: 35%;
  }


  /* Static content If it's fullscreen */
  .static-content--fullscreen {
    height: 100vh;
  }

  .static-content--fullscreen .static-content__inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  /**** end Elements ****/
  /*-----------------------------------------------------------------------------------*/
  /*  end RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}


/** Laptop **/
@media (min-width: 992px) and (max-width: 1199px) {

  .main-nav > ul > li > a {
    font-size: 12px;
  }

  .main-nav ul ul.sub-menu li a {
    font-size: 10px;
  }

  .main-nav .hg_mega_container li a {
    font-size: 10px;
  }

  .chaser .main-menu li a {
    font-size: 10px;
  }

  .chaser .main-menu .hg_mega_container {
    width: 940px;
  }

  /*** Header Styles ***/
  .site-header .topnav .topnav-item,
  .site-header .topnav > a,
  .site-header .topnav > .topnav-item span,
  .site-header .kl-header-toptext,
  .site-header .kl-header-toptext a,
  .site-header .topnav.topnav--lang .toplang-item a {
    font-size: 0.7rem;
  }

  .kl-iconbox__content-wrapper {
    clear: both;
  }

  .photogallery-widget .pg-thumb .pg-caption,
  .photogallery-widget .pg-thumb .pg-caption a {
    font-size: 12px;
    font-weight: 400;
    padding: 10px 5px 0 10px;
  }

  /* Css Accordion (Latest posts 1) */
  .latest_posts-acc-inner,
  .latest_posts-acc-item:hover ~ .latest_posts-acc-item > .latest_posts-acc-inner {
    width: 465px !important;
  }

  .latest_posts.default-style.kl-style-2 .lp-title {
    height: 289px;
  }

  /* Devices */
  .el-devimages--type-img .el-devimages__laptop {
    height: 490px;
    width: 850px;
  }

  .el-devimages--type-vector .el-devimages__laptop {
    height: 428px;
    width: 845px;
  }

  .el-devimages__frame {
    height: 490px;
    width: 900px;
  }

  .el-devimages--type-vector .el-devimages__smartphone {
    height: 330px;
    width: 166px;
  }

  .el-devimages--type-img .el-devimages__smartphone {
    height: 324px;
    width: 197px;
  }

  /* Big social */
  .bigsocialblock.count-4 .bigsocialblock__social-icon {
    border-width: 5px;
    bottom: -20px;
    font-size: 60px;
    height: 120px;
    left: -30px;
    line-height: 110px;
    width: 120px;
  }

  .bigsocialblock.count-5 .bigsocialblock__social-icon {
    border-width: 5px;
    bottom: -10px;
    font-size: 50px;
    height: 100px;
    left: -30px;
    line-height: 90px;
    width: 100px;
  }

  .bigsocialblock.count-4 .bigsocialblock__title,
  .bigsocialblock.count-5 .bigsocialblock__title {
    font-size: 16px;
  }

  /* Process steps */
  .process_steps--style2 .process_steps__container {
    padding-right: calc(((100vw - 970px) / 2) + 15px);
  }

  .process_steps--style2 .process_steps__container:before {
    padding-right: calc(((100vw - 970px) / 2) + 60px);
  }

  .process_steps--style2 .process_steps__intro {
    padding-left: calc(((100vw - 970px) / 2) + 15px);
  }

  .statistic-box__title {
    font-size: 32px;
  }

  .media-container {
    height: 400px;
  }

  /* Static content */
  .static-content__title {
    font-size: 48px;
  }

  .static-content__subtitle {
    font-size: 28px;
  }

  /* Boxes */
  .sc__boxes {
    margin-top: 50px;
  }

  .sc__boxes .static-content__infopop {
    margin: 0 20px;
  }

  /*Maps panel*/
  .kl-contentmaps__panel {
    left: calc((100vw - 970px) / 2);
  }

  /**** end Elements ****/
  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}


/** Large Tablets **/
@media (max-width: 992px) {

  body {
    padding-top: 0;
  }

  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: static;
  }

  .navbar-fixed-top {
    margin-bottom: 20px;
  }

  .navbar-fixed-bottom {
    margin-top: 20px;
  }

  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }

  .navbar .container {
    padding: 0;
    width: auto;
  }

  .navbar .brand {
    margin: 0 0 0 -5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav-collapse {
    clear: both;
  }

  .nav-collapse .nav {
    float: none;
    margin: 0 0 10px;
  }

  .nav-collapse .nav > li {
    float: none;
  }

  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }

  .nav-collapse .nav > .divider-vertical {
    display: none;
  }

  .nav-collapse .nav .nav-header {
    color: #777777;
    text-shadow: none;
  }

  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    border-radius: 3px;
    color: #777777;
    font-weight: bold;
    padding: 9px 15px;
  }

  .nav-collapse .btn {
    border-radius: 4px;
    font-weight: normal;
    padding: 4px 10px 4px;
  }

  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }

  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: #f2f2f2;
  }

  .navbar-inverse .nav-collapse .nav > li > a,
  .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #999999;
  }

  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .nav > li > a:focus,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111111;
  }

  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }

  .nav-collapse .dropdown-menu {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    display: none;
    float: none;
    left: auto;
    margin: 0 15px;
    max-width: none;
    padding: 0;
    position: static;
    top: auto;
  }

  .nav-collapse .open > .dropdown-menu {
    display: block;
  }

  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }

  .nav-collapse .dropdown-menu .divider {
    display: none;
  }

  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }

  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    border-bottom: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    float: none;
    margin: 10px 0;
    padding: 10px 15px;
  }

  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-bottom-color: #111111;
    border-top-color: #111111;
  }

  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }

  .nav-collapse,
  .nav-collapse.collapse {
    height: 0;
    overflow: hidden;
  }

  .navbar .btn-navbar {
    display: block;
  }

  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }


  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
  body.boxed-layout #page_wrapper {
    border: 0;
    width: 100%;
  }

  body .kl-slideshow .scrollbarContainer {
    width: 100%;
  }

  .hg_section .full_width {
    margin-left: 15px;
    margin-right: 15px;
  }

  .topnav {
    margin: 0;
  }

  .support-info {
    margin: 0;
  }

  .site-header-main-center {
    margin-left: auto;
    margin-right: 30px;
  }

  .topnav.topnav--lang .languages .pPanel {
    left: auto;
    right: -10px;
  }

  .site-header .logo-container {
    padding: 10px 0;
  }

  /**** end Header ****/
  /**** Sub-header ****/
  .subheader-maintitle {
    font-size: 1.5rem;
  }

  .breadcrumbs li {
    font-size: 0.8rem;
  }


  /**** Elements ****/
  .latest_posts.default-style.kl-style-2 .lp-title .m_title {
    font-size: 2rem;
  }

  .pos-vertical-middle > .container {
    top: 200px;
    transform: none;
  }

  .photogallery-widget .pg-thumb .pg-caption {
    display: none
  }

  .logolist {
    width: 100%;
  }

  .logolist a {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
  }

  .logolist img {
    display: block;
    max-width: 100%;
  }

  .hover-box-2 {
    margin-bottom: 25px;
  }

  .gobox.ok > .far {
    left: 20px;
  }

  /* Latest Posts Accordion style */
  .latest_posts-acc-title {
    font-size: 15px;
    line-height: 1.3;
    margin: 8px 0;
  }

  .latest_posts-acc-text {
    font-size: 14px;
    line-height: 1.4;
  }

  .hg-portfolio-sortable ul#thumbs li {
    width: 33.333% !important
  }

  /* Style 1 - horiontal odd/even */
  .statistic-box--stl-style1.statistic-box__container {
    display: block;
    margin: 0;
    width: 100%;
  }

  .statistic-box--stl-style1 .statistic-box {
    display: block;
    float: left;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    width: 50%;
  }

  .statistic-box--stl-style1 .statistic-box__icon-holder {
    padding-bottom: 0;
    padding-top: 0;
    position: static;
    transform: none;
  }

  .statistic-box--stl-style1 .statistic-box__details {
    min-height: 90px;
    padding-bottom: 0;
    padding-top: 0;
    position: static;
  }

  .statistic-box--stl-style1 .statistic-box--odd .statistic-box__icon-holder {
    padding-bottom: 0;
    padding-top: 0;
    transform: none;
  }

  .statistic-box--stl-style1 .statistic-box--odd .statistic-box__details {
    padding-bottom: 0;
    padding-top: 0;
    transform: none;
  }

  .statistic-box--stl-style1 .statistic-box__line {
    display: none;
  }

  /* Style 2 - horiontal odd/even */
  .statistic-box--stl-style2.statistic-box__container {
    display: block;
    margin: 0;
    width: 100%;
  }

  .statistic-box--stl-style2 .statistic-box {
    display: block;
    float: left;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    width: 50%;
  }

  .statistic-box--stl-style2 .statistic-box__icon-holder {
    padding-bottom: 0;
    padding-top: 0;
    position: static;
    transform: none;
  }

  .statistic-box--stl-style2 .statistic-box__details {
    min-height: 90px;
    padding-bottom: 0;
    padding-top: 0;
    position: static;
  }

  .statistic-box--stl-style2 .statistic-box--odd .statistic-box__icon-holder {
    padding-bottom: 0;
    padding-top: 0;
    transform: none;
  }

  .statistic-box--stl-style2 .statistic-box--odd .statistic-box__details {
    padding-bottom: 0;
    padding-top: 0;
    transform: none;
  }

  .statistic-box--stl-style2 .statistic-box__line {
    display: none;
  }

  /* Pricing table */
  .pricing-table-element .features-column.hidesm {
    display: none
  }

  .pricing-table-element[data-columns="1"] .features-column ~ .plan-column {
    width: 100%;
  }

  /* with features-col */
  .pricing-table-element[data-columns="2"] .features-column ~ .plan-column {
    width: calc(100% / 2);
  }

  /* with features-col */
  .pricing-table-element[data-columns="3"] .features-column ~ .plan-column {
    width: calc(100% / 3);
  }

  /* with features-col */
  .pricing-table-element[data-columns="4"] .features-column ~ .plan-column {
    width: calc(100% / 4);
  }

  /* with features-col */
  .pricing-table-element[data-columns="5"] .features-column ~ .plan-column {
    width: calc(100% / 5);
  }

  /* Partners & Testimonials */
  .ts-pt-testimonials__item--size-1,
  .ts-pt-testimonials__item--size-2,
  .ts-pt-testimonials__item--size-3 {
    width: calc(50% - 50px);
  }

  .ts-pt-partners__title {
    float: none;
    margin-bottom: 30px;
    width: 100%;
  }

  .ts-pt-partners__title:after {
    background: #fff;
    bottom: -15px;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 50px;
  }

  .ts-pt-partners--y-title .ts-pt-partners__carousel-wrapper {
    clear: left;
    float: none;
    width: 100%;
  }

  /* Grid photo gallery */
  [class*='gridPhotoGalleryItem--w'] {
    width: 50% !important;
  }

  [class*='gridPhotoGalleryItem--h'] {
    padding-bottom: 100% !important;
  }


  /* Boxes */
  .sc__boxes {
    margin-top: 40px;
  }

  .sc__boxes .static-content__infopop {
    margin: 0 15px;
  }

  /* Services boxes style 3 */
  .services_boxes_wgt .intro-col + ul[class*="sbox-"] {
    margin-left: 0;
    width: auto;
  }

  .services_boxes_wgt .sbox-1 > li,
  .services_boxes_wgt .sbox-2 > li,
  .services_boxes_wgt .sbox-3 > li,
  .services_boxes_wgt .sbox-4 > li,
  .services_boxes_wgt .sbox-5 > li {
    margin-left: 0;
    width: 100%;
  }

  .services_boxes_wgt .intro-col {
    color: #888888;
    float: left;
    margin-bottom: 40px;
    padding-right: 0 !important;
    width: 100%;
  }

  .services_boxes_wgt .inner-box {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .services_boxes_wgt .hover-details {
    box-shadow: none;
    clear: both;
    opacity: 1;
    padding: 0;
    position: static;
    visibility: visible;
  }

  .services_boxes_wgt .hover-details::after {
    display: none;
  }


  /* Static Content - Weather box */
  .sc--wb-style .sc__actionarea {
    margin-top: 20px;
  }

  .sc__actionarea .btn {
    width: auto;
  }

  .sc--wb-style .sc-infopop__text {
    font-size: 13px;
  }

  .sc__weather {
    min-height: 100px;
  }

  /* Weather list */
  ul.scw_list {
    margin: 50px 10px 0;
  }

  .scw_list .wt-icon {
    font-size: 90px;
    height: 70px;
  }

  .scw__degs {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .scw__high {
    font-weight: 400
  }

  .scw__day {
    font-size: 18px;
  }

  .scw__alt {
    font-size: 13px;
  }

  .scw_list li:first-child {
    margin-top: -35px;
    width: 32%;
  }

  .scw_list li:first-child .wt-icon {
    font-size: 160px;
    height: 125px;
    margin-bottom: 10px;
  }

  .scw_list li:first-child .scw__degs {
    font-size: 30px;
  }

  .scw_list li:first-child .scw__high {
    font-weight: 700;
  }

  .scw_list li:first-child .scw__day {
    font-size: 28px;
  }

  .scw_list li:first-child .scw__alt {
    font-size: 18px;
  }

  /* Showroom Carousel */
  .sc--showroom-carousel .static-content--fullscreen {
    height: auto;
  }

  .sc--showroomcrs-style {
    max-width: 90%;
  }

  /* FreeWall Gallery */
  .photo-wall .isotope-item,
  .photo-wall .grid-sizer {
    width: calc(100% / 2);
  }

  .photo-wall .isotope-item {
    padding-bottom: calc(100% / 2);
  }

  .photo-wall .isotope-item.width2 {
    width: calc((100% / 2) * 2);
  }

  .photo-wall .isotope-item.height2 {
    padding-bottom: calc((100% / 2) * 2);
  }

  .photo-wall .isotope-item {
    padding-bottom: calc(100% / 2);
    width: calc(100% / 2)
  }

  .photo-wall .isotope-item.width2 {
    width: calc(100% / 2)
  }

  .photo-wall .isotope-item.height2 {
    padding-bottom: calc(100% / 2)
  }

  .photo-wall .isotope-item:nth-child(odd):last-child {
    padding-bottom: 100%;
    width: 100%
  }


  /* Step boxes style 2 (go boxes) element */
  .gobox:after,
  .gobox:before {
    left: auto;
    right: auto;
    transform: none;
  }

  .gobox.ok {
    padding-left: 50px
  }

  .gobox.ok > .glyphicon {
    left: 20px;
  }

  .process-list .processitems li {
    margin-bottom: 30px;
    width: 100%;
  }

  .process-list .processitems li .ico-wrapper {
    margin-bottom: 70px;
  }

  .process-list .processitems li.lined .ico-wrapper > span::after {
    height: 100%;
    left: calc(50% - 2px);
    width: 2px;
  }

  /* Video section */
  .section-video-icons .right-side,
  .section-video-icons .left-side {
    padding: 50px 15px !important;
  }

  .grid-ibx--cols-3 .grid-ibx__item {
    width: 100%;
    border:none
  }

  /* Counter number */
  .fun-fact .fun-number {
    font-size: 60px;
  }

  /**** end Elements ****/
  /*-----------------------------------------------------------------------------------*/
  /*  end RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}


/** Tablets (landscape mode)  **/
@media (min-width: 768px) and (max-width: 991px) {

  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }

  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
  /**** Header ****/
  #zn-res-menu {
    width: 50%;
  }

  /**** end Header ****/
  /**** Elements ****/
  .kl-iconbox__content-wrapper {
    clear: both;
  }

  .kl-slideshow.iosslider-slideshow {
    padding-bottom: 80% !important;
  }

  .recentwork_carousel li a iframe {
    height: auto;
    width: 100%;
  }

  .subheader-subtitle {
    font-size: 14px;
  }

  body .gobox h4 {
    font-size: 15px;
    line-height: 1.2;
  }

  body .gobox.ok > img {
    margin-bottom: 10px;
  }

  .css3accordion .content > .m_title {
    font-size: 12px;
    line-height: 1.3;
    margin: 8px 0;
  }

  .css3accordion .text {
    font-size: 11px;
    line-height: 1.4;
  }

  body .css3panels {
    height: 450px;
  }

  body .css3panels .panel img {
    max-height: 460px;
  }

  body .css3panels .caption {
    top: 300px;
    width: 300px;
  }

  body .css3panels .caption.upper {
    top: 250px;
  }

  #skills_diagram .legend {
    right: -30px;
  }

  .feature_box.style3 .box {
    font-size: 12px;
  }

  .how_to_shop > .row {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .tabs_style1 > ul.nav > li > a {
    padding: 8px 10px;
  }

  .feature_box .box .title + p {
    font-size: 12px;
  }

  /* Css Accordion (Latest posts 1) */
  .latest_posts-acc-inner,
  .latest_posts-acc-item:hover ~ .latest_posts-acc-item > .latest_posts-acc-inner {
    width: 345px !important;
  }


  .flickr_feeds li a {
    width: 40px;
  }

  .header-search .searchBtn {
    margin-top: 0;
  }

  .social-share li {
    margin-left: 0;
  }

  .flickr_feeds li a {
    width: 65px;
  }

  .info-text {
    font-size: 20px;
  }

  .hover-box > img {
    margin: 10px 10px 8px 5px;
  }

  .hover-box > h3 {
    font-size: 18px
  }

  .hover-box > h4 {
    font-size: 14px;
  }

  .screenshot-slider {
    float: none;
  }

  .screenshot-box .left-side {
    float: none;
    margin: 20px;
    width: auto;
  }

  .vertical_tabs .tabbable .nav {
    width: 35%;
  }

  .vertical_tabs .tabbable .tab-content {
    width: 65%;
  }

  .statbox {
    min-height: 90px;
  }

  .statbox h4 {
    font-size: 24px;
  }

  .testimonial_box {
    padding: 0;
  }

  .latest_posts.default-style.kl-style-2 .lp-title {
    height: 215px;
  }

  /* Devices */
  .el-devimages--type-img .el-devimages__laptop {
    height: 416px;
    width: 720px;
  }

  .el-devimages--type-vector .el-devimages__laptop {
    height: 370px;
    width: 720px;
  }

  .el-devimages__frame {
    height: 440px;
    width: 720px;
  }

  .el-devimages--type-vector .el-devimages__smartphone {
    height: 250px;
    width: 125px;
  }

  .el-devimages--type-img .el-devimages__smartphone {
    height: 274px;
    width: 167px;
  }

  .el-devimages--type-vector .el-devimages__smartphone {
    left: 70px;
  }

  .el-devimages--rtl.el-devimages--type-img .el-devimages__smartphone {
    right: 40px;
  }

  .el-devimages--rtl.el-devimages--type-vector .el-devimages__smartphone {
    right: 70px;
  }

  /* Big social */
  .bigsocialblock.count-3 .bigsocialblock__social-icon {
    border-width: 5px;
    bottom: -20px;
    font-size: 60px;
    height: 120px;
    left: -30px;
    line-height: 110px;
    width: 120px;
  }

  .bigsocialblock.count-4 .bigsocialblock__social-icon {
    border-color: transparent;
    border-width: 5px;
    bottom: -20px;
    font-size: 60px;
    height: 120px;
    left: -30px;
    line-height: 110px;
    width: 120px;
  }

  .bigsocialblock.count-3 .bigsocialblock__title,
  .bigsocialblock.count-4 .bigsocialblock__title {
    font-size: 16px;
  }

  /* 5 items */
  .bigsocialblock.count-5 .bigsocialblock__item {
    padding: 15px;
  }

  .bigsocialblock.count-5 .bigsocialblock__title {
    bottom: auto;
    font-size: 15px;
    margin-bottom: 10px;
    opacity: 1;
    position: relative;
    right: 0;
    text-align: right;
    top: auto;
    visibility: visible;
  }

  .bigsocialblock__item:hover .bigsocialblock__title {
    bottom: auto;
  }

  .bigsocialblock.count-5 .bigsocialblock__count,
  .bigsocialblock.count-5 .bigsocialblock__follow {
    bottom: auto;
    font-size: 14px;
    opacity: 1;
    position: relative;
    right: 0;
    text-align: right;
    top: auto;
    transform: none;
    visibility: visible;
  }

  .bigsocialblock.count-5 .bigsocialblock__social-icon {
    border-color: transparent;
    bottom: -10px;
    font-size: 50px;
    height: 100px;
    left: -20px;
    line-height: 90px;
    width: 100px;
  }

  /* Style 2 */
  .bigsocialblock--style2 .bigsocialblock__title {
    bottom: auto;
    margin-bottom: 10px;
    position: relative;
    right: auto;
  }

  /* Process steps */
  .process_steps--style1 {
    display: block;
  }

  .process_steps--style1 .process_steps__intro {
    display: block;
    height: auto;
    width: 100%;
  }

  .process_steps--style2 {
    display: block;
  }

  .process_steps--style2 .process_steps__container {
    width: 100%;
  }

  .process_steps--style2 .process_steps__intro {
    display: block;
    height: auto;
    min-height: 0;
    width: 100%;
  }

  .process_steps--style2 .process_steps__container:before {
    left: -5px;
    padding: 0;
    transform: none;
    width: calc(100% + 10px);
  }

  .process_steps--style2 .process_steps__container:after {
    display: none;
  }

  .process_steps--style2 .process_steps__container .process_steps__step:after {
    transform: none;
  }

  .media-container {
    height: 600px;
  }


  /* Event style */
  .static-content.event-style .or {
    display: none;
  }

  /* Maps panel */
  .kl-contentmaps__panel {
    left: calc((100vw - 750px) / 2);
  }

  /* Maps Height in fullscreen mode */
  .scontent__maps.static-content--fullscreen {
    height: 90vh;
  }

  /* Pricing list */
  .pricing-table-element[data-columns="5"] .plan-column .subscription-price .price {
    font-size: 30px;
  }

  .pricing-table-element[data-columns="5"] .plan-title .inner-cell {
    font-size: 18px;
  }

  /**** end Elements ****/
  /**** Knowledge Base ****/
  .post-info li:first-child {
    margin-left: 0;
  }

}

/** Tablets+  **/
@media (min-width: 768px) {

  .process_box .number span {
    transform: translateY(-50%)
  }

  .process_box[data-align=right] .content {
    text-align: right;
  }

  .process_box .content:before, .process_box .content:after {
    transition: all .25s ease-in-out;
  }

  .process_box .content:before {
    background-color: var(--color-primary);
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 2px;
  }

  .process_box[data-align=left] .content:before {
    left: 240px;
  }

  .process_box[data-align=right] .content:before {
    right: 240px;
  }

  .process_box:hover .content:before {
    width: 5px;
  }

  .process_box .content:after {
    border: 14px solid;
    border-color: transparent;
    content: '';
    margin-top: -14px;
    position: absolute;
    top: 50%;
  }

  .process_box[data-align=left] .content:after {
    border-left-color: var(--color-primary);
    left: 242px;
  }

  .process_box[data-align=right] .content:after {
    border-right-color: var(--color-primary);
    right: 242px;
  }

  .process_box[data-align=left]:hover .content:after {
    left: 245px;
  }

  .process_box[data-align=right]:hover .content:after {
    right: 245px;
  }

  .process_box:after, .process_box .number:before, .process_box .number:after {
    background-color: #F1F1F1 !important;
  }

  .process_box:after {
    bottom: -43px;
    content: '';
    height: 6px;
    position: absolute;
    width: calc(100% - 234px);
  }

  .process_box[data-align=left]:after {
    left: 130px;
  }

  .process_box[data-align=right]:after {
    right: 114px;
  }

  .process_box .number:before, .process_box .number:after {
    content: '';
    height: 50px;
    position: absolute;
    width: 6px;
  }

  .process_box .number:before {
    top: -37px;
  }

  .process_box .number:after {
    bottom: -37px;
  }

  .process_box:first-child .number:before, .process_box.last .number:after, .process_box.last:after {
    content: none;
  }

  .process_box.last {
    margin-bottom: 0
  }

  /* Latest posts */
  .latest_posts.default-style.kl-style-2 .lp-title {
    margin-bottom: 0;
  }

  /* Vertical tabs */
  .vertical_tabs.kl-style-2 .tabbable .nav {
    border-right: 0;
  }

  .vertical_tabs.kl-style-2 .tabbable .nav > li.active > a {
    background-color: #fff;
    background-image: none;
    border-right: 0;
  }

  .vertical_tabs .tabbable .nav {
    float: left;
    width: 20%;
  }

  .vertical_tabs.kl-style-2 .tabbable .nav {
    width: 30%;
  }

  .vertical_tabs .tabbable .tab-content {
    border-left: 1px solid #c2c2c2;
    float: left;
    min-height: 240px;
    padding-left: 30px;
    width: 80%;
  }

  .vertical_tabs.kl-style-2 .tabbable .tab-content {
    min-height: 316px;
    width: 70%;
  }

  /* Immersive video */
  .cd-immersive-video .intro-wrapper {
    display: table;
    height: 100%;
    opacity: 0;
    transition: opacity 1.5s, visibility 1.5s;
    visibility: hidden;
    width: 100%;
  }

  .cd-immersive-video.video-is-loaded .intro-wrapper {
    opacity: 1;
    visibility: visible;
  }

  .cd-loader {
    animation: cd-loading .8s infinite;
    background: #ececec;
    display: block;
    height: 32px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: transform .3s;
    width: 32px;
  }

  .cd-loader.no-animation {
    animation: none;
  }

  .cd-loader.scale-down {
    transform: translateY(-50%) translateX(-50%) scale(0.001);
  }

  .video-is-loaded .cd-loader {
    opacity: 0;
    visibility: hidden;
  }

  .mobileDescriptor {
    display: none;
  }
}


/** Tablets (portrait mode)  **/
@media (min-width: 481px) and (max-width: 768px) {

  .header-search .searchBtn {
    margin-top: 0;
  }


  /**** Elements ****/
  .feature_box .title {
    font-size: 13px;
  }

  .feature_box.feature_box.style2 p {
    font-size: 12px;
  }

  .feature_box.style2 .box {
    min-height: 130px;
  }

  .css3accordion .content > .m_title {
    font-size: 15px;
    line-height: 1.3;
    margin: 8px 0;
  }

  .css3accordion .text {
    font-size: 14px;
    line-height: 1.4;
  }

  .recentwork_carousel li .details h4 {
    font-size: 13px;
  }

  .social-share li {
    margin-left: 0;
  }

  .flickr_feeds li a {
    width: 65px;
  }

  .info-text {
    font-size: 20px;
  }

  .hover-box > img {
    margin: 10px 10px 8px 5px;
  }

  .hover-box > h3 {
    font-size: 18px
  }

  .hover-box > h4 {
    font-size: 14px;
  }

  .screenshot-slider {
    float: none;
  }

  .screenshot-box .left-side {
    float: none;
    margin: 20px;
    width: auto;
  }

  .statbox {
    min-height: 90px;
  }

  .statbox h4 {
    font-size: 24px;
  }

  body .css3panels {
    height: 450px;
  }

  body .css3panels .panel img {
    max-height: 460px;
  }

  body .css3panels .caption {
    top: 300px;
    width: 300px;
  }

  body .css3panels .caption.upper {
    top: 250px;
  }

  .flickr_feeds[data-size="small"] li a {
    width: 50px;
  }

  #skills_diagram .legend {
    right: -20px;
  }

  .feature_box.style3 .box {
    font-size: 12px;
  }

  .how_to_shop > .row {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .contact_form .form-horizontal input[type=text].inputbox {
    width: 200px;
  }

  .scrollbarContainer {
    display: none;
  }

  .media-container__link--style-borderanim2 .media-container__text {
    font-size: 26px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*  end RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}


/** Smartphone landscape mode / Mini Tablet **/
@media (max-width: 767.99px) {

  .pricing-table-element {
    background:transparent;
    border:none;
    flex-wrap: wrap;
  }

  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin-left: -20px;
    margin-right: -20px;
  }

  .dl-horizontal dt {
    clear: none;
    float: none;
    text-align: left;
    width: auto;
  }

  .dl-horizontal dd {
    margin-left: 0;
  }

  .thumbnails {
    margin-left: 0;
  }

  .thumbnails > li {
    float: none;
    margin-left: 0;
  }

  .input-large,
  .input-xlarge,
  .input-xxlarge,
  .uneditable-input {
    display: block;
    min-height: 30px;
    width: 100%;
  }

  .input-prepend input,
  .input-append input {
    display: inline-block;
    width: auto;
  }

  #support_p:checked + .support_panel {
    text-align: center;
  }

  .how_to_shop_link + a {
    display: block;
    margin-bottom: 34px;
  }

  .even_ends>div:nth-child(2) {
    padding-left:0;
    padding-right:0
  }

  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
  /**** Header ****/
  body #header.site-header {
    height: auto !important;
    position: relative !important;
  }

  body.sticky-header #header.site-header {
    position: fixed !important;
  }

  .logo-container.hasInfoCard:before,
  .logo-container .logo-infocard {
    display: none;
  }

  .zn_menu_trigger {
    background: rgba(228, 228, 228, 0.25);
    cursor: pointer;
    display: block;
    padding: 5px 0;
    text-align: center;
  }


  .separator.visible-xxs {
    display: block !important;
  }

  /**** Sub-Header ****/
  .subheader-currentdate {
    display: none;
  }

  [dir="ltr"] .subheader-titles {
    text-align: left;
  }

  [dir="rtl"] .subheader-titles {
    text-align: right;
  }

  .subheader-titles {
    margin-top: 0; /*margin-bottom: 20px;*/
  }

  .breadcrumbs {
    margin-bottom: 20px;
    padding: 4px 8px 3px;
  }

  .breadcrumbs li {
    font-size: 0.8rem;
    font-weight: 400;
  }

  .page-subheader .ph-content-wrap {
    height: auto !important;
    min-height: 300px;
    padding-top: 0;
  }

  .page-subheader[class*="maskcontainer--"] {
    padding-bottom: 0;
  }

  /**** end Sub-Header ****/
  /**** Elements ****/
  .recentwork_carousel--2 .recentwork_carousel__controls,
  .recentwork_carousel_v3 .recentwork_carousel__controls {
    opacity: 1;
  }

  /* Buttons */
  .btn-fullwidth {
    width: 50%;
  }

  .circlehover.style2 {
    width: 35%;
  }

  .rev_slider,
  .rev_slider_wrapper {
    height: 600px;
  }

  .screenshot-box .screenshot-slider {
    overflow: hidden !important;
  }

  .imgbox_image.cover-fit-img {
    height: 100%;
  }

  .imgboxes_style1 .hoverBorderWrapper,
  .imgboxes_style2 .imgboxes-wrapper,
  .imgboxes_style4 .imgboxes-wrapper {
    height: auto;
  }

  .buybtn-panel {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .buybtn-cta {
    font-size: 12px;
    padding: 5px 12px;
  }

  .buybtn-close {
    height: 50px;
    line-height: 47px;
  }

  .kl-slideshow.iosslider-slideshow {
    padding-bottom: 84%;
  }

  #action_box {
    height: auto;
    text-align: center;
  }

  #action_box h4.text {
    font-size: 16px;
  }

  .action_box.style3 {
    margin-top: 50px !important;
  }

  .kl-contentmaps__panel {
    display: none;
  }

  body .testimonial_box {
    margin-bottom: 20px;
    width: 100% !important;
  }

  .hboxes {
    margin-bottom: 30px;
  }

  .image-boxes .box {
    text-align: center;
  }

  .hg-portfolio-sortable ul#thumbs li {
    width: 50% !important;
  }

  .testimonial_slider__carousel-wrapper {
    margin: 0 20px;
  }

  .hg-portfolio-sortable #sorting {
    float: left;
    margin-bottom: 15px;
  }

  .hg-portfolio-sortable #portfolio-nav li {
    float: left;
    width: 50%;
  }

  .hg-portfolio-sortable #portfolio-nav,
  .hg-portfolio-sortable #portfolio-nav li a {
    width: 100%;
  }

  /* Slideshow */
  .static-content__wrapper {
    min-height: 400px !important;
  }

  /* Google maps */
  .scontent__maps:not(.static-content--fullscreen),
  .scontent__maps:not(.static-content--fullscreen) .th-google_map {
    height: auto;
    max-height: 400px;
  }

  /* Call out Banner */
  .callout-banner .col-sm-10 {
    margin-bottom: 20px;
  }


  /* Latests Posts Accordion style */
  .latest_posts-acc-elm-title {
    margin: 0 0 20px;
    position: static;
  }

  .latest_posts-acc-details {
    display: block;
  }

  .latest_posts-acc-item,
  .latest_posts-acc-item:hover,
  .latest_posts-acc-item.last,
  .latest_posts-acc-item.last:hover {
    float: none;
    margin-right: 0;
    padding: 20px 0;
    width: 100% !important;
  }

  .latest_posts-acc-inner,
  .latest_posts-acc-item:hover ~ .latest_posts-acc-item > .latest_posts-acc-inner {
    width: 100% !important;
  }

  .keywordbox {
    line-height: 1.4;
  }

  .latest_posts.default-style a + em {
    display: block;
  }

  .latest_posts.style2 ul.posts .details {
    float: none;
    margin-bottom: 25px;
  }

  .latest_posts.style2 ul.posts .text {
    margin-left: 0;
  }

  .infobox2 .btn {
    margin-left: 20px;
  }

  .process_box .number {
    position: relative;
    width: 100%;
  }

  .process_box .content {
    margin-left: 0;
  }

  .process_box[data-align="right"] .content {
    margin-right: 0;
  }

  .span6 + .span12.end_timeline {
    margin-bottom: 0;
  }

  .timeline_box:hover::before, .timeline_box::after {
    background: transparent;
    border: 0;
  }

  .team_member {
    clear: both;
    padding-left: 10px;
    padding-top: 10px;
  }

  .team_member:before, .team_member:after {
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
  }

  .team_member:after {
    clear: both;
  }

  .team_member a.grayHover {
    display: inline-block;
    float: left;
    margin-right: 20px;
    max-width: 130px;
    overflow: hidden;
    position: relative;
  }

  .team_member .details {
    background: none;
    box-shadow: none;
    float: left;
    opacity: 1;
    overflow: visible;
    padding: 10px 0 0;
    position: static;
    visibility: visible;
    width: auto;
  }

  #skills_diagram,
  .timeline_box:hover::before, .timeline_box::after {
    display: none !important;
  }

  div[data-align=right] .timeline_box .date,
  .timeline_box .date {
    left: 30%;
    padding: 5px 0;
    right: 30%;
    text-align: center;
    top: -39px;
    width: 40%;
  }

  div[data-align=right] .timeline_box:before,
  .timeline_box:before {
    display: none;
  }

  .timeline_box,
  div[data-align="right"] .timeline_box {
    margin: 76px 0
  }

  .vertical_tabs .tabbable .nav > li > a {
    text-align: left;
  }

  .vertical_tabs .tabbable .nav > li > a > span {
    float: left;
  }

  .bubble-box {
    bottom: 0;
  }

  .bubble-box.bb--anim-show {
    border-radius: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    z-index: 11111;
  }

  .ads-box .pp-adimg {
    float: left;
    max-width: 100px;
  }

  .iosslider-slideshow .iosSlider,
  .static-content__source {
    transform: none !important;
  }

  .services_box--boxed:hover {
    z-index: 3;
  }

  .services_box--boxed .services_box__list {
    padding-top: 250px !important;
  }

  .container .process_steps--style2 .process_steps__intro {
    border-top-right-radius: 5px;
  }

  .process_steps__intro-wrp {
    margin-bottom: 30px
  }

  .bigsocialblock__title {
    margin-bottom: 0;
  }

  .photo-wall .pw-details [data-role="title"] {
    line-height: 24px;
  }

  .kl-slideshow .kl-slideshow-safepadding {
    padding-top: 75px !important;
  }

  .flickrfeed {
    margin-bottom: 30px;
    overflow: hidden;
  }

  .hover-box.hover-box-2 {
    margin-bottom: 15px;
  }

  .info-text {
    margin-bottom: 25px;
  }

  .full_width {
    padding-left: 0;
    padding-right: 0;
  }


  .hg_section--relative.ptop-210 {
    padding-top: 70px !important;
  }

  .team_member,
  .team_member:hover {
    margin-bottom: 20px;
    padding: 20px;
  }

  .vertical_tabs.kl-style-2 .tabbable {
    flex-direction: column;
  }

  .vertical_tabs.kl-style-2 .tabbable .nav > li > a {
    margin-right: 1px;
  }

  .vertical_tabs.kl-style-2 .tabbable .nav > li:first-child a {
    border-top-right-radius: 3px;
  }

  .vertical_tabs.kl-style-2 .tabbable .nav > li:last-child a {
    border-bottom-right-radius: 3px;
  }

  .imgboxes_style4 .imgboxes-wrapper {
    display: block;
  }

  .login-panel .create_account {
    margin-bottom: 10px;
  }

  .login-panel .form-control {
    margin-bottom: 10px;
  }

  .error404-content h2 {
    font-size: 160px;
  }

  .pos-vertical-middle > .container {
    top: 100px;
  }

  .photogallery-widget .caroufredsel {
    width: 100%;
  }

  .photogallery-widget .cfs--navigation {
    width: 100%;
  }

  .photogallery-widget .cfs--pagination {
    height: auto;
    position: static;
    width: 100%;
  }

  .photogallery-widget .pg-thumb,
  .photogallery-widget .pg-thumb:nth-child(9):last-child {
    height: auto;
    width: 20%;
  }

  .testimonial_slider.quoter-style {
    margin: 0 30px 20px;
  }

  .media-container__link--style-borderanim2 .media-container__text {
    font-size: 18px;
  }


  /* Counter number */
  .jsf-block {
    display: block;
    margin-bottom: 30px;
  }

  .fun-fact .fun-thing {
    font-size: 13px;
    margin-top: 25px;
  }

  .section-video-icons .kl-video img {
    height: 50% !important;
  }

  /* Devices */
  .el-devimages--type-img .el-devimages__laptop {
    height: 0;
    padding-bottom: 57.77%;
    width: 100%;
  }

  .el-devimages--type-vector .el-devimages__laptop {
    height: 0;
    padding-bottom: 51%;
    width: 100%;
  }

  .el-devimages__frame {
    height: 0;
    padding-bottom: 63%;
    width: 100%;
  }

  .el-devimages__smartphone {
    display: none;
  }

  /* Search Box */
  .elm-searchbox__submit {
    width: 130px !important;
  }

  .elm-searchbox__input {
    width: calc(100% - 130px) !important;
  }

  /* Big social */
  .bigsocialblock {
    flex-direction: column;
  }

  .bigsocialblock__item {
    display: block;
    min-height: 100px;
    padding: 15px;
    width: 100%;
  }

  .bigsocialblock__title {
    bottom: auto;
    margin-bottom: 10px;
    opacity: 1;
    position: relative;
    right: 0;
    text-align: right;
    top: auto;
    visibility: visible;
  }

  .bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__title {
    bottom: auto;
  }

  .bigsocialblock__count,
  .bigsocialblock__follow {
    bottom: auto;
    opacity: 1;
    position: relative;
    right: 0;
    text-align: right;
    top: auto;
    transform: none;
    visibility: visible;
  }

  .bigsocialblock--style2 .bigsocialblock__item {
    height: 120px;
  }

  /* Grid icon boxes */
  .grid-ibx--cols-3 .grid-ibx__item,
  .grid-ibx--cols-4 .grid-ibx__item,
  .grid-ibx--cols-5 .grid-ibx__item {
    width: 100%;
    border: none;
  }

  .grid-ibx--style-lined-gradient.grid-ibx--cols-3 .grid-ibx__item,
  .grid-ibx--style-lined-gradient.grid-ibx--cols-4 .grid-ibx__item,
  .grid-ibx--style-lined-gradient.grid-ibx--cols-5 .grid-ibx__item {
    width: calc(50% - 30px);
  }

  /* Process steps */
  .process_steps--style1 {
    display: block;
  }

  .process_steps--style1 .process_steps__intro {
    display: block;
    height: auto;
    width: 100%;
  }

  .process_steps--style2 {
    display: block;
  }

  .process_steps--style2 .process_steps__container {
    width: 100%;
  }

  .process_steps--style2 .process_steps__step {
    margin-bottom: 30px;
    width: 100%;
  }

  .process_steps--style2 .process_steps__intro {
    display: block;
    float: none;
    height: auto;
    margin-bottom: 0;
    min-height: 0;
    padding: 30px;
    width: 100%;
  }

  .process_steps--style2 .process_steps__container:before {
    left: -5px;
    padding: 0;
    transform: none;
    width: calc(100% + 10px);
  }

  .process_steps--style2 .process_steps__container:after,
  .process_steps--style2 .process_steps__container .process_steps__step:after {
    display: none;
  }

  .process_box4 {
    display: block;
    margin-bottom: 50px;
  }

  .statistic-box--stl-style1 .statistic-box,
  .statistic-box--stl-style2 .statistic-box {
    display: block;
    float: none;
    width: 100%;
  }

  .vertical_tabs .tabbable > .nav {
    margin-bottom: 25px;
  }

  /* Screenshot box */
  .screenshot-box .left-side {
    padding: 0 30px 25px 30px;
  }

  .screenshot-box .left-side h3.title {
    text-align: center;
  }

  .screenshot-box .left-side > .btn {
    display: block;
    margin-left: 0;
  }

  .screenshot-box .screenshot-slider {
    margin-top: 0;
  }

  /* Partners & Testimonials */
  .ts-pt-testimonials__item--size-1,
  .ts-pt-testimonials__item--size-2,
  .ts-pt-testimonials__item--size-3 {
    width: calc(100% - 50px);
  }

  .media-container {
    height: 400px;
    min-height: 400px !important;
  }

  .kl-skills-diagram {
    width: 100%;
  }

  /* Recent work carousel */
  .recentwork_carousel--2 .recentwork_carousel__left {
    margin-bottom: 30px;
  }

  .recent_works3 .carousel-item--height {
    height: auto;
  }

  /* Static content */
  .static-content__title,
  .static-content__subtitle {
    word-break: normal;
  }

  /* Boxes */
  .sc__boxes {
    margin-top: 40px;
  }

  .sc__boxes .static-content__infopop {
    margin: 10px 15px;
  }


  /* Textpop */
  .textpop__line1 {
    font-size: 18px;
  }

  .textpop__line2 {
    font-size: 42px;
  }

  .textpop__line3 {
    font-size: 32px;
  }

  .textpop__line4 {
    font-size: 24px;
  }

  /* Video background */
  .video-container .captions .line {
    font-size: 26px;
  }

  /* Event style */
  .static-content.event-style .ud_counter {
    text-align: center;
  }

  .static-content.event-style .till_lauch {
    margin: 0;
  }

  .static-content.event-style .ud_counter ul li {
    font-size: 18px;
    margin-bottom: 20px;
    margin-right: 0;
    padding: 15px 10px;
    width: 100%;
  }

  .static-content.event-style .static-content__subtitle {
    font-size: 2rem;
    text-align: center;
  }

  .static-content.event-style .sc_counter {
    text-align: center;
    width: 100%;
  }

  .static-content.event-style .mail_when_ready {
    display: block;
    float: none;
    margin-bottom: 15px;
    text-align: center;
  }

  .static-content.event-style .or {
    clear: left;
    display: block;
    float: none;
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
  }

  .static-content.event-style .social-icons {
    display: block;
    text-align: center;
  }

  /* Static Content - Weather box */
  .sc--weatherbox .static-content__wrapper {
    height: auto !important;
  }

  .sc__actionarea .btn.btn-third {
    width: auto;
  }

  /* Weather list */
  ul.scw_list {
    margin: 50px 10px 0;
  }

  ul.scw_list li {
    width: 25%;
  }

  .scw_list .wt-icon {
    font-size: 50px;
    height: 40px;
  }

  .scw__degs {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .scw__high {
    font-weight: 400
  }

  .scw__day {
    font-size: 18px;
  }

  .scw__alt {
    font-size: 13px;
  }

  .scw_list li:first-child {
    display: block;
    margin-top: -35px;
    width: 100%;
  }

  .scw_list li:first-child .wt-icon {
    font-size: 160px;
    height: 125px;
    margin-bottom: 10px;
  }

  .scw_list li:first-child .scw__degs {
    font-size: 30px;
  }

  .scw_list li:first-child .scw__high {
    font-weight: 700;
  }

  .scw_list li:first-child .scw__day {
    font-size: 28px;
  }

  .scw_list li:first-child .scw__alt {
    font-size: 18px;
  }

  /* Reservation form */
  .sc__res-form {
    margin: 70px auto 0;
    width: 95%;
  }

  /* Showroom Carousel */
  .sc-huge-arrow {
    display: none;
  }

  .sc--showroomcrs-style .sc__textcontent h2 {
    font-size: 3rem;
  }

  .sc--showroomcrs-style {
    max-width: 100%;
  }

  /* Maps panel */
  .kl-contentmaps__panel {
    border-radius: 0;
    position: static;
    width: 100%;
  }

  .scontent__maps.static-content--fullscreen {
    height: 90vh;
  }


  /* Info box */
  .infobox3 {
    padding: 50px 30px;
  }

  /* Contact form pop-up element (Get a quote) */
  .contact-popup {
    width: 100%;
  }

  .contact-popup-panel .contactForm {
    padding: 20px;
  }

  .contact-popup-panel .contactForm form {
    padding: 0;
  }

  /**** end Elements ****/
  /**** Footer ****/
  #footer .menu:before,
  #footer .menu:after {
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
  }

  #footer .menu:after {
    clear: both;
  }

  #footer .social-icons {
    float: none;
    margin-bottom: 30px;
    margin-left: 0;
    padding-left: 0;
  }

  #footer .copyright img {
    margin-bottom: 20px;
  }

  /**** Knowledge Base ****/
  .post-info li:first-child {
    margin-left: 0;
  }

  /**** end Knowledge Base ****/
  /**** Coming soon page ****/
  .offline-page .containerbox {
    margin-top: 40px;
    width: 100%;
  }

  .offline-page .containerbox__logo {
    height: auto;
    padding-bottom: 20px;
    position: static;
    width: 100%;
  }

  .offline-page .containerbox .site-logo {
    float: none;
    top: auto;
    transform: none;
    width: 100%;
  }

  .offline-page .containerbox .content {
    border-left: 0;
    border-top: 1px solid #777;
    clear: both;
    margin: 0;
    padding: 40px 0 0;
  }

  .offline-page .mail_when_ready {
    float: none;
  }

  .offline-page .ud_counter ul {
    width: 100%;
  }

  .offline-page .ud_counter ul li {
    display: block;
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .offline-page .ud_counter .till_lauch {
    height: 70px;
    margin: 10px 0;
    vertical-align: middle;
    width: 100%;
  }

  .offline-page .till_lauch img {
    max-height: 100%;
  }

  .desktopDescriptor {
    display:none
  }

  .grid-ibx--cols-2 .grid-ibx__item {
    width:100%
  }
}


/** Smartphone landscape mode / Mini Tablet **/
@media (max-width: 600px) {
  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
  /**** Header ****/
  .site-header .kl-header-toptext {
    display: none;
  }

  /**** Elements ****/
  .tabs_style5 > ul.nav {
    border-bottom: none;
  }

  .tabs_style5 > ul.nav > li > a {
    border-bottom: 2px solid transparent;
  }

  .tabbable > ul.nav > li {
    display: block;
    float: none;
    width: 100%;
  }

  .tabs_style5 > ul.nav > li:first-child > a {
    padding: 8px 20px;
  }

  .tabs_style2 > ul.nav > li:first-child a,
  .tabs_style5 > ul.nav > li a,
  .tabs_style2 > ul.nav > li a,
  .tabs_style3 > ul.nav > li > a,
  .tabs_style4 > ul.nav > li > a,
  .tabs_style1 > ul.nav > li > a {
    display: block;
    padding: 12px 20px;
    width: 100%;
  }

  .tabs_style4 > ul.nav {
    border: solid 1px #ddd;
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .tabs_style4 > ul.nav > li > a.active {
    border: none;
  }

  .symbol-line::before,
  .symbol-line::after {
    width: 47%;
  }

  /* Services box */
  .services_box {
    width: 100%;
  }

  /**** end Elements ****/
  /**** Knowledge Base ****/
  .post-info li {
    display: block;
  }

  /**** end Knowledge Base ****/
  .kl-store-page ul.products li.product {
    width: 100%;
  }


  /*-----------------------------------------------------------------------------------*/
  /*  end RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}


/** Smartphone portrait mode **/
@media (max-width: 480px) {

  .visible-xxs {
    display: block !important;
  }

  .hidden-xxs {
    display: none !important;
  }

  .static-content__source .kl-bg-source__bgimage {
    background-position: 64%!important;
  }

  .nav-collapse {
    transform: translate3d(0, 0, 0);
  }

  .site-logo {
    max-width: 100%;
    width: 129px;
  }

  .topnav > .topnav-item span, .topnav .topnav-item {
    font-size:.7rem
  }

  .site-header .site-header-top {
    padding: 10px 0 4px;
  }

  .loginbox-popup {
    width: 100%;
  }

  .zn-res-trigger {
    margin-left: 0;
    margin-top: 11px;
  }

  .action_box_content .ac-buttons a {
    margin-bottom: 10px;
  }

  .header-leftside-container {
    overflow: visible;
  }

  .topnav li.drop .inner.cart-container {
    position: absolute;
    right: 0;
    width: 230px;
  }

  .cart-container .cart_list {
    margin: 0 0 7px !important;
  }

  .cart-container .cart_list li {
    text-align: left;
  }

  .cart-container .cart_list li a {
    line-height: 13px;
  }

  .cart-container .cart_list li img {
    width: 59px !important;
  }

  .cart-container .buttons .button.wc-forward {
    font-size: 9px;
  }

  .cart-container .buttons .button.checkout {
    font-size: 9px;
    padding: 9px 20px;
  }

  .cart-container .buttons .button {
    width: 48%;
  }

  .cart-container .total {
    padding: 0 0 6px;
  }


  /*** Header Styles ***/
  .site-header.kl-cta-lined {
    display: none;
  }

  .kl-iconbox--fright .kl-iconbox__icon-wrapper,
  .kl-iconbox--fleft .kl-iconbox__icon-wrapper {
    float: none;
  }

  .zn_post_image {
    margin-right: 0;
    width: 100%;
  }

  .page-header h1 small {
    display: block;
    line-height: 20px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }

  .form-horizontal .control-label {
    float: none;
    padding-top: 0;
    text-align: left;
    width: auto;
  }

  .form-horizontal .controls {
    margin-left: 0;
  }

  .form-horizontal .control-list {
    padding-top: 0;
  }

  .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }

  .media .pull-left,
  .media .pull-right {
    display: block;
    float: none;
    margin-bottom: 10px;
  }

  .media-object {
    margin-left: 0;
    margin-right: 0;
  }

  .modal {
    left: 10px;
    right: 10px;
    top: 10px;
  }

  .modal-header .close {
    margin: -10px;
    padding: 10px;
  }

  .carousel-caption {
    position: static;
  }

  .css3accordion > ul > li .thumb {
    float: none;
    width: auto;
  }

  .social-share li {
    margin-bottom: 10px;
  }

  .infobox2 {
    text-align: center;
  }

  .infobox2 .btn {
    margin: 0 0 10px;
  }

  .action_box_content .ac-buttons a {
    margin-bottom: 10px;
  }

  .symbol-line::before,
  .symbol-line::after {
    width: 46%;
  }

  .hg-portfolio-sortable ul#thumbs li {
    width: 100% !important
  }

  .eBlog .featured-post .zn_sticky_no_image {
    min-height: 200px;
  }

  .eBlog .featured-post .itemFeatContent {
    padding: 10px 15px;
  }

  .eBlog .featured-post .itemTitle, .eBlog .itemTitle {
    font-size: 16px;
    line-height: 1.6;
    margin: 0 0 10px
  }

  .eBlog .featured-post .itemHeader {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  /* Latest posts */
  .latest_posts.default-style.kl-style-2 .m_title {
    font-size: 10px;
    margin-bottom: 0;
  }

  .latest_posts.default-style.kl-style-2 .post:not(.lp-title) .m_title:after {
    content: none;
  }

  .latest_posts.default-style.kl-style-2 .post-details em {
    display: none;
  }

  /* Grid icon boxes */
  .grid-ibx--cols-2 .grid-ibx__item,
  .grid-ibx--cols-3 .grid-ibx__item,
  .grid-ibx--cols-4 .grid-ibx__item,
  .grid-ibx--cols-5 .grid-ibx__item {
    float: none !important;
    margin-left: 0;
    width: 100% !important;
  }

  /* Grid photo gallery */
  [class*='gridPhotoGalleryItem--w'] {
    width: 100% !important;
  }

  .gridPhotoGallery__link:after {
    font-size: 13px;
  }


  /* Static content */
  /* Event style */
  .static-content.event-style .sc_counter {
    text-align: center;
    width: 100%;
  }

  .static-content.event-style .static-content__subtitle {
    text-align: center;
  }

  .static-content.event-style .ud_counter ul li {
    width: 19%;
  }

  .static-content.event-style .ud_counter ul li:last-child {
    margin-right: 0;
  }

  .static-content.event-style .mail_when_ready {
    display: block;
    float: none;
    margin-bottom: 15px;
    text-align: center;
  }

  .static-content.event-style .or {
    clear: left;
    display: block;
    float: none;
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
  }

  .static-content.event-style .social-icons {
    text-align: center;
  }

  /* Reservation form */
  .rf__block {
    border-radius: 3px 3px 0 0;
    float: none;
    padding: 20px 15px;
    width: 100%;
  }

  .rfblock--submit {
    padding: 0px 15px;
  }

  .rfblock--fields .rf__field {
    margin-left: 15px;
    padding-left: 15px;
  }

  .rfblock--fields .rf__field:first-child {
    width: calc(50% - 10px);
  }

  .rf__submit {
    border-radius: 0 0 3px 3px;
    height: 60px;
    width: 100%;
  }

  .rf__submit:after {
    color: rgba(255, 255, 255, 0.25);
    font-size: 38px;
    line-height: 60px;
  }

  /* Login panel */
  .auth-popup {
    width: 100%;
  }

  .testimonial_fader__carousel .testimonials_fader__carousel-item:after {
    font-size: 100px;
  }

  .testimonial_fader__carousel .testimonials_fader__carousel-item {
    padding: 0 0 20px 80px;
  }

  /* Recent work element */
  .recentwork_carousel__crsl .recent-work_carousel-item {
    padding: 0;
  }

  /**** end Elements ****/
  /**** KnowledgeBase ****/
  .kb-category--related-articles li {
    overflow: hidden;
  }

  .kb-category--related-articles .likes,
  .kb-category--related-articles .views {
    clear: right;
  }

  /*-----------------------------------------------------------------------------------*/
  /*  end RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}


/** Normal phone portrait mode+ **/
@media (min-width: 320px) {
  /*-----------------------------------------------------------------------------------*/
  /*  RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
  /**** Elements ****/
  /* Action box */
  .action_box .ac-btn {
    margin: 0 5px;
  }

  /**** end Elements ****/
  /*-----------------------------------------------------------------------------------*/
  /*  end RangerDT EDIT
   /*-----------------------------------------------------------------------------------*/
}

/* FIX IE10 MOBILE  1.6 */
@-webkit-viewport {
  width: device-width
}

@-moz-viewport {
  width: device-width
}

@-ms-viewport {
  width: device-width
}

@-o-viewport {
  width: device-width
}

@viewport {
  width: device-width
}


/*
 * Margins Classes
 * Helper classes for side margins
 */
.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mt-250 {
  margin-top: 250px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.mb-250 {
  margin-bottom: 250px !important;
}


/*
 * Margins Classes - Negative
 * Helper classes for side margins on negative
 */
.mnl-10 {
  margin-left: -10px !important;
}

.mnl-20 {
  margin-left: -20px !important;
}

.mnl-30 {
  margin-left: -30px !important;
}

.mnl-40 {
  margin-left: -40px !important;
}

.mnl-50 {
  margin-left: -50px !important;
}

.mnl-60 {
  margin-left: -60px !important;
}

.mnl-70 {
  margin-left: -70px !important;
}

.mnl-80 {
  margin-left: -80px !important;
}

.mnl-90 {
  margin-left: -90px !important;
}

.mnl-100 {
  margin-left: -100px !important;
}

.mnl-110 {
  margin-left: -110px !important;
}

.mnl-120 {
  margin-left: -120px !important;
}

.mnl-130 {
  margin-left: -130px !important;
}

.mnl-140 {
  margin-left: -140px !important;
}

.mnl-150 {
  margin-left: -150px !important;
}

.mnr-10 {
  margin-right: -10px !important;
}

.mnr-20 {
  margin-right: -20px !important;
}

.mnr-30 {
  margin-right: -30px !important;
}

.mnr-40 {
  margin-right: -40px !important;
}

.mnr-50 {
  margin-right: -50px !important;
}

.mnr-60 {
  margin-right: -60px !important;
}

.mnr-70 {
  margin-right: -70px !important;
}

.mnr-80 {
  margin-right: -80px !important;
}

.mnr-90 {
  margin-right: -90px !important;
}

.mnr-100 {
  margin-right: -100px !important;
}

.mnr-110 {
  margin-right: -110px !important;
}

.mnr-120 {
  margin-right: -120px !important;
}

.mnr-130 {
  margin-right: -130px !important;
}

.mnr-140 {
  margin-right: -140px !important;
}

.mnr-150 {
  margin-right: -150px !important;
}

.mnt-10 {
  margin-top: -10px !important;
}

.mnt-20 {
  margin-top: -20px !important;
}

.mnt-30 {
  margin-top: -30px !important;
}

.mnt-40 {
  margin-top: -40px !important;
}

.mnt-50 {
  margin-top: -50px !important;
}

.mnt-60 {
  margin-top: -60px !important;
}

.mnt-70 {
  margin-top: -70px !important;
}

.mnt-80 {
  margin-top: -80px !important;
}

.mnt-90 {
  margin-top: -90px !important;
}

.mnt-100 {
  margin-top: -100px !important;
}

.mnt-110 {
  margin-top: -110px !important;
}

.mnt-120 {
  margin-top: -120px !important;
}

.mnt-130 {
  margin-top: -130px !important;
}

.mnt-140 {
  margin-top: -140px !important;
}

.mnt-150 {
  margin-top: -150px !important;
}

.mnb-10 {
  margin-bottom: -10px !important;
}

.mnb-20 {
  margin-bottom: -20px !important;
}

.mnb-30 {
  margin-bottom: -30px !important;
}

.mnb-40 {
  margin-bottom: -40px !important;
}

.mnb-50 {
  margin-bottom: -50px !important;
}

.mnb-60 {
  margin-bottom: -60px !important;
}

.mnb-70 {
  margin-bottom: -70px !important;
}

.mnb-80 {
  margin-bottom: -80px !important;
}

.mnb-90 {
  margin-bottom: -90px !important;
}

.mnb-100 {
  margin-bottom: -100px !important;
}

.mnb-110 {
  margin-bottom: -110px !important;
}

.mnb-120 {
  margin-bottom: -120px !important;
}

.mnb-130 {
  margin-bottom: -130px !important;
}

.mnb-140 {
  margin-bottom: -140px !important;
}

.mnb-150 {
  margin-bottom: -150px !important;
}


/*
 * Padding Classes
 * Helper classes for side padding
 */
.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pt-250 {
  padding-top: 250px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pb-250 {
  padding-bottom: 250px !important;
}

.p-50 {
  padding: 50px;
}


/*
 * Font-sizes-presets
 */
.fs-smaller {
  font-size: 0.6rem;
  line-height: 1.6;
}

.fs-small {
  font-size: 0.8rem;
  line-height: 1.6;
}

.fs-xn {
  font-size: 0.9rem;
  line-height: 1.7;
}

.fs-normal {
  font-size: 1rem;
  line-height: 1.7;
}

.fs-s {
  font-size: 1.2rem;
  line-height: 1.6;
}

.fs-m {
  font-size: 1.4rem;
  line-height: 1.4;
}

.fs-l {
  font-size: 1.6rem;
  line-height: 1.4;
}

.fs-xl {
  font-size: 1.9rem;
  line-height: 1.3;
}

.fs-xxl {
  font-size: 2.2rem;
  line-height: 1.3;
}

.fs-larger {
  font-size: 2.6rem;
  line-height: 1.3;
}

.fs-xxxl {
  font-size: 3rem;
  line-height: 1.3;
}

.fs-xxxxl {
  font-size: 4rem;
  line-height: 1.3;
}

.fs-jumbo {
  font-size: 5rem;
  line-height: 1.4;
}

.fs-crazy {
  font-size: 12rem;
}


/*
 * Height Sizing Presets
 * These are height values from 10 to 10 to set responsive height to a block
 */
.hsize-reset {
  height: 0 !important;
}

.hsize-10 {
  height: 10px;
}

.hsize-20 {
  height: 20px;
}

.hsize-30 {
  height: 30px;
}

.hsize-40 {
  height: 40px;
}

.hsize-50 {
  height: 50px;
}

.hsize-60 {
  height: 60px;
}

.hsize-70 {
  height: 70px;
}

.hsize-80 {
  height: 80px;
}

.hsize-90 {
  height: 90px;
}

.hsize-100 {
  height: 100px;
}

.hsize-110 {
  height: 110px;
}

.hsize-120 {
  height: 120px;
}

.hsize-130 {
  height: 130px;
}

.hsize-140 {
  height: 140px;
}

.hsize-150 {
  height: 150px;
}

.hsize-160 {
  height: 160px;
}

.hsize-170 {
  height: 170px;
}

.hsize-180 {
  height: 180px;
}

.hsize-190 {
  height: 190px;
}

.hsize-200 {
  height: 200px;
}

.hsize-210 {
  height: 210px;
}

.hsize-220 {
  height: 220px;
}

.hsize-230 {
  height: 230px;
}

.hsize-240 {
  height: 240px;
}

.hsize-250 {
  height: 250px;
}

.hsize-260 {
  height: 260px;
}

.hsize-270 {
  height: 270px;
}

.hsize-280 {
  height: 280px;
}

.hsize-290 {
  height: 290px;
}

.hsize-300 {
  height: 300px;
}

.hsize-310 {
  height: 310px;
}

.hsize-320 {
  height: 320px;
}

.hsize-330 {
  height: 330px;
}

.hsize-340 {
  height: 340px;
}

.hsize-350 {
  height: 350px;
}

.hsize-360 {
  height: 360px;
}

.hsize-370 {
  height: 370px;
}

.hsize-380 {
  height: 380px;
}

.hsize-390 {
  height: 390px;
}

.hsize-400 {
  height: 400px;
}

.hsize-410 {
  height: 410px;
}

.hsize-420 {
  height: 420px;
}

.hsize-430 {
  height: 430px;
}

.hsize-440 {
  height: 440px;
}

.hsize-450 {
  height: 450px;
}

.hsize-460 {
  height: 460px;
}

.hsize-470 {
  height: 470px;
}

.hsize-480 {
  height: 480px;
}

.hsize-490 {
  height: 490px;
}

.hsize-500 {
  height: 500px;
}

.hsize-510 {
  height: 510px;
}

.hsize-520 {
  height: 520px;
}

.hsize-530 {
  height: 530px;
}

.hsize-540 {
  height: 540px;
}

.hsize-550 {
  height: 550px;
}

.hsize-560 {
  height: 560px;
}

.hsize-570 {
  height: 570px;
}

.hsize-580 {
  height: 580px;
}

.hsize-590 {
  height: 590px;
}

.hsize-600 {
  height: 600px;
}

.hsize-610 {
  height: 610px;
}

.hsize-620 {
  height: 620px;
}

.hsize-630 {
  height: 630px;
}

.hsize-640 {
  height: 640px;
}

.hsize-650 {
  height: 650px;
}

.hsize-660 {
  height: 660px;
}

.hsize-670 {
  height: 670px;
}

.hsize-680 {
  height: 680px;
}

.hsize-690 {
  height: 690px;
}

.hsize-700 {
  height: 700px;
}


/*
 * Float None
 */
.pull-none {
  float: none !important;
}


/*
  * Float Clearing Presets
  */
.cleft {
  clear: left;
}

.cright {
  clear: right;
}

.cboth {
  clear: both;
}


/*
  * Width presets
  */
.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}


/* Font weight helpers */
.fw-vthin {
  font-weight: 100;
}

.fw-thin {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}


/* Letter spacing */
.lt-spacing-1 {
  letter-spacing: 1px;
}

.lt-spacing-2 {
  letter-spacing: 2px;
}

.lt-spacing-3 {
  letter-spacing: 3px;
}

.lt-spacing-5 {
  letter-spacing: 5px;
}

.lt-spacing-10 {
  letter-spacing: 10px;
}

/* Letter spacing - Negative */
.lt-spacing-neg-1 {
  letter-spacing: -1px;
}

.lt-spacing-neg-2 {
  letter-spacing: -2px;
}

.lt-spacing-neg-3 {
  letter-spacing: -3px;
}

.lt-spacing-neg-5 {
  letter-spacing: -5px;
}

.lt-spacing-neg-10 {
  letter-spacing: -10px;
}


/* Line Height */
.lh-22 {
  line-height: 22px;
}

.lh-26 {
  line-height: 26px;
}

.lh-30 {
  line-height: 30px;
}

.lh-32 {
  line-height: 32px;
}

.lh-34 {
  line-height: 34px;
}

.lh-38 {
  line-height: 38px;
}

.lh-44 {
  line-height: 44px;
}

.lh-46 {
  line-height: 46px;
}

.lh-52 {
  line-height: 52px;
}

.lh-62 {
  line-height: 62px;
}


/* Opacity helpers */
.opacity0,
.hov-opacity0:hover {
  opacity: 0;
}

.opacity1,
.hov-opacity1:hover {
  opacity: .1;
}

.opacity2,
.hov-opacity2:hover {
  opacity: .2;
}

.opacity3,
.hov-opacity3:hover {
  opacity: .3;
}

.opacity4,
.hov-opacity4:hover {
  opacity: .4;
}

.opacity5,
.hov-opacity5:hover {
  opacity: .5;
}

.opacity6,
.hov-opacity6:hover {
  opacity: .6;
}

.opacity7,
.hov-opacity7:hover {
  opacity: .7;
}

.opacity8,
.hov-opacity8:hover {
  opacity: .8;
}

.opacity9,
.hov-opacity9:hover {
  opacity: .9;
}

.opacity10,
.hov-opacity10:hover {
  opacity: 1;
}


/* Reset line height */
.reset-line-height {
  line-height: 1
}


/* to uppercase */
.uppercase {
  text-transform: uppercase;
}

.flipX-icon:before {
  display: inline-block;
  transform: scaleX(-1);
}

.flipY-icon:before {
  display: inline-block;
  transform: scaleY(-1);
}


/* Custom columns grid */
.col-xs-4 {
  width: 33.333%;
}


/* Responsive base sizing */

@media (min-width: 1200px) {
  /*
   * Margins Classes
   * Helper classes for side margins EXTRA-LARGE
   */
  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .ml-xl-5 {
    margin-left: 5px !important;
  }

  .ml-xl-10 {
    margin-left: 10px !important;
  }

  .ml-xl-15 {
    margin-left: 15px !important;
  }

  .ml-xl-20 {
    margin-left: 20px !important;
  }

  .ml-xl-25 {
    margin-left: 25px !important;
  }

  .ml-xl-30 {
    margin-left: 30px !important;
  }

  .ml-xl-35 {
    margin-left: 35px !important;
  }

  .ml-xl-40 {
    margin-left: 40px !important;
  }

  .ml-xl-45 {
    margin-left: 45px !important;
  }

  .ml-xl-50 {
    margin-left: 50px !important;
  }

  .ml-xl-60 {
    margin-left: 60px !important;
  }

  .ml-xl-70 {
    margin-left: 70px !important;
  }

  .ml-xl-80 {
    margin-left: 80px !important;
  }

  .ml-xl-90 {
    margin-left: 90px !important;
  }

  .ml-xl-100 {
    margin-left: 100px !important;
  }

  .ml-xl-110 {
    margin-left: 110px !important;
  }

  .ml-xl-120 {
    margin-left: 120px !important;
  }

  .ml-xl-130 {
    margin-left: 130px !important;
  }

  .ml-xl-140 {
    margin-left: 140px !important;
  }

  .ml-xl-150 {
    margin-left: 150px !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mr-xl-5 {
    margin-right: 5px !important;
  }

  .mr-xl-10 {
    margin-right: 10px !important;
  }

  .mr-xl-15 {
    margin-right: 15px !important;
  }

  .mr-xl-20 {
    margin-right: 20px !important;
  }

  .mr-xl-25 {
    margin-right: 25px !important;
  }

  .mr-xl-30 {
    margin-right: 30px !important;
  }

  .mr-xl-35 {
    margin-right: 35px !important;
  }

  .mr-xl-40 {
    margin-right: 40px !important;
  }

  .mr-xl-45 {
    margin-right: 45px !important;
  }

  .mr-xl-50 {
    margin-right: 50px !important;
  }

  .mr-xl-60 {
    margin-right: 60px !important;
  }

  .mr-xl-70 {
    margin-right: 70px !important;
  }

  .mr-xl-80 {
    margin-right: 80px !important;
  }

  .mr-xl-90 {
    margin-right: 90px !important;
  }

  .mr-xl-100 {
    margin-right: 100px !important;
  }

  .mr-xl-110 {
    margin-right: 110px !important;
  }

  .mr-xl-120 {
    margin-right: 120px !important;
  }

  .mr-xl-130 {
    margin-right: 130px !important;
  }

  .mr-xl-140 {
    margin-right: 140px !important;
  }

  .mr-xl-150 {
    margin-right: 150px !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-5 {
    margin-top: 5px !important;
  }

  .mt-xl-10 {
    margin-top: 10px !important;
  }

  .mt-xl-15 {
    margin-top: 15px !important;
  }

  .mt-xl-20 {
    margin-top: 20px !important;
  }

  .mt-xl-25 {
    margin-top: 25px !important;
  }

  .mt-xl-30 {
    margin-top: 30px !important;
  }

  .mt-xl-35 {
    margin-top: 35px !important;
  }

  .mt-xl-40 {
    margin-top: 40px !important;
  }

  .mt-xl-45 {
    margin-top: 45px !important;
  }

  .mt-xl-50 {
    margin-top: 50px !important;
  }

  .mt-xl-60 {
    margin-top: 60px !important;
  }

  .mt-xl-70 {
    margin-top: 70px !important;
  }

  .mt-xl-80 {
    margin-top: 80px !important;
  }

  .mt-xl-90 {
    margin-top: 90px !important;
  }

  .mt-xl-100 {
    margin-top: 100px !important;
  }

  .mt-xl-110 {
    margin-top: 110px !important;
  }

  .mt-xl-120 {
    margin-top: 120px !important;
  }

  .mt-xl-130 {
    margin-top: 130px !important;
  }

  .mt-xl-140 {
    margin-top: 140px !important;
  }

  .mt-xl-150 {
    margin-top: 150px !important;
  }

  .mt-xl-200 {
    margin-top: 200px !important;
  }

  .mt-xl-250 {
    margin-top: 250px !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-5 {
    margin-bottom: 5px !important;
  }

  .mb-xl-10 {
    margin-bottom: 10px !important;
  }

  .mb-xl-15 {
    margin-bottom: 15px !important;
  }

  .mb-xl-20 {
    margin-bottom: 20px !important;
  }

  .mb-xl-25 {
    margin-bottom: 25px !important;
  }

  .mb-xl-30 {
    margin-bottom: 30px !important;
  }

  .mb-xl-35 {
    margin-bottom: 35px !important;
  }

  .mb-xl-40 {
    margin-bottom: 40px !important;
  }

  .mb-xl-45 {
    margin-bottom: 45px !important;
  }

  .mb-xl-50 {
    margin-bottom: 50px !important;
  }

  .mb-xl-60 {
    margin-bottom: 60px !important;
  }

  .mb-xl-70 {
    margin-bottom: 70px !important;
  }

  .mb-xl-80 {
    margin-bottom: 80px !important;
  }

  .mb-xl-90 {
    margin-bottom: 90px !important;
  }

  .mb-xl-100 {
    margin-bottom: 100px !important;
  }

  .mb-xl-110 {
    margin-bottom: 110px !important;
  }

  .mb-xl-120 {
    margin-bottom: 120px !important;
  }

  .mb-xl-130 {
    margin-bottom: 130px !important;
  }

  .mb-xl-140 {
    margin-bottom: 140px !important;
  }

  .mb-xl-150 {
    margin-bottom: 150px !important;
  }

  .mb-xl-200 {
    margin-bottom: 200px !important;
  }

  .mb-xl-250 {
    margin-bottom: 250px !important;
  }
}

@media (min-width: 992px) {
  /*
   * Margins Classes
   * Helper classes for side margins LARGE
   */
  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .ml-lg-5 {
    margin-left: 5px !important;
  }

  .ml-lg-10 {
    margin-left: 10px !important;
  }

  .ml-lg-15 {
    margin-left: 15px !important;
  }

  .ml-lg-20 {
    margin-left: 20px !important;
  }

  .ml-lg-25 {
    margin-left: 25px !important;
  }

  .ml-lg-30 {
    margin-left: 30px !important;
  }

  .ml-lg-35 {
    margin-left: 35px !important;
  }

  .ml-lg-40 {
    margin-left: 40px !important;
  }

  .ml-lg-45 {
    margin-left: 45px !important;
  }

  .ml-lg-50 {
    margin-left: 50px !important;
  }

  .ml-lg-60 {
    margin-left: 60px !important;
  }

  .ml-lg-70 {
    margin-left: 70px !important;
  }

  .ml-lg-80 {
    margin-left: 80px !important;
  }

  .ml-lg-90 {
    margin-left: 90px !important;
  }

  .ml-lg-100 {
    margin-left: 100px !important;
  }

  .ml-lg-110 {
    margin-left: 110px !important;
  }

  .ml-lg-120 {
    margin-left: 120px !important;
  }

  .ml-lg-130 {
    margin-left: 130px !important;
  }

  .ml-lg-140 {
    margin-left: 140px !important;
  }

  .ml-lg-150 {
    margin-left: 150px !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mr-lg-5 {
    margin-right: 5px !important;
  }

  .mr-lg-10 {
    margin-right: 10px !important;
  }

  .mr-lg-15 {
    margin-right: 15px !important;
  }

  .mr-lg-20 {
    margin-right: 20px !important;
  }

  .mr-lg-25 {
    margin-right: 25px !important;
  }

  .mr-lg-30 {
    margin-right: 30px !important;
  }

  .mr-lg-35 {
    margin-right: 35px !important;
  }

  .mr-lg-40 {
    margin-right: 40px !important;
  }

  .mr-lg-45 {
    margin-right: 45px !important;
  }

  .mr-lg-50 {
    margin-right: 50px !important;
  }

  .mr-lg-60 {
    margin-right: 60px !important;
  }

  .mr-lg-70 {
    margin-right: 70px !important;
  }

  .mr-lg-80 {
    margin-right: 80px !important;
  }

  .mr-lg-90 {
    margin-right: 90px !important;
  }

  .mr-lg-100 {
    margin-right: 100px !important;
  }

  .mr-lg-110 {
    margin-right: 110px !important;
  }

  .mr-lg-120 {
    margin-right: 120px !important;
  }

  .mr-lg-130 {
    margin-right: 130px !important;
  }

  .mr-lg-140 {
    margin-right: 140px !important;
  }

  .mr-lg-150 {
    margin-right: 150px !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-5 {
    margin-top: 5px !important;
  }

  .mt-lg-10 {
    margin-top: 10px !important;
  }

  .mt-lg-15 {
    margin-top: 15px !important;
  }

  .mt-lg-20 {
    margin-top: 20px !important;
  }

  .mt-lg-25 {
    margin-top: 25px !important;
  }

  .mt-lg-30 {
    margin-top: 30px !important;
  }

  .mt-lg-35 {
    margin-top: 35px !important;
  }

  .mt-lg-40 {
    margin-top: 40px !important;
  }

  .mt-lg-45 {
    margin-top: 45px !important;
  }

  .mt-lg-50 {
    margin-top: 50px !important;
  }

  .mt-lg-60 {
    margin-top: 60px !important;
  }

  .mt-lg-70 {
    margin-top: 70px !important;
  }

  .mt-lg-80 {
    margin-top: 80px !important;
  }

  .mt-lg-90 {
    margin-top: 90px !important;
  }

  .mt-lg-100 {
    margin-top: 100px !important;
  }

  .mt-lg-110 {
    margin-top: 110px !important;
  }

  .mt-lg-120 {
    margin-top: 120px !important;
  }

  .mt-lg-130 {
    margin-top: 130px !important;
  }

  .mt-lg-140 {
    margin-top: 140px !important;
  }

  .mt-lg-150 {
    margin-top: 150px !important;
  }

  .mt-lg-200 {
    margin-top: 200px !important;
  }

  .mt-lg-250 {
    margin-top: 250px !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-5 {
    margin-bottom: 5px !important;
  }

  .mb-lg-10 {
    margin-bottom: 10px !important;
  }

  .mb-lg-15 {
    margin-bottom: 15px !important;
  }

  .mb-lg-20 {
    margin-bottom: 20px !important;
  }

  .mb-lg-25 {
    margin-bottom: 25px !important;
  }

  .mb-lg-30 {
    margin-bottom: 30px !important;
  }

  .mb-lg-35 {
    margin-bottom: 35px !important;
  }

  .mb-lg-40 {
    margin-bottom: 40px !important;
  }

  .mb-lg-45 {
    margin-bottom: 45px !important;
  }

  .mb-lg-50 {
    margin-bottom: 50px !important;
  }

  .mb-lg-60 {
    margin-bottom: 60px !important;
  }

  .mb-lg-70 {
    margin-bottom: 70px !important;
  }

  .mb-lg-80 {
    margin-bottom: 80px !important;
  }

  .mb-lg-90 {
    margin-bottom: 90px !important;
  }

  .mb-lg-100 {
    margin-bottom: 100px !important;
  }

  .mb-lg-110 {
    margin-bottom: 110px !important;
  }

  .mb-lg-120 {
    margin-bottom: 120px !important;
  }

  .mb-lg-130 {
    margin-bottom: 130px !important;
  }

  .mb-lg-140 {
    margin-bottom: 140px !important;
  }

  .mb-lg-150 {
    margin-bottom: 150px !important;
  }

  .mb-lg-200 {
    margin-bottom: 200px !important;
  }

  .mb-lg-250 {
    margin-bottom: 250px !important;
  }
}

@media only screen and (max-width: 992px) {
  /*
   * Margins Classes
   * Helper classes for side margins MEDIUM
   */
  .ml-md-0 {
    margin-left: 0 !important;
  }

  .ml-md-5 {
    margin-left: 5px !important;
  }

  .ml-md-10 {
    margin-left: 10px !important;
  }

  .ml-md-15 {
    margin-left: 15px !important;
  }

  .ml-md-20 {
    margin-left: 20px !important;
  }

  .ml-md-25 {
    margin-left: 25px !important;
  }

  .ml-md-30 {
    margin-left: 30px !important;
  }

  .ml-md-35 {
    margin-left: 35px !important;
  }

  .ml-md-40 {
    margin-left: 40px !important;
  }

  .ml-md-45 {
    margin-left: 45px !important;
  }

  .ml-md-50 {
    margin-left: 50px !important;
  }

  .ml-md-60 {
    margin-left: 60px !important;
  }

  .ml-md-70 {
    margin-left: 70px !important;
  }

  .ml-md-80 {
    margin-left: 80px !important;
  }

  .ml-md-90 {
    margin-left: 90px !important;
  }

  .ml-md-100 {
    margin-left: 100px !important;
  }

  .ml-md-110 {
    margin-left: 110px !important;
  }

  .ml-md-120 {
    margin-left: 120px !important;
  }

  .ml-md-130 {
    margin-left: 130px !important;
  }

  .ml-md-140 {
    margin-left: 140px !important;
  }

  .ml-md-150 {
    margin-left: 150px !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mr-md-5 {
    margin-right: 5px !important;
  }

  .mr-md-10 {
    margin-right: 10px !important;
  }

  .mr-md-15 {
    margin-right: 15px !important;
  }

  .mr-md-20 {
    margin-right: 20px !important;
  }

  .mr-md-25 {
    margin-right: 25px !important;
  }

  .mr-md-30 {
    margin-right: 30px !important;
  }

  .mr-md-35 {
    margin-right: 35px !important;
  }

  .mr-md-40 {
    margin-right: 40px !important;
  }

  .mr-md-45 {
    margin-right: 45px !important;
  }

  .mr-md-50 {
    margin-right: 50px !important;
  }

  .mr-md-60 {
    margin-right: 60px !important;
  }

  .mr-md-70 {
    margin-right: 70px !important;
  }

  .mr-md-80 {
    margin-right: 80px !important;
  }

  .mr-md-90 {
    margin-right: 90px !important;
  }

  .mr-md-100 {
    margin-right: 100px !important;
  }

  .mr-md-110 {
    margin-right: 110px !important;
  }

  .mr-md-120 {
    margin-right: 120px !important;
  }

  .mr-md-130 {
    margin-right: 130px !important;
  }

  .mr-md-140 {
    margin-right: 140px !important;
  }

  .mr-md-150 {
    margin-right: 150px !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-5 {
    margin-top: 5px !important;
  }

  .mt-md-10 {
    margin-top: 10px !important;
  }

  .mt-md-15 {
    margin-top: 15px !important;
  }

  .mt-md-20 {
    margin-top: 20px !important;
  }

  .mt-md-25 {
    margin-top: 25px !important;
  }

  .mt-md-30 {
    margin-top: 30px !important;
  }

  .mt-md-35 {
    margin-top: 35px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-45 {
    margin-top: 45px !important;
  }

  .mt-md-50 {
    margin-top: 50px !important;
  }

  .mt-md-60 {
    margin-top: 60px !important;
  }

  .mt-md-70 {
    margin-top: 70px !important;
  }

  .mt-md-80 {
    margin-top: 80px !important;
  }

  .mt-md-90 {
    margin-top: 90px !important;
  }

  .mt-md-100 {
    margin-top: 100px !important;
  }

  .mt-md-110 {
    margin-top: 110px !important;
  }

  .mt-md-120 {
    margin-top: 120px !important;
  }

  .mt-md-130 {
    margin-top: 130px !important;
  }

  .mt-md-140 {
    margin-top: 140px !important;
  }

  .mt-md-150 {
    margin-top: 150px !important;
  }

  .mt-md-200 {
    margin-top: 200px !important;
  }

  .mt-md-250 {
    margin-top: 250px !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-5 {
    margin-bottom: 5px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-15 {
    margin-bottom: 15px !important;
  }

  .mb-md-20 {
    margin-bottom: 20px !important;
  }

  .mb-md-25 {
    margin-bottom: 25px !important;
  }

  .mb-md-30 {
    margin-bottom: 30px !important;
  }

  .mb-md-35 {
    margin-bottom: 35px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md-45 {
    margin-bottom: 45px !important;
  }

  .mb-md-50 {
    margin-bottom: 50px !important;
  }

  .mb-md-60 {
    margin-bottom: 60px !important;
  }

  .mb-md-70 {
    margin-bottom: 70px !important;
  }

  .mb-md-80 {
    margin-bottom: 80px !important;
  }

  .mb-md-90 {
    margin-bottom: 90px !important;
  }

  .mb-md-100 {
    margin-bottom: 100px !important;
  }

  .mb-md-110 {
    margin-bottom: 110px !important;
  }

  .mb-md-120 {
    margin-bottom: 120px !important;
  }

  .mb-md-130 {
    margin-bottom: 130px !important;
  }

  .mb-md-140 {
    margin-bottom: 140px !important;
  }

  .mb-md-150 {
    margin-bottom: 150px !important;
  }

  .mb-md-200 {
    margin-bottom: 200px !important;
  }

  .mb-md-250 {
    margin-bottom: 250px !important;
  }

  /*
   * Margins Classes - Negative
   * Helper classes for side margins on negative MEDIUM
   */
  .mnl-md-10 {
    margin-left: -10px !important;
  }

  .mnl-md-20 {
    margin-left: -20px !important;
  }

  .mnl-md-30 {
    margin-left: -30px !important;
  }

  .mnl-md-40 {
    margin-left: -40px !important;
  }

  .mnl-md-50 {
    margin-left: -50px !important;
  }

  .mnl-md-60 {
    margin-left: -60px !important;
  }

  .mnl-md-70 {
    margin-left: -70px !important;
  }

  .mnl-md-80 {
    margin-left: -80px !important;
  }

  .mnl-md-90 {
    margin-left: -90px !important;
  }

  .mnl-md-100 {
    margin-left: -100px !important;
  }

  .mnl-md-110 {
    margin-left: -110px !important;
  }

  .mnl-md-120 {
    margin-left: -120px !important;
  }

  .mnl-md-130 {
    margin-left: -130px !important;
  }

  .mnl-md-140 {
    margin-left: -140px !important;
  }

  .mnl-md-150 {
    margin-left: -150px !important;
  }

  .mnr-md-10 {
    margin-right: -10px !important;
  }

  .mnr-md-20 {
    margin-right: -20px !important;
  }

  .mnr-md-30 {
    margin-right: -30px !important;
  }

  .mnr-md-40 {
    margin-right: -40px !important;
  }

  .mnr-md-50 {
    margin-right: -50px !important;
  }

  .mnr-md-60 {
    margin-right: -60px !important;
  }

  .mnr-md-70 {
    margin-right: -70px !important;
  }

  .mnr-md-80 {
    margin-right: -80px !important;
  }

  .mnr-md-90 {
    margin-right: -90px !important;
  }

  .mnr-md-100 {
    margin-right: -100px !important;
  }

  .mnr-md-110 {
    margin-right: -110px !important;
  }

  .mnr-md-120 {
    margin-right: -120px !important;
  }

  .mnr-md-130 {
    margin-right: -130px !important;
  }

  .mnr-md-140 {
    margin-right: -140px !important;
  }

  .mnr-md-150 {
    margin-right: -150px !important;
  }

  .mnt-md-10 {
    margin-top: -10px !important;
  }

  .mnt-md-20 {
    margin-top: -20px !important;
  }

  .mnt-md-30 {
    margin-top: -30px !important;
  }

  .mnt-md-40 {
    margin-top: -40px !important;
  }

  .mnt-md-50 {
    margin-top: -50px !important;
  }

  .mnt-md-60 {
    margin-top: -60px !important;
  }

  .mnt-md-70 {
    margin-top: -70px !important;
  }

  .mnt-md-80 {
    margin-top: -80px !important;
  }

  .mnt-md-90 {
    margin-top: -90px !important;
  }

  .mnt-md-100 {
    margin-top: -100px !important;
  }

  .mnt-md-110 {
    margin-top: -110px !important;
  }

  .mnt-md-120 {
    margin-top: -120px !important;
  }

  .mnt-md-130 {
    margin-top: -130px !important;
  }

  .mnt-md-140 {
    margin-top: -140px !important;
  }

  .mnt-md-150 {
    margin-top: -150px !important;
  }

  .mnb-md-10 {
    margin-bottom: -10px !important;
  }

  .mnb-md-20 {
    margin-bottom: -20px !important;
  }

  .mnb-md-30 {
    margin-bottom: -30px !important;
  }

  .mnb-md-40 {
    margin-bottom: -40px !important;
  }

  .mnb-md-50 {
    margin-bottom: -50px !important;
  }

  .mnb-md-60 {
    margin-bottom: -60px !important;
  }

  .mnb-md-70 {
    margin-bottom: -70px !important;
  }

  .mnb-md-80 {
    margin-bottom: -80px !important;
  }

  .mnb-md-90 {
    margin-bottom: -90px !important;
  }

  .mnb-md-100 {
    margin-bottom: -100px !important;
  }

  .mnb-md-110 {
    margin-bottom: -110px !important;
  }

  .mnb-md-120 {
    margin-bottom: -120px !important;
  }

  .mnb-md-130 {
    margin-bottom: -130px !important;
  }

  .mnb-md-140 {
    margin-bottom: -140px !important;
  }

  .mnb-md-150 {
    margin-bottom: -150px !important;
  }

  /*
   * Padding Classes
   * Helper classes for side padding MEDIUM
   */
  .pl-md-0 {
    padding-left: 0 !important;
  }

  .pl-md-5 {
    padding-left: 5px !important;
  }

  .pl-md-10 {
    padding-left: 10px !important;
  }

  .pl-md-15 {
    padding-left: 15px !important;
  }

  .pl-md-20 {
    padding-left: 20px !important;
  }

  .pl-md-25 {
    padding-left: 25px !important;
  }

  .pl-md-30 {
    padding-left: 30px !important;
  }

  .pl-md-35 {
    padding-left: 35px !important;
  }

  .pl-md-40 {
    padding-left: 40px !important;
  }

  .pl-md-45 {
    padding-left: 45px !important;
  }

  .pl-md-50 {
    padding-left: 50px !important;
  }

  .pl-md-60 {
    padding-left: 60px !important;
  }

  .pl-md-70 {
    padding-left: 70px !important;
  }

  .pl-md-80 {
    padding-left: 80px !important;
  }

  .pl-md-90 {
    padding-left: 90px !important;
  }

  .pl-md-100 {
    padding-left: 100px !important;
  }

  .pl-md-110 {
    padding-left: 110px !important;
  }

  .pl-md-120 {
    padding-left: 120px !important;
  }

  .pl-md-130 {
    padding-left: 130px !important;
  }

  .pl-md-140 {
    padding-left: 140px !important;
  }

  .pl-md-150 {
    padding-left: 150px !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pr-md-5 {
    padding-right: 5px !important;
  }

  .pr-md-10 {
    padding-right: 10px !important;
  }

  .pr-md-15 {
    padding-right: 15px !important;
  }

  .pr-md-20 {
    padding-right: 20px !important;
  }

  .pr-md-25 {
    padding-right: 25px !important;
  }

  .pr-md-30 {
    padding-right: 30px !important;
  }

  .pr-md-35 {
    padding-right: 35px !important;
  }

  .pr-md-40 {
    padding-right: 40px !important;
  }

  .pr-md-45 {
    padding-right: 45px !important;
  }

  .pr-md-50 {
    padding-right: 50px !important;
  }

  .pr-md-60 {
    padding-right: 60px !important;
  }

  .pr-md-70 {
    padding-right: 70px !important;
  }

  .pr-md-80 {
    padding-right: 80px !important;
  }

  .pr-md-90 {
    padding-right: 90px !important;
  }

  .pr-md-100 {
    padding-right: 100px !important;
  }

  .pr-md-110 {
    padding-right: 110px !important;
  }

  .pr-md-120 {
    padding-right: 120px !important;
  }

  .pr-md-130 {
    padding-right: 130px !important;
  }

  .pr-md-140 {
    padding-right: 140px !important;
  }

  .pr-md-150 {
    padding-right: 150px !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-5 {
    padding-top: 5px !important;
  }

  .pt-md-10 {
    padding-top: 10px !important;
  }

  .pt-md-15 {
    padding-top: 15px !important;
  }

  .pt-md-20 {
    padding-top: 20px !important;
  }

  .pt-md-25 {
    padding-top: 25px !important;
  }

  .pt-md-30 {
    padding-top: 30px !important;
  }

  .pt-md-35 {
    padding-top: 35px !important;
  }

  .pt-md-40 {
    padding-top: 40px !important;
  }

  .pt-md-45 {
    padding-top: 45px !important;
  }

  .pt-md-50 {
    padding-top: 50px !important;
  }

  .pt-md-60 {
    padding-top: 60px !important;
  }

  .pt-md-70 {
    padding-top: 70px !important;
  }

  .pt-md-80 {
    padding-top: 80px !important;
  }

  .pt-md-90 {
    padding-top: 90px !important;
  }

  .pt-md-100 {
    padding-top: 100px !important;
  }

  .pt-md-110 {
    padding-top: 110px !important;
  }

  .pt-md-120 {
    padding-top: 120px !important;
  }

  .pt-md-130 {
    padding-top: 130px !important;
  }

  .pt-md-140 {
    padding-top: 140px !important;
  }

  .pt-md-150 {
    padding-top: 150px !important;
  }

  .pt-md-200 {
    padding-top: 200px !important;
  }

  .pt-md-250 {
    padding-top: 250px !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-5 {
    padding-bottom: 5px !important;
  }

  .pb-md-10 {
    padding-bottom: 10px !important;
  }

  .pb-md-15 {
    padding-bottom: 15px !important;
  }

  .pb-md-20 {
    padding-bottom: 20px !important;
  }

  .pb-md-25 {
    padding-bottom: 25px !important;
  }

  .pb-md-30 {
    padding-bottom: 30px !important;
  }

  .pb-md-35 {
    padding-bottom: 35px !important;
  }

  .pb-md-40 {
    padding-bottom: 40px !important;
  }

  .pb-md-45 {
    padding-bottom: 45px !important;
  }

  .pb-md-50 {
    padding-bottom: 50px !important;
  }

  .pb-md-60 {
    padding-bottom: 60px !important;
  }

  .pb-md-70 {
    padding-bottom: 70px !important;
  }

  .pb-md-80 {
    padding-bottom: 80px !important;
  }

  .pb-md-90 {
    padding-bottom: 90px !important;
  }

  .pb-md-100 {
    padding-bottom: 100px !important;
  }

  .pb-md-110 {
    padding-bottom: 110px !important;
  }

  .pb-md-120 {
    padding-bottom: 120px !important;
  }

  .pb-md-130 {
    padding-bottom: 130px !important;
  }

  .pb-md-140 {
    padding-bottom: 140px !important;
  }

  .pb-md-150 {
    padding-bottom: 150px !important;
  }

  .pb-md-200 {
    padding-bottom: 200px !important;
  }

  .pb-md-250 {
    padding-bottom: 250px !important;
  }

  /*
   * Font-sizes-presets
   */
  .fs-md-micro {
    font-size: 9px;
  }

  .fs-md-xsmall {
    font-size: 11px;
  }

  .fs-md-normal {
    font-size: 13px;
  }

  .fs-md-smaller {
    font-size: 15px;
  }

  .fs-md-small {
    font-size: 16px;
  }

  .fs-md-m {
    font-size: 18px;
  }

  .fs-md-l {
    font-size: 20px;
  }

  .fs-md-xl {
    font-size: 24px;
  }

  .fs-md-xxl {
    font-size: 32px;
  }

  .fs-md-larger {
    font-size: 42px;
  }

  .fs-md-xxxl {
    font-size: 56px;
  }

  .fs-md-xxxxl {
    font-size: 75px;
  }

  .fs-md-jumbo {
    font-size: 80px;
  }

  .fs-md-huge {
    font-size: 120px;
  }

  .fs-md-immense {
    font-size: 150px;
  }

  .fs-md-crazy {
    font-size: 200px;
  }

  /*
   * Height Sizing Presets
   * These are height values from 10 to 10 to set responsive height to a block
   */
  .hsize-md-reset {
    height: 0 !important;
  }

  .hsize-md-10 {
    height: 10px;
  }

  .hsize-md-20 {
    height: 20px;
  }

  .hsize-md-30 {
    height: 30px;
  }

  .hsize-md-40 {
    height: 40px;
  }

  .hsize-md-50 {
    height: 50px;
  }

  .hsize-md-60 {
    height: 60px;
  }

  .hsize-md-70 {
    height: 70px;
  }

  .hsize-md-80 {
    height: 80px;
  }

  .hsize-md-90 {
    height: 90px;
  }

  .hsize-md-100 {
    height: 100px;
  }

  .hsize-md-110 {
    height: 110px;
  }

  .hsize-md-120 {
    height: 120px;
  }

  .hsize-md-130 {
    height: 130px;
  }

  .hsize-md-140 {
    height: 140px;
  }

  .hsize-md-150 {
    height: 150px;
  }

  .hsize-md-160 {
    height: 160px;
  }

  .hsize-md-170 {
    height: 170px;
  }

  .hsize-md-180 {
    height: 180px;
  }

  .hsize-md-190 {
    height: 190px;
  }

  .hsize-md-200 {
    height: 200px;
  }

  .hsize-md-210 {
    height: 210px;
  }

  .hsize-md-220 {
    height: 220px;
  }

  .hsize-md-230 {
    height: 230px;
  }

  .hsize-md-240 {
    height: 240px;
  }

  .hsize-md-250 {
    height: 250px;
  }

  .hsize-md-260 {
    height: 260px;
  }

  .hsize-md-270 {
    height: 270px;
  }

  .hsize-md-280 {
    height: 280px;
  }

  .hsize-md-290 {
    height: 290px;
  }

  .hsize-md-300 {
    height: 300px;
  }

  .hsize-md-310 {
    height: 310px;
  }

  .hsize-md-320 {
    height: 320px;
  }

  .hsize-md-330 {
    height: 330px;
  }

  .hsize-md-340 {
    height: 340px;
  }

  .hsize-md-350 {
    height: 350px;
  }

  .hsize-md-360 {
    height: 360px;
  }

  .hsize-md-370 {
    height: 370px;
  }

  .hsize-md-380 {
    height: 380px;
  }

  .hsize-md-390 {
    height: 390px;
  }

  .hsize-md-400 {
    height: 400px;
  }

  .hsize-md-410 {
    height: 410px;
  }

  .hsize-md-420 {
    height: 420px;
  }

  .hsize-md-430 {
    height: 430px;
  }

  .hsize-md-440 {
    height: 440px;
  }

  .hsize-md-450 {
    height: 450px;
  }

  .hsize-md-460 {
    height: 460px;
  }

  .hsize-md-470 {
    height: 470px;
  }

  .hsize-md-480 {
    height: 480px;
  }

  .hsize-md-490 {
    height: 490px;
  }

  .hsize-md-500 {
    height: 500px;
  }

  .hsize-md-510 {
    height: 510px;
  }

  .hsize-md-520 {
    height: 520px;
  }

  .hsize-md-530 {
    height: 530px;
  }

  .hsize-md-540 {
    height: 540px;
  }

  .hsize-md-550 {
    height: 550px;
  }

  .hsize-md-560 {
    height: 560px;
  }

  .hsize-md-570 {
    height: 570px;
  }

  .hsize-md-580 {
    height: 580px;
  }

  .hsize-md-590 {
    height: 590px;
  }

  .hsize-md-600 {
    height: 600px;
  }

  .hsize-md-610 {
    height: 610px;
  }

  .hsize-md-620 {
    height: 620px;
  }

  .hsize-md-630 {
    height: 630px;
  }

  .hsize-md-640 {
    height: 640px;
  }

  .hsize-md-650 {
    height: 650px;
  }

  .hsize-md-660 {
    height: 660px;
  }

  .hsize-md-670 {
    height: 670px;
  }

  .hsize-md-680 {
    height: 680px;
  }

  .hsize-md-690 {
    height: 690px;
  }

  .hsize-md-700 {
    height: 700px;
  }

  /* Reset Columnized Lists */
  .list-col-reset-md[data-columns] {
    margin-left: 0;
  }

  .list-col-reset-md[data-columns] li {
    display: block;
    float: none;
    margin-left: 0;
    width: auto;
  }

  /*
   * Float Clearing Presets
   */
  .cleft-md {
    clear: left;
  }

  .cright-md {
    clear: right;
  }

  .cboth-md {
    clear: both;
  }

  /*
   * Float None
   */
  .pull-none-md {
    float: none !important;
  }


  /*
   * Button Padding Presets
   */
  .btn.mid-padding-md {
    line-height: 1;
    padding: 10px 20px;
  }

  .btn.big-padding-md {
    line-height: 1;
    padding: 15px 30px;
  }

  .btn.long-padding-md {
    padding-left: 30px;
    padding-right: 30px;
  }

  /*
    * Width presets
    */
  .w-md-10 {
    width: 10% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  /* Text Center */
  .text-center-md {
    text-align: center;
  }

}

@media only screen and (max-width: 767px) {
  /*
   * Margins Classes
   * Helper classes for side margins SMALL
   */
  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .ml-sm-5 {
    margin-left: 5px !important;
  }

  .ml-sm-10 {
    margin-left: 10px !important;
  }

  .ml-sm-15 {
    margin-left: 15px !important;
  }

  .ml-sm-20 {
    margin-left: 20px !important;
  }

  .ml-sm-25 {
    margin-left: 25px !important;
  }

  .ml-sm-30 {
    margin-left: 30px !important;
  }

  .ml-sm-35 {
    margin-left: 35px !important;
  }

  .ml-sm-40 {
    margin-left: 40px !important;
  }

  .ml-sm-45 {
    margin-left: 45px !important;
  }

  .ml-sm-50 {
    margin-left: 50px !important;
  }

  .ml-sm-60 {
    margin-left: 60px !important;
  }

  .ml-sm-70 {
    margin-left: 70px !important;
  }

  .ml-sm-80 {
    margin-left: 80px !important;
  }

  .ml-sm-90 {
    margin-left: 90px !important;
  }

  .ml-sm-100 {
    margin-left: 100px !important;
  }

  .ml-sm-110 {
    margin-left: 110px !important;
  }

  .ml-sm-120 {
    margin-left: 120px !important;
  }

  .ml-sm-130 {
    margin-left: 130px !important;
  }

  .ml-sm-140 {
    margin-left: 140px !important;
  }

  .ml-sm-150 {
    margin-left: 150px !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mr-sm-5 {
    margin-right: 5px !important;
  }

  .mr-sm-10 {
    margin-right: 10px !important;
  }

  .mr-sm-15 {
    margin-right: 15px !important;
  }

  .mr-sm-20 {
    margin-right: 20px !important;
  }

  .mr-sm-25 {
    margin-right: 25px !important;
  }

  .mr-sm-30 {
    margin-right: 30px !important;
  }

  .mr-sm-35 {
    margin-right: 35px !important;
  }

  .mr-sm-40 {
    margin-right: 40px !important;
  }

  .mr-sm-45 {
    margin-right: 45px !important;
  }

  .mr-sm-50 {
    margin-right: 50px !important;
  }

  .mr-sm-60 {
    margin-right: 60px !important;
  }

  .mr-sm-70 {
    margin-right: 70px !important;
  }

  .mr-sm-80 {
    margin-right: 80px !important;
  }

  .mr-sm-90 {
    margin-right: 90px !important;
  }

  .mr-sm-100 {
    margin-right: 100px !important;
  }

  .mr-sm-110 {
    margin-right: 110px !important;
  }

  .mr-sm-120 {
    margin-right: 120px !important;
  }

  .mr-sm-130 {
    margin-right: 130px !important;
  }

  .mr-sm-140 {
    margin-right: 140px !important;
  }

  .mr-sm-150 {
    margin-right: 150px !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-5 {
    margin-top: 5px !important;
  }

  .mt-sm-10 {
    margin-top: 10px !important;
  }

  .mt-sm-15 {
    margin-top: 15px !important;
  }

  .mt-sm-20 {
    margin-top: 20px !important;
  }

  .mt-sm-25 {
    margin-top: 25px !important;
  }

  .mt-sm-30 {
    margin-top: 30px !important;
  }

  .mt-sm-35 {
    margin-top: 35px !important;
  }

  .mt-sm-40 {
    margin-top: 40px !important;
  }

  .mt-sm-45 {
    margin-top: 45px !important;
  }

  .mt-sm-50 {
    margin-top: 50px !important;
  }

  .mt-sm-60 {
    margin-top: 60px !important;
  }

  .mt-sm-70 {
    margin-top: 70px !important;
  }

  .mt-sm-80 {
    margin-top: 80px !important;
  }

  .mt-sm-90 {
    margin-top: 90px !important;
  }

  .mt-sm-100 {
    margin-top: 100px !important;
  }

  .mt-sm-110 {
    margin-top: 110px !important;
  }

  .mt-sm-120 {
    margin-top: 120px !important;
  }

  .mt-sm-130 {
    margin-top: 130px !important;
  }

  .mt-sm-140 {
    margin-top: 140px !important;
  }

  .mt-sm-150 {
    margin-top: 150px !important;
  }

  .mt-sm-200 {
    margin-top: 200px !important;
  }

  .mt-sm-250 {
    margin-top: 250px !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-5 {
    margin-bottom: 5px !important;
  }

  .mb-sm-10 {
    margin-bottom: 10px !important;
  }

  .mb-sm-15 {
    margin-bottom: 15px !important;
  }

  .mb-sm-20 {
    margin-bottom: 20px !important;
  }

  .mb-sm-25 {
    margin-bottom: 25px !important;
  }

  .mb-sm-30 {
    margin-bottom: 30px !important;
  }

  .mb-sm-35 {
    margin-bottom: 35px !important;
  }

  .mb-sm-40 {
    margin-bottom: 40px !important;
  }

  .mb-sm-45 {
    margin-bottom: 45px !important;
  }

  .mb-sm-50 {
    margin-bottom: 50px !important;
  }

  .mb-sm-60 {
    margin-bottom: 60px !important;
  }

  .mb-sm-70 {
    margin-bottom: 70px !important;
  }

  .mb-sm-80 {
    margin-bottom: 80px !important;
  }

  .mb-sm-90 {
    margin-bottom: 90px !important;
  }

  .mb-sm-100 {
    margin-bottom: 100px !important;
  }

  .mb-sm-110 {
    margin-bottom: 110px !important;
  }

  .mb-sm-120 {
    margin-bottom: 120px !important;
  }

  .mb-sm-130 {
    margin-bottom: 130px !important;
  }

  .mb-sm-140 {
    margin-bottom: 140px !important;
  }

  .mb-sm-150 {
    margin-bottom: 150px !important;
  }

  .mb-sm-200 {
    margin-bottom: 200px !important;
  }

  .mb-sm-250 {
    margin-bottom: 250px !important;
  }

  /*
   * Margins Classes - Negative
   * Helper classes for side margins on negative SMALL
   */
  .mnl-sm-10 {
    margin-left: -10px !important;
  }

  .mnl-sm-20 {
    margin-left: -20px !important;
  }

  .mnl-sm-30 {
    margin-left: -30px !important;
  }

  .mnl-sm-40 {
    margin-left: -40px !important;
  }

  .mnl-sm-50 {
    margin-left: -50px !important;
  }

  .mnl-sm-60 {
    margin-left: -60px !important;
  }

  .mnl-sm-70 {
    margin-left: -70px !important;
  }

  .mnl-sm-80 {
    margin-left: -80px !important;
  }

  .mnl-sm-90 {
    margin-left: -90px !important;
  }

  .mnl-sm-100 {
    margin-left: -100px !important;
  }

  .mnl-sm-110 {
    margin-left: -110px !important;
  }

  .mnl-sm-120 {
    margin-left: -120px !important;
  }

  .mnl-sm-130 {
    margin-left: -130px !important;
  }

  .mnl-sm-140 {
    margin-left: -140px !important;
  }

  .mnl-sm-150 {
    margin-left: -150px !important;
  }

  .mnr-sm-10 {
    margin-right: -10px !important;
  }

  .mnr-sm-20 {
    margin-right: -20px !important;
  }

  .mnr-sm-30 {
    margin-right: -30px !important;
  }

  .mnr-sm-40 {
    margin-right: -40px !important;
  }

  .mnr-sm-50 {
    margin-right: -50px !important;
  }

  .mnr-sm-60 {
    margin-right: -60px !important;
  }

  .mnr-sm-70 {
    margin-right: -70px !important;
  }

  .mnr-sm-80 {
    margin-right: -80px !important;
  }

  .mnr-sm-90 {
    margin-right: -90px !important;
  }

  .mnr-sm-100 {
    margin-right: -100px !important;
  }

  .mnr-sm-110 {
    margin-right: -110px !important;
  }

  .mnr-sm-120 {
    margin-right: -120px !important;
  }

  .mnr-sm-130 {
    margin-right: -130px !important;
  }

  .mnr-sm-140 {
    margin-right: -140px !important;
  }

  .mnr-sm-150 {
    margin-right: -150px !important;
  }

  .mnt-sm-10 {
    margin-top: -10px !important;
  }

  .mnt-sm-20 {
    margin-top: -20px !important;
  }

  .mnt-sm-30 {
    margin-top: -30px !important;
  }

  .mnt-sm-40 {
    margin-top: -40px !important;
  }

  .mnt-sm-50 {
    margin-top: -50px !important;
  }

  .mnt-sm-60 {
    margin-top: -60px !important;
  }

  .mnt-sm-70 {
    margin-top: -70px !important;
  }

  .mnt-sm-80 {
    margin-top: -80px !important;
  }

  .mnt-sm-90 {
    margin-top: -90px !important;
  }

  .mnt-sm-100 {
    margin-top: -100px !important;
  }

  .mnt-sm-110 {
    margin-top: -110px !important;
  }

  .mnt-sm-120 {
    margin-top: -120px !important;
  }

  .mnt-sm-130 {
    margin-top: -130px !important;
  }

  .mnt-sm-140 {
    margin-top: -140px !important;
  }

  .mnt-sm-150 {
    margin-top: -150px !important;
  }

  .mnb-sm-10 {
    margin-bottom: -10px !important;
  }

  .mnb-sm-20 {
    margin-bottom: -20px !important;
  }

  .mnb-sm-30 {
    margin-bottom: -30px !important;
  }

  .mnb-sm-40 {
    margin-bottom: -40px !important;
  }

  .mnb-sm-50 {
    margin-bottom: -50px !important;
  }

  .mnb-sm-60 {
    margin-bottom: -60px !important;
  }

  .mnb-sm-70 {
    margin-bottom: -70px !important;
  }

  .mnb-sm-80 {
    margin-bottom: -80px !important;
  }

  .mnb-sm-90 {
    margin-bottom: -90px !important;
  }

  .mnb-sm-100 {
    margin-bottom: -100px !important;
  }

  .mnb-sm-110 {
    margin-bottom: -110px !important;
  }

  .mnb-sm-120 {
    margin-bottom: -120px !important;
  }

  .mnb-sm-130 {
    margin-bottom: -130px !important;
  }

  .mnb-sm-140 {
    margin-bottom: -140px !important;
  }

  .mnb-sm-150 {
    margin-bottom: -150px !important;
  }

  /*
   * Padding Classes
   * Helper classes for side padding SMALL
   */
  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .pl-sm-5 {
    padding-left: 5px !important;
  }

  .pl-sm-10 {
    padding-left: 10px !important;
  }

  .pl-sm-15 {
    padding-left: 15px !important;
  }

  .pl-sm-20 {
    padding-left: 20px !important;
  }

  .pl-sm-25 {
    padding-left: 25px !important;
  }

  .pl-sm-30 {
    padding-left: 30px !important;
  }

  .pl-sm-35 {
    padding-left: 35px !important;
  }

  .pl-sm-40 {
    padding-left: 40px !important;
  }

  .pl-sm-45 {
    padding-left: 45px !important;
  }

  .pl-sm-50 {
    padding-left: 50px !important;
  }

  .pl-sm-60 {
    padding-left: 60px !important;
  }

  .pl-sm-70 {
    padding-left: 70px !important;
  }

  .pl-sm-80 {
    padding-left: 80px !important;
  }

  .pl-sm-90 {
    padding-left: 90px !important;
  }

  .pl-sm-100 {
    padding-left: 100px !important;
  }

  .pl-sm-110 {
    padding-left: 110px !important;
  }

  .pl-sm-120 {
    padding-left: 120px !important;
  }

  .pl-sm-130 {
    padding-left: 130px !important;
  }

  .pl-sm-140 {
    padding-left: 140px !important;
  }

  .pl-sm-150 {
    padding-left: 150px !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pr-sm-5 {
    padding-right: 5px !important;
  }

  .pr-sm-10 {
    padding-right: 10px !important;
  }

  .pr-sm-15 {
    padding-right: 15px !important;
  }

  .pr-sm-20 {
    padding-right: 20px !important;
  }

  .pr-sm-25 {
    padding-right: 25px !important;
  }

  .pr-sm-30 {
    padding-right: 30px !important;
  }

  .pr-sm-35 {
    padding-right: 35px !important;
  }

  .pr-sm-40 {
    padding-right: 40px !important;
  }

  .pr-sm-45 {
    padding-right: 45px !important;
  }

  .pr-sm-50 {
    padding-right: 50px !important;
  }

  .pr-sm-60 {
    padding-right: 60px !important;
  }

  .pr-sm-70 {
    padding-right: 70px !important;
  }

  .pr-sm-80 {
    padding-right: 80px !important;
  }

  .pr-sm-90 {
    padding-right: 90px !important;
  }

  .pr-sm-100 {
    padding-right: 100px !important;
  }

  .pr-sm-110 {
    padding-right: 110px !important;
  }

  .pr-sm-120 {
    padding-right: 120px !important;
  }

  .pr-sm-130 {
    padding-right: 130px !important;
  }

  .pr-sm-140 {
    padding-right: 140px !important;
  }

  .pr-sm-150 {
    padding-right: 150px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-5 {
    padding-top: 5px !important;
  }

  .pt-sm-10 {
    padding-top: 10px !important;
  }

  .pt-sm-15 {
    padding-top: 15px !important;
  }

  .pt-sm-20 {
    padding-top: 20px !important;
  }

  .pt-sm-25 {
    padding-top: 25px !important;
  }

  .pt-sm-30 {
    padding-top: 30px !important;
  }

  .pt-sm-35 {
    padding-top: 35px !important;
  }

  .pt-sm-40 {
    padding-top: 40px !important;
  }

  .pt-sm-45 {
    padding-top: 45px !important;
  }

  .pt-sm-50 {
    padding-top: 50px !important;
  }

  .pt-sm-60 {
    padding-top: 60px !important;
  }

  .pt-sm-70 {
    padding-top: 70px !important;
  }

  .pt-sm-80 {
    padding-top: 80px !important;
  }

  .pt-sm-90 {
    padding-top: 90px !important;
  }

  .pt-sm-100 {
    padding-top: 100px !important;
  }

  .pt-sm-110 {
    padding-top: 110px !important;
  }

  .pt-sm-120 {
    padding-top: 120px !important;
  }

  .pt-sm-130 {
    padding-top: 130px !important;
  }

  .pt-sm-140 {
    padding-top: 140px !important;
  }

  .pt-sm-150 {
    padding-top: 150px !important;
  }

  .pt-sm-200 {
    padding-top: 200px !important;
  }

  .pt-sm-250 {
    padding-top: 250px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-5 {
    padding-bottom: 5px !important;
  }

  .pb-sm-10 {
    padding-bottom: 10px !important;
  }

  .pb-sm-15 {
    padding-bottom: 15px !important;
  }

  .pb-sm-20 {
    padding-bottom: 20px !important;
  }

  .pb-sm-25 {
    padding-bottom: 25px !important;
  }

  .pb-sm-30 {
    padding-bottom: 30px !important;
  }

  .pb-sm-35 {
    padding-bottom: 35px !important;
  }

  .pb-sm-40 {
    padding-bottom: 40px !important;
  }

  .pb-sm-45 {
    padding-bottom: 45px !important;
  }

  .pb-sm-50 {
    padding-bottom: 50px !important;
  }

  .pb-sm-60 {
    padding-bottom: 60px !important;
  }

  .pb-sm-70 {
    padding-bottom: 70px !important;
  }

  .pb-sm-80 {
    padding-bottom: 80px !important;
  }

  .pb-sm-90 {
    padding-bottom: 90px !important;
  }

  .pb-sm-100 {
    padding-bottom: 100px !important;
  }

  .pb-sm-110 {
    padding-bottom: 110px !important;
  }

  .pb-sm-120 {
    padding-bottom: 120px !important;
  }

  .pb-sm-130 {
    padding-bottom: 130px !important;
  }

  .pb-sm-140 {
    padding-bottom: 140px !important;
  }

  .pb-sm-150 {
    padding-bottom: 150px !important;
  }

  .pb-sm-200 {
    padding-bottom: 200px !important;
  }

  .pb-sm-250 {
    padding-bottom: 250px !important;
  }

  .p-sm-30 {
    padding: 30px !important;
  }

  /*
   * Height Sizing Presets
   * These are height values from 10 to 10 to set responsive height to a block
   */
  .hsize-sm-reset {
    height: 0 !important;
  }

  .hsize-sm-10 {
    height: 10px;
  }

  .hsize-sm-20 {
    height: 20px;
  }

  .hsize-sm-30 {
    height: 30px;
  }

  .hsize-sm-40 {
    height: 40px;
  }

  .hsize-sm-50 {
    height: 50px;
  }

  .hsize-sm-60 {
    height: 60px;
  }

  .hsize-sm-70 {
    height: 70px;
  }

  .hsize-sm-80 {
    height: 80px;
  }

  .hsize-sm-90 {
    height: 90px;
  }

  .hsize-sm-100 {
    height: 100px;
  }

  .hsize-sm-110 {
    height: 110px;
  }

  .hsize-sm-120 {
    height: 120px;
  }

  .hsize-sm-130 {
    height: 130px;
  }

  .hsize-sm-140 {
    height: 140px;
  }

  .hsize-sm-150 {
    height: 150px;
  }

  .hsize-sm-160 {
    height: 160px;
  }

  .hsize-sm-170 {
    height: 170px;
  }

  .hsize-sm-180 {
    height: 180px;
  }

  .hsize-sm-190 {
    height: 190px;
  }

  .hsize-sm-200 {
    height: 200px;
  }

  .hsize-sm-210 {
    height: 210px;
  }

  .hsize-sm-220 {
    height: 220px;
  }

  .hsize-sm-230 {
    height: 230px;
  }

  .hsize-sm-240 {
    height: 240px;
  }

  .hsize-sm-250 {
    height: 250px;
  }

  .hsize-sm-260 {
    height: 260px;
  }

  .hsize-sm-270 {
    height: 270px;
  }

  .hsize-sm-280 {
    height: 280px;
  }

  .hsize-sm-290 {
    height: 290px;
  }

  .hsize-sm-300 {
    height: 300px;
  }

  .hsize-sm-310 {
    height: 310px;
  }

  .hsize-sm-320 {
    height: 320px;
  }

  .hsize-sm-330 {
    height: 330px;
  }

  .hsize-sm-340 {
    height: 340px;
  }

  .hsize-sm-350 {
    height: 350px;
  }

  .hsize-sm-360 {
    height: 360px;
  }

  .hsize-sm-370 {
    height: 370px;
  }

  .hsize-sm-380 {
    height: 380px;
  }

  .hsize-sm-390 {
    height: 390px;
  }

  .hsize-sm-400 {
    height: 400px;
  }

  .hsize-sm-410 {
    height: 410px;
  }

  .hsize-sm-420 {
    height: 420px;
  }

  .hsize-sm-430 {
    height: 430px;
  }

  .hsize-sm-440 {
    height: 440px;
  }

  .hsize-sm-450 {
    height: 450px;
  }

  .hsize-sm-460 {
    height: 460px;
  }

  .hsize-sm-470 {
    height: 470px;
  }

  .hsize-sm-480 {
    height: 480px;
  }

  .hsize-sm-490 {
    height: 490px;
  }

  .hsize-sm-500 {
    height: 500px;
  }

  .hsize-sm-510 {
    height: 510px;
  }

  .hsize-sm-520 {
    height: 520px;
  }

  .hsize-sm-530 {
    height: 530px;
  }

  .hsize-sm-540 {
    height: 540px;
  }

  .hsize-sm-550 {
    height: 550px;
  }

  .hsize-sm-560 {
    height: 560px;
  }

  .hsize-sm-570 {
    height: 570px;
  }

  .hsize-sm-580 {
    height: 580px;
  }

  .hsize-sm-590 {
    height: 590px;
  }

  .hsize-sm-600 {
    height: 600px;
  }

  .hsize-sm-610 {
    height: 610px;
  }

  .hsize-sm-620 {
    height: 620px;
  }

  .hsize-sm-630 {
    height: 630px;
  }

  .hsize-sm-640 {
    height: 640px;
  }

  .hsize-sm-650 {
    height: 650px;
  }

  .hsize-sm-660 {
    height: 660px;
  }

  .hsize-sm-670 {
    height: 670px;
  }

  .hsize-sm-680 {
    height: 680px;
  }

  .hsize-sm-690 {
    height: 690px;
  }

  .hsize-sm-700 {
    height: 700px;
  }

  /* Reset Columnized Lists */
  .list-col-reset-sm[data-columns] {
    margin-left: 0;
  }

  .list-col-reset-sm[data-columns] li {
    display: block;
    float: none;
    margin-left: 0;
    width: auto;
  }

  /*
   * Float Clearing Presets
   */
  .cleft-sm {
    clear: left;
  }

  .cright-sm {
    clear: right;
  }

  .cboth-sm {
    clear: both;
  }

  /*
   * Float None
   */
  .pull-none-sm {
    float: none !important;
  }

  /*
   * Button Padding Presets
   */
  .btn.mid-padding-sm {
    line-height: 1;
    padding: 10px 20px;
  }

  .btn.big-padding-sm {
    line-height: 1;
    padding: 12px 23px;
  }

  .btn.long-padding-sm {
    padding: 10px 30px;
  }

  /*
    * Width presets
    */
  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  /* Text Center */
  .text-center-sm {
    text-align: center;
  }

  /*
    * Resize the large fonts on small screens
    */
  .fs-xl {
    font-size: 1.5rem;
  }

  .fs-xxl {
    font-size: 1.8rem;
  }

  .fs-larger {
    font-size: 2.1rem;
  }

  .fs-xxxl {
    font-size: clamp(2rem,4vw,2.2rem);
    font-weight: 600;
  }

  .fs-xxxxl {
    font-size: 3rem;
    line-height: 1.3;
  }

  .fs-jumbo {
    font-size: 3rem;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 576px) {
  form button {
    align-self: center;
    margin-left: 15px;
  }
  /*
   * Margins Classes
   * Helper classes for side margins EXTRA SMALL
   */
  .ml-xs-0 {
    margin-left: 0 !important;
  }

  .ml-xs-5 {
    margin-left: 5px !important;
  }

  .ml-xs-10 {
    margin-left: 10px !important;
  }

  .ml-xs-15 {
    margin-left: 15px !important;
  }

  .ml-xs-20 {
    margin-left: 20px !important;
  }

  .ml-xs-25 {
    margin-left: 25px !important;
  }

  .ml-xs-30 {
    margin-left: 30px !important;
  }

  .ml-xs-35 {
    margin-left: 35px !important;
  }

  .ml-xs-40 {
    margin-left: 40px !important;
  }

  .ml-xs-45 {
    margin-left: 45px !important;
  }

  .ml-xs-50 {
    margin-left: 50px !important;
  }

  .ml-xs-60 {
    margin-left: 60px !important;
  }

  .ml-xs-70 {
    margin-left: 70px !important;
  }

  .ml-xs-80 {
    margin-left: 80px !important;
  }

  .ml-xs-90 {
    margin-left: 90px !important;
  }

  .ml-xs-100 {
    margin-left: 100px !important;
  }

  .ml-xs-110 {
    margin-left: 110px !important;
  }

  .ml-xs-120 {
    margin-left: 120px !important;
  }

  .ml-xs-130 {
    margin-left: 130px !important;
  }

  .ml-xs-140 {
    margin-left: 140px !important;
  }

  .ml-xs-150 {
    margin-left: 150px !important;
  }

  .mr-xs-0 {
    margin-right: 0 !important;
  }

  .mr-xs-5 {
    margin-right: 5px !important;
  }

  .mr-xs-10 {
    margin-right: 10px !important;
  }

  .mr-xs-15 {
    margin-right: 15px !important;
  }

  .mr-xs-20 {
    margin-right: 20px !important;
  }

  .mr-xs-25 {
    margin-right: 25px !important;
  }

  .mr-xs-30 {
    margin-right: 30px !important;
  }

  .mr-xs-35 {
    margin-right: 35px !important;
  }

  .mr-xs-40 {
    margin-right: 40px !important;
  }

  .mr-xs-45 {
    margin-right: 45px !important;
  }

  .mr-xs-50 {
    margin-right: 50px !important;
  }

  .mr-xs-60 {
    margin-right: 60px !important;
  }

  .mr-xs-70 {
    margin-right: 70px !important;
  }

  .mr-xs-80 {
    margin-right: 80px !important;
  }

  .mr-xs-90 {
    margin-right: 90px !important;
  }

  .mr-xs-100 {
    margin-right: 100px !important;
  }

  .mr-xs-110 {
    margin-right: 110px !important;
  }

  .mr-xs-120 {
    margin-right: 120px !important;
  }

  .mr-xs-130 {
    margin-right: 130px !important;
  }

  .mr-xs-140 {
    margin-right: 140px !important;
  }

  .mr-xs-150 {
    margin-right: 150px !important;
  }

  .mt-xs-0 {
    margin-top: 0 !important;
  }

  .mt-xs-5 {
    margin-top: 5px !important;
  }

  .mt-xs-10 {
    margin-top: 10px !important;
  }

  .mt-xs-15 {
    margin-top: 15px !important;
  }

  .mt-xs-20 {
    margin-top: 20px !important;
  }

  .mt-xs-25 {
    margin-top: 25px !important;
  }

  .mt-xs-30 {
    margin-top: 30px !important;
  }

  .mt-xs-35 {
    margin-top: 35px !important;
  }

  .mt-xs-40 {
    margin-top: 40px !important;
  }

  .mt-xs-45 {
    margin-top: 45px !important;
  }

  .mt-xs-50 {
    margin-top: 50px !important;
  }

  .mt-xs-60 {
    margin-top: 60px !important;
  }

  .mt-xs-70 {
    margin-top: 70px !important;
  }

  .mt-xs-80 {
    margin-top: 80px !important;
  }

  .mt-xs-90 {
    margin-top: 90px !important;
  }

  .mt-xs-100 {
    margin-top: 100px !important;
  }

  .mt-xs-110 {
    margin-top: 110px !important;
  }

  .mt-xs-120 {
    margin-top: 120px !important;
  }

  .mt-xs-130 {
    margin-top: 130px !important;
  }

  .mt-xs-140 {
    margin-top: 140px !important;
  }

  .mt-xs-150 {
    margin-top: 150px !important;
  }

  .mt-xs-200 {
    margin-top: 200px !important;
  }

  .mt-xs-250 {
    margin-top: 250px !important;
  }

  .mb-xs-0 {
    margin-bottom: 0 !important;
  }

  .mb-xs-5 {
    margin-bottom: 5px !important;
  }

  .mb-xs-10 {
    margin-bottom: 10px !important;
  }

  .mb-xs-15 {
    margin-bottom: 15px !important;
  }

  .mb-xs-20 {
    margin-bottom: 20px !important;
  }

  .mb-xs-25 {
    margin-bottom: 25px !important;
  }

  .mb-xs-30 {
    margin-bottom: 30px !important;
  }

  .mb-xs-35 {
    margin-bottom: 35px !important;
  }

  .mb-xs-40 {
    margin-bottom: 40px !important;
  }

  .mb-xs-45 {
    margin-bottom: 45px !important;
  }

  .mb-xs-50 {
    margin-bottom: 50px !important;
  }

  .mb-xs-60 {
    margin-bottom: 60px !important;
  }

  .mb-xs-70 {
    margin-bottom: 70px !important;
  }

  .mb-xs-80 {
    margin-bottom: 80px !important;
  }

  .mb-xs-90 {
    margin-bottom: 90px !important;
  }

  .mb-xs-100 {
    margin-bottom: 100px !important;
  }

  .mb-xs-110 {
    margin-bottom: 110px !important;
  }

  .mb-xs-120 {
    margin-bottom: 120px !important;
  }

  .mb-xs-130 {
    margin-bottom: 130px !important;
  }

  .mb-xs-140 {
    margin-bottom: 140px !important;
  }

  .mb-xs-150 {
    margin-bottom: 150px !important;
  }

  .mb-xs-200 {
    margin-bottom: 200px !important;
  }

  .mb-xs-250 {
    margin-bottom: 250px !important;
  }

  /*
   * Margins Classes - Negative
   * Helper classes for side margins on negative EXTRA SMALL
   */
  .mnl-xs-10 {
    margin-left: -10px !important;
  }

  .mnl-xs-20 {
    margin-left: -20px !important;
  }

  .mnl-xs-30 {
    margin-left: -30px !important;
  }

  .mnl-xs-40 {
    margin-left: -40px !important;
  }

  .mnl-xs-50 {
    margin-left: -50px !important;
  }

  .mnl-xs-60 {
    margin-left: -60px !important;
  }

  .mnl-xs-70 {
    margin-left: -70px !important;
  }

  .mnl-xs-80 {
    margin-left: -80px !important;
  }

  .mnl-xs-90 {
    margin-left: -90px !important;
  }

  .mnl-xs-100 {
    margin-left: -100px !important;
  }

  .mnl-xs-110 {
    margin-left: -110px !important;
  }

  .mnl-xs-120 {
    margin-left: -120px !important;
  }

  .mnl-xs-130 {
    margin-left: -130px !important;
  }

  .mnl-xs-140 {
    margin-left: -140px !important;
  }

  .mnl-xs-150 {
    margin-left: -150px !important;
  }

  .mnr-xs-10 {
    margin-right: -10px !important;
  }

  .mnr-xs-20 {
    margin-right: -20px !important;
  }

  .mnr-xs-30 {
    margin-right: -30px !important;
  }

  .mnr-xs-40 {
    margin-right: -40px !important;
  }

  .mnr-xs-50 {
    margin-right: -50px !important;
  }

  .mnr-xs-60 {
    margin-right: -60px !important;
  }

  .mnr-xs-70 {
    margin-right: -70px !important;
  }

  .mnr-xs-80 {
    margin-right: -80px !important;
  }

  .mnr-xs-90 {
    margin-right: -90px !important;
  }

  .mnr-xs-100 {
    margin-right: -100px !important;
  }

  .mnr-xs-110 {
    margin-right: -110px !important;
  }

  .mnr-xs-120 {
    margin-right: -120px !important;
  }

  .mnr-xs-130 {
    margin-right: -130px !important;
  }

  .mnr-xs-140 {
    margin-right: -140px !important;
  }

  .mnr-xs-150 {
    margin-right: -150px !important;
  }

  .mnt-xs-10 {
    margin-top: -10px !important;
  }

  .mnt-xs-20 {
    margin-top: -20px !important;
  }

  .mnt-xs-30 {
    margin-top: -30px !important;
  }

  .mnt-xs-40 {
    margin-top: -40px !important;
  }

  .mnt-xs-50 {
    margin-top: -50px !important;
  }

  .mnt-xs-60 {
    margin-top: -60px !important;
  }

  .mnt-xs-70 {
    margin-top: -70px !important;
  }

  .mnt-xs-80 {
    margin-top: -80px !important;
  }

  .mnt-xs-90 {
    margin-top: -90px !important;
  }

  .mnt-xs-100 {
    margin-top: -100px !important;
  }

  .mnt-xs-110 {
    margin-top: -110px !important;
  }

  .mnt-xs-120 {
    margin-top: -120px !important;
  }

  .mnt-xs-130 {
    margin-top: -130px !important;
  }

  .mnt-xs-140 {
    margin-top: -140px !important;
  }

  .mnt-xs-150 {
    margin-top: -150px !important;
  }

  .mnb-xs-10 {
    margin-bottom: -10px !important;
  }

  .mnb-xs-20 {
    margin-bottom: -20px !important;
  }

  .mnb-xs-30 {
    margin-bottom: -30px !important;
  }

  .mnb-xs-40 {
    margin-bottom: -40px !important;
  }

  .mnb-xs-50 {
    margin-bottom: -50px !important;
  }

  .mnb-xs-60 {
    margin-bottom: -60px !important;
  }

  .mnb-xs-70 {
    margin-bottom: -70px !important;
  }

  .mnb-xs-80 {
    margin-bottom: -80px !important;
  }

  .mnb-xs-90 {
    margin-bottom: -90px !important;
  }

  .mnb-xs-100 {
    margin-bottom: -100px !important;
  }

  .mnb-xs-110 {
    margin-bottom: -110px !important;
  }

  .mnb-xs-120 {
    margin-bottom: -120px !important;
  }

  .mnb-xs-130 {
    margin-bottom: -130px !important;
  }

  .mnb-xs-140 {
    margin-bottom: -140px !important;
  }

  .mnb-xs-150 {
    margin-bottom: -150px !important;
  }

  /*
   * Padding Classes
   * Helper classes for side padding EXTRA SMALL
   */
  .pl-xs-0 {
    padding-left: 0 !important;
  }

  .pl-xs-5 {
    padding-left: 5px !important;
  }

  .pl-xs-10 {
    padding-left: 10px !important;
  }

  .pl-xs-15 {
    padding-left: 15px !important;
  }

  .pl-xs-20 {
    padding-left: 20px !important;
  }

  .pl-xs-25 {
    padding-left: 25px !important;
  }

  .pl-xs-30 {
    padding-left: 30px !important;
  }

  .pl-xs-35 {
    padding-left: 35px !important;
  }

  .pl-xs-40 {
    padding-left: 40px !important;
  }

  .pl-xs-45 {
    padding-left: 45px !important;
  }

  .pl-xs-50 {
    padding-left: 50px !important;
  }

  .pl-xs-60 {
    padding-left: 60px !important;
  }

  .pl-xs-70 {
    padding-left: 70px !important;
  }

  .pl-xs-80 {
    padding-left: 80px !important;
  }

  .pl-xs-90 {
    padding-left: 90px !important;
  }

  .pl-xs-100 {
    padding-left: 100px !important;
  }

  .pl-xs-110 {
    padding-left: 110px !important;
  }

  .pl-xs-120 {
    padding-left: 120px !important;
  }

  .pl-xs-130 {
    padding-left: 130px !important;
  }

  .pl-xs-140 {
    padding-left: 140px !important;
  }

  .pl-xs-150 {
    padding-left: 150px !important;
  }

  .pr-xs-0 {
    padding-right: 0 !important;
  }

  .pr-xs-5 {
    padding-right: 5px !important;
  }

  .pr-xs-10 {
    padding-right: 10px !important;
  }

  .pr-xs-15 {
    padding-right: 15px !important;
  }

  .pr-xs-20 {
    padding-right: 20px !important;
  }

  .pr-xs-25 {
    padding-right: 25px !important;
  }

  .pr-xs-30 {
    padding-right: 30px !important;
  }

  .pr-xs-35 {
    padding-right: 35px !important;
  }

  .pr-xs-40 {
    padding-right: 40px !important;
  }

  .pr-xs-45 {
    padding-right: 45px !important;
  }

  .pr-xs-50 {
    padding-right: 50px !important;
  }

  .pr-xs-60 {
    padding-right: 60px !important;
  }

  .pr-xs-70 {
    padding-right: 70px !important;
  }

  .pr-xs-80 {
    padding-right: 80px !important;
  }

  .pr-xs-90 {
    padding-right: 90px !important;
  }

  .pr-xs-100 {
    padding-right: 100px !important;
  }

  .pr-xs-110 {
    padding-right: 110px !important;
  }

  .pr-xs-120 {
    padding-right: 120px !important;
  }

  .pr-xs-130 {
    padding-right: 130px !important;
  }

  .pr-xs-140 {
    padding-right: 140px !important;
  }

  .pr-xs-150 {
    padding-right: 150px !important;
  }

  .pt-xs-0 {
    padding-top: 0 !important;
  }

  .pt-xs-5 {
    padding-top: 5px !important;
  }

  .pt-xs-10 {
    padding-top: 10px !important;
  }

  .pt-xs-15 {
    padding-top: 15px !important;
  }

  .pt-xs-20 {
    padding-top: 20px !important;
  }

  .pt-xs-25 {
    padding-top: 25px !important;
  }

  .pt-xs-30 {
    padding-top: 30px !important;
  }

  .pt-xs-35 {
    padding-top: 35px !important;
  }

  .pt-xs-40 {
    padding-top: 40px !important;
  }

  .pt-xs-45 {
    padding-top: 45px !important;
  }

  .pt-xs-50 {
    padding-top: 50px !important;
  }

  .pt-xs-60 {
    padding-top: 60px !important;
  }

  .pt-xs-70 {
    padding-top: 70px !important;
  }

  .pt-xs-80 {
    padding-top: 80px !important;
  }

  .pt-xs-90 {
    padding-top: 90px !important;
  }

  .pt-xs-100 {
    padding-top: 100px !important;
  }

  .pt-xs-110 {
    padding-top: 110px !important;
  }

  .pt-xs-120 {
    padding-top: 120px !important;
  }

  .pt-xs-130 {
    padding-top: 130px !important;
  }

  .pt-xs-140 {
    padding-top: 140px !important;
  }

  .pt-xs-150 {
    padding-top: 150px !important;
  }

  .pt-xs-200 {
    padding-top: 200px !important;
  }

  .pt-xs-250 {
    padding-top: 250px !important;
  }

  .pb-xs-0 {
    padding-bottom: 0 !important;
  }

  .pb-xs-5 {
    padding-bottom: 5px !important;
  }

  .pb-xs-10 {
    padding-bottom: 10px !important;
  }

  .pb-xs-15 {
    padding-bottom: 15px !important;
  }

  .pb-xs-20 {
    padding-bottom: 20px !important;
  }

  .pb-xs-25 {
    padding-bottom: 25px !important;
  }

  .pb-xs-30 {
    padding-bottom: 30px !important;
  }

  .pb-xs-35 {
    padding-bottom: 35px !important;
  }

  .pb-xs-40 {
    padding-bottom: 40px !important;
  }

  .pb-xs-45 {
    padding-bottom: 45px !important;
  }

  .pb-xs-50 {
    padding-bottom: 50px !important;
  }

  .pb-xs-60 {
    padding-bottom: 60px !important;
  }

  .pb-xs-70 {
    padding-bottom: 70px !important;
  }

  .pb-xs-80 {
    padding-bottom: 80px !important;
  }

  .pb-xs-90 {
    padding-bottom: 90px !important;
  }

  .pb-xs-100 {
    padding-bottom: 100px !important;
  }

  .pb-xs-110 {
    padding-bottom: 110px !important;
  }

  .pb-xs-120 {
    padding-bottom: 120px !important;
  }

  .pb-xs-130 {
    padding-bottom: 130px !important;
  }

  .pb-xs-140 {
    padding-bottom: 140px !important;
  }

  .pb-xs-150 {
    padding-bottom: 150px !important;
  }

  .pb-xs-200 {
    padding-bottom: 200px !important;
  }

  .pb-xs-250 {
    padding-bottom: 250px !important;
  }

  /*
   * Font-sizes-presets
   */
  .fs-xs-normal {
    font-size: 13px;
  }

  .fs-xs-smaller {
    font-size: 15px;
  }

  .fs-xs-small {
    font-size: 16px;
  }

  .fs-xs-m {
    font-size: 18px;
  }

  .fs-xs-l {
    font-size: 20px;
  }

  .fs-xs-xl {
    font-size: 24px;
  }

  .fs-xs-xxl {
    font-size: 32px;
  }

  .fs-xs-larger {
    font-size: 42px;
  }

  .fs-xs-xxxl {
    font-size: 56px;
  }

  .fs-xs-xxxxl {
    font-size: 75px;
  }

  .fs-xs-jumbo {
    font-size: 80px;
  }

  .fs-xs-huge {
    font-size: 120px;
  }

  .fs-xs-immense {
    font-size: 150px;
  }

  .fs-xs-crazy {
    font-size: 200px;
  }

  /*
   * Height Sizing Presets
   * These are height values from 10 to 10 to set responsive height to a block
   */
  .hsize-xs-reset {
    height: 0 !important;
  }

  .hsize-xs-10 {
    height: 10px;
  }

  .hsize-xs-20 {
    height: 20px;
  }

  .hsize-xs-30 {
    height: 30px;
  }

  .hsize-xs-40 {
    height: 40px;
  }

  .hsize-xs-50 {
    height: 50px;
  }

  .hsize-xs-60 {
    height: 60px;
  }

  .hsize-xs-70 {
    height: 70px;
  }

  .hsize-xs-80 {
    height: 80px;
  }

  .hsize-xs-90 {
    height: 90px;
  }

  .hsize-xs-100 {
    height: 100px;
  }

  .hsize-xs-110 {
    height: 110px;
  }

  .hsize-xs-120 {
    height: 120px;
  }

  .hsize-xs-130 {
    height: 130px;
  }

  .hsize-xs-140 {
    height: 140px;
  }

  .hsize-xs-150 {
    height: 150px;
  }

  .hsize-xs-160 {
    height: 160px;
  }

  .hsize-xs-170 {
    height: 170px;
  }

  .hsize-xs-180 {
    height: 180px;
  }

  .hsize-xs-190 {
    height: 190px;
  }

  .hsize-xs-200 {
    height: 200px;
  }

  .hsize-xs-210 {
    height: 210px;
  }

  .hsize-xs-220 {
    height: 220px;
  }

  .hsize-xs-230 {
    height: 230px;
  }

  .hsize-xs-240 {
    height: 240px;
  }

  .hsize-xs-250 {
    height: 250px;
  }

  .hsize-xs-260 {
    height: 260px;
  }

  .hsize-xs-270 {
    height: 270px;
  }

  .hsize-xs-280 {
    height: 280px;
  }

  .hsize-xs-290 {
    height: 290px;
  }

  .hsize-xs-300 {
    height: 300px;
  }

  .hsize-xs-310 {
    height: 310px;
  }

  .hsize-xs-320 {
    height: 320px;
  }

  .hsize-xs-330 {
    height: 330px;
  }

  .hsize-xs-340 {
    height: 340px;
  }

  .hsize-xs-350 {
    height: 350px;
  }

  .hsize-xs-360 {
    height: 360px;
  }

  .hsize-xs-370 {
    height: 370px;
  }

  .hsize-xs-380 {
    height: 380px;
  }

  .hsize-xs-390 {
    height: 390px;
  }

  .hsize-xs-400 {
    height: 400px;
  }

  .hsize-xs-410 {
    height: 410px;
  }

  .hsize-xs-420 {
    height: 420px;
  }

  .hsize-xs-430 {
    height: 430px;
  }

  .hsize-xs-440 {
    height: 440px;
  }

  .hsize-xs-450 {
    height: 450px;
  }

  .hsize-xs-460 {
    height: 460px;
  }

  .hsize-xs-470 {
    height: 470px;
  }

  .hsize-xs-480 {
    height: 480px;
  }

  .hsize-xs-490 {
    height: 490px;
  }

  .hsize-xs-500 {
    height: 500px;
  }

  .hsize-xs-510 {
    height: 510px;
  }

  .hsize-xs-520 {
    height: 520px;
  }

  .hsize-xs-530 {
    height: 530px;
  }

  .hsize-xs-540 {
    height: 540px;
  }

  .hsize-xs-550 {
    height: 550px;
  }

  .hsize-xs-560 {
    height: 560px;
  }

  .hsize-xs-570 {
    height: 570px;
  }

  .hsize-xs-580 {
    height: 580px;
  }

  .hsize-xs-590 {
    height: 590px;
  }

  .hsize-xs-600 {
    height: 600px;
  }

  .hsize-xs-610 {
    height: 610px;
  }

  .hsize-xs-620 {
    height: 620px;
  }

  .hsize-xs-630 {
    height: 630px;
  }

  .hsize-xs-640 {
    height: 640px;
  }

  .hsize-xs-650 {
    height: 650px;
  }

  .hsize-xs-660 {
    height: 660px;
  }

  .hsize-xs-670 {
    height: 670px;
  }

  .hsize-xs-680 {
    height: 680px;
  }

  .hsize-xs-690 {
    height: 690px;
  }

  .hsize-xs-700 {
    height: 700px;
  }

  /* Reset Columnized Lists */
  .list-col-reset-xs[data-columns] {
    margin-left: 0;
  }

  .list-col-reset-xs[data-columns] li {
    display: block;
    float: none;
    margin-left: 0;
    width: auto;
  }

  /*
   * Float Clearing Presets
   */
  .cleft-xs {
    clear: left;
  }

  .cright-xs {
    clear: right;
  }

  .cboth-xs {
    clear: both;
  }

  /*
   * Float None
   */
  .pull-none-xs {
    float: none !important;
  }

  /*
    * Width presets
    */
  .w-xs-10 {
    width: 10% !important;
  }

  .w-xs-20 {
    width: 20% !important;
  }

  .w-xs-30 {
    width: 30% !important;
  }

  .w-xs-40 {
    width: 40% !important;
  }

  .w-xs-50 {
    width: 50% !important;
  }

  .w-xs-60 {
    width: 60% !important;
  }

  .w-xs-70 {
    width: 70% !important;
  }

  .w-xs-80 {
    width: 80% !important;
  }

  .w-xs-90 {
    width: 90% !important;
  }

  .w-xs-100 {
    width: 100% !important;
  }

  /* Text Center */
  .text-center-xs {
    text-align: center !important;
  }

  .p-left-6 {
    padding-left: 0;
  }

  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .th-google_map {
    margin-top: 50px;
  }
}

.kl-slideshow-css3panels { /*overflow: hidden;*/
  position: relative;
}

.css3panels-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: -62px;
  margin-right: -62px;
  min-height: 150px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

/* Panels */
.css3panel {
  -webkit-backface-visibility: hidden;
  flex: 1;
  float: left;
  height: 500px;
  position: relative;
  /* Chrome Hacks */
  z-index: 0;
}

.css3panel:hover {
  z-index: 1;
}

.css3panel:hover .css3panel-inner {
  box-shadow:0 0 50px 20px rgba(0, 0, 0, 0.5);
}

.css3panels--resize .css3panel {
  transition: flex-grow .2s ease-out;
  will-change: flex-grow;
}

.css3panels--resize .css3panel-inner {
  transition: box-shadow .2s ease-out;
  will-change: box-shadow;
}

.css3panels--resize .css3panel:not(:first-child):not(:last-child):hover {
  flex-grow: 1.3;
  -ms-flex-positive: 1.3;
}

/* Panel width sizes */
.no-flexbox .css3panels-container[data-panels="1"] .css3panel {
  float: none;
  width: 100%;
}

.no-flexbox .css3panels-container[data-panels="2"] .css3panel {
  width: 50%;
}

.no-flexbox .css3panels-container[data-panels="3"] .css3panel {
  width: 33.3333%;
}

.no-flexbox .css3panels-container[data-panels="4"] .css3panel {
  width: 25%;
}

.no-flexbox .css3panels-container[data-panels="5"] .css3panel {
  width: 20%;
}

.no-flexbox .css3panels-container[data-panels="6"] .css3panel {
  width: 16.6666%;
}

/* Hover resize */
.no-flexbox .css3panels-container.css3panels--resize[data-panels="2"]:hover .css3panel {
  width: 40%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="2"]:hover .css3panel:hover {
  width: 60%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="3"]:hover .css3panel {
  width: 30%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="3"]:hover .css3panel:hover {
  width: 40%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="4"]:hover .css3panel {
  width: 22%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="4"]:hover .css3panel:hover {
  width: 34%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="5"]:hover .css3panel {
  width: 16%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="5"]:hover .css3panel:hover {
  width: 36%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="6"]:hover .css3panel {
  width: 15%;
}

.no-flexbox .css3panels-container.css3panels--resize[data-panels="6"]:hover .css3panel:hover {
  width: 25%;
}

/* Inner panel */
.css3panel-inner {
  height:100%;
  overflow: hidden;
  position: relative;
  transition: box-shadow .2s ease-out;
}

/* Image */
.css3panel-mainimage-wrapper {
  -webkit-backface-visibility: hidden;
  height:100%;
  margin-left: -60px;
  margin-right: -60px;
  position: relative;
  transform-style: preserve-3d;
}

.css3panel-mainimage {
  -webkit-backface-visibility: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -2px;
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
  transition: .2s ease-out;
}

/* Image overlay */
.css3p-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .15s ease-out;
}

.css3panel:hover .css3p-overlay {
  opacity: 0;
}

.css3p-overlay--gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  opacity: 0.5;
  top: 50%;
}

/* Image animations */
.css3panel-mainimage.anim--grayscale {
  filter: grayscale(100%);
  transition: opacity .15s ease-out;
}

.css3panel:hover .css3panel-mainimage.anim--grayscale {
  opacity: 0;
}

.css3panel-mainimage.anim--blur {
  filter: blur(9px);
  transition: opacity .15s ease-out;
}

.css3panel:hover .css3panel-mainimage.anim--blur {
  opacity: 0;
}

.css3panel-mainimage.anim--grayscale.anim--blur {
  filter: grayscale(100%) blur(9px);
  transition: opacity .15s ease-out;
}

.css3panel:hover .css3panel-mainimage.anim--grayscale.anim--blur {
  opacity: 0;
}

.css3panel-caption {
  color: #FFFFFF;
  padding: 100px 50px;
  text-align: right;
  width: 100%;
  z-index: 1;
}

.css3panel--2 .css3panel-caption {
  text-align:left;
}

.css3panel-caption p {
  display: inline-block;
  padding-top: 30px;
}

.css3panel:first-child .css3panel-caption {
  padding-left: 80px;
}

.css3panel:last-child .css3panel-caption {
  padding-right: 80px;
}

.css3panel-caption.css3caption--middle {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.css3panel-title {
  font-family: var(--heading-font);
}

/* Fading in Caption */
.cssp-capt-fadein .css3panel-title,
.cssp-capt-fadein .css3panel-text,
.cssp-capt-fadein .css3panel-btn-area {
  opacity: 0;
  transition: opacity .15s ease-out, visibility .15s ease-out;
  visibility: hidden;
}

.cssp-capt-fadein .css3panel:hover .css3panel-title,
.cssp-capt-fadein .css3panel:hover .css3panel-text,
.cssp-capt-fadein .css3panel:hover .css3panel-btn-area {
  opacity: 1;
  visibility: visible;
}

/* Fading Out Caption */
.cssp-capt-fadeout .css3panel-title,
.cssp-capt-fadeout .css3panel-text,
.cssp-capt-fadeout .css3panel-btn-area {
  opacity: 1;
  transition: opacity .15s ease-out, visibility .15s ease-out;
  visibility: visible;
}

.cssp-capt-fadeout .css3panel:hover .css3panel-title,
.cssp-capt-fadeout .css3panel:hover .css3panel-text {
  opacity: 0;
  visibility: hidden;
}

.cssp-capt-fadeout .css3panel:hover .css3panel-btn-area {
}

/* Slide in Caption */
.cssp-capt-slidein .css3panel-title,
.cssp-capt-slidein .css3panel-text,
.cssp-capt-slidein .css3panel-btn-area {
  opacity: 0;
  transform: translateX(200px);
  transition: opacity .15s ease-out, visibility .15s ease-out, transform .15s ease-out;
  visibility: hidden;
}

.cssp-capt-slidein .css3panel:hover .css3panel-title,
.cssp-capt-slidein .css3panel:hover .css3panel-text,
.cssp-capt-slidein .css3panel:hover .css3panel-btn-area {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}

/* Slide Out Caption */
.cssp-capt-slideout .css3panel-title,
.cssp-capt-slideout .css3panel-text,
.cssp-capt-slideout .css3panel-btn-area {
  opacity: 1;
  transform: translateX(0);
  transition: opacity .15s ease-out, visibility .15s ease-out, transform .15s ease-out;
  visibility: visible;
}

.cssp-capt-slideout .css3panel:hover .css3panel-title,
.cssp-capt-slideout .css3panel:hover .css3panel-text {
  opacity: 0;
  transform: translateX(200px);
  visibility: hidden;
}

.cssp-capt-slideout .css3panel:hover .css3panel-btn-area {
  transform: translateY(40px) translateX(-10px);
  transition-delay: .1s !important;
  transition-duration: .3s !important;
}

.cssp-capt-slideout .css3panel:hover .css3p-overlay {
  opacity: 0;
}

/* Delay individually */
.cssp-capt-animated .css3panel-title {
  transition-delay: .1s;
}

.cssp-capt-animated .css3panel-text {
  transition-delay: .2s;
}

.cssp-capt-animated .css3panel-btn-area {
  transition-delay: 0s;
}

.cssp-capt-animated .css3panel:hover .css3panel-btn-area {
  transition-delay: .3s;
}

.cssp-capt-animated .css3p-overlay {
  transition-delay: .1s;
}

.css3panel--1 {
  background: #231f20;
  overflow: hidden;
}
.css3panel--2 {
  background: #8f1818;
  overflow: hidden;
}

/* Css Title */
.css3panel-title {
  color: #FFF;
  float: right;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
  position: relative;
}

.css3panel--2 .css3panel-title, .css3panel--2 .css3panel-btn-area {
  float: left;
}

.css3panel-title.title-size-bigger {
  font-size: 70px;
  letter-spacing: -2px;
  line-height: 1.1;
  text-shadow: 1px 1px 50px rgba(0, 0, 0, 0.4);
}

.css3panel:last-child .css3panel-title {
  margin-right: 10px;
}

/* Panel text*/
.css3panel-text {
  clear: both;
  color: #fff;
  float: right;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  margin-right: 20px;
  margin-top: 20px;
  max-width: 380px;
}

/* Dark colors */
.cp-theme--dark .css3panel-title:not(.captiontitle--wbg):not(.captiontitle--dbg),
.cp-theme--dark .css3panel-text {
  color: #252525;
}

/* Title presets */
.captiontitle--wbg { /* color:#181818; */
}

.captiontitle--wbg,
.captiontitle--dbg {
  padding: 13px 0px 13px 45px;
}

.captiontitle--wbg:before,
.captiontitle--dbg:before {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 80%);
  border-radius: 4px;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  padding-right: 35px;
  position: absolute;
  right: -35px;
  top: 0;
  transform-style: preserve-3d;
  z-index: -1;
}

.captiontitle--wbg:before {
  background: rgba(255, 255, 255, 0.9);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.25) 40%, rgba(255, 255, 255, 0) 100%);
}

/* Btn area */
.css3panel-btn-area {
  clear: both;
  float: right;
  margin-right: 20px;
  margin-top: 30px;
}

.css3panel-btn-area .btn {
  margin-bottom: 10px;
  margin-left: 20px;
}

@media (max-width: 1440px) {
  .css3panel-caption {
    padding-left: 50px;
  }

  .css3panel:first-child,
  .css3panel:last-child {
    flex-grow: 1.1;
    -ms-flex-positive: 1.1;
  }

  .css3panel .css3panel-title {
    font-size: 18px;
    margin-right: 30px;
  }

  .css3panel .css3panel-text {
    font-size: 13px;
    margin-right: 10px;
  }

  .css3panel .css3panel-btn-area {
    margin-right: 10px;
  }

  .css3panel .css3panel-btn-area .btn {
    display: block;
    margin-bottom: 10px;
  }

  .css3panel .css3panel-title.title-size-bigger {
    font-size: 30px;
  }
}

@media (max-width: 767.99px) {

  .pricing-table-element .plan-column.featured {
    background: none;
    box-shadow: none;
    margin: 0;
  }

  .pricing-table-element .plan-column {
    width:50%
  }

  .pricing-table-element .plan-column .inner-cell:empty {
    display: none;
  }

  .fontawesome-i2svg-complete .text_box .pricing-table-element ul > li svg {
    display:none
  }

  .mobileDescriptor {
    text-align: center;
    width: 100%;
    display: inline-block;
  }

  .mobileDescriptor.table--subheading {
    font-weight: 800;
    color: #333;
  }

  .captiontitle--wbg,
  .captiontitle--dbg {
    padding: 10px 0 10px 25px;
  }

  .pricing-table-element .plan-column .inner-cell {
    padding:8px
  }

  .pricing-table-element .plan-column {
    background: transparent;
    flex-wrap: wrap;
    padding: 12px 0;
    border: none;
  }

  .pricing-table-element ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background: #fff;
    box-shadow: 2px 2px 8px rgba(51, 51, 51, .1);
  }

}

@media (max-width: 480px) {
  .css3panel:first-child {
    box-sizing: border-box;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
  }

  .css3panel:first-child .css3panel-inner {
    top: auto;
  }

  .css3panel:first-child .css3panel-caption {
    margin-top: 0;
  }

  .css3panel-text {
    display: none;
  }

  .css3panel-btn-area {
    clear: right;
    float: right;
    left: 0;
    margin-right: 0;
    margin-top: 20px;
    max-width: 100%;
    position: relative;
    text-align: left;
    top: 0;
  }

}

@media (max-width: 537px) {
  .kl-title-block .lined-custom {
    margin-top: 20px;
  }
}
@media (max-width: 1500px) and (min-width: 1202px){
  .kl-title-block .lined-custom {
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .pricing-table-element .plan-column {
    flex-wrap: wrap;
  }
}

@media (min-width: 538px) and (max-width:767.99px) {
  .pricing-table-element[data-columns="3"] .plan-column,
  .pricing-table-element[data-columns="5"] .plan-column {
    width: 50%
  }
}

@media (max-width: 537.99px) {
  .pricing-table-element[data-columns="3"] .plan-column,
  .pricing-table-element[data-columns="5"] .plan-column {
    width: 100%
  }
}
footer .sbs {
  display: inline-block;
}

.contact-field {
  margin-bottom: 15px;
}

.contact-field input,
.contact-field textarea{
  width: 100%;
  background: #ebebeb;
  padding: 14px;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
}

.contact-form .formError {
  border:none;
  border-bottom: 3px solid #ff4d4d;
}
.formResult {
  text-align: center;
  margin: 20px 0 0 0;
  padding: 10px 25px;
  font-size: 15px;
}
.formSuccess {
  border: 2px solid #568f43;
}
.formFail {
  border: 2px solid #ff4d4d;
}
.invalid-label {
  padding-left: 10px;
  white-space: nowrap;
}
.send-btn {
  position: relative;
  margin-bottom: 15px;
}
.btnMainText {
  opacity: 1;
  transition: opacity .2s;
}
.btnProcessing {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity .3s;
}
.processing .btnMainText {
  opacity: 0;
}
.processing .btnProcessing {
  opacity: 1;
}
.grecaptcha-badge {
  visibility: hidden;
}

.contactBox {
  box-shadow: 5px 5px 11px #ccc, -5px -5px 10px #f5f5f5;
  margin-bottom: 20px;
  padding: 30px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.contactBox h3 {
  margin-top: 0;
}

label[for="formCommunication"] {
  padding-left: 14px;
}
